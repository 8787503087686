import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { useDispatch, useSelector } from 'react-redux'
import { togglePopup } from '@actions/'
import { isPopUpOpen } from '@selectors/ui'
import UploadFileArea from '@components/uploadFileArea'

export const UPLOAD_FILE_POPUPS = {
  UPLOAD_SAML_FILE_POPUP: 'UPLOAD_SAML_FILE_POPUP'
}

const UploadFilePopup = (props) => {
  const {
    popupName,
    popupHeader,
    popupSubHeader,
    popupInstructions,
    validFileTypes,
    fileDescription,
    uploadedFileType,
    successMessage,
    postUploadAction,
    uploadEventType
  } = props

  // Using the alwaysVisible is a workaround to make storybook to work without need for redux
  const isOpen = useSelector((state) => isPopUpOpen(state, popupName))

  const [showFooter, setShowFooter] = useState(false)
  const [isError, setIsError] = useState(false)

  const dispatch = useDispatch()

  const onReset = () => {
    setShowFooter(false)
    setIsError(false)
  }

  const onClosing = () => {
    onReset()
    dispatch(togglePopup(popupName, false))
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClosing}>
      <ToriiPopup.Header header={popupHeader} subHeader={popupSubHeader} />
      <ToriiPopup.Content>
        <UploadFileArea
          popupInstructions={popupInstructions}
          validFileTypes={validFileTypes}
          fileDescription={fileDescription}
          uploadedFileType={uploadedFileType}
          successMessage={successMessage}
          onIsError={setIsError}
          onShowFooter={setShowFooter}
          isError={isError}
          postUploadAction={postUploadAction}
          uploadEventType={uploadEventType}
        />
      </ToriiPopup.Content>
      {showFooter && (
        <ToriiPopup.Footer
          showCancelButton={isError}
          cancelButtonText={'Cancel'}
          mainButtonText={isError ? 'Try again' : 'Done'}
          mainButtonAction={isError ? onReset : onClosing}
        />
      )}
    </ToriiPopup>
  )
}

UploadFilePopup.propTypes = {
  popupName: PropTypes.string.isRequired,
  popupHeader: PropTypes.string.isRequired,
  popupSubHeader: PropTypes.string.isRequired,
  popupInstructions: PropTypes.string.isRequired,
  validFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileDescription: PropTypes.string.isRequired,
  uploadedFileType: PropTypes.string.isRequired,
  successMessage: PropTypes.object.isRequired,
  postUploadAction: PropTypes.func
}

export default UploadFilePopup
