import React, { Fragment, useEffect } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import TriggerPreviewTable from '@components/popups/triggerPreviewPopup/triggerPreviewTable'
import { useDispatch, useSelector } from 'react-redux'
import { getTriggerPreview, toggleTriggerPreviewPopup } from '@actions/'
import {
  getTriggerPreview as getTriggerPreviewSelector,
  hasUnsavedChanges,
  isUpdatingWorkflows
} from '@selectors/workflows'
import { EMPTY_OBJECT } from '@root/constants'
import { getTriggerPreviewPropsByTriggerType } from '@lenses/workflows'
import { getIdOrg } from '@selectors/org'

const TriggerPreviewPopup = () => {
  const { isOpen, idWorkflow, triggerPreviewConfig: { header, subHeader }, workflowName } = useSelector(state => state.ui.triggerPreviewPopup)
  const idOrg = useSelector(getIdOrg)

  const workflowsTriggerPreview = useSelector(getTriggerPreviewSelector)
  const { triggerType } = workflowsTriggerPreview[idWorkflow] || EMPTY_OBJECT
  const workflowHasUnsavedChanges = useSelector(hasUnsavedChanges)
  const isWorkflowUpdateInProgress = useSelector(isUpdatingWorkflows)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isWorkflowUpdateInProgress && !workflowHasUnsavedChanges) {
      dispatch(getTriggerPreview({ idOrg, idWorkflow }))
    }
  }, [dispatch, isWorkflowUpdateInProgress, workflowHasUnsavedChanges, idOrg, idWorkflow])

  const onCancel = () => {
    dispatch(toggleTriggerPreviewPopup(false))
  }

  const { popupWidth, getTheadThProps } = getTriggerPreviewPropsByTriggerType(triggerType)

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onCancel} width={popupWidth}>
      <ToriiPopup.Header header={header} subHeader={subHeader || workflowName} />
      <ToriiPopup.Content>
        <Fragment>
          <TriggerPreviewTable idWorkflow={idWorkflow} getTheadThProps={getTheadThProps} />
        </Fragment>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton={false}
        mainButtonText='Close'
        mainButtonAction={onCancel}
      />
    </ToriiPopup>
  )
}

export default TriggerPreviewPopup
