import { Button, ButtonType, Icon } from '@toriihq/design-system'
import React, { ReactElement, useState } from 'react'
import { Styles } from './style'
import { useSelector, useDispatch } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import Analytics from '@components/popups/configureMergeUsersRulesPopup/analytics'
import { sendMergeUsersPreviewReport } from '@actions/org'
import { MERGE_USERS_RULES_TYPE, MergeUsersRulesAliasConfig } from '@components/mergeUsersRules/types'

type Props = {
  isRevokeMode: boolean,
  previewRule?: {
    type: MERGE_USERS_RULES_TYPE,
    config: MergeUsersRulesAliasConfig
  }
}

export const MergeUsersReport = ({ isRevokeMode, previewRule }: Props): ReactElement => {
  const dispatch = useDispatch()
  const [reportSuccess, setReportSuccess] = useState<boolean>(false)
  const idOrg = useSelector(getIdOrg)

  const onReportClick = async () => {
    await dispatch(sendMergeUsersPreviewReport({ idOrg, previewRule, isRevokeMode }))
    setReportSuccess(true)
    Analytics.onSendReportClick({ idOrg })
  }

  return reportSuccess
    ? <Styles.RequestSent>
      <Icon name={'CheckCircle'} color={'secondary'} />
      We’re on it! check your email in a few minutes
    </Styles.RequestSent>
    : <Button icon={'Mail'} label={'Email full changes report'} onClick={onReportClick} type={ButtonType.secondary} />
}
