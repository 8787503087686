import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getUserTasks as getUserTasksSelector, isLoadingUserTasks, isShowOffboardingMessage, isUserTasksAuthError, getOffboardingMessage, isUserTasksUpdateError } from '@selectors/instantAccess'
import { getUserTasks, updateTaskCompletionStatusInstantAccess, toggleShowOffboardingMessage } from '@actions/'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idUser = ownProps.match.params.idUser ? parseInt(ownProps.match.params.idUser, 10) : null
  const { id, secret } = ownProps.match.params

  const loading = isLoadingUserTasks(state)
  const { apps = {}, users = {}, tasks = [], accessInfo, isLoaded } = getUserTasksSelector(state)[idUser] || {}
  const isAuthError = isUserTasksAuthError(state)
  const isUpdateError = isUserTasksUpdateError(state)
  const isCompletedAllTasks = isLoaded && tasks.length === 0
  const isShowMessage = isShowOffboardingMessage(state) || false
  const message = getOffboardingMessage(state)

  return {
    token: `${id}-${secret}`,
    idOrg,
    idUser,
    apps,
    users,
    tasks,
    isAuthError,
    isUpdateError,
    loading: loading && !isLoaded,
    isCompletedAllTasks,
    isShowMessage,
    message,
    accessInfo
  }
}

const mapDispatchToProps = {
  getUserTasks,
  updateTaskCompletionStatusInstantAccess,
  toggleShowOffboardingMessage
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idUser, token } = stateProps
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    updateTaskCompletionStatus: ({ idTask, isCompleted }) => dispatchProps.updateTaskCompletionStatusInstantAccess({ idOrg, idUser, token, idTask, isCompleted })
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
