import { createSelector } from 'reselect'
import { TABLES, USER_FIELDS_TYPES } from '@root/constants'
import identity from 'lodash/identity'
import map from 'lodash/map'

export const getUserDetailsFieldsSelector = createSelector(
  state => state.userDetails.fields,
  identity
)

export const getUserFieldsDisplayNamesSelector = createSelector(
  state => state.userDetails.fieldsDisplayNames,
  identity
)

export const getChargebackUserDetailsFieldsAsSelectOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (userFields) => userFields.filter(userField => ![USER_FIELDS_TYPES.datePicker, USER_FIELDS_TYPES.email, USER_FIELDS_TYPES.multiValueString].includes(userField.type))
    .map(userField => ({ value: userField.key, label: userField.name }))
)

export const getUserConfigurableColumnsOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .map(field => ({ value: field.key.toString(), label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.usersTable
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getPastUserConfigurableColumnsOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .map(field => ({ value: field.key.toString(), label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.deletedUsersTable
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getAppUserConfigurableColumnsOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .map(field => ({ value: field.key.toString(), label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.appUsersTable
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getAppActiveUserConfigurableColumnsOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .map(field => ({ value: field.key.toString(), label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.appActiveUsersListPopup
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getInactiveUserConfigurableColumnsOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .map(field => ({ value: field.key.toString(), label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.inactiveUsersListPopup
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getInactiveLicensesColumnsOptions = createSelector(
  [getUserDetailsFieldsSelector],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .map(field => ({ value: field.key.toString(), label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.licenseRecommendationsTable
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)
