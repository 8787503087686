import { SharedProps, SharedState, WIZARD_STEPS } from './types'
import { PopupStepConfig } from '../../wizardPopup/types'
import DeletionConfirmation from './deletionConfirmation'
import LoadingAfterDeletion from './loadingAfterDeletion'
import DeletionFailure from './deletionFailure'

export const stepsConfig = (): PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS>[] => {
  const steps = [
    { stepName: WIZARD_STEPS.CONFIRMATION, stepRenderer: DeletionConfirmation },
    { stepName: WIZARD_STEPS.LOADING_AFTER_DELETION, stepRenderer: LoadingAfterDeletion },
    { stepName: WIZARD_STEPS.FAILURE, stepRenderer: DeletionFailure }
  ]
  return steps
}
