import React from 'react'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import PieChart from '../../pieChartBox/pieChart'
import HoverableHeader from '../../hoverableHeader'
import CustomTooltip from '../../expensesSummary/customTooltip'
import AllExpenseCategoriesPopup from '../../allExpenseCategoriesPopup'
import { Button, ButtonType, ButtonSize, theme } from '@toriihq/design-system'
import pluralize from 'pluralize'
import { fontSize } from '../../../shared/style/sizes'
import PropTypes from 'prop-types'
import Currency from '@root/components/currency'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const CSS = {
  main: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  header: css({
    minHeight: '50px',
    padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
    borderBottom: `1px solid ${theme.palette.border.primary}`
  }),
  chartsContainer: css({
    height: `calc(100% - ${HEADER_HEIGHT})`
  }),
  tooltipAppCount: css({
    color: colors.grey2,
    fontSize: fontSize.small,
    textTransform: 'none'
  })
}

const className = 'annualRenewalsByCategory'

class AnnualRenewalsByCategory extends React.Component {
  tooltipValueDisplayFunction = (value, payload) => {
    return <div>
      <Currency value={value} />
      <div {...CSS.tooltipAppCount}>{`${payload.count} ${pluralize('Contract', payload.count)}`}</div>
    </div>
  }

  render () {
    const { loading, yearlyRenewalsByCategoryForPie, yearlyRenewalsByCategory, toggleAllExpenseCategories, total } = this.props
    const seeAllButton = <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => toggleAllExpenseCategories(true)} label='See all' />

    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={CSS.header} header='renewals by category' subHeader='Next 12 months. Contracts with renewal costs' rightContent={seeAllButton} />
        <div {...CSS.chartsContainer}>
          <PieChart
            data={yearlyRenewalsByCategoryForPie}
            loading={loading}
            hasData={yearlyRenewalsByCategoryForPie.length > 0}
            valueDisplayFunction={(value) => <div style={{ whiteSpace: 'nowrap' }}><Currency value={value} /></div>}
            customToolTip={<CustomTooltip valueDisplayFunction={this.tooltipValueDisplayFunction} />}
          />

          {!loading && <AllExpenseCategoriesPopup title='All categories' categories={yearlyRenewalsByCategory} total={total} />}
        </div>
      </div>
    )
  }
}

AnnualRenewalsByCategory.propTypes = {
  loadingRenewals: PropTypes.bool
}

export default AnnualRenewalsByCategory
