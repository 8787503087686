import { DropdownOption } from '../dropdowns/types'
import { SelectAccountOption } from '@components/workflows/selectAccount/types'

export interface App {
  id: number
  name: string
  url: string
  imageUrl: string
}

export interface AppData extends App {
  accountOptions: SelectAccountOption[]
  userFieldOptions: DropdownOption[]
}

export interface AppWithValue extends App {
  value: string
}

interface AppInputValue {
  id: 'app'
  value: AppWithValue | null
}

interface AccountInputValue {
  id: 'account'
  value: SelectAccountOption | null
}

export enum USER_FIELD_SOURCE {
  INTEGRATION = 'integration',
  APP_USER = 'appUser'
}

export interface UserFieldInputDropdownOption extends DropdownOption {
  value: {
    key: string
    type: string
    fieldSource: USER_FIELD_SOURCE
  }
}

export interface UserFieldInputValue {
  id: 'userField'
  value: UserFieldInputDropdownOption | null
}

interface ChangedFromInputValue {
  id: 'changedFrom'
  value: DropdownOption | null
}

interface ChangedToInputValue {
  id: 'changedTo'
  value: DropdownOption | null
}

interface ChangedFromOtherValue {
  id: 'changedFromOtherValue'
  value: string | null
}

interface ChangedToOtherValue {
  id: 'changedToOtherValue'
  value: string | null
}
export type InputValue = [
  AppInputValue,
  AccountInputValue,
  UserFieldInputValue,
  ChangedFromInputValue,
  ChangedFromOtherValue,
  ChangedToInputValue,
  ChangedToOtherValue
]
