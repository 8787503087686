import View from './view'
import { connect } from 'react-redux'
import { filterUsersCustomOptionsByTrigger, getUsersSpecialOptionsByTrigger, getSingleUserCustomUserOptions } from '@lenses/workflows'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'
import { getActionsUserOptions, getTriggersByType } from '@selectors/workflows'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import { toggleAddUser } from '@actions/'
import { getSelf, getMyOrg } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'
import { getAppDetailsFields } from '@selectors/apps'

const mapStateToProps = (state, ownProps) => {
  const { includeCustomUserFields, triggerType, value, onChange } = ownProps
  const triggerTypes = getTriggersByType(state) || EMPTY_OBJECT
  const outputSchemaConfig = keyBy(Object.values(get(triggerTypes, [triggerType, 'outputSchema'], {})), 'type')
  const customUserOptions = includeCustomUserFields ? filterUsersCustomOptionsByTrigger(state, getActionsUserOptions(state), triggerType) : EMPTY_ARRAY
  const appDetailsFields = getAppDetailsFields(state)
  const customUserOptionsFiltered = getSingleUserCustomUserOptions(customUserOptions, appDetailsFields)
  const specialUsers = ownProps.specialUsers || (triggerType && getUsersSpecialOptionsByTrigger(state, triggerType, ownProps.field)) || EMPTY_ARRAY
  const allSpecialUsers = [
    ...specialUsers,
    ...customUserOptionsFiltered.map(option => ({ ...option, description: get(outputSchemaConfig[option.type], 'description', '') }))
  ]

  const { addUser } = state.ui

  const { id: idOrg } = getCurrentOrg(state)
  const currentUserIdOrg = getMyOrg(state)
  const isCurrentUserInOrg = currentUserIdOrg === idOrg
  let newValue = null
  if (value === 'currentUser') {
    if (isCurrentUserInOrg) {
      newValue = (getSelf(state) || {}).id
    }
    onChange && onChange(newValue)
  } else {
    newValue = value
  }

  return {
    specialUsers: keyBy(allSpecialUsers.map(u => ({ ...u, id: u.id || u.value })), 'value'),
    isCreateUserPopupOpen: addUser.isOpen,
    value: newValue
  }
}

const mapDispatchToProps = {
  toggleAddUser
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
