import React, { Fragment } from 'react'
import ToriiPopup from '../../../ToriiPopupV2'
import { SharedProps, SharedState, WIZARD_STEPS } from '../types'
import { PopupRendererProps } from '../../../wizardPopup/types'
import Success from '@media/success.svg'
import { Styles } from '../styles'
import { SCOPES } from '@root/constants'

export const ChangesApplied = ({
  onSubmitSuccess, sharedState, sharedProps
}: PopupRendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const totalMatchedUsersFoundCount = sharedState.changesPreview?.results?.totalMatchedUsersFoundCount

  return (<Fragment>
    <ToriiPopup.Header header={'Changes applied'} />
    <ToriiPopup.Content>
      <Styles.TextWithImageAside>
        <Styles.MultilineText>
          <Styles.BoldText>Number of {sharedProps.usersLabel} updated to {totalMatchedUsersFoundCount}</Styles.BoldText>
          <Styles.BoldText>The changes will take effect in a minute.</Styles.BoldText>
        </Styles.MultilineText>
        <Styles.SideImage alt='Changes applied' src={Success} />
      </Styles.TextWithImageAside>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      showCancelButton={false}
      mainButtonText={'Close'}
      isMainButtonDisabled={false}
      mainButtonAction={() => onSubmitSuccess()}
      mainButtonVisibilityScopes={[SCOPES.SETTINGS_WRITE]} />
  </Fragment>)
}
