import { fieldTypes, formFieldTypes } from '@root/constants'
import { ops } from '@lenses/filters'
import { USER_FILTER_MODEL_TYPE } from '@shared/filters/userFilters/consts'

export const optionsOpsMap = {
  [fieldTypes.longText]: [
    { label: 'contains', value: ops.contains, type: formFieldTypes.singleLine },
    { label: 'does not contain', value: ops.notContains, type: formFieldTypes.singleLine },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet }
  ],
  [fieldTypes.dropdown]: [
    { label: 'is', value: ops.equals, type: formFieldTypes.dropdown },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.dropdown },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet },
    { label: 'is one of', value: ops.anyOf, type: formFieldTypes.dropdownMulti },
    { label: 'is none of', value: ops.noneOf, type: formFieldTypes.dropdownMulti }
  ],
  [fieldTypes.text]: [
    { label: 'is', value: ops.equals, type: formFieldTypes.dropdown },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.dropdown },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet },
    { label: 'contains', value: ops.contains, type: formFieldTypes.singleLine },
    { label: 'does not contain', value: ops.notContains, type: formFieldTypes.singleLine },
    { label: 'is one of', value: ops.anyOf, type: formFieldTypes.dropdownMulti },
    { label: 'is none of', value: ops.noneOf, type: formFieldTypes.dropdownMulti }
  ],
  [fieldTypes.number]: [
    { label: 'greater than', value: ops.gt, type: formFieldTypes.number },
    { label: 'greater than or equals', value: ops.gte, type: formFieldTypes.number },
    { label: 'lower than', value: ops.lt, type: formFieldTypes.number },
    { label: 'lower than or equals', value: ops.lte, type: formFieldTypes.number },
    { label: 'is', value: ops.equals, type: formFieldTypes.number },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.number }
  ],
  [fieldTypes.currency]: [
    { label: 'greater than', value: ops.gt, type: formFieldTypes.number },
    { label: 'greater than or equals', value: ops.gte, type: formFieldTypes.number },
    { label: 'lower than', value: ops.lt, type: formFieldTypes.number },
    { label: 'lower than or equals', value: ops.lte, type: formFieldTypes.number },
    { label: 'is', value: ops.equals, type: formFieldTypes.number },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.number }
  ],
  [fieldTypes.bool]: [
    { label: 'is', value: ops.equals, type: formFieldTypes.bool }
  ],
  [fieldTypes.date]: [
    { label: 'is after', value: ops.dayAfter, type: formFieldTypes.datePicker },
    { label: 'is on or after', value: ops.dayOnOrAfter, type: formFieldTypes.datePicker },
    { label: 'is before', value: ops.dayBefore, type: formFieldTypes.datePicker },
    { label: 'is on or before', value: ops.dayOnOrBefore, type: formFieldTypes.datePicker },
    { label: 'is more than', value: ops.relativeDateMore, type: formFieldTypes.relativeDate },
    { label: 'is exactly', value: ops.relativeDateOn, type: formFieldTypes.relativeDate },
    { label: 'is less than', value: ops.relativeDateLess, type: formFieldTypes.relativeDate },
    { label: 'is today', value: ops.relativeDateToday },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet }
  ],
  [fieldTypes.user]: [
    { label: 'is', value: ops.equals, type: formFieldTypes.dropdown },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.dropdown },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet },
    { label: 'is one of', value: ops.anyOf, type: formFieldTypes.dropdownMulti },
    { label: 'is none of', value: ops.noneOf, type: formFieldTypes.dropdownMulti }
  ],
  [fieldTypes.userMulti]: [
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet },
    { label: 'is one of', value: ops.anyOf, type: formFieldTypes.dropdownMulti },
    { label: 'is none of', value: ops.noneOf, type: formFieldTypes.dropdownMulti }
  ],
  [fieldTypes.name]: [
    { label: 'contains', value: ops.contains, type: formFieldTypes.singleLine },
    { label: 'does not contain', value: ops.notContains, type: formFieldTypes.singleLine },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet },
    { label: 'is', value: ops.equals, type: formFieldTypes.dropdown },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.dropdown },
    { label: 'is one of', value: ops.anyOf, type: formFieldTypes.dropdownMulti },
    { label: 'is none of', value: ops.noneOf, type: formFieldTypes.dropdownMulti }
  ],
  [fieldTypes.freeText]: [
    { label: 'contains', value: ops.contains, type: formFieldTypes.singleLine },
    { label: 'does not contain', value: ops.notContains, type: formFieldTypes.singleLine },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet }
  ],
  [fieldTypes.appUserAccount]: [
    { label: 'exists', value: ops.exists },
    { label: 'not exists', value: ops.notExists }
  ],
  [fieldTypes.contractsDropdownMulti]: [
    { label: 'is', value: ops.equals, type: formFieldTypes.dropdown },
    { label: 'is not', value: ops.notEquals, type: formFieldTypes.dropdown },
    { label: 'is set', value: ops.isSet },
    { label: 'is not set', value: ops.isNotSet },
    { label: 'is one of', value: ops.anyOf, type: formFieldTypes.dropdownMulti },
    { label: 'is none of', value: ops.noneOf, type: formFieldTypes.dropdownMulti }
  ]
}

export const getNewCreatedValueByModelType = ({ modelType, inputValue } : {
  modelType?: USER_FILTER_MODEL_TYPE,
  inputValue: string
}) => {
  const trimmedInputValue = inputValue.trim()
  const newValue = {
    value: trimmedInputValue.toLowerCase(),
    label: trimmedInputValue
  }

  if (modelType && [
    USER_FILTER_MODEL_TYPE.USER_FIELD,
    USER_FILTER_MODEL_TYPE.USER,
    USER_FILTER_MODEL_TYPE.APP_USER
  ].includes(modelType)) {
    return {
      key: trimmedInputValue.toLowerCase(),
      ...newValue
    }
  }

  return newValue
}
