import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AlertBox, AlertBoxType, Checkbox, Col, Grid, Link, Row, Stack, H3, Body1 } from '@toriihq/design-system'
import debounce from 'lodash/debounce'
import Page from '@components/page'
import PageHeader from '@components/pageHeader'
import TableSearch from '@components/table/tableSearch'
import * as Style from './style'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { getPlugins } from '@actions/plugins'
import TileList from './components/tileList'

const DEFAULT_FILTERS = [
  { value: 'installed', label: 'Installed' },
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' }
]

const PluginMarketplace = () => {
  const { idOrg } = useParams()

  const [plugins, setPlugins] = useState<any>([])
  const [selectedFilters, setSelectedFilters] = useState<any>({})
  const [query, setQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchPlugins = async () => {
      const { plugins } = await dispatch(getPlugins({ idOrg }))
      setPlugins(plugins)
      setIsLoading(false)
    }

    fetchPlugins()
  }, [idOrg, dispatch])

  const categories = useMemo(() => {
    const set = (plugins || []).reduce((acc, plugin) => {
      acc.add(...plugin.categories)
      return acc
    }, new Set())
    return [...set]
  }, [plugins])

  const title = (
    <Stack gap='space-200'>
      <H3>Plugin Marketplace</H3>
      <Body1 color='secondary'>Take Torii to the next level with plugins.</Body1>
    </Stack>
  )

  const onSelectFilters = (e) => {
    const value = e.target.value
    if (selectedFilters[value]) {
      const { [value]: clickedFilter, ...remainingFilters } = selectedFilters
      setSelectedFilters(remainingFilters)
    } else {
      setSelectedFilters(prevFilters => ({ ...prevFilters, [value]: true }))
    }
  }

  const getFilteredPlugins = useCallback(() => {
    return plugins.filter(plugin => {
      if (query) {
        if (!plugin.name.toLowerCase().includes(query.toLowerCase())) return false
      }
      for (const filter in selectedFilters) {
        switch (filter) {
          case 'installed':
            if (!plugin.isInstalled) return false
            break
          case 'public':
            if (!plugin.isPublic) return false
            break
          case 'private':
            if (plugin.isPublic) return false
            break
          default:
            if (plugin.categories.every(category => !selectedFilters[category])) return false
            break
        }
      }
      return true
    })
  }, [plugins, selectedFilters, query])

  const onSearch = (e) => {
    const { value: query } = e.currentTarget
    onSearchStopped(query)
  }

  const onSearchStopped = debounce((query) => {
    setQuery(query)
  }, 500)

  const filteredPlugins = getFilteredPlugins()
  return (
    <Page title='Plugin Marketplace'>
      <PageHeader
        title={title}
        contentRight={<TableSearch onSearch={onSearch} />}
      />
      <Grid>
        <Row>
          <Col>
            <AlertBox type={AlertBoxType.INFORMATIVE} description={<div>Follow our <Link href={'https://developers.toriihq.com/docs/build-a-plugin'} target='_blank'>Getting Started</Link> guide if you want to build your own plugin.</div>} />
          </Col>
        </Row>
        <Row>
          <Col xs={3} sm={2}>
            <Style.Filters>
              <Stack gap='space-600'>
                <Stack gap='space-150'>
                  {DEFAULT_FILTERS.map(filter => (
                    <div key={filter.value}>
                      <Checkbox
                        value={filter.value}
                        label={filter.label}
                        checked={selectedFilters[filter.value]}
                        onChange={onSelectFilters}
                      />
                    </div>
                  ))}
                </Stack>
                <Stack gap='space-150'>
                  <div>CATEGORIES</div>
                  {categories.map(category => (
                    <div key={category}>
                      <Checkbox
                        value={category}
                        label={category}
                        checked={selectedFilters[category]}
                        onChange={onSelectFilters}
                      />
                    </div>
                  ))}
                </Stack>
              </Stack>
            </Style.Filters>
          </Col>
          <Col xs={9} sm={10}>
            <TileList plugins={filteredPlugins} loading={isLoading} />
          </Col>
        </Row>
      </Grid>
    </Page>
  )
}

export default PluginMarketplace
