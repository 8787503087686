export const GET_PENDING_OFFBOARDING_TASKS = 'GET_PENDING_OFFBOARDING_TASKS'
export const GET_PENDING_OFFBOARDING_TICKETS = 'GET_PENDING_OFFBOARDING_TICKETS'
export const OFFBOARDING_TASK_MARKED_AS_DONE = 'OFFBOARDING_TASK_MARKED_AS_DONE'

export const GET_RENEWALS_SUMMARY = 'GET_RENEWALS_SUMMARY'
export const GET_CALENDARS = 'GET_CALENDARS'

export enum FIELD_TYPES {
  longText = 'longText',
  text = 'text',
  number = 'number',
  currency = 'currency',
  bool = 'bool',
  dropdown = 'dropdown',
  date = 'date',
  user = 'user',
  userMulti = 'userMulti',
  name = 'name',
  freeText = 'freeText',
  appUserAccount = 'appUserAccount',
  fileUpload = 'fileUpload',
  contractsDropdownMulti = 'contractsDropdownMulti'
}

export enum SCOPES {
  MEMBERS_AND_ROLES_READ = 'members-and-roles:read',
  MEMBERS_AND_ROLES_WRITE = 'members-and-roles:write',
  API_MANAGEMENT_READ = 'api-management:read',
  API_MANAGEMENT_WRITE = 'api-management:write',
  SETTINGS_READ = 'settings:read',
  SETTINGS_WRITE = 'settings:write',
  PUBLIC_VIEWS_READ = 'public-views:read',
  PUBLIC_VIEWS_WRITE = 'public-views:write',
  INTEGRATIONS_READ = 'integrations:read',
  INTEGRATIONS_WRITE = 'integrations:write',
  AUTOMATION_NONE = 'automation:none',
  AUTOMATION_READ = 'automation:read',
  AUTOMATION_WRITE = 'automation:write',
  APPLICATIONS_READ = 'applications:read',
  APPLICATIONS_WRITE = 'applications:write',
  CONTRACTS_NONE = 'contracts:none',
  CONTRACTS_READ = 'contracts:read',
  CONTRACTS_WRITE = 'contracts:write',
  LICENSE_AND_CHARGEBACK_NONE = 'license-and-chargeback:none',
  LICENSE_AND_CHARGEBACK_READ = 'license-and-chargeback:read',
  LICENSE_AND_CHARGEBACK_WRITE = 'license-and-chargeback:write',
  EXPENSE_NONE = 'expense:none',
  EXPENSE_READ = 'expense:read',
  EXPENSE_WRITE = 'expense:write',
  APPLICATION_CATALOG_READ = 'application-catalog:read',
  APPLICATION_CATALOG_WRITE = 'application-catalog:write',
  USERS_NONE = 'users:none',
  USERS_READ = 'users:read',
  OFFBOARDING_NONE = 'offboarding:none',
  OFFBOARDING_READ = 'offboarding:read',
  OFFBOARDING_WRITE = 'offboarding:write',
  BASIC_ADMIN = 'basic-admin',
  APP_OWNER_READ = 'app-owner:read',
  APP_OWNER_WRITE = 'app-owner:write'
}

export enum APP_ACCOUNT_TYPE {
  REGULAR = 'regular',
  MANUAL = 'manual',
  CUSTOM = 'custom'
}

export enum CONTRACT_SOURCE {
  MANUAL = 'manual',
  EMAIL = 'email',
  INTEGRATION = 'integration',
  FILE = 'file',
  API = 'api',
  ZIP = 'zip'
}

export const LOGO_TYPES = {
  CATALOG: 'logoUrl',
  EMAIL: 'emailLogoUrl',
  CUSTOM_APP: 'customAppImageUrl'
} as const

export interface FilterOption {
  value: string
  type: string
  label: string
}

export enum SEND_REQUEST_METHOD {
  EMAIL = 'Email',
  SLACK = 'Slack'
}
