import * as Style from './style'
import KeyOpValueFilter from '@components/filters/keyOpValueFilter'
import React from 'react'
import { KeyOption, ValueOption } from '@components/filters/keyOpValueFilter/types'
import { Filter } from '@shared/filters/types'

interface Props {
  filter?: Filter
  optionsKey?: KeyOption[]
  optionsValues?: ValueOption[]
  onChange: (Filter) => void
  disabled: boolean
  fieldsAutoFocus?: boolean
  allowCustomValuesInMultiDropdown?: boolean
}

const KeyOpValueVerticalFilter = ({
  filter = { id: 'default' },
  optionsKey = [],
  optionsValues,
  onChange,
  disabled,
  fieldsAutoFocus = true,
  allowCustomValuesInMultiDropdown = false
}: Props) => {
  return (
    <KeyOpValueFilter
      filter={filter}
      optionsKey={optionsKey}
      optionsValues={optionsValues}
      onChange={onChange}
      disabled={disabled}
      fieldsAutoFocus={fieldsAutoFocus}
      CSS={Style.KeyOpValueVerticalFilterCSS}
      layout='vertical'
      allowCustomValuesInMultiDropdown={allowCustomValuesInMultiDropdown}
    />
  )
}

export default KeyOpValueVerticalFilter
