import View from './view'
import { connect } from 'react-redux'
import {
  createSourceToken,
  getServicesConfig,
  getServicesSyncData,
  toggleConnectSource
} from '@actions/'
import {
  createTestConnectionEntry,
  getTestConnectionInfo,
  testConnection,
  updateTestConnectionEntry,
  createSourceTokenAfterTestConnection
} from '@actions/testConnection'
import { getConstantServices, getServicesWithConnectStatusMultipleAccounts, getSyncStatus } from '@selectors/services'
import { SYNC_TYPE } from '@root/constants'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import { getCapabilities, hasTestConnectionInUrl } from '@components/testConnection/utils'
import get from 'lodash/get'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { isConnectSourceOpen: isOpen, sourceIdApp, isReconnect, showReconnectAlertInfo, idAppToken, idAppAccount, validTestInProgress, hideConnectByLink } = state.ui

  const config = getConstantServices(state)
  const isConfigLoaded = config.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const isAllDataLoaded = (isServicesLoaded && isConfigLoaded)
  const services = isAllDataLoaded ? getServicesWithConnectStatusMultipleAccounts(state) : []

  const service = services.find(service => {
    if (isReconnect) {
      if (idAppToken) {
        return service.idAppToken === idAppToken
      }
      if (idAppAccount) {
        return service.idAppAccount === idAppAccount
      }
    }
    return service.idApp === sourceIdApp && service.syncType === SYNC_TYPE.API
  }) || {}

  const serviceConfig = config ? config.find(serviceConfig => serviceConfig.idApp === service.idApp) : {}
  const { capabilities = {}, integrationCategory } = serviceConfig || {}

  const afterRedirect = hasTestConnectionInUrl()
  const urlParams = new URLSearchParams(window.location.search)
  const idTestConnection = urlParams.get('idTestConnection')
  const error = urlParams.get('error')
  const hasTestConnection = get(serviceConfig, 'testConnection')
  const capabilityList = serviceConfig ? getCapabilities(capabilities, integrationCategory) : []

  return {
    idOrg,
    isOpen,
    isReconnect,
    showReconnectAlertInfo,
    idApp: sourceIdApp,
    serviceName: service.name,
    source: service.source,
    connectUrl: service.connectUrl,
    integrationType: service.integrationType,
    supportsWritePermission: service.supportsWritePermission,
    connectFormConfiguration: service.connectFormConfiguration,
    permission: ownProps.permission || service.permission,
    serviceConfig,
    afterRedirect: afterRedirect && sourceIdApp,
    idTestConnection: parseInt(idTestConnection),
    authenticationError: error,
    validTestInProgress,
    hasTestConnection,
    capabilityList,
    hideConnectByLink: ownProps.hideConnectByLink || hideConnectByLink
  }
}

const mapDispatchToProps = {
  createSourceToken,
  getServicesSyncData,
  toggleConnectSource,
  getServicesConfig,
  testConnection,
  getTestConnectionInfo,
  createTestConnectionEntry,
  updateTestConnectionEntry,
  createSourceTokenAfterTestConnection
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleConnectSource({ isConnectSourceOpen: false, sourceId: null, sourceIdApp: null, hasTestConnection: false })
  },
  close () {
    dispatchProps.toggleConnectSource({ isConnectSourceOpen: false, sourceId: null, sourceIdApp: null, hasTestConnection: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
