import React, { ReactElement } from 'react'
import { NodeProps } from 'reactflow'
import { BranchData } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { ActionNodeModel, ActionWithFixedBranchesNodeModel, BranchNodeModel, WORKFLOW_ACTION_TYPE, WORKFLOW_NODE_TYPE } from '@shared/workflows/types'
import { getBranchActionBranchesData } from '@shared/workflows/actions/utils/getBranchActionBranchesData'
import { BranchLabelContent } from './branchLabelContent'
import { BRANCH_TYPE, GetBranchDataResult } from './types'
import { getFixedBranchesData } from '@shared/workflows/actions/utils/getFixedBranchesData'
import { exhaustiveCheck } from '@shared/utils'
import { useActionConfiguration } from '@pages/workflow_v2/hooks/actionConfiguration/useActionConfiguration'

type Props = NodeProps<BranchData>

const getBranchData = (
  branchNode: BranchNodeModel | ActionWithFixedBranchesNodeModel,
  branchIndex: number
): GetBranchDataResult => {
  if (branchNode.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
    const { branches } = getBranchActionBranchesData(branchNode)
    const branch = branches[branchIndex]

    return {
      branch,
      branchLabel: branch?.label
    }
  } else if (branchNode.type === WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES) {
    const { branches } = getFixedBranchesData(branchNode)

    return {
      branch: branches[branchIndex]
    }
  }
  exhaustiveCheck(branchNode)

  const actionNode = (branchNode as ActionNodeModel).action?.type
  if (actionNode !== WORKFLOW_ACTION_TYPE.REQUEST_APPROVAL) {
    throw new Error('Branch node type is not supported')
  }

  return {}
}

export const BranchLabel = (props: Props): ReactElement => {
  const { idBranchingNode, branchIndex } = props.data
  const actionConfiguration = useActionConfiguration({ idAction: idBranchingNode })

  const branchesConfig = actionConfiguration?.branchesConfiguration
  const label = branchesConfig?.branches[branchIndex]?.label ?? ''
  const branchNode = actionConfiguration?.actionNode as BranchNodeModel | ActionWithFixedBranchesNodeModel | undefined

  const { branch = null, branchLabel = label } = branchNode ? getBranchData(branchNode, branchIndex) : {}

  return branch ? <BranchLabelContent branchType={BRANCH_TYPE.REGULAR} label={branchLabel} /> : <></>
}
