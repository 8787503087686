import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingAppsComparisonData, deprecatedGetAppsByIds, getAppsPermissions as getAppsPermissionsSelector } from '@selectors/apps'
import { SCOPES } from '@root/constants'
import SelectState from '@components/selectState'
import moment from 'moment'
import colors from '@shared/style/colors'
import SourceIcon from '@components/sourceIcon'
import appsToolTip from '@shared/appToolTip'
import RiskIcon from '@components/riskIcon'
import HorizontalTable from '@components/HorizontalTable'
import { getComparisonApps, getAppsPermissions, getContracts, toggleContractPopup, deprecatedGetApps as getAppsExpenses, getContractsFields, getContractsGroups } from '@actions/'
import { getPermissionDescription } from '@shared/thirdPartyPermissions'
import { Line, LineChart } from 'recharts'
import { css } from 'glamor'
import * as Style from './style'
import { Icon, Tooltip, Button, ButtonType, ButtonSize, Spacer, AvatarGroup, Popover } from '@toriihq/design-system'
import { getContractsByIdApp } from '@selectors/contracts'
import SelectApps from '@components/selectApps'
import sumBy from 'lodash/sumBy'
import PropTypes from 'prop-types'
import RelativeTeamLink from '@components/relativeTeamLink'
import useDeepCompareEffect from 'use-deep-compare-effect'
import get from 'lodash/get'
import Analytics from '@helpers/analytics'
import BackLink from '@components/backLink'
import AccessControl from '@lenses/accessControl'
import { getContractCurrencyConvertedValue } from '@components/contracts/utils'
import isNumber from 'lodash/isNumber'
import Currency from '@root/components/currency'
import AssignAppOwnersButton from '@components/assignAppOwnersButton'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import ContractImg from '@media/contract.svg'

const MAX_APPS_NUM = 4

const CreationTimeCell = ({ row: { creationTime } }) => (creationTime ? moment.utc(creationTime).format('MMM D, YYYY') : null)
const SourcesCell = ({ row: { sources, name, addedBy } }) => (
  sources && <div>
    {sources.map(source => (
      <SourceIcon isAppSource key={source} sourceType={source} tooltipText={appsToolTip({ appName: name, source, addedBy })} />
    ))}
  </div>
)

const ApplicationsComparisonTable = ({ idApps, idOrg, onAppsChange }) => {
  const [showAllRows, setShowAllRows] = useState(false)
  const [showSelectApp, setShowSelectApp] = useState(false)
  const [comparisonAppsData, setComparisonAppsData] = useState({})

  const idAppsRef = useRef([])

  const isLoading = useSelector(isLoadingAppsComparisonData) && idApps.length !== 0

  const appsPermissions = useSelector(getAppsPermissionsSelector)
  const contractsByIdApp = useSelector(getContractsByIdApp)
  const appsById = useSelector(deprecatedGetAppsByIds)

  const hasExpensesAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.EXPENSE_READ] })
  const hasContractsAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.CONTRACTS_READ] })

  const { apps = [], resources = {} } = comparisonAppsData
  const { fields } = resources

  const dispatch = useDispatch()

  useEffect(() => {
    if (!idOrg) {
      return
    }

    dispatch(getAppsPermissions({ idOrg, sources: ['google', 'slack', 'azure_ad'] }))
    if (hasContractsAccess) {
      dispatch(getContracts({ idOrg }))
    }
    dispatch(getContractsFields({ idOrg }))
    dispatch(getContractsGroups({ idOrg }))
    dispatch(getAppsExpenses({ idOrg }))
  }, [idOrg, dispatch, hasContractsAccess])

  useEffectOnce(() => {
    return () => {
      if (idAppsRef.current?.length === 1) {
        onAppsChange([], true)
      }
    }
  })

  useDeepCompareEffect(() => {
    if (!idOrg || idApps.length === 0) {
      return
    }

    const getAppsData = async ({ idOrg, idApps }) => {
      const { apps, resources } = idApps.length !== 1 ? await dispatch(getComparisonApps({ idOrg, idApps })) : { apps: [{ id: idApps[0] }], resources: {} }

      const sortedApps = idApps.length < 2 ? apps : apps.sort((a, b) => (idApps.indexOf(a.id) - idApps.indexOf(b.id)))

      setComparisonAppsData({
        apps: sortedApps,
        resources
      })
    }

    getAppsData({ idOrg, idApps })

    idAppsRef.current = idApps
  }, [idOrg, idApps])

  const hasAppsForComparison = idApps.length >= 2

  const onAppChange = (newIdApp, previousIdApp) => {
    const newIdApps = !newIdApp ? idApps.filter(idApp => idApp !== previousIdApp)
      : (!previousIdApp ? [...idApps, newIdApp] : idApps.map(idApp => idApp === previousIdApp ? newIdApp : idApp))
    onAppsChange(newIdApps)
    !previousIdApp && setShowSelectApp(false)
  }

  const onOwnerAssign = async (owner, idApp, assignUserCB) => {
    Analytics.track('Click on Assign owner / Summary / Comparison page / Compare tab / Applications')

    setComparisonAppsData({ ...comparisonAppsData, apps: apps.map(app => app.id === idApp ? { ...app, owner } : app) })
    await assignUserCB()
  }

  const onBackButtonClick = () => {
    Analytics.track('Click on Back / Comparison dashboard / Compare tab / Applications')
    onAppsChange([], true)
  }

  const OwnerCell = ({ value, row: { id }, assignUserCB }) => id && (
    <AvatarGroup max={1}>
      <AssignAppOwnersButton
        idApp={id}
        existingPrimaryOwner={value}
        addButtonTooltipLabel={'Assign app owners'}
        onSubmitSuccess={({ selectedPrimaryOwner }) => onOwnerAssign(selectedPrimaryOwner, id, assignUserCB)}
      />
    </AvatarGroup>
  )

  const onAppRemove = (id) => {
    if (!id) {
      setShowSelectApp(false)
    } else {
      Analytics.track('Click on Delete app / Summary / Comparison page / Compare tab / Applications', {
        'App order': `App ${apps.length}`
      })

      onAppChange(undefined, id)
    }
  }

  const onAddAppClick = () => {
    Analytics.track('Click on Add app link / Summary / Comparison page / Compare tab / Applications', {
      'App order': `App ${apps.length + 1}`
    })
    setShowSelectApp(true)
  }

  const selectAppsCell = ({ row: { id }, index }) => {
    const showRemoveIcon = index >= 2 && (showSelectApp ? index === apps.length : index === apps.length - 1)
    return id || (showSelectApp && index === apps.length) || index < 2
      ? <div {...Style.SelectAppsCell}>
        <SelectApps
          placeholder='Select app...'
          clearable={false}
          disableHiddenApps
          searchable
          autoFocus={!id && index === apps.length}
          value={id}
          includeAllApps={false}
          filterOptions={options => options.filter(option => !idApps.filter((app, idAppsIndex) => idAppsIndex !== index).includes(option.id))}
          onChange={app => {
            onAppChange(app.id, id)
          }}
          openOnFocus
        />
        {showRemoveIcon && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => onAppRemove(id)} icon='Trash' />}
      </div> : index === apps.length && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onAddAppClick} label='+ Add app' />
  }

  const ExpensesCell = ({ row: { id } }) => {
    const appInfo = appsById[id]
    const trendInfo = appInfo && appInfo.expenses.slice().reverse()
    const total = appInfo && sumBy(appInfo.expenses, month => month.total)

    const onExpensesClick = () => {
      Analytics.track('Click on Expenses / Summary / Comparison page / Compare tab / Applications')
    }

    return id && <>
      {total ? <RelativeTeamLink overrideStyle={Style.ExpensesCell} to={`/app/${id}/expenses`} onClick={onExpensesClick}>
        <div {...Style.ExpensesCellText}>
          <Currency value={total} />
        </div>
        <LineChart width={100} height={28} data={trendInfo}>
          <Line type='monotone' dataKey='total' stroke={colors.purple} strokeWidth={2} isAnimationActive={false} dot={false} />
        </LineChart>
      </RelativeTeamLink> : <div {...Style.NoExpensesText}>No expenses uploaded</div>}
    </>
  }

  const RiskLevelCell = ({ riskLevel, source, idApp }) => {
    const appInfo = appsPermissions[source].find(appPermissionsInfo => appPermissionsInfo.idApp === idApp)

    if (!appInfo) {
      return <div {...Style.RiskLevelNoActiveUsersText}>No users connected the app</div>
    }

    const permissions = appInfo.permissions
    const permissionsDescriptionContainers = permissions.map((permission) => {
      const permissionDescription = getPermissionDescription(source, permission)
      return <div {...Style.PermissionDescription}>{permissionDescription}</div>
    })

    return <div key={idApp} {...Style.RiskLevel}>
      <RiskIcon riskLevel={riskLevel} />
      {riskLevel}
      <Spacer left='space-050'>
        <Popover
          showArrow
          content={<div {...Style.PermissionsTooltip}>
            <div {...Style.PermissionsTooltipTitle}>Permissions granted to the app</div>
            {permissionsDescriptionContainers}
          </div>}
        >
          <Icon name='Info' color='secondary' />
        </Popover>
      </Spacer>

    </div>
  }

  const onContractClick = (id) => {
    Analytics.track('Click on contract name / Summary / Comparison page / Compare tab / Applications')
    dispatch(toggleContractPopup({ idContract: id, isOpen: true }))
  }

  const ActiveContractsCell = ({ row: { id } }) => {
    const appContracts = contractsByIdApp[id] || []
    const activeContracts = appContracts.filter(contract => contract.status === 'active')

    return id &&
      <div {...Style.ActiveContractsCell}>{ activeContracts.length ? activeContracts.map((contract, index) =>
        <div {...css(Style.Contract, index && { margin: '30px 0 0 0' })}>
          <div {...Style.IconAndText}>
            <img alt='contract' src={ContractImg} width={18} height={18} />
            <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => { onContractClick(contract.id) }} label={contract.name} />
          </div>
          {contract.endDate &&
          <span {...css(Style.IconAndText, Style.ContractsEndDate)}>
            <Tooltip
              placement='top'
              label={'End date'}
            >
              <Icon name='Calendar' />
            </Tooltip>
            {moment.utc(contract.endDate).format('MMM D, YYYY')}
          </span>}
          {isNumber(getContractCurrencyConvertedValue(contract.amount)) &&
            <span {...Style.IconAndText}>
              <Tooltip
                placement='top'
                label={'Contract value'}>
                <Icon name='Expenses' />
              </Tooltip>
              <Currency value={getContractCurrencyConvertedValue(contract.amount)} />
            </span>}
        </div>)
        : <div {...Style.NoContractsMessage}>No active contracts</div> }
      </div>
  }

  const showRowsButtonOnClick = () => {
    Analytics.track('Click on Show more link / Summary /Comparison page / Compare tab / Applications', {
      Text: showAllRows ? 'Show less' : 'Show more'
    })

    setShowAllRows(!showAllRows)
  }

  const onStateChange = (stateLabel) => {
    Analytics.track('Click on select state in the dropdown / Summary / Comparison page / Compare tab / Applications', {
      Text: stateLabel
    })
  }

  const rows = [
    {
      Header: 'App name',
      accessor: 'name',
      Cell: selectAppsCell
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value, row: { id } }) => {
        if (value && fields) {
          const stateInfo = fields.find(field => field.systemKey === 'state')
          return <SelectState {...stateInfo} onChange={(state) => state && state.label ? onStateChange(state.label) : null} idApp={id} selectedValue={stateInfo && get(stateInfo.options.find(o => o.label === value), 'value')} allowedScopes={[SCOPES.APPLICATIONS_WRITE]} />
        }
      },
      show: hasAppsForComparison
    },
    {
      Header: 'App owner',
      accessor: 'owner',
      Cell: data => OwnerCell({ ...data }),
      show: hasAppsForComparison
    },
    {
      Header: 'Active contracts',
      Cell: ActiveContractsCell,
      show: hasAppsForComparison && hasContractsAccess
    },
    {
      Header: (<div>
        Expenses
        <div>
          (last 12 months)
        </div>
      </div>),
      Cell: ExpensesCell,
      show: hasAppsForComparison && hasExpensesAccess
    },
    {
      Header: 'Discovery date',
      id: 'creationTime',
      show: hasAppsForComparison && (showAllRows || !hasContractsAccess || !hasExpensesAccess),
      Cell: CreationTimeCell
    },
    {
      Header: 'Data sources',
      accessor: 'sources',
      show: hasAppsForComparison && (showAllRows || (!hasContractsAccess && !hasExpensesAccess)),
      Cell: SourcesCell
    },
    {
      Header: 'Google Workspace Security risk',
      accessor: 'GSuiteRiskLevel',
      show: hasAppsForComparison && showAllRows && apps.some(app => app.GSuiteRiskLevel),
      Cell: ({ row: { GSuiteRiskLevel, id } }) => id ? <RiskLevelCell key={id} riskLevel={GSuiteRiskLevel} source={'google'} idApp={id} /> : null
    },
    {
      Header: 'Slack Security risk',
      accessor: 'slackRiskLevel',
      show: hasAppsForComparison && showAllRows && apps.some(app => app.slackRiskLevel),
      Cell: ({ row: { slackRiskLevel, id } }) => id ? <RiskLevelCell riskLevel={slackRiskLevel} source={'slack'} idApp={id} /> : null
    },
    {
      Header: 'Azure Security risk',
      accessor: 'azureRiskLevel',
      show: hasAppsForComparison && showAllRows && apps.some(app => app.azureRiskLevel),
      Cell: ({ row: { azureRiskLevel, id } }) => id ? <RiskLevelCell riskLevel={azureRiskLevel} source={'azure_ad'} idApp={id} /> : null
    }
  ]

  return (<>
    <BackLink onClick={onBackButtonClick} linkText={'Back to Compare board'} overrideStyle={Style.BackButton} />
    <div {...Style.Main}>
      <div {...Style.TableTitle}>{idApps.length < 2 ? 'SELECT APPS TO COMPARE' : 'SUMMARY'}</div>
      <HorizontalTable
        data={apps}
        maxDataColumns={MAX_APPS_NUM}
        rows={rows}
        loading={isLoading}
        overrideStyle={{ width: '100%', minWidth: 1024 }}
      />
      {hasAppsForComparison && <Spacer top={'space-050'}><Button type={ButtonType.compact} size={ButtonSize.small} onClick={showRowsButtonOnClick} label={`Show ${showAllRows ? 'less' : 'more'}`} icon={showAllRows ? 'ChevronUp' : 'ChevronDown'} /></Spacer>}
    </div>
  </>
  )
}

ApplicationsComparisonTable.propTypes = {
  idApps: PropTypes.array.isRequired,
  idOrg: PropTypes.number,
  onAppsChange: PropTypes.func.isRequired
}

export default ApplicationsComparisonTable
