import View from './view'
import { connect } from 'react-redux'
import {
  changeAppHiddenState,
  getSingleApp,
  toggleHideAppPopup
} from '@actions/'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)

  return {
    idOrg,
    hideAppPopup: state.ui.hideAppPopup
  }
}

const mapDispatchToProps = {
  toggleHideAppPopup,
  changeAppHiddenState,
  getSingleApp
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
