import React, { JSX } from 'react'
import { Button, ButtonType, Tooltip } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'

const FeedbackBoardButton = () : JSX.Element => {
  const onClick = () => {
    Analytics.track('Clicked to open feedback board')
    const newWindow = window.open('https://api.toriihq.com/api/featurebase/login?return_to=https://feedback.toriihq.com', '_blank') || { opener: null }
    newWindow.opener = null
  }

  return <Tooltip
    placement='bottom'
    label='Give us feedback'>
    <Button type={ButtonType.tertiary} icon='MessageCircle' onClick={onClick} />
  </Tooltip>
}

export default FeedbackBoardButton
