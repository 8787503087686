import React from 'react'
import Table from '@components/table'
import { RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS, SCOPES, TABLES } from '@root/constants'
import getColumns from '@components/renewalsSummary/renewalsListTable/columns'
import { useSelector, useDispatch } from 'react-redux/es'
import { getContracts, getContractsResources } from '@selectors/contracts'
import { getAppDetailsFields } from '@selectors/apps'
import { keyBy, round } from 'lodash'
import { toggleContractPopup } from '@actions/'

const RenewalsListTable = ({ baseYearRenewals, baseYear, bucketOption, onBucketChange }) => {
  const dispatch = useDispatch()

  const { apps: appsById } = useSelector(getContractsResources)
  const contracts = useSelector(getContracts)

  const appDetailsFields = useSelector(getAppDetailsFields)
  const contractsById = keyBy(contracts, 'id')
  const stateInfo = appDetailsFields.find(field => field.systemKey === 'state')

  const openContractPopup = (idContract) => dispatch(toggleContractPopup({ isOpen: true, isByUser: true, idContract, initialValues: null, buttonLabel: null, isDuplicated: false, isAppMatchingMode: false, idApp: null, onSuccessCB: null }))

  const columns = getColumns({ baseYear: baseYear.value, appsById, stateInfo, contractsById, openContractPopup })
  const calculateDiffWithPreviousYearPercentage = (renewal) => {
    if (renewal.previousYearAnnualValue) {
      return round(renewal.diffWithPreviousYear / renewal.previousYearAnnualValue * 100) / 100
    } else if (renewal.baseYearAnnualValue) {
      return 1
    }
    return 0
  }
  const mappedData = baseYearRenewals.map(renewal => ({
    ...renewal,
    appName: appsById[renewal.idApp]?.name || '',
    diffWithPreviousYearPercentage: calculateDiffWithPreviousYearPercentage(renewal)
  }
  ))

  const filterByCustomSelect = (data, customSelectOption) => {
    if (customSelectOption.value === 'all') {
      return data
    }
    return data.filter(renewal => renewal.bucket === customSelectOption.value)
  }

  const searchFilterMethod = (row, search) => {
    const values = [
      row.appName
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  return (
    <Table
      data={filterByCustomSelect(mappedData, bucketOption)}
      columns={columns}
      style={{
        borderTop: 'none',
        border: 'none'
      }}
      allowedScopes={[SCOPES.CONTRACTS_WRITE]}
      itemsName='renewal'
      exportable
      tableKey={TABLES.renewalsSummaryTable.key}
      searchable
      searchFilterMethod={searchFilterMethod}
      searchPlaceholder='Search applications...'
      forceShowNumberOfResults
      customSelect
      customSelectOptions={Object.values(RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS)}
      customSelectSelectedValue={bucketOption}
      customSelectOnChange={onBucketChange}
    />
  )
}

export default RenewalsListTable
