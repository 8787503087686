import { connect } from 'react-redux'
import View from './view'
import { getContracts } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { getContracts as getContractsSelector, getContractsResources } from '@selectors/contracts'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const contracts = getContractsSelector(state)
  const { apps } = getContractsResources(state)

  return {
    idOrg,
    contracts,
    apps
  }
}

const mapDispatchToProps = {
  getContracts
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
