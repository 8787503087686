import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  updateUserSettings,
  getUserSettings
} from '@actions/'
import { getMappedSettings, getSettings } from '@selectors/userSettings'
import { USER_SETTINGS_TYPES } from './settings'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  const { loading: loadingUserSettings } = state.userSettings
  const userSettingsStatus = getSettings(state)
  const userSettings = getMappedSettings(state)

  return {
    idOrg,
    userSettings,
    userSettingsTypes: USER_SETTINGS_TYPES,
    loading: loadingUserSettings && isEmpty(userSettingsStatus)
  }
}

const mapDispatchToProps = {
  getUserSettings,
  updateUserSettings
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
