import View from './view'
import { connect } from 'react-redux'
import { getSourcesWithApps } from '@selectors/apps'
import { getAppsPermissions } from '@actions/'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps

  const sourcesWithApps = getSourcesWithApps(state)
  const sourcesWithRiskLevels = sourcesWithApps
    .reduce((result, source) => {
      const appFromSource = source.apps.find((app) => app.idApp === idApp)
      if (appFromSource) {
        const sourceRiskObj = { 'source': source, 'app': appFromSource }
        result.push(sourceRiskObj)
      }
      return result
    }, [])

  return {
    sourcesWithRiskLevels
  }
}

const mapDispatchToProps = {
  getAppsPermissions
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
