import View from './view'
import { connect } from 'react-redux'
import { getBrowserExtensionActivationUsers, toggleConfigureBrowserActivationEmailPopup, getBrowserUsersCount, getExtensionReportUsers } from '@actions/'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import { getBrowserActivationEmailUsersCount, getBrowsersCounts, getExtensionStatusInfo, getUsersForExtensionReport } from '@selectors/reports'
import { TABLES } from '@root/constants'
import { getUserPreferences } from '@selectors/ui'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const userPreferences = getUserPreferences(state)
  const { defaultSort } = userPreferences[TABLES.extensionRolloutReportTable.key] || {}
  const browsersCounts = getBrowsersCounts(state)
  const { loading, total, loadingMore } = getExtensionStatusInfo(state)
  const users = getUsersForExtensionReport(state)
  const browserActivationEmailUsersCount = getBrowserActivationEmailUsersCount(state)

  return {
    idOrg,
    users,
    total,
    defaultSort,
    browsersCounts,
    loading: loading && users.length === 0,
    loadingMore,
    browserActivationEmailUsersCount
  }
}

const mapDispatchToProps = {
  getExtensionReportUsers,
  getBrowserUsersCount,
  getBrowserExtensionActivationUsers,
  toggleConfigureBrowserActivationEmailPopup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
