import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Style from './style'
import { Spacer, H4, Stack, Subtitle1, Body2 } from '@toriihq/design-system'
import LogoContainer from '@components/appCatalog/components/LogoContainer'
import BrowseUploadBox from '@components/browseUploadBox'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { LOGO_TYPES } from '@root/constants.t'
import Input from '@components/form/input'
import Placeholder from '@components/placeholder'
import { isOrgLoading, getCurrentOrg } from '@selectors/org'
import { removeOrgLogo, updateOrg, uploadOrgLogo } from '@actions/'
import Analytics from '@helpers/analytics'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import FormGroup from '@components/form/formGroup'

const EmailsSettings = () => {
  const dispatch = useDispatch()
  const org = useSelector(getCurrentOrg)
  const { id: idOrg } = org
  const loading = useSelector(isOrgLoading) && !idOrg

  const [emailAlias, setEmailAlias] = useState(org.emailAlias)
  const [isEditLogoLoading, setIsEditLogoLoading] = useState(false)
  const [logoUploadingErrorOpen, setLogoUploadingErrorOpen] = useState(false)

  const onFileSelected = async (file, shouldDelete) => {
    Analytics.track('Changed emails logo')
    setIsEditLogoLoading(true)

    shouldDelete && await handleRemoveLogo()
    const succeed = await dispatch(uploadOrgLogo({ idOrg, logoFile: file, logoType: LOGO_TYPES.EMAIL }))

    setLogoUploadingErrorOpen(!succeed)
    setIsEditLogoLoading(false)
  }

  const handleRemoveLogo = async () => {
    Analytics.track('Removed email logo')
    await dispatch(removeOrgLogo({ idOrg, logoType: LOGO_TYPES.EMAIL }))
  }

  useEffect(() => {
    setEmailAlias(org.emailAlias)
  }, [org.emailAlias])

  const onEmailAliasChange = (emailAlias) => {
    if (emailAlias) {
      dispatch(updateOrg({ idOrg, emailAlias }))
    }
  }

  return (
    <Style.Wrapper>
      <Spacer bottom={'space-300'}><H4>Emails</H4></Spacer>
      <Stack gap='space-500'>
        <Style.Section>
          <Stack gap='space-100'>
            <Subtitle1 color='primary'>Outgoing email display name</Subtitle1>
            <Stack gap='space-200'>
              <Body2 color='secondary'>Default email display name for outgoing emails (emails are sent from hello@toriihq.com)</Body2>
              <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
                <div style={{ width: '200px' }}>
                  <FormGroup>
                    <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                      <Input
                        suffix='via Torii'
                        placeholder='via Torii'
                        value={emailAlias}
                        onChange={(e) => setEmailAlias(e.target.value)}
                        onBlur={e => onEmailAliasChange(e.target.value)}
                      />
                    </EnableFor>
                  </FormGroup>
                </div>
              </Placeholder>
            </Stack>
          </Stack>
        </Style.Section>
        <Style.Section>
          <Stack gap='space-100'>
            <Subtitle1 color='primary'>Logo</Subtitle1>
            <Stack gap='space-200'>
              <Body2 color='secondary'>Show your company logo in outgoing emails</Body2>
              <FormGroup>
                {
                  org.emailLogoUrl
                    ? <LogoContainer logo={org.emailLogoUrl} onEdit={(file) => onFileSelected(file, true)} onRemove={handleRemoveLogo} isLoading={isEditLogoLoading} logoType={LOGO_TYPES.EMAIL} />
                    : <BrowseUploadBox onFileSelect={(file) => onFileSelected(file, false)} types={['image/png']} scopes={[SCOPES.SETTINGS_WRITE]} description='Recommended size: 1320px x 300px. PNG format is supported.' />
                }
              </FormGroup>
            </Stack>
          </Stack>
          <ToriiPopup isOpen={logoUploadingErrorOpen} onCloseAction={() => setLogoUploadingErrorOpen(false)}>
            <ToriiPopup.Header header={`Failed To Upload Image`} />
            <ToriiPopup.Content>
              <span>Your image could not be uploaded because it’s format was not PNG or SVG. Please select a valid image and try again.</span>
            </ToriiPopup.Content>
            <ToriiPopup.Footer
              showCancelButton={false}
              mainButtonAction={() => setLogoUploadingErrorOpen(false)}
              mainButtonText='Close'
            />
          </ToriiPopup>
        </Style.Section>
      </Stack>
    </Style.Wrapper>)
}

export default EmailsSettings
