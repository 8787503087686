import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffboardingSettings } from '@actions/'
import { getAppOffboardingSettings, isLoadingAppOffboardingSettings, getCurrentOrg } from '@selectors/org'
import { Icon, Tooltip } from '@toriihq/design-system'
import Placeholder from '@components/placeholder'
import isEmpty from 'lodash/isEmpty'
import * as Style from './style'

const INTERVAL_DAYS_TO_TEXT = {
  3: '3 days',
  7: 'week',
  14: '2 weeks'
}

const OfboardingRemindersSettingsSummary = (props) => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const offboardingSettings = useSelector(getAppOffboardingSettings)
  const loading = useSelector(isLoadingAppOffboardingSettings) && isEmpty(offboardingSettings)
  const dispatch = useDispatch()

  const tooltipText = offboardingSettings.remindersEnabled
    ? `If user is not removed, email reminders will be sent to the delegated person every ${INTERVAL_DAYS_TO_TEXT[offboardingSettings.remindersIntervalInDays]}. To update the configuration go to Offboarding settings page.`
    : 'The request will be sent only once. No reminders will be sent if the task is not completed. To update the configuration go to Offboarding settings page.'

  useEffect(() => {
    idOrg && dispatch(getOffboardingSettings({ idOrg }))
  }, [dispatch, idOrg])

  return <Placeholder loading={loading} type='text' rows={1} style={Style.Placeholder}>
    <div {...Style.Header}>
      <div>
        <span>Reminders: {offboardingSettings.remindersEnabled ? 'ON' : 'OFF'} </span>
        <Tooltip
          placement='bottom'
          label={tooltipText}
        >
          <Icon name='Info' color='secondary' />
        </Tooltip>
      </div>
    </div>
  </Placeholder>
}

export default OfboardingRemindersSettingsSummary
