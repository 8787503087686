import { LOAD_USER_PREFERENCES, TABLES } from '@root/constants'

const LOCAL_STORAGE_KEY = 'userPreferences'
let IS_LOCAL_STORAGE_SUPPORTED = false
try {
  IS_LOCAL_STORAGE_SUPPORTED = Boolean(window && window.localStorage && window.localStorage.getItem && window.localStorage.setItem)
} catch (e) {}

const storageMiddleWare = ({ getState }) => next => action => {
  if (!IS_LOCAL_STORAGE_SUPPORTED) {
    return next(action)
  }

  const prevUserPreferences = getState().ui.userPreferences

  if (action.type === LOAD_USER_PREFERENCES) {
    const userPreferences = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')

    // This logic can be removed from here after 6 months
    if (userPreferences[TABLES.allExpensesTable.key] &&
        userPreferences[TABLES.allExpensesTable.key].columnsConfiguration &&
        userPreferences[TABLES.allExpensesTable.key].columnsConfiguration.length > 0 &&
        !userPreferences[TABLES.allExpensesTable.key].columnsConfiguration.find(column => column === 'externalAccountId')
    ) {
      userPreferences[TABLES.allExpensesTable.key].columnsConfiguration.push('externalAccountId', 'externalAccountName')
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }

    if (userPreferences[TABLES.appExpensesTable.key] &&
        userPreferences[TABLES.appExpensesTable.key].columnsConfiguration &&
        userPreferences[TABLES.appExpensesTable.key].columnsConfiguration.length > 0 &&
        !userPreferences[TABLES.appExpensesTable.key].columnsConfiguration.find(column => column === 'externalAccountId')
    ) {
      userPreferences[TABLES.appExpensesTable.key].columnsConfiguration.push('externalAccountId', 'externalAccountName')
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }

    if (userPreferences[TABLES.appUsersTable.key] &&
      !userPreferences[TABLES.appUsersTable.key].isEmailColumnAlreadyAdded &&
      userPreferences[TABLES.appUsersTable.key].columnsConfiguration?.length > 0 &&
      !userPreferences[TABLES.appUsersTable.key].columnsConfiguration.find(column => column === 'originEmail')) {
      const fullNameIndex = userPreferences[TABLES.appUsersTable.key].columnsConfiguration.indexOf('fullName')
      userPreferences[TABLES.appUsersTable.key].columnsConfiguration.splice(fullNameIndex + 1, 0, 'originEmail')
      userPreferences[TABLES.appUsersTable.key].isEmailColumnAlreadyAdded = true
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }
    if (userPreferences[TABLES.usersTable.key] &&
      !userPreferences[TABLES.usersTable.key].isAdditionalEmailsColumnAlreadyAdded &&
      userPreferences[TABLES.usersTable.key].columnsConfiguration?.length > 0 &&
      !userPreferences[TABLES.usersTable.key].columnsConfiguration.find(column => column === 'additionalEmails')) {
      const fullNameIndex = userPreferences[TABLES.usersTable.key].columnsConfiguration.indexOf('fullName')
      userPreferences[TABLES.usersTable.key].columnsConfiguration.splice(fullNameIndex + 1, 0, 'additionalEmails')
      userPreferences[TABLES.usersTable.key].isAdditionalEmailsColumnAlreadyAdded = true
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }
    if (userPreferences[TABLES.deletedUsersTable.key] &&
      !userPreferences[TABLES.deletedUsersTable.key].isAdditionalEmailsColumnAlreadyAdded &&
      userPreferences[TABLES.deletedUsersTable.key].columnsConfiguration?.length > 0 &&
      !userPreferences[TABLES.deletedUsersTable.key].columnsConfiguration.find(column => column === 'additionalEmails')) {
      const fullNameIndex = userPreferences[TABLES.deletedUsersTable.key].columnsConfiguration.indexOf('fullName')
      userPreferences[TABLES.deletedUsersTable.key].columnsConfiguration.splice(fullNameIndex + 1, 0, 'additionalEmails')
      userPreferences[TABLES.deletedUsersTable.key].isAdditionalEmailsColumnAlreadyAdded = true
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }
    if (userPreferences[TABLES.licenseRecommendationsTable.key] &&
      !userPreferences[TABLES.licenseRecommendationsTable.key].isEmailColumnAlreadyAdded &&
      userPreferences[TABLES.licenseRecommendationsTable.key].columnsConfiguration?.length > 0) {
      const originEmailIndex = userPreferences[TABLES.licenseRecommendationsTable.key].columnsConfiguration.indexOf('originEmail')
      if (originEmailIndex !== -1) {
        userPreferences[TABLES.licenseRecommendationsTable.key].columnsConfiguration.splice(originEmailIndex, 1)
      }

      const fullNameIndex = userPreferences[TABLES.licenseRecommendationsTable.key].columnsConfiguration.indexOf('fullName')
      userPreferences[TABLES.licenseRecommendationsTable.key].columnsConfiguration.splice(fullNameIndex + 1, 0, 'originEmail')

      userPreferences[TABLES.licenseRecommendationsTable.key].isEmailColumnAlreadyAdded = true
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }
    if (userPreferences[TABLES.inactiveUsersListPopup.key] &&
      !userPreferences[TABLES.inactiveUsersListPopup.key].isEmailColumnAlreadyAdded &&
      userPreferences[TABLES.inactiveUsersListPopup.key].columnsConfiguration?.length > 0) {
      const originEmailIndex = userPreferences[TABLES.inactiveUsersListPopup.key].columnsConfiguration.indexOf('originEmail')
      if (originEmailIndex !== -1) {
        userPreferences[TABLES.inactiveUsersListPopup.key].columnsConfiguration.splice(originEmailIndex, 1)
      }

      const fullNameIndex = userPreferences[TABLES.inactiveUsersListPopup.key].columnsConfiguration.indexOf('fullName')
      userPreferences[TABLES.inactiveUsersListPopup.key].columnsConfiguration.splice(fullNameIndex + 1, 0, 'originEmail')

      userPreferences[TABLES.inactiveUsersListPopup.key].isEmailColumnAlreadyAdded = true
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }
    if (userPreferences[TABLES.appDiscoveredAppsTable.key] &&
      !userPreferences[TABLES.appDiscoveredAppsTable.key].isAdditionalColumnsConfigurationAlreadyAdded) {
      userPreferences[TABLES.appDiscoveredAppsTable.key].isAdditionalColumnsConfigurationAlreadyAdded = true
      const oldColumnsConfiguration = userPreferences[TABLES.appDiscoveredAppsTable.key].columnsConfiguration
      userPreferences[TABLES.appDiscoveredAppsTable.key].columnsConfiguration = [...oldColumnsConfiguration, 'creationTime', 'accountName']
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }
    if (userPreferences[TABLES.allDiscoveredAppsTable.key] &&
      !userPreferences[TABLES.allDiscoveredAppsTable.key].isAdditionalColumnsConfigurationAlreadyAdded) {
      userPreferences[TABLES.allDiscoveredAppsTable.key].isAdditionalColumnsConfigurationAlreadyAdded = true
      const oldColumnsConfiguration = userPreferences[TABLES.allDiscoveredAppsTable.key].columnsConfiguration
      userPreferences[TABLES.allDiscoveredAppsTable.key].columnsConfiguration = [...oldColumnsConfiguration, 'creationTime', 'accountName']
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }

    next({
      ...action,
      payload: { userPreferences }
    })
    return
  }

  next(action)

  const nextUserPreferences = getState().ui.userPreferences
  if (prevUserPreferences !== nextUserPreferences) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(nextUserPreferences))
  }
}

export default storageMiddleWare
