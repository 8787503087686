import { useEffect, useRef, useState } from 'react'

const POLL_INTERVAL_IN_SECONDS = 5
const STOP_POLL_AFTER_IN_SECONDS = 60 * 4

interface UsePollingParams {
  onPollFailure: (parsingResult: { errorCode?: string }) => void
}

export const UsePolling = ({ onPollFailure }: UsePollingParams) => {
  const intervalId = useRef<number | null>(null)
  const timeoutId = useRef<number | null>(null)
  const [isError, setIsError] = useState<boolean>(false)

  const clearPolling = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }

  const startPolling = async (pollLogic): Promise<void> => {
    const poll = async () => {
      try {
        await pollLogic()
      } catch (e) {
        clearPolling()
        if (e instanceof Error) {
          onPollFailure({ errorCode: e.message })
        } else {
          onPollFailure({ errorCode: 'unknown' })
        }
        throw e
      }
    }

    return new Promise((resolve, reject) => {
      intervalId.current = window.setInterval(async () => {
        try {
          await poll()
        } catch (error) {
          clearPolling()
          reject(error)
        }
      }, POLL_INTERVAL_IN_SECONDS * 1000)

      timeoutId.current = window.setTimeout(() => {
        clearPolling()
        setIsError(true)
        reject(new Error('Polling timed out'))
      }, STOP_POLL_AFTER_IN_SECONDS * 1000)
    })
  }

  useEffect(() => {
    return () => {
      clearPolling()
    }
  }, [])

  return { startPolling, isError, setIsError, clearPolling }
}
