import { CHANGE_APP_HIDDEN_STATE, GET_HIDDEN_APPS } from '@root/constants'

const initialState = {
  loading: false,
  apps: [],
  resources: { users: {} }

}

const hiddenAppsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_HIDDEN_APPS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_HIDDEN_APPS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_HIDDEN_APPS}_RESPONSE`: {
      const { hiddenApps, resources } = action.payload

      return {
        ...state,
        loading: false,
        apps: hiddenApps,
        resources
      }
    }
    case `${CHANGE_APP_HIDDEN_STATE}_RESPONSE`: {
      const { isHidden, idApp } = action.meta

      let modifiedApps = state.apps
      if (!isHidden) {
        modifiedApps = state.apps.filter(app => app.id !== idApp)
      }

      return {
        ...state,
        apps: modifiedApps
      }
    }
    default: {
      return state
    }
  }
}

export default hiddenAppsReducer
