import React, { ReactElement, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { Controls, ReactFlow } from 'reactflow'
import 'reactflow/dist/style.css'
import { getReactflowGraphData } from './utils/getReactflowGraphData'
import { nodeTypes } from './utils/nodeTypes'
import { edgeTypes } from './utils/edgeTypes'
import { Workflow } from '@shared/workflows/types'
import { useWorkflowGraphPositioning } from './hooks/useWorkflowGraphPositioning'
import { ReactflowGraphData } from './types'

interface Props {
  workflow: Workflow
}

export const WorkflowGraph = ({ workflow }: Props): ReactElement => {
  const [graphData, setGraphData] = useState<ReactflowGraphData>({ nodes: [], edges: [] })

  useEffect(() => {
    const newGraphData = getReactflowGraphData({ workflow })

    if (!isEqual(newGraphData, graphData)) {
      setGraphData(newGraphData)
    }
  }, [graphData, workflow])

  const { nodes, edges } = graphData

  useWorkflowGraphPositioning()

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      minZoom={0.1}
      maxZoom={1}
      elementsSelectable={false}
      proOptions={{ hideAttribution: true }}
      panOnScroll
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={false}
    >
      <Controls
        position='top-left'
        showInteractive={false}
      />
    </ReactFlow>
  )
}
