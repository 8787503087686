import { USER_LIFECYCLE_STATUS } from '@shared/types'
import type { IdOrg, IdUser, IdApiKey } from '@store/types'

export type GetApiKeysProps = {
  idOrg: IdOrg
}

export const PUBLIC_API_KEY_PERMISSIONS = {
  READ: 'read',
  READ_WRITE: 'read_write',
  SCIM: 'scim'
} as const

export type ApiKey = {
  id: IdApiKey
  idOrg: IdOrg
  createdBy: IdUser
  expirationTime: string | null
  description: string | null
  creationTime: string
  permission: typeof PUBLIC_API_KEY_PERMISSIONS[keyof typeof PUBLIC_API_KEY_PERMISSIONS]
}

export type ApiKeysConfig = {
  maxDescriptionLength: number
  maxExpirationDays: number
  minExpirationDays: number
}

export type UserResourceMap = Record<number, {
  id: number,
  firstName: string,
  lastName?: string,
  email?: string,
  lifecycleStatus?: USER_LIFECYCLE_STATUS,
  photoUrl?: string | null,
  isDeletedInIdentitySources?: boolean,
  isExternal?: boolean,
  isSupport?: boolean
}>

export type GetApiKeysResponse = {
  apiKeys: Array<ApiKey>,
  config: ApiKeysConfig,
  resources: {
    apps: object
    users: UserResourceMap
  }
}

export type CreateApiKeyProps = {
  idOrg: IdOrg,
  permission: typeof PUBLIC_API_KEY_PERMISSIONS[keyof typeof PUBLIC_API_KEY_PERMISSIONS],
  expirationTime: string,
  description: string
}

export type CreateApiKeyResponse = {
  apiKey: {
    id: IdApiKey,
    idOrg: IdOrg,
    createdBy: IdUser,
    token: string
  }
}

export type DeleteApiKeyProps = {
  id: IdApiKey,
  idOrg: IdOrg
}

export type DeleteApiKeyResponse = {
  success: boolean
}
