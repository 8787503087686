import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isOrgLoading, getExtensionMode } from '@selectors/org'
import { updateOrg } from '@actions/'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const extensionMode = getExtensionMode(state)
  const loading = isOrgLoading(state) && !extensionMode

  return {
    idOrg,
    loading,
    extensionMode
  }
}

const mapDispatchToProps = {
  updateOrg
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
