import React, { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WorkflowNodeWrapper } from '../workflowNodeWrapper'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { getNodeStatus } from '../utils/getNodeStatus'
import { WorkflowNodeData, WORKFLOW_UI_NODE_TYPE } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { Container, ContentContainer, DeleteButtonContainer } from './styles'
import { WorkflowNodeContent } from '../workflowNodeContent'
import EnableFor from '@root/components/enableFor'
import { SCOPES } from '@root/constants'
import { DeleteActionConfirmation } from './deleteActionConfirmation'
import { analytics } from '@shared/services/workflows/analytics'
import { useActionConfiguration } from '@pages/workflow_v2/hooks/actionConfiguration/useActionConfiguration'

export interface WorkflowNodeProps {
  data: WorkflowNodeData
  iconUrl: string
}

export const ActionNodeBase = ({
  data,
  iconUrl
}: WorkflowNodeProps): ReactElement => {
  const { idWorkflowNode } = data
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const actionConfiguration = useActionConfiguration({ idAction: idWorkflowNode })
  const { idNewNode } = useWorkflowEditorContext()
  const { idSelectedNode } = useWorkflowNavigation()
  const actionLabel = actionConfiguration?.label
  const { idWorkflow } = useParams()

  const { statusType, text: statusText } = getNodeStatus({
    isNew: idNewNode === idWorkflowNode,
    isValid: actionConfiguration?.isValid ?? false,
    isSelected: idSelectedNode === idWorkflowNode,
    nodeType: WORKFLOW_UI_NODE_TYPE.ACTION
  })

  const handleDeleteClick = () => {
    analytics.action.delete.popupOpened({
      idWorkflow: Number(idWorkflow),
      actionName: actionLabel!
    })
    setIsDeleteOpen(true)
  }

  return (
    <>
      {actionConfiguration && (
        <>
          <WorkflowNodeWrapper id={idWorkflowNode}>
            <Container>
              <ContentContainer>
                <WorkflowNodeContent
                  title={actionLabel ?? ''}
                  status={statusText}
                  statusType={statusType}
                  iconUrl={iconUrl}
                  errorMsg={actionConfiguration.errorMsg} />
              </ContentContainer>
              <DeleteButtonContainer>
                <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                  <Button type={ButtonType.compact} size={ButtonSize.small} onClick={handleDeleteClick} icon='Trash' />
                </EnableFor>
              </DeleteButtonContainer>
            </Container>
          </WorkflowNodeWrapper>
          <DeleteActionConfirmation
            idAction={idWorkflowNode}
            isOpen={isDeleteOpen}
            onClose={() => setIsDeleteOpen(false)} />
        </>
      )}
    </>
  )
}
