import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SCOPES, TABLES } from '../../../constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { updateUsersLifecycleStatus, getOffboardingTodoUsers, toggleOffboardingPreviewPopup } from '@actions/'
import omit from 'lodash/omit'
import { getUserPreferences } from '@selectors/ui'
import AccessControl from '@lenses/accessControl'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const tableInfo = TABLES.offboardingTodoTable

  const userPreferences = getUserPreferences(state)
  const { defaultSort = [] } = userPreferences[TABLES.offboardingTodoTable.key] || {}

  const hasLicenseCostAndChargebackAccess = AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ], state })
  const columnsRestrictions = { hasLicenseCostAndChargebackAccess }

  return {
    idOrg,
    tableInfo,
    defaultSort,
    columnsRestrictions
  }
}

const mapDispatchToProps = {
  toggleOffboardingPreviewPopup,
  updateUsersLifecycleStatus,
  getOffboardingTodoUsers
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg } = stateProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUsersLifecycleStatus', 'toggleOffboardingPreviewPopup']),
    startOffboarding: (users) => dispatchProps.toggleOffboardingPreviewPopup({ isOpen: true, users }),
    skipOffboarding: idUsers => dispatchProps.updateUsersLifecycleStatus({ idOrg, idUsers, lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDED }),
    ...ownProps
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
