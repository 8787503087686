import { css } from 'glamor'
import { fontWeight } from '@shared/style/sizes'
import { theme } from '@toriihq/design-system'

const SIDE_PADDING = '21px'

export const Main = css({
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px'
})

export const Header = css({
  padding: `7px ${SIDE_PADDING} 0 ${SIDE_PADDING}`,
  borderBottom: `1px solid ${theme.palette.border.primary}`,
  fontWeight: fontWeight.bold
})

export const ChartContainer = {
  height: '243px'
}

export const Chart = css({
  paddingTop: '32px',
  paddingBottom: '26px'
})
