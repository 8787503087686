import {
  LOGOUT, RESET_LOGOUT
} from '@root/constants'
import ParseValidation from '@helpers/parse-validation'

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {}
}

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGOUT}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }

    case `${LOGOUT}_FAILED`:
    case `${LOGOUT}_RESPONSE`: {
      const validation = ParseValidation(action.payload)

      return {
        ...state,
        loading: false,
        success: !action.error,
        error: validation.error,
        hasError: validation.hasError,
        help: validation.help
      }
    }

    case RESET_LOGOUT:
      return initialState

    default: {
      return state
    }
  }
}

export default logoutReducer
