import React, { ReactElement, useEffect, useState } from 'react'
import InProgressUsers from '@components/offboardingTabs/inProgressTabsContainer/tabs/users'
import { useSelector, useDispatch } from 'react-redux'
import TabsPage from '@root/pages/tabs'
import { getHiddenApps } from '@actions/'
import { getOffboardingInProgressUsersCountSelector, getPendingOffboardingTasksSelector, getPendingOffboardingTicketsSelector } from '@selectors/offboarding'
import { getCurrentOrg } from '@selectors/org'
import { withRouter } from 'react-router-dom'
import PendingTasksTab from './tabs/pendingTasks'
import { getPendingOffboardingTasks, getPendingOffboardingTickets } from '@actions/offboarding'
import { AppAccountTasks, UnmergedPendingTask, UnmergedPendingTicket } from './tabs/pendingTasks/types'
import { OFFBOARDING_TASKS_GROUP_TYPE } from './tabs/pendingTasks/consts'
import { groupTasksByAppAccount } from './utils'
import { getHiddenApps as getHiddenAppsSelector } from '@selectors/apps'
import { keyBy } from 'lodash'
import { H4 } from '@toriihq/design-system'

interface Props {
  refreshAllOffboardingData: () => void
}

const InProgressTabsContainer = ({ refreshAllOffboardingData }: Props): ReactElement => {
  const { id: idOrg } = useSelector(getCurrentOrg) || { id: null }
  const inProgressUsersCount = useSelector(getOffboardingInProgressUsersCountSelector)
  const pendingTasks: UnmergedPendingTask[] = useSelector(getPendingOffboardingTasksSelector)
  const pendingTickets: UnmergedPendingTicket[] = useSelector(getPendingOffboardingTicketsSelector)
  const hiddenApps = useSelector(getHiddenAppsSelector)
  const hiddenAppsById = keyBy(hiddenApps, 'id')

  const [initialPendingTasksLoading, setInitialPendingTasksLoading] = useState(true)

  const getPendingTasksState = () => {
    const { appAccountTasks, totalTasks } = groupTasksByAppAccount(pendingTasks, OFFBOARDING_TASKS_GROUP_TYPE.TASKS, hiddenAppsById)
    const { appAccountTasks: appAccountTickets, totalTasks: totalTickets } = groupTasksByAppAccount(pendingTickets, OFFBOARDING_TASKS_GROUP_TYPE.TICKETS, hiddenAppsById)

    const unsortedPendingTasks: AppAccountTasks[] = [
      ...appAccountTasks,
      ...appAccountTickets
    ]

    return {
      tasksByAppAccount: unsortedPendingTasks,
      totalTasks: totalTasks + totalTickets
    }
  }

  const pendingTasksTabState = getPendingTasksState()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!idOrg) {
      return
    }

    dispatch(getHiddenApps({ idOrg }))

    const fetchPendingTasks = async () => {
      await Promise.all([
        dispatch(getPendingOffboardingTasks({ idOrg })),
        dispatch(getPendingOffboardingTickets({ idOrg }))
      ])

      setInitialPendingTasksLoading(false)
    }

    fetchPendingTasks()
  }, [dispatch, idOrg])

  const tabsName = ['users', 'pendingTasks']

  const tabsHeader = [
    { header: 'Users', counter: inProgressUsersCount, isLoading: false },
    { header: 'Pending tasks', counter: pendingTasksTabState.totalTasks, isLoading: initialPendingTasksLoading }
  ]

  const tabsContent = [
    <InProgressUsers
      title='In Progress'
      refreshAllOffboardingData={refreshAllOffboardingData}
    />,
    <PendingTasksTab
      idOrg={idOrg}
      isLoading={initialPendingTasksLoading}
      tasksByAppAccount={pendingTasksTabState.tasksByAppAccount}
    />
  ]

  const pageHeader = <H4>{`In progress ${inProgressUsersCount ? `(${inProgressUsersCount})` : ''}`}</H4>

  return (
    <TabsPage
      pageName='In progress'
      pageTitle='In progress'
      pageHeader={pageHeader}
      path={`/team/${idOrg}/offboarding/progress`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      isSecondary
    />
  )
}

export default withRouter(InProgressTabsContainer)
