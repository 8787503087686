import View from './view'
import { connect } from 'react-redux'
import { setAppsDetails } from '@actions/'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { tableKey } = ownProps
  const { id: idOrg } = getCurrentOrg(state)

  return {
    tableKey,
    idOrg
  }
}

const mapDispatchToProps = {
  setAppsDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
