import React, { useEffect, useState } from 'react'
import Placeholder from '@components/placeholder'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import Select from '@components/select'
import { useDispatch, useSelector } from 'react-redux'
import { getInactivityTimeout, isOrgLoading, getIsScimEnabledSelector, getSessionTimeout, getCurrentOrg } from '@selectors/org'
import {
  deleteSamlConfiguration,
  getSamlConfiguration,
  togglePopup,
  updateOrg,
  updateSamlConfiguration,
  updateIsScimEnabled,
  createSamlConfigurationBySource
} from '@actions/'
import * as Style from './style'
import {
  getIsSSOEnabled,
  getIsSSOEnforced,
  getSSOProvider,
  isSamlConfigurationLoading
} from '@selectors/samlConfiguration'
import UploadSamlFilePopup from '@components/popups/uploadFilePopup/uploadSamlFilePopup'
import { UPLOAD_FILE_POPUPS } from '@components/popups/uploadFilePopup'
import ToriiToggle from '@components/toriiToggle'
import { Stack, Button, ButtonType, H4, Link, ButtonSize, Subtitle1, Body2 } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'
import { FEATURES } from '@shared/features'
import ToggleOfFeature from '@components/toggleOfFeature'
import { isAppSyncStatusEnabled } from '@selectors/services'
import FormGroup from '@components/form/formGroup'

const INACTIVITY_TIMEOUT_OPTIONS = [-1, 5, 15, 30, 60].map(value => ({
  value,
  label: value === -1 ? 'Do not sign user out' : `${value} minutes of being idle`
}))

const DEFAULT_SESSION_TTL_IN_HOURS = 12

const SESSION_TIMEOUT_OPTIONS = [
  { value: 240, label: '4 hours' },
  { value: 720, label: '12 hours' },
  { value: 1440, label: '1 day' },
  { value: 4320, label: '3 days' },
  { value: 10080, label: '1 week' },
  { value: 43200, label: '1 month' }
]

const ssoStatus = (isSSOEnabled) => {
  if (isSSOEnabled) {
    return (<span>Configured</span>)
  }

  return (
    <span>Not configured - follow the instructions&nbsp;
      <Link href={'https://support.toriihq.com/hc/en-us/articles/5199815253787-Setup-Your-Torii-Account-to-Use-SSO-Single-Sign-On-For-Login'} target='_blank'>
        here
      </Link>
      &nbsp;to configure.
    </span>)
}

const SecuritySettings = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const orgLoading = useSelector(isOrgLoading)
  const samlConfigurationLoading = useSelector(isSamlConfigurationLoading)
  const inactivityTimeout = useSelector(getInactivityTimeout) || -1
  const sessionTimeout = useSelector(getSessionTimeout)

  const isSSOEnabled = useSelector(getIsSSOEnabled)
  const ssoProvider = useSelector(getSSOProvider)
  const isSSOEnforced = useSelector(getIsSSOEnforced)

  const isScimEnabled = useSelector(getIsScimEnabledSelector)

  const RIPPLING_ID_APP = 6730
  const isRipplingEnabled = useSelector(isAppSyncStatusEnabled(RIPPLING_ID_APP))

  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    idOrg && dispatch(getSamlConfiguration({ idOrg }))
  }, [dispatch, idOrg, orgLoading])

  const onInactivityTimeoutChanged = (option) => {
    const inactivityTimeout = option.value === -1 ? null : option.value
    dispatch(updateOrg({ idOrg, inactivityTimeout }))
  }

  const onSessionTimeoutChanged = (option) => {
    const sessionTimeout = option.value ?? DEFAULT_SESSION_TTL_IN_HOURS * 60
    dispatch(updateOrg({ idOrg, sessionTimeout }))
  }

  const configureSsoButton = () => {
    return (
      <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
        <Button
          label='Configure SSO'
          onClick={() => dispatch(togglePopup(UPLOAD_FILE_POPUPS.UPLOAD_SAML_FILE_POPUP, true))}
        />
      </EnableFor>
    )
  }
  const ssoEnforcementButton = () => {
    return (
      <Stack direction='row' gap='space-100'>
        <Body2 color='primary'>Enforce users to sign in using SSO</Body2>
        <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
          <ToriiToggle
            checked={isSSOEnforced}
            onToggle={toggleSSOEnforcement}
          />
        </EnableFor>
      </Stack>
    )
  }

  const toggleSSOEnforcement = () => {
    dispatch(updateSamlConfiguration({ idOrg, allowLoginsViaEmailAndPassword: isSSOEnforced }))
  }

  const removeSSOButton = () => {
    return (
      <>
        <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
          <Button
            label='Remove SSO'
            type={ButtonType.destructive}
            onClick={() => setRemoveConfirmationOpen(true)}
          />
        </EnableFor>
        <Confirmation
          isOpen={removeConfirmationOpen}
          header='Remove SSO Configuration'
          text={(<span>After this change, users will only be able to login to Torii by using email and password.<br />
            Are you sure you want to remove your SSO configuration?</span>)}
          confirmText='Remove SSO'
          declineText='Cancel'
          confirm={onRemoveConfirm}
          decline={closeRemoveConfirm}
          close={closeRemoveConfirm}
          mainButtonType={ButtonType.destructive}
        />
      </>
    )
  }

  const onRemoveConfirm = async () => {
    await dispatch(deleteSamlConfiguration({ idOrg }))
    setTimeout(closeRemoveConfirm, 1000)
  }

  const closeRemoveConfirm = () => {
    setRemoveConfirmationOpen(false)
  }

  const onToggleChange = async () => {
    await dispatch(updateIsScimEnabled({ idOrg, enable: !isScimEnabled }))
  }

  return (
    <Stack gap='space-300'>
      <H4>Security</H4>
      <Stack gap='space-500'>
        <Stack gap='space-100'>
          <Subtitle1 color='primary'>Web session length</Subtitle1>
          <Stack gap='space-200'>
            <Body2 color='secondary'>Set how long users can stay signed into Torii</Body2>
            <Placeholder loading={orgLoading || samlConfigurationLoading} type='rect' style={Style.placeholderStyle}>
              <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                <div {...Style.Select}>
                  <Select
                    options={SESSION_TIMEOUT_OPTIONS}
                    value={sessionTimeout}
                    onChange={onSessionTimeoutChanged}
                    clearable={false}
                    searchable={false}
                    openOnFocus
                  />
                </div>
              </EnableFor>
            </Placeholder>
          </Stack>
        </Stack>

        <Stack gap='space-100'>
          <Subtitle1 color='primary'>Sign out users on being idle</Subtitle1>
          <Stack gap='space-200'>
            <Body2 color='secondary'>Torii users will be signed out and required to sign in after being idle</Body2>
            <Placeholder loading={orgLoading || samlConfigurationLoading} type='rect' style={Style.placeholderStyle}>
              <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                <div {...Style.Select}>
                  <Select
                    options={INACTIVITY_TIMEOUT_OPTIONS}
                    value={inactivityTimeout}
                    onChange={onInactivityTimeoutChanged}
                    clearable={false}
                    searchable={false}
                    key='inactivity_timeout_fields_select'
                    name='inactivity_timeout_fields_select'
                    openOnFocus
                  />
                </div>
              </EnableFor>
            </Placeholder>
          </Stack>
        </Stack>

        <Stack gap='space-100'>
          <Subtitle1 color='primary'>Single Sign On (SSO)</Subtitle1>
          <Placeholder loading={orgLoading || samlConfigurationLoading} type='rect' style={Style.placeholderStyle}>
            <Stack gap='space-200' alignItems='flex-start'>
              <Body2 color='secondary'>Status: {ssoStatus(isSSOEnabled)}</Body2>
              {isSSOEnabled && <Body2 color='primary'>Provider: {ssoProvider}</Body2>}
              {isSSOEnabled && ssoEnforcementButton()}
              {isSSOEnabled && removeSSOButton()}
              {!isSSOEnabled && configureSsoButton()}
            </Stack>
          </Placeholder>
        </Stack>

        {isRipplingEnabled && !isSSOEnabled &&
        <Stack gap='space-100'>
          <Subtitle1 color='primary'>Rippling</Subtitle1>
          <Placeholder loading={orgLoading} type='rect' style={Style.placeholderStyle}>
            <Stack gap='space-200'>
              <Body2 color='secondary'>For Rippling integration only, you can configure SSO automatically</Body2>
              <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                <Button type={ButtonType.compact} size={ButtonSize.small}
                  label='Configure Now'
                  onClick={() => dispatch(createSamlConfigurationBySource({ idOrg, source: 'rippling' }))}
                />
              </EnableFor>
            </Stack>
          </Placeholder>
        </Stack>
        }

        <Stack gap='space-100'>
          <Subtitle1 color='primary'>Enable SCIM</Subtitle1>
          <FormGroup>
            <Placeholder loading={orgLoading} type='rect' style={Style.placeholderStyle}>
              <Stack direction='row' gap='space-100'>
                <Body2 color='secondary'>Allow Torii to be managed by other applications using {' '}
                  <Link href='https://support.toriihq.com/hc/en-us/articles/9183460072347-Torii-s-SCIM-User-Provisioning' target='_blank'>
                    SCIM APIs
                  </Link></Body2>
                <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                  <ToggleOfFeature feature={FEATURES.SETTINGS.TABS.API_ACCESS} checked={isScimEnabled} onChange={onToggleChange} disabled={false} />
                </EnableFor>
              </Stack>
            </Placeholder>
          </FormGroup>
        </Stack>
        <UploadSamlFilePopup />
      </Stack>
    </Stack>
  )
}

export default SecuritySettings
