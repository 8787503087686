import { GET_CALENDARS } from '@root/constants.t'
import { Action, ActionTypeStatus } from '@reducers/types'
import type { IdOrg, IdOrgCalendar } from '@store/types'

export enum ORG_CALENDAR_TYPE {
  RENEWAL = 'renewal'
}

export type Calendar = {
  id: IdOrgCalendar
  idOrg?: IdOrg
  uuid?: string
  type?: ORG_CALENDAR_TYPE
}

export type CalendarsState = {
  loading: boolean
  calendars: Calendar[]
}

type GetCalendarsAction = Action<
  `${typeof GET_CALENDARS}_${ActionTypeStatus}`,
  { calendars: Calendar[] }
>

export type CalendarsAction =
  | GetCalendarsAction
