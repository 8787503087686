import React from 'react'
import { css } from 'glamor'
import copy from 'copy-to-clipboard'
import { AppIcon, Button, ButtonType, ButtonSize, Link, Icon, Menu } from '@toriihq/design-system'
import BaseService from '../baseService/view'
import { getSyncedText } from '../baseService/index'
import { SCOPES, SYNC_ERRORS, syncDisabledString } from '@root/constants'
import colors from '@shared/style/colors'
import EnableFor from '@components/enableFor'
import moment from 'moment'
import OverflowTooltip from '@components/overflowTooltip'
import * as Style from '../baseService/style'
import Placeholder from '@components/placeholder'
import Analytics from '@helpers/analytics'
import Confirmation from '@components/confirmation'
import noop from 'lodash/noop'

class CustomIntegrationService extends BaseService {
  state = {
    copied: false
  }

  copyToClipboard = (link) => {
    Analytics.track('Click on Copy API link / Integrations', {
      'Link location': 'Tile'
    })

    copy(link)
    this.setState({ copied: true })
    setTimeout(() => this.setState({ copied: false }), 2000)
  }

  renderConnected = () => {
    const { copied } = this.state
    const {
      syncError,
      errorMessage,
      idAppAccount,
      lastSyncTime
    } = this.props

    const hasUserAttentionError = Boolean((syncError && SYNC_ERRORS[syncError]) || errorMessage)

    return (
      <div {...Style.Tile}>
        <div {...Style.Status}>
          {hasUserAttentionError ? <span {...css({ color: colors.errorDark })}>{errorMessage || syncDisabledString + SYNC_ERRORS[syncError]}</span> : <span>{getSyncedText({ lastSyncTime, isCustomIntegration: true })}</span>}
        </div>
        <div>
          <div>
            App account ID:
            <div {...Style.AppAccountIDContainer}>
              <OverflowTooltip label={idAppAccount}>{idAppAccount.toString()}</OverflowTooltip>
              {copied ? <div {...Style.CopyText}>Copied!</div> : <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.copyToClipboard(idAppAccount)} label='Copy' />}
            </div>
          </div>
          <EnableFor scopes={[SCOPES.INTEGRATIONS_WRITE]}>
            <Menu
              items={this.options('custom')}
              onToggle={this.onMenuClickCallback}
            >
              <Button icon='Dots' type={ButtonType.secondary} onClick={noop} />
            </Menu>
          </EnableFor>
        </div>
      </div>
    )
  }

  renderStatusIcon = () => {
    const { syncError, errorMessage, lastSyncTime } = this.props

    const days = moment().diff(lastSyncTime, 'days')
    const hasUserAttentionError = (syncError && SYNC_ERRORS[syncError]) || errorMessage
    const showSyncError = Boolean(hasUserAttentionError) || days > 10
    const neverSynced = !lastSyncTime
    const iconColor = showSyncError ? 'error' : neverSynced ? 'secondary' : 'success'
    const iconName = showSyncError ? 'CircleAlertFilled' : neverSynced ? 'CircleHourglassFilled' : 'CheckCircleFill'
    return (
      <div {...Style.StatusIcon}>
        <Icon color={iconColor} name={iconName} />
      </div>
    )
  }

  renderLogo = () => {
    const { icon, loading, appName } = this.props
    return <div {...Style.CustomIntegrationLogo}>
      <Placeholder loading={loading} type='rect' style={{ width: '100%', height: '100%', maxWidth: '100%', margin: 0 }}>
        <div {...Style.CustomIntegrationAppImage}>
          <AppIcon size='x-large' appName={appName} appImageUrl={icon} />
        </div>
      </Placeholder>
    </div>
  }

  renderDisconnectServiceConfirmationPopup = () => {
    const { showDisconnectConfirmation } = this.state
    const { workflowsToInvalidateOnDisconnect } = this.props
    const workflowsToInvalidateNames = workflowsToInvalidateOnDisconnect.map(wf => wf.name)
    const moreThanOneWorkflow = workflowsToInvalidateOnDisconnect.length > 1
    const workflowsToInvalidateDiv = workflowsToInvalidateNames.length > 0 ? <><span><b>Notice:<br /></b>{moreThanOneWorkflow ? `${workflowsToInvalidateNames.length} workflows` : 'One workflow'} will be invalidated since {moreThanOneWorkflow ? 'their' : 'its'} trigger depends on the integrated account: {workflowsToInvalidateNames.join(', ')}.</span><br /></> : ''
    const content = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Real time data associated with the account will be removed.</span>
        <br />
        {workflowsToInvalidateDiv}
        <span>{'For more details please see: '}
          <Link href='https://support.toriihq.com/hc/en-us/articles/6755817160347#heading-6:~:text=and%20custom%20integrations.-,Disconnect%20integrated%20account,-In%20some%20cases' target='_blank'>Disconnecting an integrated account</Link>
        </span>
        <span>Note that it may take up to several hours for this action to be completed.</span>
      </div>
    )

    return <Confirmation
      isOpen={showDisconnectConfirmation}
      header='Remove and Revoke App Account ID?'
      text={content}
      confirmText='Remove'
      confirm={this.onConfirmDisconnection}
      declineText='Cancel'
      decline={this.onDeclineDisconnection}
      close={this.onDeclineDisconnection}
      bgColor={colors.red}
      modalWidth='480px'
      mainButtonType={ButtonType.destructive}
    />
  }
}

export default CustomIntegrationService
