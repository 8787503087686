import React, { ReactElement, useState, useEffect } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { SCOPES, WORKFLOW_TAG_NAME_MAX_LENGTH } from '@root/constants'
import { toggleEditWorkflowTagsPopup } from '@actions/'
import { getWorkflowsById, getWorkflowTags as getWorkflowTagsSelector } from '@selectors/workflows'
import MultipleCheckboxSelect from '@components/multipleCheckboxSelect'
import FormGroup from '@components/form/formGroup'
import { AlertBox, AlertBoxType, Tag, TagSize, TagTheme } from '@toriihq/design-system'
import { Workflow } from '@shared/workflows/types'
import { isEmpty, isEqual } from 'lodash'
import { ContentAreaStyle, CreatableSelectOption } from './styles'
import { createWorkflowTags, getWorkflowTags, updateWorkflowTags } from '@actions/workflows'
import { analytics } from '@shared/services/workflows/analytics'
import { getSortedTags } from '@lenses/workflows.t'

const EditWorkflowTagsPopup = (): ReactElement => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen, idWorkflow } = useSelector(state => state.ui.editWorkflowTagsPopup)
  const workflow: Workflow = useSelector(getWorkflowsById)[idWorkflow] ?? {}
  const tagOptions = useSelector(getWorkflowTagsSelector)

  const dispatch = useDispatch()

  const initialSelectedTags = workflow.tags?.map(tag => tag.id) ?? []
  const [selectedTags, setSelectedTags] = useState(initialSelectedTags)
  const [actionError, setActionError] = useState('')

  const isTagsDirty = !isEqual(initialSelectedTags.sort(), selectedTags.sort())

  useEffect(() => {
    dispatch(getWorkflowTags({ idOrg }))
  }, [dispatch, idOrg])

  const closePopup = (): void => {
    dispatch(toggleEditWorkflowTagsPopup({ isOpen: false }))
  }

  const onClose = (): void => {
    analytics.tags.popupClosed({ buttonLabel: 'X' })
    closePopup()
  }

  const onCancel = () => {
    analytics.tags.popupClosed({ buttonLabel: 'Cancel' })
    closePopup()
  }

  const onChange = (updatedTags: number[]): void => {
    const tagsValues = updatedTags.map(tag => Number(tag))
    setSelectedTags(tagsValues)
  }

  const onSave = async (): Promise<void> => {
    try {
      analytics.tags.save()
      await dispatch(updateWorkflowTags({ idOrg, idWorkflow, idTags: selectedTags }))
      setActionError('')
      setTimeout(onClose, 500)
    } catch (err) {
      setActionError('The changes couldn\'t be saved. Try saving again.')
      throw new Error(`Unable to save tags (${selectedTags}) for workflow: ${idWorkflow}`)
    }
  }

  const renderOption = (option) => {
    if (option.created) {
      return <Tag color='grey' theme={TagTheme.Light} size={TagSize.Small}>{option.label}</Tag>
    }

    const tagName = option.label.match(/"([^"]+)"/)[1]
    return <>
      <CreatableSelectOption>Create</CreatableSelectOption>
      <Tag color='grey' theme={TagTheme.Light} size={TagSize.Small} >{tagName}</Tag>
    </>
  }

  const onNewOptionClick = async (inputValue) => {
    try {
      const { tags } = await dispatch(createWorkflowTags({ idOrg, tags: [inputValue] }))
      setSelectedTags([
        ...selectedTags,
        ...tags.map(tag => tag.id)
      ])
      setActionError('')
    } catch (err) {
      if ((err as Error)?.message?.includes('already exist')) {
        setActionError(`"${inputValue}" tag already exists.`)
      } else {
        setActionError('The tag couldn\'t be created. Try creating again.')
      }
    }
  }

  const options = getSortedTags(tagOptions).map(tag => ({ value: tag.id, label: tag.label, created: true }))

  return <ToriiPopup isOpen={isOpen} onCloseAction={onClose} closeOnOverlayClick={false}>
    <ToriiPopup.Header header={`Edit tags`} />
    <ToriiPopup.Content contentAreaStyle={ContentAreaStyle}>
      {
        actionError ? (
          <FormGroup>
            <AlertBox type={AlertBoxType.NEGATIVE} description={actionError} />
          </FormGroup>
        ) : null
      }
      <FormGroup label='Workflow'>
        <div>{workflow.name}</div>
      </FormGroup>
      <FormGroup label='Tags'>
        <MultipleCheckboxSelect
          loadingPlaceholder={'Loading...'}
          options={options}
          isLoading={isEmpty(tagOptions)}
          selectedValues={selectedTags}
          showValues
          searchable
          cache={false}
          noResultsText='No tag found'
          closeMenuOnSelect={false}
          closeOnSelect={false}
          onChange={onChange}
          placeholder='Select tags...'
          renderOption={renderOption}
          creatable
          onNewOptionClick={onNewOptionClick}
          isValidNewOption={(inputValue, value, options) => {
            if (!inputValue) return false
            return Boolean(inputValue.length <= WORKFLOW_TAG_NAME_MAX_LENGTH && !options.find(option => option.label === inputValue))
          }}
        />
      </FormGroup>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonAction={onSave}
      mainButtonText='Save'
      scopes={[SCOPES.AUTOMATION_WRITE]}
      cancelButtonAction={onCancel}
      cancelButtonText='Cancel'
      isMainButtonDisabled={!isTagsDirty}
      onClose={onClose}
    />
  </ToriiPopup>
}

export default EditWorkflowTagsPopup
