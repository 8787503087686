import React from 'react'
import { useSelector } from 'react-redux'
import Table from '../../../table'
import { getColumns } from './transactionsPreviewColumn/transactionsPreviewColumn'
import { getIsLoadingTransactions } from '@selectors/transactions'
import PropTypes from 'prop-types'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import * as Style from './style'
import colors from '@shared/style/colors'
import { TRANSACTION_MAPPING_STATUS } from '@root/constants'
import emptyStateImage from './images/noTransactions.svg'
import { AlertBox, AlertBoxType, Tooltip } from '@toriihq/design-system'
import { css } from 'glamor'

const TransactionsPreview = ({ transactions, matchingRule, fetchData, numberOfOverrideMapped, onSortChange, total }) => {
  const appsById = useSelector(deprecatedGetAppsByIds)
  const columns = getColumns({ term: matchingRule.term, appsById })

  const isLoading = useSelector(getIsLoadingTransactions)
  const loading = isLoading && transactions.length === 0

  const trStyleRule = (rowInfo) => {
    const status = rowInfo.row.mappingStatus
    const idApp = matchingRule.app ? matchingRule.app.id : null

    if (status === TRANSACTION_MAPPING_STATUS.MAPPED) {
      return idApp ? rowInfo.row.idApp && rowInfo.row.idApp !== idApp : true
    } else {
      return status === TRANSACTION_MAPPING_STATUS.IGNORED
    }
  }

  const warningMappedText = <strong>{numberOfOverrideMapped ? `${numberOfOverrideMapped} matched` : ''}</strong>
  const warningText = <div>{warningMappedText} transactions will be impacted by this rule. If this is not what you want, fine-tune your rule definition.</div>

  const renderNoTransacions =
    <div {...css(Style.NoTransactionsContainer, Style.MarginTop({ margin: '15px' }))}>
      <div {...Style.NoTranscationImageWrapper}>
        <img alt='No transactions were found' src={emptyStateImage} />
      </div>
      <div>
        <div {...css(Style.Flex, Style.Bold, Style.MarginTop({ margin: '20px' }, Style.ShowingResultStyle))}>
          No results found for "
          <Tooltip label={matchingRule.term}>
            <div {...Style.NoResultsTerm({ maxWidth: '140px' })}>
              {matchingRule.term}
            </div>
          </Tooltip>
          "
        </div>
        <div {...css(Style.ShowingResultStyle, Style.NoResultsText, Style.MarginTop({ margin: '5px' }))}>
          <div>
            Please keep in mind that matching rules only apply to <div {...Style.Flex}>full words. If<div {...css(Style.NoResultsTerm({ maxWidth: '125px' }), Style.MarginSides({ margin: '4px' }), Style.BoldAndItalic)}>{matchingRule.term }</div>is only a part of the word </div> you’re looking for, type the full word and try again.
          </div>
        </div>
      </div>
    </div>

  const macthingRuleValid = () => {
    const { op, term, mappingStatus, app } = matchingRule
    if (mappingStatus === TRANSACTION_MAPPING_STATUS.MAPPED) {
      return op && term && app
    } else if (mappingStatus === TRANSACTION_MAPPING_STATUS.IGNORED) {
      return op && term
    } else {
      return false
    }
  }

  const showWarningBar = macthingRuleValid() && numberOfOverrideMapped > 0
  return (
    <div {...Style.MarginTop({ margin: showWarningBar ? '16px' : '30px' })}>
      {showWarningBar && <AlertBox type={AlertBoxType.NOTICE} description={warningText} />}
      <div>
        {transactions.length === 0 ? null
          : <div {...css(Style.HeadlineText, Style.MarginTop({ margin: showWarningBar ? '16px' : '0' }))}>
            <div {...Style.Bold}>Preview matching transactions</div>
            <div {...Style.ShowingResultStyle}>Showing {total} Transactions</div>
          </div>}
        <div {...Style.TableWrapper({ tableHeight: showWarningBar ? '300px' : '335px' })} id={'transactions-table'}>
          {transactions.length === 0 && !loading
            ? renderNoTransacions
            : (
              <Table
                data={transactions}
                columns={columns}
                fetchData={() => fetchData({ reset: false, matchRule: matchingRule })}
                loading={loading}
                overrideTrStyleRule={(rowInfo) => trStyleRule(rowInfo) && macthingRuleValid()}
                highlightStyle={{ backgroundColor: colors.lightYellow, borderLeft: `4px solid ${colors.orange3}` }}
                onSortedChangeCB={onSortChange}
                manual
                totalCount={total}
                style={{ minHeight: 0 }}
                tableHeaderStyle={{ paddingBottom: 0 }}
                scrollObjectId={'transactions-table'}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

TransactionsPreview.propTypes = {
  idOrg: PropTypes.number.isRequired
}

export default TransactionsPreview
