import View from './view'
import { connect } from 'react-redux'
import { toggleCsvExportPopup } from '@actions/'
import { getSelf } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = state => {
  const { csvExportPopup } = state.ui
  const { id: idOrg } = getCurrentOrg(state)
  const me = getSelf(state)

  return {
    isOpen: csvExportPopup.isOpen,
    idOrg,
    email: me.email
  }
}

const mapDispatchToProps = {
  toggleCsvExportPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleCsvExportPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleCsvExportPopup({ isOpen: false })
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
