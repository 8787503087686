import View from './view'
import { connect } from 'react-redux'
import { toggleImportContracts } from '@actions/'

const mapStateToProps = state => {
  const { isImportContractsOpen: isOpen } = state.ui
  return {
    isOpen
  }
}

const mapDispatchToProps = {
  toggleImportContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel: () => dispatchProps.toggleImportContracts(false, true),
  close: () => dispatchProps.toggleImportContracts(false, false)
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
