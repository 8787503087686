import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import {
  H3,
  Icon,
  Avatar,
  Tooltip,
  AppIcon,
  Spacer,
  Button,
  ButtonType,
  ButtonSize,
  ProgressBar,
  ExpandableSection,
  Body2
} from '@toriihq/design-system'
import { getDisplayName } from '@lenses/users'
import * as Style from './styles'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { TABLES } from '../../constants'
import BasicTopBar from '../../components/basicTopBar'
import Table from '../../components/table'
import UserDetails from '../../components/userDetails'
import Placeholder from '../../components/placeholder'
import UsersIconsList from '../../components/usersIconsList'
import ToriiPopup from '../../components/popups/ToriiPopupV2'
import pluralize from 'pluralize'
import uniq from 'lodash/uniq'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import ResizeDetector from 'react-resize-detector'
import { getFormattedDate } from '@lenses/utils'
import NotFoundImg from '@media/404.svg'
import CheckmarkImg from '@media/checkmark.svg'
import Analytics from '@helpers/analytics'
import { isEqual, isUndefined } from 'lodash'

const HEADER_HEIGHT = 60
const ROW_HEIGHT = 90
const SMALL_SCREEN_WIDTH = 768

const CSS = {
  main: css({
    maxWidth: '1440px',
    padding: '60px 20px',
    margin: '0 auto',
    '@media(max-width: 768px)': {
      padding: '20px 10px'
    }
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  subHeader: css(texts.heading, {
    color: colors.grey2,
    margin: '33px 0 24px 0'
  }),
  tasksDescription: css(texts.heading, {
    color: colors.grey2,
    marginBottom: '24px'
  }),
  textAndIconBox: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
  }),
  description: css(texts.body, {
    marginTop: '16px'
  }),
  groupHeader: css({
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px',
    height: ROW_HEIGHT
  }),
  tasksStatus: css(texts.caption, {
    marginLeft: '5px'
  }),
  doneIcon: css({
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    lineHeight: '18px',
    textAlign: 'center',
    transform: 'none',
    fontSize: '8px',
    backgroundColor: '#00E0CF',
    color: colors.white
  }),
  error: css({
    color: colors.red
  }),
  requestedBy: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '122px'
  }),
  appTasksWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginTop: 16
  })
}

class UserOffboardingTasksPage extends React.Component {
  state = { selectedIdApp: null, columns: [], isSmallScreen: false }

  componentDidMount () {
    const { getUserTasks, idOrg, idUser, token } = this.props
    getUserTasks({ idOrg, idUser, token })
  }

  componentDidUpdate (prevProps) {
    if (!isEmpty(this.props.users) && prevProps.users !== this.props.users) {
      this.setState({ columns: this.getColumns(this.state.isSmallScreen) })
    }

    if (!isEmpty(this.props.tasks) && prevProps.tasks !== this.props.tasks && !this.state.selectedIdApp) {
      const selectedIdApp = Object.keys(groupBy(this.props.tasks, 'idApp'))[0]
      this.setState({ selectedIdApp })
    }

    if (!isEqual(prevProps.accessInfo, this.props.accessInfo)) {
      const { sendMethod, isNewNotification } = this.props.accessInfo ?? {}
      if (!sendMethod || isUndefined(isNewNotification)) {
        return
      }

      const notificationType = isNewNotification ? 'first' : 'reminder'

      Analytics.track('Open offboarding-tasks-page', {
        'Open from': sendMethod,
        'Notification type': notificationType
      })
    }
  }

  getColumns = (isSmallScreen) => {
    const { users, toggleShowOffboardingMessage, updateTaskCompletionStatus } = this.props
    const applyCompletionStyle = isCompleted => isCompleted ? { opacity: 0.5 } : {}

    return [
      {
        Header: 'User',
        accessor: 'onUser',
        Cell: ({ value: idUser, row: { isCompleted } }) => {
          const { firstName, lastName, email, isExternal, photoUrl } = users[idUser] || {}
          return <UserDetails
            firstName={firstName}
            lastName={lastName}
            email={email}
            idUser={idUser}
            isExternal={isExternal}
            photoUrl={photoUrl}
            overrideStyle={applyCompletionStyle(isCompleted)}
            linkToUserPage={false}
          />
        },
        sortMethod: (a, b) => Table.sortMethods.user(users, a, b)
      },
      {
        Header: 'Email',
        Cell: ({ row: { onUser, isCompleted } }) => {
          const { originEmail } = users[onUser] || {}
          return <div style={applyCompletionStyle(isCompleted)}>{originEmail}</div>
        }
      },
      {
        Header: 'Account',
        accessor: 'appAccountName',
        Cell: ({ value: appAccountName, row: { isCompleted } }) => {
          return <div style={applyCompletionStyle(isCompleted)}>{appAccountName}</div>
        }
      },
      {
        Header: 'Requested on',
        accessor: 'creationTime',
        Cell: ({ value: creationTime, row: { isCompleted } }) => {
          return <div style={applyCompletionStyle(isCompleted)}>{getFormattedDate({ date: creationTime })}</div>
        },
        showFunc: () => !isSmallScreen
      },
      {
        Header: 'Requested by',
        accessor: 'createdBy',
        Cell: ({ value: idUser, row: { isCompleted, message } }) => {
          const { firstName, lastName, photoUrl } = users[idUser] || {}
          return <Style.Wrapper isCompleted={isCompleted}>
            <Tooltip label={getDisplayName(users[idUser])}>
              <Avatar
                firstName={firstName}
                lastName={lastName}
                imageUrl={photoUrl}
              />
            </Tooltip>
            <Spacer left={'space-100'} />
            {message && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => toggleShowOffboardingMessage({ isOpen: true, message })} label='View message' />}
          </Style.Wrapper>
        },
        showFunc: () => !isSmallScreen
      },
      {
        Header: 'Status',
        accessor: 'isCompleted',
        Cell: ({ value: isCompleted, row: { id: idTask } }) => {
          return isCompleted
            ? <Icon name='CheckCircleFill' color='success' />
            : <Button onClick={() => updateTaskCompletionStatus({ isCompleted: true, idTask })} label='Mark as done' />
        }
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'message',
        show: false
      }
    ]
  }

  renderAuthError () {
    return this.renderTextAndIcon(
      <img alt='Not found' src={NotFoundImg} width='110' />,
      'You don\'t have the required permissions')
  }

  renderDoneMessage () {
    return this.renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      `You've completed all your offboarding tasks!`)
  }

  renderTextAndIcon (element, header) {
    return <div {...CSS.textAndIconBox}>
      {element}
      <H3>{header}</H3>
    </div>
  }

  onSelectGroup = (idApp) => {
    const { selectedIdApp } = this.state
    const isSelected = (selectedIdApp === idApp)
    this.setState({ selectedIdApp: isSelected ? null : idApp })
  }

  renderTasksTable (tasks) {
    const { columns } = this.state
    const minHeight = tasks.length * ROW_HEIGHT
    return <Table
      tableKey={TABLES.userTasksTable.key}
      data={tasks}
      columns={columns}
      style={{ minHeight }}
      tableHeaderStyle={{ padding: 0, paddingTop: 8 }}
      forceShowNumberOfResults
    />
  }

  renderAppTasks (appTasks, idApp) {
    const { isSmallScreen } = this.state
    const app = this.props.apps[idApp]
    const maxHeight = (appTasks.length + 1) * ROW_HEIGHT + HEADER_HEIGHT + 15
    const { selectedIdApp } = this.state
    const isOpen = (selectedIdApp === idApp)
    const completedTasks = appTasks.filter(task => task.isCompleted).length
    const totalTasks = appTasks.length

    return (
      <ExpandableSection
        key={idApp}
        maxHeight={maxHeight}
        avatar={<AppIcon appImageUrl={app.imageUrl} appName={app.name} />}
        title={app.name}
        rightSlot={(
          <Style.RightSlotWrapper>
            {!isSmallScreen && (
              <Style.ProgressBarContainer>
                <ProgressBar value={completedTasks} max={totalTasks} size='Large' />
              </Style.ProgressBarContainer>
            )}
            <Body2>{completedTasks} / {totalTasks} completed</Body2>
          </Style.RightSlotWrapper>
        )}
        isOpen={isOpen}
        onToggle={() => this.onSelectGroup(idApp)}
      >
        {this.renderTasksTable(appTasks)}
      </ExpandableSection>
    )
  }

  renderTasks () {
    const { tasks, users, isUpdateError, idUser } = this.props
    const tasksByApps = groupBy(tasks, 'idApp')
    const incompleteTasks = tasks.filter(task => !task.isCompleted)
    const idUsersToRemove = uniq(incompleteTasks.map(task => task.onUser))
    const usersToRemove = Object.values(users || {}).filter(user => idUsersToRemove.includes(user.id))
    const idApps = uniq(incompleteTasks.map(task => task.idApp))
    const hiText = users[idUser] ? `Hi ${users[idUser].firstName},` : ''
    return <>
      <div {...CSS.headerContainer}>
        {hiText && <div {...texts.headers.medium}>{hiText}</div>}
        <div {...texts.headers.medium}>Please complete your offboarding tasks</div>
        <div {...CSS.subHeader}>{`You have ${pluralize('task', incompleteTasks.length, true)}`}</div>
        {Boolean(incompleteTasks.length) && <div {...CSS.tasksDescription}>{`Please make sure you complete the offboarding of ${pluralize('user', idUsersToRemove.length, true)} across ${pluralize('application', idApps.length, true)}`}</div>}
        <UsersIconsList center users={usersToRemove.map(user => ({ ...user, email: user.originEmail }))} maxUsers={8} />
      </div>
      {isUpdateError && <div {...CSS.error}>Something went wrong, please try again</div>}
      <div {...CSS.appTasksWrapper}>{map(tasksByApps, (appTasks, idApp) => this.renderAppTasks(appTasks, idApp))}</div>
    </>
  }

  renderMessagePopup () {
    const { isShowMessage, message, toggleShowOffboardingMessage } = this.props
    return <ToriiPopup
      isOpen={isShowMessage}
      onCloseAction={() => toggleShowOffboardingMessage({ isOpen: false })}
    >
      <ToriiPopup.Content>
        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: message }} />
      </ToriiPopup.Content>
    </ToriiPopup>
  }

  onResize = (width) => {
    const isSmallScreen = width < SMALL_SCREEN_WIDTH

    if (this.state.isSmallScreen !== isSmallScreen) {
      this.setState({ isSmallScreen, columns: this.getColumns(isSmallScreen) })
    }
  }

  render () {
    const { loading, isAuthError, isCompletedAllTasks } = this.props
    const showTasks = !isAuthError && !isCompletedAllTasks
    const showCompletedAllTasks = !isAuthError && isCompletedAllTasks

    return (
      <>
        <BasicTopBar />
        <div {...CSS.main}>
          <ResizeDetector handleWidth onResize={this.onResize} />
          <Placeholder loading={loading} type='text' rows={6} style={{ width: '100%' }}>
            {isAuthError && this.renderAuthError()}
            {showTasks && this.renderTasks()}
            {showCompletedAllTasks && this.renderDoneMessage()}
          </Placeholder>
        </div>
        {this.renderMessagePopup()}
      </>
    )
  }
}

UserOffboardingTasksPage.propTypes = {
  loading: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  apps: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  isAuthError: PropTypes.bool
}

export default UserOffboardingTasksPage
