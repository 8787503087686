import View from './view'
import { connect } from 'react-redux'
import { toggleSelectFieldTypePopup } from '@actions/'

const mapStateToProps = state => {
  const { isOpen, idGroup, addDetailsField, groupsForSelectGroup, hideFormQuestion, entityType } = state.ui.selectFieldTypePopup
  return {
    isOpen,
    idGroup,
    addDetailsField,
    groupsForSelectGroup,
    hideFormQuestion,
    entityType
  }
}

const mapDispatchToProps = {
  toggleSelectFieldTypePopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleSelectFieldTypePopup(false)
  },
  close () {
    dispatchProps.toggleSelectFieldTypePopup(false)
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
