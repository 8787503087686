import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../table/index'
import { DATE_FORMAT, ITEMS_PER_PAGE, TABLES } from '../../../constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import moment from 'moment'
import { getNameColumn, getStartedByColumns } from '../columns'
import emptyImage from '@media/dance.svg'
import debounce from 'lodash/debounce'
import { sortStringFromArray } from '../../../shared/utils'
import config from '../../../config'
import { getTdStyle } from '../styles'
import { EmptyState, H4 } from '@toriihq/design-system'
import { isParentUserFilter } from '@actions/'

const getCellDate = date => (date && moment.utc(date).format(DATE_FORMAT)) || '-'

class Done extends React.Component {
  state = { q: '' }

  constructor (props) {
    super(props)
    this.columns = this.getColumns()
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData(true)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData(true)
    }
  }

  getHeader = () => {
    const { title, total } = this.props
    return <H4>{`${title} (${total}) `}</H4>
  }

  getColumns = () => {
    return [
      getNameColumn(),
      {
        Header: 'Date of departure',
        id: 'identitySourcesDeletionTime',
        accessor: 'identitySourcesDeletionTime',
        Cell: ({ value }) => getCellDate(value),
        width: 150
      },
      {
        accessor: 'email',
        show: false,
        textHeader: 'Email'
      },
      {
        Header: 'Completed at',
        id: 'offboardingEndTime',
        accessor: 'offboardingEndTime',
        Cell: ({ value }) => getCellDate(value),
        width: 150
      },
      ...getStartedByColumns(),
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      }
    ]
  }

  getUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, sort = this.props.defaultSort, q = this.state.q, reset = false }) => {
    const { idOrg, getOffboardingDoneUsers } = this.props
    getOffboardingDoneUsers({ idOrg, limit, offset, sort: sortStringFromArray(sort), q, reset })
  }

  fetchData = debounce((reset = false) => {
    const { users } = this.props

    this.getUsers({ offset: reset ? 0 : users.length, reset })
  }, 500, { leading: true, trailing: false })

  onSortedChange = (sort) => {
    this.getUsers({ sort, reset: true })
  }

  onSearch = debounce((q) => {
    this.setState({ q })
    this.getUsers({ reset: true, q })
  }, 250)

  exportToCsv = ({ sort, query }) => {
    const { idOrg } = this.props

    const sortParam = 'sort=' + sortStringFromArray(sort)
    const queryParam = `q=${query}`
    const fieldsParam = `fields=firstName,lastName,email,identitySourcesDeletionTime,offboardingEndTime`
    const filters = `lifecycleStatus=${USER_LIFECYCLE_STATUS.OFFBOARDED}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify([isParentUserFilter]))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${filters}&${filtersParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  renderNoUsers = () => {
    return <EmptyState
      image={<img src={emptyImage} alt='No users' />}
      title='There are no completed offboardings yet'
    />
  }

  searchFilterMethod (row, search) {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  render () {
    const { users, total, loading, loadingMore } = this.props
    return (
      <Table
        tableKey={TABLES.offboardingDoneTable.key}
        emptyStateMessage={this.renderNoUsers()}
        data={users}
        header={this.getHeader}
        columns={this.columns}
        loading={loading}
        loadingMore={loadingMore}
        exportable
        searchable
        searchFilterMethod={this.searchFilterMethod}
        getTdProps={getTdStyle}
        manual
        fetchData={this.fetchData}
        onSortedChangeCB={this.onSortedChange}
        onSearch={this.onSearch}
        forceShowSearch
        exportFunction={this.exportToCsv}
        totalCount={total}
      />
    )
  }
}

Done.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lifecycleStatus: PropTypes.string.isRequired,
    identitySourcesDeletionTime: PropTypes.string
  }))
}

export default Done
