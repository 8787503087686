import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrencySymbol } from '@selectors/org'
import { getMonthlyExpensesPerApp } from '@selectors/transactions'

const mapStateToProps = (state, ownProps) => {
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const currencySymbol = getCurrencySymbol(state)
  const expensesAggsPerApp = getMonthlyExpensesPerApp(state)

  return {
    idApp,
    idOrg,
    currencySymbol,
    expensesAggs: expensesAggsPerApp[idApp]
  }
}

export default withRouter(connect(mapStateToProps)(View))
