import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { WIZARD_STEPS, SharedProps, SharedState } from '../types'
import { Content } from './style'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { useDispatch } from 'react-redux'
import { getSyncDataForAppAccount, toggleImportUsers, updateImportAppUsersStatus } from '@actions/'
import useEffectOnce from '@root/shared/hooks/useEffectOnce'
import { Spacer, Spinner, SpinnerColor, Subtitle2, toast, ToastType } from '@toriihq/design-system'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import { UsePolling } from '../usePolling'
import { SYNC_STATUS } from '@root/constants'
import { IMPORT_TYPE } from '@selectors/importUsers/types'

const LoadingAfterImport = ({
  sharedState, sharedProps, setState, navigateToStep
}: PopupRendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const dispatch = useDispatch()

  const onPollFailure = () => {
    clearPolling()
  }

  const { startPolling, clearPolling } = UsePolling({
    onPollFailure
  })

  const pollLogic = async ({ idOrg, idAppAccount, lastSyncTime, importMode }) => {
    const response = await dispatch(getSyncDataForAppAccount({ idOrg, idAppAccount }))
    const { syncStatus } = response
    const { syncStatus: appAccountSyncStatus, lastSyncTime: newSyncTime } = syncStatus[0]
    const isFinishLaterSync = lastSyncTime ? lastSyncTime < newSyncTime : true
    if (appAccountSyncStatus === SYNC_STATUS.FINISHED_SUCCESSFULLY && isFinishLaterSync) {
      const SECONDS_TO_WAIT_AFTER_FINISH = 3
      clearPolling()
      dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_IN_PROGRESS))
      setTimeout(() => {
        toast({ type: ToastType.SUCCESS, message: importMode === IMPORT_TYPE.USERS ? 'Users imported successfully' : 'User licenses adjusted successfully' })
        dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED))
        closePopup()
      }, SECONDS_TO_WAIT_AFTER_FINISH * 1000)
    } else if (appAccountSyncStatus === SYNC_STATUS.FINISHED_FAILED) {
      setState({
        ...sharedState,
        failureMessage: sharedProps.importMode === IMPORT_TYPE.LICENSES
          ? 'The license adjustment could not be completed due to sync errors in the integration. Please resolve the errors and try again.'
          : 'Something went wrong during the import. Please try again later.'
      })
      navigateToStep(WIZARD_STEPS.FAILURE)
    }
  }

  useEffectOnce(() => {
    const fetchData = async () => {
      const { idOrg, lastSyncTime, importMode } = sharedProps
      const { idAppAccount } = sharedState
      try {
        await startPolling(() => pollLogic({ idOrg, lastSyncTime, idAppAccount, importMode }))
      } catch (error) {
        throw error
      }
    }
    fetchData()
  })

  const closePopup = () => {
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  return (
    <>
      <ToriiPopup.Header header='Importing users' subHeader={sharedProps.appAccountName} />
      <ToriiPopup.Content>
        <Content>
          <Spinner color={SpinnerColor.active} />
          <Spacer top='space-200'>
            <Subtitle2>{sharedProps.importMode === IMPORT_TYPE.USERS ? 'Importing your users list...' : 'Importing your license updates...'}</Subtitle2>
          </Spacer>
        </Content>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showMainButton={false}
        showCancelButton={false}
      />
    </>
  )
}

export default LoadingAfterImport
