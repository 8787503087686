import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ChartWidget from '../chartWidget'
import { useDispatch, useSelector } from 'react-redux'
import { getCompareActiveUsers } from '@actions/'
import { keyBy } from 'lodash'
import ActiveUsersPopup from './activeUsersPopup'
import Analytics from '@helpers/analytics'
import EmptyStateImage from '@media/people.svg'
import * as Style from './style'
import { deprecatedGetAppsByIds, getExtensionOnlyApps, isLoadingAppsComparisonData } from '@selectors/apps'
import { customJoin } from '@lenses/utils'
import isUndefined from 'lodash/isUndefined'
import moment from 'moment'
import SourcesTooltip from '@pages/appsComparison/components/sourcesTooltip'
import get from 'lodash/get'
import { ACTIVE_USERS_WIDGET } from '@pages/appsComparison/style'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { Icon, Link, EmptyState, Popover } from '@toriihq/design-system'

const ActiveUsers = (props) => {
  const { idOrg, idApps, getAlerts } = props

  const dispatch = useDispatch()

  const [activeUsers, setActiveUsers] = useState([])
  const [last30Days, setLast30Days] = useState({})
  const [popupState, setPopupState] = useState({ isOpen: false })
  const [usageSources, setUsageSources] = useState({})

  const appsById = useSelector(deprecatedGetAppsByIds)
  const extensionOnlyApps = useSelector(getExtensionOnlyApps)
  const isLoading = useSelector(isLoadingAppsComparisonData)

  const extensionAppsWithData = extensionOnlyApps.filter(app => activeUsers.find(activeUsersInfo => activeUsersInfo.idApp === app.id && !isUndefined(activeUsersInfo.activeUsers)))

  useEffect(() => {
    const getActiveUsers = async ({ idOrg, idApps }) => {
      const { activeUsers, last30Days, usageSources } = await dispatch(getCompareActiveUsers({ idOrg, idApps }))
      setActiveUsers(activeUsers)
      setLast30Days(keyBy(last30Days, 'idApp'))
      setUsageSources(keyBy(usageSources, 'idApp'))
    }

    if (idOrg && idApps) {
      getActiveUsers({ idOrg, idApps })
    }
  }, [idOrg, idApps, dispatch])

  const onActiveUsersLegendClick = (idApp) => {
    setPopupState({ isOpen: true, idApp })
    Analytics.track('Click on N of users per app link / Active users widget / Comparison page / Compare tab / Applications')
  }

  const renderLegendInfo = ({ idApp }) => {
    const value = last30Days[idApp] ? last30Days[idApp].activeUsers : undefined
    const sourcesList = get(usageSources, [idApp, 'usageSources'], [])
    const appName = get(usageSources, [idApp, 'name'])

    return value === undefined ? null : value === 0
      ? <>
        <span {...Style.NonActiveUsers}>No active users</span>
        <SourcesTooltip appName={appName} sourcesList={sourcesList} header={'SOURCES'} />
      </>
      : <>
        <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin showAsDisabled={false}>
          <span {...Style.InfoLine} onClick={() => onActiveUsersLegendClick(idApp)}>{`${value} users`}</span>
        </EnableFor>
        <SourcesTooltip appName={appName} sourcesList={sourcesList} header={'SOURCES'} />
      </>
  }

  const renderLegendDataSeparator = (missingDataArray) => {
    return (
      <span {...Style.MissingDataText}>
        Some data is missing...
        <Popover
          position='top'
          showArrow
          content={<div style={{ width: 230 }}>
            Cannot wait to discover who is using {customJoin(missingDataArray.map(app => app.name))}? <br />Connect the
            apps to your SSO provider or deploy the Torii browser extension.
            <br />
            <Link onClick={onReadMoreButtonClick} href='https://support.toriihq.com/hc/en-us/articles/5129309596955'
              target='_blank'>
              Read more in Torii help center
            </Link>
          </div>}
        >
          <Icon name='Info' color='secondary' />
        </Popover>
      </span>
    )
  }

  const renderTooltipInfo = ({ value }) => {
    return `${value} users`
  }

  const onReadMoreButtonClick = () => {
    Analytics.track('Click on Read more link / Comparison page / Compare tab / Applications', {
      widget: 'Active Users'
    })
  }

  const EmptyStateComponent = () => {
    return <div {...Style.EmptyStateContainer}>
      <EmptyState
        size='small'
        image={<img src={EmptyStateImage} alt='no data' />}
        description={<span>Cannot wait to discover who is using {customJoin(idApps.map(idApp => appsById[idApp] && appsById[idApp].name))}? <br />Connect the apps to your SSO provider or deploy the Torii browser extension.</span>}
        link={<Link href='https://support.toriihq.com/hc/en-us/articles/5129309596955' target='_blank' onClick={onReadMoreButtonClick}>Read more in Torii help center</Link>}
      />
    </div>
  }

  const trendTitle = `User activity over time: ${moment.utc().subtract(11, 'months').format('MMM YYYY')}  - ${moment().format('MMM YYYY')}`

  return (
    <Fragment>
      <ChartWidget
        loading={isLoading}
        subHeaderText='Users that logged in or used apps at least once'
        data={activeUsers}
        title='USER ACTIVITY'
        tooltipTitle='USER ACTIVITY'
        trendTitle={trendTitle}
        yAxisLabel='User activity'
        renderLegendInfo={renderLegendInfo}
        renderLegendDataSeparator={renderLegendDataSeparator}
        renderTooltipInfo={renderTooltipInfo}
        valueKey='activeUsers'
        orderBy={idApps}
        alerts={!isLoading && getAlerts(ACTIVE_USERS_WIDGET, usageSources, extensionAppsWithData)}
        emptyStateComponent={EmptyStateComponent}
      />
      {popupState.isOpen && <ActiveUsersPopup isOpen={popupState.isOpen} idOrg={idOrg} idApp={popupState.idApp} onClose={() => setPopupState({ isOpen: false })} />}
    </Fragment>
  )
}

ActiveUsers.propTypes = {
  getAlerts: PropTypes.func,
  idOrg: PropTypes.number,
  idApps: PropTypes.arrayOf(PropTypes.number)
}

export default ActiveUsers
