import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TABLES } from '../../../constants'
import { getOffboardingDoneUsers } from '@actions/'
import { getUserPreferences } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const tableInfo = TABLES.offboardingDoneTable

  const userPreferences = getUserPreferences(state)
  const { defaultSort = [] } = userPreferences[TABLES.offboardingDoneTable.key] || {}
  return {
    idOrg,
    tableInfo,
    defaultSort
  }
}

const mapDispatchToProps = {
  getOffboardingDoneUsers
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
