import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2/popupContext'
import Modal from 'react-responsive-modal'
import * as Style from '@components/popups/ToriiPopupV2/style'
import ConfirmationPopup from '@components/popups/ToriiPopupV2/confirmationPopup'
import { useMedia } from '@shared/hooks'
import { css } from 'glamor'

const RenderModals = ({ children, closeOnOverlayClick, modalOverrideStyle, styles = {}, isOpen, onCloseAction, width, height, showCloseIcon = true }) => {
  const { onClose, isConfirmCloseOpen, setIsConfirmCloseOpen } = useContext(ToriiPopupContext)
  const SMALL_SCREEN_WIDTH = '1025px'

  const [isSmallScreen, setIsSmallScreen] = useState()

  const screenChanged = useMedia(`(max-width: ${SMALL_SCREEN_WIDTH})`)

  useEffect(() => {
    setIsSmallScreen(screenChanged)
  }, [screenChanged])

  const modalStyle = useMemo(() => {
    return `${isSmallScreen ? css(Style.SmallScreenModal, { width, height }) : css(Style.Modal, { width, height })} ${modalOverrideStyle}`
  }, [isSmallScreen, width, height, modalOverrideStyle])

  return (
    <Fragment>
      <Modal
        showCloseIcon={showCloseIcon}
        closeIconSize={18}
        center
        open={isOpen}
        onClose={onClose}
        closeOnOverlayClick={closeOnOverlayClick}
        classNames={{ closeButton: Style.CloseButton.toString(), modal: modalStyle.toString() }}
        styles={{
          ...styles,
          overlay: {
            ...(styles.overlay ?? {}),
            background: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        {children}
      </Modal>
      <ConfirmationPopup isOpen={isConfirmCloseOpen} quitButtonAction={onCloseAction} stayButtonAction={() => setIsConfirmCloseOpen(false)} />
    </Fragment>
  )
}

export default RenderModals
