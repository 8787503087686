import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state) => {
  const { domain } = getCurrentOrg(state)

  return {
    domain
  }
}

export default connect(mapStateToProps)(View)
