import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import { fontSize } from '../../shared/style/sizes'
import SourcesLine from '@components/dataSourcesBox/sourcesLine/index.jsx'
import { theme } from '@toriihq/design-system'

const CSS = {
  main: css(texts.body, {
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    fontSize: '12px'
  }),
  header: css(texts.heading, {
    padding: '18px 20px',
    ' a': {
      fontSize: fontSize.small
    }
  })
}

const SOURCES_LINE_TO_PROPS = [
  {
    name: 'Users',
    emptyListMessage: 'List of users is not provided by data sources'
  },
  {
    name: 'Usage',
    emptyListMessage: 'Usage is not provided by data sources'
  },
  {
    name: 'Expenses',
    emptyListMessage: <div>No expenses source was connected<br /> Please connect an expenses integration or upload an expenses file</div>
  },
  {
    name: 'Contracts',
    emptyListMessage: 'No contract uploaded'
  }
]

class DataSourcesBox extends React.Component {
  render () {
    const { usersSources, usageSources, expensesSources, contractSources, appName, isSmallScreen } = this.props
    const dataSources = [usersSources, usageSources, expensesSources, contractSources]
    return (
      <div {...CSS.main}>
        <header {...CSS.header}>DATA SOURCES</header>
        {dataSources.map((dataSource, index) => {
          const { sources: sourcesList, disabledSource = {} } = dataSource
          return <SourcesLine
            sourcesList={sourcesList}
            disabledSource={disabledSource}
            appName={appName}
            {...SOURCES_LINE_TO_PROPS[index]}
            isSmallScreen={isSmallScreen}
          />
        })}
      </div>
    )
  }
}

DataSourcesBox.propTypes = {
  usersSources: PropTypes.object.isRequired,
  usageSources: PropTypes.object.isRequired,
  expensesSources: PropTypes.object.isRequired,
  contractSources: PropTypes.object.isRequired
}

DataSourcesBox.defaultProps = {
  usersSources: { sources: [] },
  usageSources: { sources: [] },
  expensesSources: { sources: [] },
  contractSources: { sources: [] }
}

export default DataSourcesBox
