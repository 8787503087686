import View from './view'
import { connect } from 'react-redux'
import { permissionsLegend } from '../riskIcon'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { getSourcesWithApps, isAppPermissionsLoading } from '@selectors/apps'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps
  const loading = isAppPermissionsLoading(state)

  const sourcesWithApps = getSourcesWithApps(state)
  const { apps: connectedAppsPermissions = [], name } = sourcesWithApps.find(source => source.idApp === idApp) || {}
  const basicChartInfo = sortBy(map(permissionsLegend, (permission, index) => { return { name: permission.text, color: permission.innerColor, riskLevel: parseInt(index) } }), permission => -permission.riskLevel)
  const chartInfo = basicChartInfo
    .map(permissionOption => ({
      ...permissionOption,
      value: connectedAppsPermissions.filter(app => app.riskLevel === permissionOption.riskLevel).length
    }))

  const hasData = connectedAppsPermissions.length > 0 && !loading

  return {
    header: 'connected apps risk level',
    description: `Learn about ${name || 'source'}'s third party apps risk level`,
    loading: loading && !hasData,
    data: chartInfo,
    link: {
      path: '/security',
      text: 'see full report'
    },
    hasData
  }
}

export default connect(mapStateToProps)(View)
