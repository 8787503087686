import React from 'react'
import emptyImage from '@media/expenses.svg'
import { useSelector } from 'react-redux'
import { SCOPES } from '@root/constants'
import { getIsLoadingTransactions } from '@selectors/transactions'
import { useHistory } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import EnableFor from '@components/enableFor'
import { Button, ButtonType, EmptyState } from '@toriihq/design-system'

const ExpensesEmptyState = ({ customSelectOption, idApp }): JSX.Element | null => {
  const isLoading = useSelector(getIsLoadingTransactions)
  const history = useHistory()
  const { id: idOrg } = useSelector(getCurrentOrg)

  if (isLoading) {
    return null
  }

  if (!customSelectOption.isMainOption) {
    return <div>No results found</div>
  }

  const onButtonClick = () => {
    history.push(`/team/${idOrg}/services`)
  }
  return <EmptyState
    image={<img src={emptyImage} alt='No transactions' />}
    title='There are no transactions'
    description='Discover transactions and get insights by connecting the expenses software.'
    buttons={[
      <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]} allowForToriiAdmin>
        <Button type={ButtonType.primary} onClick={onButtonClick} label='Connect software' />
      </EnableFor>
    ]}
  />
}

export default ExpensesEmptyState
