import { EditUserTypeSources } from './editUserTypeSources'
import { PreviewChanges } from './previewChanges'
import { ChangesApplied } from './changesApplied'
import { SharedProps, SharedState, WIZARD_STEPS } from './types'
import { PopupStepConfig } from '../../wizardPopup/types'

export const stepsConfig: PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS>[] = [
  {
    stepName: WIZARD_STEPS.EDIT_USER_TYPE_SOURCES,
    stepRenderer: EditUserTypeSources
  },
  {
    stepName: WIZARD_STEPS.PREVIEW_CHANGES,
    stepRenderer: PreviewChanges
  },
  {
    stepName: WIZARD_STEPS.CHANGES_APPLIED,
    stepRenderer: ChangesApplied
  }
]

export const deleteStepsConfig: PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS>[] = [
  {
    stepName: WIZARD_STEPS.PREVIEW_CHANGES,
    stepRenderer: PreviewChanges
  },
  {
    stepName: WIZARD_STEPS.CHANGES_APPLIED,
    stepRenderer: ChangesApplied
  }
]
