import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import NativeIntegrationService from '../service/nativeIntegrationService'
import Service from '../service/baseService'
import {
  INTEGRATION_CATEGORY_CONFIG,
  INTEGRATION_CATEGORY,
  SYNC_STATUS
} from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import pick from 'lodash/pick'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import { fontSize, fontWeight } from '@shared/style/sizes'
import ServiceConnectButton from '../service/serviceConnectButton'
import ConnectToriiBotPopup from '../popups/connectToriiBotPopup'
import ConnectDirectIntegrationPopup from '../popups/connectDirectIntegrationPopup'
import ConnectCustomIntegrationPopup from '../popups/connectCustomIntegrationPopup'
import CustomIntegrationService from '@components/service/customIntegrationService'
import ConnectSCIMIntegrationPopup from '@components/popups/connectSCIMIntegrationPopup'
import TabsPage from '@pages/tabs'
import { validTestConnectionInProgress } from '@components/testConnection/utils'
import { Link, theme } from '@toriihq/design-system'
import keyBy from 'lodash/keyBy'
import uniqBy from 'lodash/uniqBy'

const CSS = {
  service: css({
    marginBottom: '14px',
    padding: '10px'
  }),
  confirmation: css({
    display: 'flex'
  }),
  iconContainer: css({
    height: '26px',
    width: '26px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.error,
    color: colors.white,
    marginRight: '8px'
  }),
  icon: css({
    fontSize: '16px',
    transform: 'translateY(2px)'
  }),
  integrationsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-10px',
    marginRight: '-10px'
  }),
  yourIntegrations: css({
    fontSize: fontSize.medium,
    textTransform: 'uppercase',
    fontWeight: fontWeight.semiBold,
    paddingBottom: '14px'
  }),
  yourIntegrationsContainer: css({
    border: '1px solid',
    borderColor: theme.palette.border.primary,
    borderRadius: '8px',
    backgroundColor: colors.background,
    padding: '20px',
    paddingBottom: '10px',
    marginBottom: '50px'
  }),
  customIntegrationsHeaderContainer: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  categoryHeader: css({
    fontSize: fontSize.medium,
    textTransform: 'uppercase',
    fontWeight: fontWeight.semiBold,
    marginBottom: '20px'
  }),
  categoryContainer: css({
    marginBottom: '20px'
  }),
  filter: css({
    fontWeight: fontWeight.semiBold,
    cursor: 'pointer',
    padding: 0
  }),
  amount: css({
    display: 'inline-block',
    color: colors.white,
    borderRadius: '100px',
    padding: '3px 8px',
    width: 'fit-content',
    minWidth: 30,
    marginRight: 4
  }),
  separator: css({
    margin: '0 15px',
    width: 1,
    background: colors.grey3,
    height: 25
  }),
  filterWrap: css({
    display: 'flex',
    alignItems: 'center',
    fontSize: fontSize.small,
    paddingBottom: '25px'
  }),
  noSearchResults: css({
    marginBottom: '30px'
  }),
  tabsContainer: css({
    display: 'flex'
  }),
  tabHeader: css({
    color: colors.black
  })
}

const POLL_INTERVAL_IN_SECONDS = 10
const SERVICE_CONFIGURATION_FIELDS = ['idAppToken', 'source', 'isSyncDisabled', 'idApp', 'idAppAccount', 'isConnected', 'lastSyncTime', 'logo', 'icon', 'syncStatus', 'displayName', 'appAccountName', 'appName', 'description', 'connectionNote', 'syncError', 'errorMessage', 'id', 'connectedBy', 'connectedAt', 'connectedViaLink', 'showConnectAnotherService', 'permission', 'integrationType', 'supportsMultipleAccounts', 'mode', 'workflowsToInvalidateOnDisconnect', 'excludedUIOptions', 'breakdown', 'hasMultipleConnectedAccounts', 'hasMergeUsersRuleConfigured']

class Services extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      recommendedServices: this.getRecommendedServices()
    }

    this.customIntegrationsSectionRef = createRef()
  }

  async componentDidMount () {
    const { getServicesSyncData, getAppsV2Action, idOrg, showSyncStatus, getServicesConfig, deprecatedGetApps, getSupportedFeatures, afterRedirect } = this.props
    if (idOrg) {
      await getServicesConfig({ idOrg })
      deprecatedGetApps({ idOrg })
      getAppsV2Action({ idOrg })
      getSupportedFeatures({ idOrg })

      if (showSyncStatus) {
        const { syncStatus } = await getServicesSyncData({ idOrg })
        const hasSyncInProgress = syncStatus.some(service => service.syncStatus === SYNC_STATUS.IN_PROGRESS)
        this.updateSyncPulling(hasSyncInProgress)
      }

      if (afterRedirect) {
        await this.handleTestConnectionForOAuth()
      }
    }
  }

  async componentDidUpdate (prevProps) {
    const { services, showSyncStatus, getServicesSyncData, idOrg, getServicesConfig, apps } = this.props

    if (idOrg !== prevProps.idOrg) {
      await getServicesConfig({ idOrg })
    }

    if (showSyncStatus) {
      if (idOrg !== prevProps.idOrg) {
        await getServicesSyncData({ idOrg })
      }

      const currHasSyncInProgress = services.some(service => service.syncStatus === SYNC_STATUS.IN_PROGRESS)
      const prevHasSyncInProgress = prevProps.services.some(service => service.syncStatus === SYNC_STATUS.IN_PROGRESS)
      if (currHasSyncInProgress !== prevHasSyncInProgress) {
        this.updateSyncPulling(currHasSyncInProgress)
      }
    }

    if (!isEqual(services, prevProps.services) || !isEqual(apps, prevProps.apps)) {
      const recommendedServices = this.getRecommendedServices()
      this.setState({ recommendedServices })
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
    this.interval = null
  }

  async handleTestConnectionForOAuth () {
    const { toggleConnectSource, getTestConnectionInfo, idOrg } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const idTestConnection = urlParams.get('idTestConnection')
    const idApp = urlParams.get('idApp')
    const testConnectionInfo = await getTestConnectionInfo({ idTestConnection, idOrg, idApp })
    const validTestInProgress = validTestConnectionInProgress(idOrg, parseInt(idApp), testConnectionInfo)
    validTestInProgress && toggleConnectSource({ isConnectSourceOpen: true, sourceIdApp: parseInt(idApp), validTestInProgress })
  }

  getRecommendedServices = () => {
    const { services, apps } = this.props
    const appsToConnect = apps.filter(app => app.recommendedToConnect)
    const appsToConnectById = keyBy(appsToConnect, 'id')
    return uniqBy(services.filter(service => appsToConnectById[service.idApp]), 'idApp')
      .sort((a, b) => (a.name || a.displayName || '').localeCompare(b.name || b.displayName || ''))
  }

  updateSyncPulling (hasSyncInProgress) {
    if (hasSyncInProgress) {
      if (!this.interval) {
        const { getServicesSyncData, idOrg } = this.props
        this.interval = setInterval(() => { getServicesSyncData({ idOrg }) }, POLL_INTERVAL_IN_SECONDS * 1000)
      }
    } else {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  confirm = () => {
    const { history } = this.props

    this.setState({ showConfirmation: false })
    history.replace({ search: '' })
  }

  renderService = (service) => {
    const { allowDisconnection, loading, hideConnectByLink } = this.props
    const serviceConfiguration = pick(service, SERVICE_CONFIGURATION_FIELDS)
    const feature = INTEGRATION_CATEGORY_CONFIG[service.integrationCategory]?.feature

    const commonProps = {
      feature,
      overrideStyle: CSS.service,
      allowDisconnection,
      loading,
      hideConnectByLink,
      ...serviceConfiguration
    }

    const renderServiceByType = () => {
      switch (service.source) {
        case INTEGRATION_TYPE.CUSTOM:
          return <CustomIntegrationService {...commonProps} />
        default:
          return <NativeIntegrationService {...commonProps} />
      }
    }

    return <div {...CSS.service} key={`${service.idAppToken || service.displayName}-${service.source}-${service.type}`}>
      {renderServiceByType()}
    </div>
  }

  renderYourIntegrations = () => {
    const { services, loading, hideConnected, search } = this.props
    const connectedServices = services.filter(service => service.isConnected && service.source !== INTEGRATION_TYPE.CUSTOM)
    const filteredServices = this.filterServices({ search, services: connectedServices })
    if (filteredServices.length === 0 || hideConnected) {
      if (search) {
        return <div {...CSS.noSearchResults}>{`No connected integrations found for "${search}"`}</div>
      }

      return
    }

    return <div {...CSS.yourIntegrationsContainer}>
      <div {...CSS.yourIntegrations}>Your Integrations {loading ? '' : `(${connectedServices.length})`}</div>
      <div {...CSS.integrationsContainer}>
        {filteredServices.map(this.renderService)}
      </div>
    </div>
  }

  renderYourCustomIntegrations = () => {
    const { services, loading, search } = this.props
    const connectedCustomServices = services.filter(service => service.isConnected && service.source === INTEGRATION_TYPE.CUSTOM)
    const filteredServices = this.filterServices({ search, services: connectedCustomServices })
    if (filteredServices.length === 0) {
      return null
    }

    return <div {...CSS.yourIntegrationsContainer} ref={this.customIntegrationsSectionRef}>
      <div {...CSS.customIntegrationsHeaderContainer}>
        <div {...CSS.yourIntegrations}>Your Custom Integrations {loading ? '' : `(${connectedCustomServices.length})`}</div>
        <Link href='https://support.toriihq.com/hc/en-us/articles/5164749028507' target='_blank'>
          Learn more about custom integrations
        </Link>
      </div>
      <div {...CSS.integrationsContainer}>
        {filteredServices.map(this.renderService)}
      </div>
    </div>
  }

  renderRecommended () {
    const { search } = this.props
    const recommendedServices = this.getRecommendedServices()
    const filteredServices = this.filterServices({ search, services: recommendedServices })
    if (filteredServices.length === 0) {
      if (search) {
        return <div {...CSS.noSearchResults}>{`No recommended to connect integration found for "${search}"`}</div>
      }
    }

    return <div {...CSS.categoryContainer}>
      <div {...CSS.integrationsContainer}>
        {filteredServices
          .map(this.renderService)
        }
      </div>
    </div>
  }

  renderNeedsAttention = () => {
    const { loading, hideConnected, search, needsAttentionServices } = this.props
    const filteredServices = this.filterServices({ search, services: needsAttentionServices })
    if (filteredServices.length === 0 || hideConnected) {
      if (search) {
        return <div {...CSS.noSearchResults}>{`No failed integrations found for "${search}"`}</div>
      }
    }

    return <div {...CSS.yourIntegrationsContainer}>
      <div {...CSS.yourIntegrations}>Failed synchronization {loading ? '' : `(${needsAttentionServices.length})`}</div>
      <div {...CSS.integrationsContainer}>
        {filteredServices.map(this.renderService)}
      </div>
    </div>
  }

  renderAddIntegrationBox (isOtherCategory) {
    const { toggleConnectCustomService, isCustomIntegrationEnabledInPlan } = this.props

    if (!isOtherCategory) {
      return null
    }

    return (
      <ServiceConnectButton
        onClick={() => {
          toggleConnectCustomService({ isOpen: true, coordinate: this.customIntegrationsSectionRef.current ? this.customIntegrationsSectionRef.current.offsetTop : 0 })
        }}
        isCustomIntegrationEnabledInPlan={isCustomIntegrationEnabledInPlan}
        overrideStyle={CSS.service}
      />
    )
  }

  filterServices ({ services, isSignupFlow, search }) {
    let filteredServices = services
    if (search) {
      filteredServices = services.filter(service => (service.name || service.appName).toLowerCase().includes(search.toLowerCase()))
    }

    if (isSignupFlow) {
      filteredServices = filteredServices.filter(service => service.showOnSignup)
    }

    return filteredServices
  }

  renderByCategories () {
    const { isConnectServiceEnabled, search, isSignupFlow, services } = this.props
    const groupedServices = groupBy(services.filter(service => !service.isConnected), 'integrationCategory')
    const categoriesConfig = isSignupFlow ? [INTEGRATION_CATEGORY_CONFIG[INTEGRATION_CATEGORY.IDENTITY_PROVIDER]] : Object.values(INTEGRATION_CATEGORY_CONFIG)
    return (
      <div>
        {
          categoriesConfig.map(category => {
            const isOtherCategory = (category.type === INTEGRATION_CATEGORY.OTHER)
            const services = groupedServices[category.type] || []
            const filteredServices = this.filterServices({ search, isSignupFlow, services })
            if ((!isOtherCategory && filteredServices.length === 0) || (isOtherCategory && filteredServices.length === 0 && !isConnectServiceEnabled)) {
              return this.renderAddIntegrationBox(isOtherCategory)
            }

            return (
              <div key={category.type}>
                <div {...CSS.categoryContainer}>
                  <div {...CSS.header}>
                    {!isSignupFlow && <div {...CSS.categoryHeader}>{category.title}</div>}
                  </div>
                  <div {...CSS.integrationsContainer}>
                    {filteredServices.map(this.renderService)}
                    {this.renderAddIntegrationBox(isOtherCategory)}
                  </div>
                </div>
              </div>
            )
          }).filter(Boolean)
        }
      </div>
    )
  }

  getAllIntegrations () {
    return <>
      {this.renderYourIntegrations()}
      {this.renderYourCustomIntegrations()}
      {this.renderByCategories()}
    </>
  }

  render () {
    const { isSignupFlow, needsAttentionServices, getSyncData, loading, idOrg } = this.props
    const { recommendedServices } = this.state
    const tabsHeader = [
      { header: 'All integrations', isLoading: loading },
      { header: 'Recommended to connect', counter: recommendedServices.length, isLoading: loading },
      { header: 'Failed synchronization', counter: needsAttentionServices.length, isLoading: loading, needsAttention: true }
    ]

    const tabsContent = [
      this.getAllIntegrations(),
      this.renderRecommended(),
      this.renderNeedsAttention()
    ]

    const tabsNames = ['all', 'recommended', 'attention']

    return (
      <>
        <TabsPage
          hideTabs={isSignupFlow}
          tabsHeader={tabsHeader}
          tabsContent={tabsContent}
          pageTitle='Integrations'
          pageName='Integrations'
          tabsName={tabsNames}
          path={`/team/${idOrg}/services`}
        />
        <ConnectToriiBotPopup />
        <ConnectCustomIntegrationPopup />
        <ConnectDirectIntegrationPopup getSyncData={getSyncData} />
        <ConnectSCIMIntegrationPopup />
      </>
    )
  }
}

Services.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape(Service.propTypes)).isRequired,
  allowDisconnection: PropTypes.bool,
  showSyncStatus: PropTypes.bool,
  hideConnected: PropTypes.bool,
  isSignupFlow: PropTypes.bool,
  getSyncData: PropTypes.bool,
  hideBeta: PropTypes.bool,
  hideConnectByLink: PropTypes.bool
}

Services.defaultProps = {
  services: [],
  allowDisconnection: true,
  showSyncStatus: true,
  hideConnected: false,
  isSignupFlow: false,
  getSyncData: true,
  hideBeta: false,
  hideConnectByLink: false
}

export default Services
