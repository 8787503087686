import { IconName, Button, ButtonType, ButtonSize, Tooltip } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'

export type Props = {
  icon: IconName
  text: string
  onClick: () => void
  scopes: string[]
  disabled: boolean
  tooltipText: string
  showTooltip: boolean
}

export const UsersImportPopoverButton = ({
  icon,
  text,
  onClick,
  scopes,
  disabled,
  tooltipText,
  showTooltip
}: Props) => (
  <span>
    <Tooltip label={tooltipText} hide={!showTooltip}>
      <EnableFor scopes={scopes}>
        <Button type={ButtonType.compact} size={ButtonSize.small}
          disabled={disabled}
          onClick={onClick}
          icon={icon}
          label={text}
        />
      </EnableFor>
    </Tooltip>
  </span>
)
