import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import * as Style from './style'
import LicenseRecommendation from '../licenseRecommendation'
import LicenseRecommendationUserList from '../licenseRecommendationUserList'
import { useHistory, useLocation } from 'react-router-dom'
import { getRecommendation } from '@actions/'
import { getRecommendations } from '@selectors/recommendations'
import AllDoneRecommendationImage from './images/allDoneRecommendations.svg'
import Analytics from './analytics'
import { getIncludeAppsInPreview } from './../utils'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const LicensesRecommendationDrillDown = (props) => {
  const { idOrg, idRecommendation } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const includeAppsInPreview = getIncludeAppsInPreview()

  useEffect(() => {
    dispatch(getRecommendation({ idOrg, idRecommendation, includeAppsInPreview }))
  }, [dispatch, idOrg, idRecommendation, includeAppsInPreview])

  const { recommendations, loadingRecommendations } = useSelector(getRecommendations)
  const recommendation = recommendations.find(recommendations => recommendations.id === idRecommendation)
  const app = useSelector(deprecatedGetAppsByIds)[recommendation ? recommendation.idApp : null]

  const previousPage = location.pathname.includes('app') && Boolean(recommendation) ? `/team/${idOrg}/app/${recommendation.idApp}/recommendations` : `/team/${idOrg}/licenses/recommendations`

  const onBackClick = () => {
    const analyticsParam = {
      pageName: previousPage.includes('app') ? 'Applications' : 'Licenses',
      appName: app ? app.name : null,
      recommendationType: recommendation ? recommendation.type : null
    }

    Analytics.onBackClickAnalytics(analyticsParam.pageName, analyticsParam.appName, analyticsParam.recommendationType)

    history.push({
      pathname: previousPage,
      search: location.search
    })
  }

  const onAllAppRecommendationsClick = () => {
    Analytics.onViewAllAppRecommendationsClickAnalytics(previousPage.includes('app') ? 'Applications' : 'Licenses', app.name, recommendation.type)
    history.push(`/team/${idOrg}/app/${recommendation.idApp}/recommendations`)
  }

  const renderLicenseRecommendationUserList = () => {
    const emptyState =
      <div {...Style.EmptyStateContainer}>
        <div {...Style.EmptyState}>
          <div>
            <img src={AllDoneRecommendationImage} alt='no-recommendations' />
          </div>
          <div {...Style.TextContainer}>
            <div {...Style.EmptyStateTitle}>All done!</div>
            <div {...Style.EmptyStateText}>Looks like everything was already taken care of.</div>
          </div>
        </div>
      </div>

    const isEmptyState = (!recommendation || (recommendation && recommendation.numberOfUsers === 0)) && !loadingRecommendations
    if (isEmptyState) {
      return emptyState
    }
    if (!recommendation) {
      return null
    }

    return <LicenseRecommendationUserList idOrg={idOrg} recommendation={recommendation} appName={app && app.name} loadingRecommendation={loadingRecommendations} />
  }

  return (
    <div {...Style.Container}>
      <div {...Style.Header}>
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onBackClick} icon='ChevronLeft' label='Back to recommendations' />
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onAllAppRecommendationsClick} label={`View all ${app && app.name} recommendations`} />
      </div>
      <div {...Style.RecommendationDetails}>
        <div {...Style.LicenseRecommendationContainer}>
          {<LicenseRecommendation
            idOrg={idOrg}
            recommendation={recommendation}
            app={app}
            loading={loadingRecommendations}
          />}
        </div>
      </div>
      <div>
        {renderLicenseRecommendationUserList()}
      </div>
    </div>
  )
}

export default LicensesRecommendationDrillDown
