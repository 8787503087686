import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import moment from 'moment'
import pluralize from 'pluralize'
import * as CSS from './styles'
import IceCream from '@media/icecream.svg'
import { ProgressBar } from '@toriihq/design-system'

const TrialCountdown = ({ isOpen = true }) => {
  const currentOrg = useSelector(getCurrentOrg)

  const [trialEndsInText, setTrialEndsInText] = useState()
  const [progress, setProgress] = useState()

  useEffect(() => {
    if (currentOrg) {
      const { creationTime, trialEndTime } = currentOrg
      const trialEndTimeMoment = moment(trialEndTime).utc()
      const now = moment().utc()
      const trialEndsToday = now.isSame(trialEndTimeMoment, 'days')
      const trialEnded = trialEndTimeMoment.isBefore(now)
      let trialRemainingDays = 0

      if (trialEndsToday) {
        setTrialEndsInText('ends today')
      } else if (trialEnded) {
        setTrialEndsInText(`ended ${pluralize('day', now.diff(trialEndTimeMoment, 'days') + 1, true)} ago`)
      } else {
        trialRemainingDays = moment(trialEndTimeMoment).diff(now, 'days')
        setTrialEndsInText(`ends in ${pluralize('day', Math.max(trialRemainingDays, 1), true)}`)
      }

      const trialLength = trialEndTimeMoment.diff(creationTime, 'days') || 1
      setProgress(trialRemainingDays / trialLength)
    }
  }, [currentOrg])

  if (isOpen) {
    return (
      <div {...CSS.MainContainer}>
        <div {...CSS.TrailContainer}>
          <img {...CSS.IceCream} src={IceCream} alt='Trial count down' />
          <div {...CSS.ProgressContainer}>
            Trial {trialEndsInText}
            <ProgressBar size='Large' value={progress * 100} max={100} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div {...CSS.CollapsedContainer}>
        <img {...CSS.IceCream} src={IceCream} alt='Trial count down' />
        <ProgressBar size='Large' value={progress * 100} max={100} />
      </div>
    )
  }
}

export default TrialCountdown
