import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { getWorkflowsTemplates as getWorkflowsTemplatesSelector } from '@selectors/workflows'
import { getWorkflowsTemplates } from '@actions/'
import { createWorkflow } from '@actions/workflows'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const templates = getWorkflowsTemplatesSelector(state)
  const loading = isEmpty(templates)

  return {
    idOrg,
    templates,
    loading
  }
}

const mapDispatchToProps = {
  getWorkflowsTemplates,
  createWorkflow
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
