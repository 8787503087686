import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import toArray from 'lodash/toArray'
import {
  GET_APP_DETAILS_VALUES,
  GET_APP_DETAILS_FIELDS,
  UPDATE_APPS_DETAILS,
  DELETE_APP_DETAILS_FIELD_VALUE,
  UPLOAD_APP_ATTACHMENT,
  GET_STATE_DETAILS,
  UPDATE_APP_DETAILS_FIELD,
  ADD_APP_DETAILS_FIELD,
  GET_APP_DETAILS_GROUPS,
  EDIT_APP_DETAILS_GROUPS,
  ADD_APP_DETAILS_GROUPS,
  DELETE_APP_DETAILS_GROUPS,
  DELETE_APP_DETAILS_FIELD,
  GET_APP_DETAIL_FIELD_HISTORY,
  REORDER_APP_DETAILS_GROUPS,
  REORDER_APP_DETAILS_FIELDS
} from '@root/constants'
import { replaceOneItem, updateOneItem } from '@lenses/utils'

const initialState = {
  loadingValues: false,
  loadingFields: false,
  loadingGroups: false,
  loadingAppStates: false,
  loadingFieldsHistory: false,
  values: {},
  fields: [],
  fieldsHistory: {},
  resources: {}
}

const getNewValues = (state, action) => {
  const { values } = action.payload
  let { idApps = [], idFields = [], systemKeys = [] } = action.meta
  idApps = [].concat(idApps)
  idFields = [].concat(idFields)
  systemKeys = [].concat(systemKeys)

  const allNewValues = flatten(toArray(values))
  const allOldValues = flatten(toArray(state.values))

  const unchangedValues = allOldValues.filter(value => {
    const matchesAppQuery = (idApps.length === 0) || idApps.includes(parseInt(value.idApp))
    const matchesTypeQuery = (idFields.length === 0) || idFields.includes(value.idField)
    const matchesSystemKeyQuery = (systemKeys.length === 0) || systemKeys.includes(value.systemKey)
    const shouldDelete = matchesAppQuery && matchesTypeQuery && matchesSystemKeyQuery
    return !shouldDelete
  })

  return groupBy([
    ...unchangedValues,
    ...allNewValues
  ], 'idApp')
}

const appDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_STATE_DETAILS}_PENDING`: {
      return {
        ...state,
        loadingAppStates: true
      }
    }
    case `${GET_STATE_DETAILS}_RESPONSE`: {
      const newValues = getNewValues(state, action)
      return {
        ...state,
        loadingAppStates: false,
        values: newValues
      }
    }
    case `${GET_APP_DETAILS_VALUES}_PENDING`: {
      return {
        ...state,
        loadingValues: true
      }
    }
    case `${GET_APP_DETAILS_VALUES}_RESPONSE`: {
      const newValues = getNewValues(state, action)
      const { resources } = action.payload

      return {
        ...state,
        loadingValues: false,
        values: newValues,
        resources
      }
    }
    case `${GET_APP_DETAILS_FIELDS}_PENDING`: {
      return {
        ...state,
        loadingFields: true
      }
    }
    case `${GET_APP_DETAILS_FIELDS}_RESPONSE`: {
      const { fields } = action.payload
      return {
        ...state,
        loadingFields: false,
        fields
      }
    }
    case `${GET_APP_DETAILS_GROUPS}_PENDING`: {
      return {
        ...state,
        loadingGroups: true
      }
    }
    case `${GET_APP_DETAILS_GROUPS}_RESPONSE`: {
      const { groups } = action.payload
      return {
        ...state,
        loadingGroups: false,
        groups
      }
    }
    case `${ADD_APP_DETAILS_GROUPS}_RESPONSE`:
    case `${EDIT_APP_DETAILS_GROUPS}_RESPONSE`: {
      const { group } = action.payload
      const modifiedGroups = replaceOneItem(state.groups, group, 'id')

      return {
        ...state,
        groups: modifiedGroups
      }
    }
    case `${REORDER_APP_DETAILS_GROUPS}_PENDING`: {
      const { reorderedGroups } = action.meta
      return {
        ...state,
        groups: reorderedGroups
      }
    }
    case `${REORDER_APP_DETAILS_GROUPS}_RESPONSE`: {
      const { groups } = action.payload
      return {
        ...state,
        groups
      }
    }
    case `${DELETE_APP_DETAILS_GROUPS}_RESPONSE`: {
      const { isDeleted } = action.payload
      const { idGroup } = action.meta

      const modifiedGroups = isDeleted ? state.groups.filter(group => group.id !== idGroup) : state.groups

      return {
        ...state,
        groups: modifiedGroups
      }
    }
    case `${DELETE_APP_DETAILS_FIELD}_RESPONSE`: {
      const { isDeleted } = action.payload
      const { idField } = action.meta
      const modifiedFields = isDeleted ? state.fields.filter(field => field.idField !== idField) : state.fields
      return {
        ...state,
        fields: modifiedFields
      }
    }
    case `${REORDER_APP_DETAILS_FIELDS}_PENDING`: {
      const { reorderedData } = action.meta

      const otherFields = state.fields.filter(field => field.idGroup !== reorderedData[0].idGroup)

      return {
        ...state,
        fields: [...reorderedData, ...otherFields]
      }
    }
    case `${REORDER_APP_DETAILS_FIELDS}_FAILED`: {
      const { previousData, idGroup } = action.meta

      const otherFields = state.fields.filter(field => field.idGroup !== idGroup)

      return {
        ...state,
        fields: [...previousData, ...otherFields]
      }
    }

    case `${UPLOAD_APP_ATTACHMENT}_RESPONSE`:
    case `${DELETE_APP_DETAILS_FIELD_VALUE}_RESPONSE`: {
      const { idApp } = action.meta
      const { values } = action.payload
      return {
        ...state,
        values: {
          ...state.values,
          [idApp]: values[idApp]
        }
      }
    }
    case `${UPDATE_APPS_DETAILS}_RESPONSE`: {
      const { idApps } = action.meta
      const { values } = action.payload
      const appsValues = {}
      idApps.forEach(idApp => {
        appsValues[idApp] = values[idApp]
      })
      return {
        ...state,
        values: {
          ...state.values,
          ...appsValues
        }
      }
    }
    case `${UPDATE_APP_DETAILS_FIELD}_PENDING`: {
      const { idField, isShown, idGroup } = action.meta
      const modifiedFields = updateOneItem(state.fields, 'idField', idField, { isShown, idGroup })

      return {
        ...state,
        fields: modifiedFields
      }
    }
    case `${UPDATE_APP_DETAILS_FIELD}_FAILED`: {
      const { idField, isShown } = action.meta
      const revertedIsShownValue = !isShown
      const modifiedFields = updateOneItem(state.fields, 'idField', idField, { isShown: revertedIsShownValue })

      return {
        ...state,
        fields: modifiedFields
      }
    }
    case `${UPDATE_APP_DETAILS_FIELD}_RESPONSE`: {
      const { field } = action.payload
      const modifiedFields = replaceOneItem(state.fields, field, 'idField')
      return {
        ...state,
        fields: modifiedFields
      }
    }
    case `${GET_APP_DETAIL_FIELD_HISTORY}_PENDING`: {
      return {
        ...state,
        loadingFieldsHistory: true
      }
    }
    case `${GET_APP_DETAIL_FIELD_HISTORY}_FAILED`: {
      return {
        ...state,
        loadingFieldsHistory: false
      }
    }
    case `${GET_APP_DETAIL_FIELD_HISTORY}_RESPONSE`: {
      const { history, resources } = action.payload
      const { idField, idApp } = action.meta

      return {
        ...state,
        fieldsHistory: {
          ...state.fieldsHistory,
          [idApp]: {
            ...state.fieldsHistory[idApp],
            [idField]: history,
            resources
          }
        }
      }
    }
    case `${ADD_APP_DETAILS_FIELD}_RESPONSE`: {
      const { field } = action.payload
      const modifiedFields = replaceOneItem(state.fields, field, 'idField')
      return {
        ...state,
        fields: modifiedFields
      }
    }
    default: {
      return state
    }
  }
}

export default appDetailsReducer
