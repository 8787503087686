import View from './view'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import {
  getActionsConfigByType,
  getTriggersByType,
  isLoadingWorkflows,
  getWorkflow,
  getWorkflowsAuditLogsExtraInfoByIdAudit,
  isLoadingWorkflowsAuditLogExtraInfo,
  getAppCatalogPoliciesByIdSelector,
  getAppCatalogRequestNewAppPoliciesByIdSelector,
  isLoadingAppCatalogPoliciesSelector,
  isLoadingAppCatalogRequestNewAppPoliciesSelector
} from '@selectors/workflows'
import get from 'lodash/get'
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@root/constants'
import { EMPTY_ACTIONS_MODEL } from '@shared/workflows/actions/consts'
import { WorkflowEditorProvider } from '@pages/workflow_v2/workflowEditor/context'

const mapStateToProps = (state, ownProps) => {
  const { isAppCatalogPoliciesView = false, isRequestNewAppPoliciesView = false, idAudit } = ownProps
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idWorkflow = parseInt(ownProps.match.params.idWorkflow, 10)
  const isPreviewMode = Boolean(idAudit)

  const workflow = (
    isAppCatalogPoliciesView ? getAppCatalogPoliciesByIdSelector(state)[idWorkflow]
      : isRequestNewAppPoliciesView ? getAppCatalogRequestNewAppPoliciesByIdSelector(state)[idWorkflow]
        : getWorkflow(state, { idWorkflow })
  ) || EMPTY_OBJECT

  let loadingPreviewData
  if (isPreviewMode) {
    const workflowAudit = getWorkflowsAuditLogsExtraInfoByIdAudit(state)[idAudit]
    const previewWorkflowData = get(workflowAudit, ['properties', 'data'], EMPTY_OBJECT)
    const responsePreviewWorkflowData = get(workflowAudit, ['properties', 'responseData'], EMPTY_OBJECT)
    workflow.actions = previewWorkflowData.actions ?? responsePreviewWorkflowData.actions ?? EMPTY_ACTIONS_MODEL
    workflow.triggerConfiguration = previewWorkflowData.triggerConfiguration ?? responsePreviewWorkflowData.triggerConfiguration ?? EMPTY_ARRAY
    workflow.triggerType = previewWorkflowData.triggerType ?? responsePreviewWorkflowData.triggerType ?? null
    workflow.schedules = previewWorkflowData.schedules ?? responsePreviewWorkflowData.schedules ?? null
    workflow.isActive = previewWorkflowData.isActive ?? responsePreviewWorkflowData.isActive ?? false
    workflow.name = previewWorkflowData.name ?? responsePreviewWorkflowData.name ?? workflow.name

    loadingPreviewData = isLoadingWorkflowsAuditLogExtraInfo(state)
  }

  const triggersByType = getTriggersByType(state)
  const actionsByType = getActionsConfigByType(state)

  const loading =
    (isAppCatalogPoliciesView ? isLoadingAppCatalogPoliciesSelector(state)
      : isRequestNewAppPoliciesView ? isLoadingAppCatalogRequestNewAppPoliciesSelector(state)
        : isLoadingWorkflows(state)) || !workflow.actions ||
    Object.keys(triggersByType).length === 0 ||
    Object.keys(actionsByType).length === 0 ||
    loadingPreviewData

  return {
    idOrg,
    idWorkflow,
    workflow,
    loading,
    idAudit,
    isPreviewMode
  }
}

const WorkflowV2Page = withRouter(connect(mapStateToProps)(View))

const WorkflowV2PageWithProvider = (props) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const previewId = queryParams.get('previewId')
  const idAudit = previewId ? parseInt(previewId) : null

  const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = props
  return <WorkflowEditorProvider isAppCatalogPoliciesView={isAppCatalogPoliciesView} isRequestNewAppPoliciesView={isRequestNewAppPoliciesView} idAudit={idAudit}>
    <WorkflowV2Page {...props} idAudit={idAudit} />
  </WorkflowEditorProvider>
}

export default WorkflowV2PageWithProvider
