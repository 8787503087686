import View from './view'
import { connect } from 'react-redux'
import { toggleAssignUser } from '@actions/'
import Analytics from '../../../helpers/analytics'

const mapStateToProps = state => {
  const { assignUser: { idApp, isOpen, actionDescription, onSelectUser, suggestOwner, assignUserCB, componentPage, actionNotes, isMultipleSelect } } = state.ui
  return ({
    idApp,
    isOpen,
    actionDescription,
    onSelectUser,
    suggestOwner,
    assignUserCB,
    componentPage,
    actionNotes,
    isMultipleSelect
  })
}

const mapDispatchToProps = {
  toggleAssignUser
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { componentPage, actionDescription } = stateProps

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    cancel () {
      Analytics.track(`Dismiss ${actionDescription.toLowerCase()}`, { Page: componentPage })
      dispatchProps.toggleAssignUser(false)
    },
    close () {
      dispatchProps.toggleAssignUser(false)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps)(View)
