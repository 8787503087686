import View from './view'
import { connect } from 'react-redux'
import {
  createCustomIntegration,
  getServicesSyncData,
  toggleConnectCustomService
} from '@actions/'
import { getConstantServices } from '@selectors/services'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state) => {
  const { connectCustomService } = state.ui
  const { id: idOrg } = getCurrentOrg(state)
  const servicesConfig = getConstantServices(state)

  return {
    idOrg,
    isOpen: connectCustomService.isOpen,
    coordinate: connectCustomService.coordinate,
    servicesConfig
  }
}

const mapDispatchToProps = {
  toggleConnectCustomService,
  onConnect: createCustomIntegration,
  getServicesSyncData
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
  cancel () {
    dispatchProps.toggleConnectCustomService({ isOpen: false })
  },
  close () {
    dispatchProps.toggleConnectCustomService({ isOpen: false, isByUser: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
