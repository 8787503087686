import * as Style from './style'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { SCOPES, TRANSACTION_MAPPING_STATUS } from '@root/constants'
import PropTypes from 'prop-types'
import TransactionsPreview from './transactionsPreview'
import { getOrgMatchingRules, updateOrgMatchingRules, getTransactionsForSearch } from '@actions/'
import TransactionMatchingRule from '@root/components/transactionMatchingRules/transactionMatchingRule/view'
import { MATCH_RULE_OPERATOR } from '@root/components/transactionMatchingRules/transactionMatchingRule/constants'
import emptyStateImage from '@media/emptyState.svg'
import { getMatchingRules, getSearchedTransactions } from '@selectors/transactions'
import analytics from '@root/helpers/analytics'
import { css } from 'glamor'
import { EmptyState } from '@toriihq/design-system'

const CreateMatchingRulePopup = ({ isOpen, idOrg, onClose, onCreate }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrgMatchingRules({ idOrg }))
  }, [dispatch, idOrg])

  const orgMatchingRules = useSelector(getMatchingRules)
  const transactionsResponse = useSelector(getSearchedTransactions)

  const sortStringFromArray = (sortArray) => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }
  const getTransactions = ({ transactions = [], limit = 50, reset = false, sort = [], matchRule = matchingRule }) => {
    const idApp = matchRule.app ? matchRule.app.id : null
    dispatch(getTransactionsForSearch({ idOrg, q: matchRule.term, qOperator: matchRule.op, offset: reset ? 0 : transactions.length, limit, sort: sortStringFromArray(sort), idApp, reset }))
  }

  const fetchTransactionsData = ({ reset, matchRule }) => {
    getTransactions({ transactions: transactionsResponse ? transactionsResponse.transactions : [], reset, matchRule })
  }

  const [matchingRule, setMatchingRule] = useState({
    id: Date.now(),
    op: MATCH_RULE_OPERATOR.CONTAINS,
    term: '',
    mappingStatus: TRANSACTION_MAPPING_STATUS.MAPPED
  })

  const onSortChange = (sort) => {
    (sort && sort[0]) && analytics.track('Sort expense-matching-rule', { 'Column name': sort[0].id, 'Dialog name': 'Add a matching rule' })
    getTransactions({ sort, reset: true })
  }

  const onClosePopup = () => {
    setMatchingRule({
      id: matchingRule.id,
      op: MATCH_RULE_OPERATOR.CONTAINS,
      term: '',
      mappingStatus: TRANSACTION_MAPPING_STATUS.MAPPED
    })
    analytics.track('Close expense-matching-rules', { 'Button label': 'Cancel', 'Dialog name': 'Add a matching rule' })
    onClose()
  }

  const onCreateMatchingRule = () => {
    setMatchingRule({
      id: Date.now(),
      op: MATCH_RULE_OPERATOR.CONTAINS,
      term: '',
      mappingStatus: TRANSACTION_MAPPING_STATUS.MAPPED
    })
    onCreate()
  }

  const onApply = async () => {
    const isIgnored = matchingRule.mappingStatus === TRANSACTION_MAPPING_STATUS.IGNORED
    const matchingRuleWithPreviewFlowFlag = { ...matchingRule, previewFlow: true }
    const matchingRules = isIgnored ? [ matchingRuleWithPreviewFlowFlag, ...orgMatchingRules ] : [ ...orgMatchingRules, matchingRuleWithPreviewFlowFlag ]
    dispatch(await updateOrgMatchingRules({ rules: matchingRules, idOrg }))
    analytics.track('Create expense-matching-rules', { rules: JSON.stringify(matchingRules), transactions: transactionsResponse.total, 'Button label': 'Apply Rule', 'Dialog name': 'Add a matching rule' })
    onCreateMatchingRule()
  }

  const sendAnalyticsOnChange = (matchingRuleChange) => {
    let properties = {
      'Dialog name': 'Add a matching rule'
    }
    let analyticName = null
    const { op, term, mappingStatus, app } = matchingRule
    const idApp = matchingRuleChange.app ? matchingRuleChange.app.id : null
    if (matchingRuleChange.term !== term) {
      analyticName = 'Update expense-matching-rule-text'
      properties = { ...properties, 'New text': matchingRuleChange.term }
    } else if (matchingRuleChange.mappingStatus !== mappingStatus) {
      analyticName = 'Select expense-matching-rule-type'
      properties = { ...properties, 'Rule type': matchingRuleChange.mappingStatus }
    } else if (idApp !== (app ? app.id : null)) {
      analyticName = 'Select expense-matching-rule-app'
      properties = { ...properties, 'App name': matchingRuleChange.app.name }
    } else if (matchingRuleChange.op !== op) {
      analyticName = 'Select expense-matching-rule-operator'
      properties = { ...properties, Operator: matchingRuleChange.op }
    }

    analyticName && analytics.track(analyticName, properties)
  }

  const onMatchingRuleChange = (matchingRule) => {
    fetchTransactionsData({ matchRule: matchingRule, reset: true })
    sendAnalyticsOnChange(matchingRule)
    setMatchingRule(matchingRule)
  }

  const isRulesValid = () => (
    matchingRule.term &&
      matchingRule.term !== '' &&
      [TRANSACTION_MAPPING_STATUS.MAPPED, TRANSACTION_MAPPING_STATUS.IGNORED].includes(matchingRule.mappingStatus) &&
      (matchingRule.mappingStatus === TRANSACTION_MAPPING_STATUS.MAPPED ? !!matchingRule.app : true)
  )

  const renderHeader = () => {
    return (
      <div {...Style.HeaderArea}>
        <div>
          <div>Add a matching rule</div>
          <div {...Style.SubHeader}>Enter a search term, preview its matching transactions, tweak the rule definitions if needed and apply</div>
        </div>
      </div>)
  }

  const previewEmptyState = ({ transactions }) => {
    return (
      <div {...Style.MarginTop({ margin: '30px' })}>
        {transactions.length === 0 ? null : <div {...Style.Bold}>Preview matching transactions</div>}
        <div {...css(Style.EmptyState, Style.MarginTop({ margin: '15px' }))}>
          <EmptyState
            orientation={'horizontal'}
            align='center'
            image={<img alt='search term empty state' src={emptyStateImage} />}
            title='Nothing here, yet!'
            description='Enter a search term and preview its matching transactions.'
          />
        </div>
      </div>
    )
  }

  const { transactions, numberOfOverrideMapped, total } = transactionsResponse

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={() => onClosePopup(matchingRule.id)} styles={Style.Popup} >
      <ToriiPopup.Header header={renderHeader()} />
      <ToriiPopup.Content contentAreaStyle={Style.MainArea}>
        <div {...Style.TransactionMatchingRule}>
          <TransactionMatchingRule onChange={onMatchingRuleChange} rule={matchingRule} autofocusTerm />
        </div>
        {matchingRule.term && matchingRule.term !== ''
          ? (
            <TransactionsPreview
              idOrg={idOrg}
              transactions={transactions}
              matchingRule={matchingRule}
              onSortChange={onSortChange}
              numberOfOverrideMapped={numberOfOverrideMapped}
              total={total}
              fetchData={fetchTransactionsData}
            />
          )
          : previewEmptyState({ transactions })}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        scopes={[SCOPES.EXPENSE_WRITE]}
        showCancelButton
        cancelButtonText='Cancel'
        mainButtonText='Apply Rule'
        mainButtonAction={onApply}
        isMainButtonDisabled={!isRulesValid()}
      />
    </ToriiPopup>
  )
}

CreateMatchingRulePopup.propTypes = {
  isOpen: PropTypes.bool,
  cancel: PropTypes.func,
  idOrg: PropTypes.number.isRequired
}

export default CreateMatchingRulePopup
