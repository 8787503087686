import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import compact from 'lodash/compact'
import {
  TRANSACTION_MAPPING_LOGIC_DISPLAY,
  TRANSACTION_MAPPING_STATUS_DISPLAY,
  TRANSACTION_MAPPING_STATUS_ORDER
} from '@root/constants'

export const getIsLoadingTransactions = createSelector(
  state => state.transactions.loading,
  identity
)

export const getIsLoadingMoreTransactions = createSelector(
  state => state.transactions.loadingMore,
  identity
)

export const getTransactions = createSelector(
  state => state.transactions.transactions,
  identity
)

export const getSearchedTransactions = createSelector(
  state => get(state, ['transactions', 'searchedTransactions'], { transactions: [], numberOfOverrideMapped: 0, total: 0 }),
  identity
)

export const getTransactionsResources = createSelector(
  state => get(state, ['transactions', 'resources'], {}),
  identity
)

export const getTransactionsFilterFieldValues = createSelector(
  state => get(state, ['transactions', 'fieldValuesData'], {}),
  identity
)

export const getTransactionsFilterOptionsValuesPerKey = createSelector(
  [getTransactionsFilterFieldValues],
  (fieldValuesData) => {
    const fieldValues = fieldValuesData.fieldValues
    const { users } = fieldValuesData.resources
    return Object.keys(fieldValues).reduce((result, key) => {
      if (key === 'reportedBy') {
        result[key] = compact(fieldValues[key].map(reportedBy => {
          const user = users[reportedBy.value]
          if (!user) {
            return null
          }
          return {
            value: reportedBy.value,
            label: `${user.firstName} ${user.lastName}`
          }
        })).sort((a, b) => (a.label || '').localeCompare(b.label || ''))
      } else if (key === 'mappingStatus') {
        result[key] = fieldValues[key]
          .map(mappingStatus => ({
            value: mappingStatus.value,
            label: TRANSACTION_MAPPING_STATUS_DISPLAY[mappingStatus.label]
          }))
          .sort((a, b) => TRANSACTION_MAPPING_STATUS_ORDER[a.value] - TRANSACTION_MAPPING_STATUS_ORDER[b.value])
      } else if (key === 'mappingLogic') {
        result[key] = fieldValues[key].map(mappingLogic => ({
          value: mappingLogic.value,
          label: TRANSACTION_MAPPING_LOGIC_DISPLAY[mappingLogic.label]
        }))
      } else {
        result[key] = fieldValues[key]
      }
      return result
    }, {})
  }
)

export const getTransactionsByApp = createSelector(
  getTransactions,
  (transactions) => {
    return groupBy(Object.values(transactions), 'idApp')
  }
)

export const getTransactionsByUpload = createSelector(
  getTransactions,
  (transactions) => {
    return groupBy(Object.values(transactions), 'idUpload')
  }
)

export const getTotalTransactions = createSelector(
  state => get(state, ['transactions', 'totalTransactions'], 0),
  identity
)

export const getTransactionsWithoutDeduplication = createSelector(
  state => state.transactions.transactionsWithoutDeduplication,
  identity
)

export const getTransactionsWithoutDeduplicationByAppAndSource = createSelector(
  getTransactionsWithoutDeduplication,
  (transactions) => {
    return groupBy(Object.values(transactions), transaction => `${transaction.idApp}-${transaction.source}`)
  }
)

export const getMatchingRules = createSelector(
  state => get(state, ['transactions', 'matchingRules', 'rules'], []),
  identity
)

export const getLocalMatchingRules = createSelector(
  state => get(state, ['transactions', 'matchingRules', 'localRules'], []),
  identity
)

export const isLoadingMatchingRules = createSelector(
  state => get(state, ['transactions', 'matchingRules', 'loading'], false),
  identity
)

export const getMonthlyExpensesPerApp = createSelector(
  state => state.transactions.aggs.perApp,
  identity
)

export const getLast12MonthsExpensesAggsPerApp = createSelector(
  state => state.transactions.aggs.last12MonthsExpensesAggsPerApp,
  identity
)

export const getLastYearTotalExpenses = createSelector(
  state => state.transactions.aggs.lastYearTotalExpenses,
  identity
)

export const getExpensesByCategory = createSelector(
  state => state.transactions.aggs.byCategory.data,
  identity
)
