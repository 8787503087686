import View from './view'
import { connect } from 'react-redux'
import { getAutomationRules, updateAutomationRule } from '@actions/'
import { withRouter } from 'react-router-dom'
import { getIsSmallScreen } from '@selectors/ui'
import { isToriiAdmin } from '@selectors/me'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const canManageAccount = isToriiAdmin(state)

  return {
    idOrg,
    isSmallScreen: getIsSmallScreen(state),
    canManageAccount
  }
}

const mapDispatchToProps = {
  getAutomationRules,
  updateAutomationRule
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
