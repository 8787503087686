import View from './view'
import { connect } from 'react-redux'
import { deleteRole, toggleRolePopup } from '@actions/'

const mapDispatchToProps = {
  deleteRole,
  toggleRolePopup
}

export default connect(null, mapDispatchToProps)(View)
