import {
  GET_WORKFLOW_FORM_INFO,
  SUBMIT_WORKFLOW_FORM_INFO,
  SUBMIT_WORKFLOW_REQUEST_APPROVAL,
  GET_CONNECT_INTEGRATION_LINK_INFO,
  GET_USER_TASKS,
  UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS,
  TOGGLE_SHOW_OFFBOARDING_MESSAGE
} from '@root/constants'
import keyBy from 'lodash/keyBy'

const initialState = {
  loadingWorkflowForm: false,
  workflowForm: {
    authError: false
  },
  workflowRequestApproval: {},
  loadingConnectIntegration: false,
  connectIntegration: {
    authError: false
  },
  userTasks: {
    loading: false,
    authError: false,
    updateError: false
  }
}

const instantAccessReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_WORKFLOW_FORM_INFO}_PENDING`: {
      return {
        ...state,
        loadingWorkflowForm: true
      }
    }
    case `${GET_WORKFLOW_FORM_INFO}_RESPONSE`: {
      const info = action.payload
      return {
        ...state,
        loadingWorkflowForm: false,
        workflowForm: {
          authError: false,
          ...state.workflowForm,
          ...info
        }
      }
    }
    case `${SUBMIT_WORKFLOW_FORM_INFO}_PENDING`: {
      return {
        ...state,
        workflowForm: {
          ...state.workflowForm,
          submitSuccess: false
        }
      }
    }
    case `${SUBMIT_WORKFLOW_FORM_INFO}_FAILED`: {
      return {
        ...state,
        workflowForm: {
          ...state.workflowForm,
          submitSuccess: false
        }
      }
    }
    case `${SUBMIT_WORKFLOW_FORM_INFO}_RESPONSE`: {
      return {
        ...state,
        workflowForm: {
          ...state.workflowForm,
          submitSuccess: true
        }
      }
    }
    case `${SUBMIT_WORKFLOW_REQUEST_APPROVAL}_PENDING`: {
      return {
        ...state,
        workflowRequestApproval: {
          loading: true
        }
      }
    }
    case `${SUBMIT_WORKFLOW_REQUEST_APPROVAL}_FAILED`: {
      return {
        ...state,
        workflowRequestApproval: {
          ...state.workflowRequestApproval,
          submitSuccess: false,
          loading: false,
          authError: true
        }
      }
    }
    case `${SUBMIT_WORKFLOW_REQUEST_APPROVAL}_RESPONSE`: {
      const { success, alreadyCompleted, approveText, declineText } = action.payload
      return {
        ...state,
        workflowRequestApproval: {
          ...state.workflowRequestApproval,
          submitSuccess: success,
          alreadyCompleted,
          approveText,
          declineText,
          loading: false
        }
      }
    }
    case `${GET_CONNECT_INTEGRATION_LINK_INFO}_PENDING`: {
      return {
        ...state,
        loadingConnectIntegration: true
      }
    }
    case `${GET_CONNECT_INTEGRATION_LINK_INFO}_FAILED`: {
      return {
        ...state,
        loadingConnectIntegration: false,
        connectIntegration: {
          ...state.connectIntegration,
          authError: true
        }
      }
    }
    case `${GET_CONNECT_INTEGRATION_LINK_INFO}_RESPONSE`: {
      const info = action.payload
      return {
        ...state,
        loadingConnectIntegration: false,
        connectIntegration: {
          authError: false,
          ...state.connectIntegration,
          ...info
        }
      }
    }
    case `${GET_USER_TASKS}_PENDING`: {
      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          loading: true
        }
      }
    }
    case `${GET_USER_TASKS}_FAILED`: {
      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          authError: true,
          loading: false
        }
      }
    }
    case `${GET_USER_TASKS}_RESPONSE`: {
      const { apps, users, tasks, accessInfo } = action.payload
      const { idUser } = action.meta

      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          [idUser]: {
            isLoaded: true,
            tasks,
            users: keyBy(users, 'id'),
            apps: keyBy(apps, 'id'),
            accessInfo
          },
          authError: false,
          loading: false
        }
      }
    }
    case `${UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS}_PENDING`: {
      const { idUser, idTask } = action.meta
      const oldInfo = state.userTasks[idUser]

      const updatedTasks = oldInfo.tasks.map(task => {
        return {
          ...task,
          isCompleted: idTask === task.id ? true : task.isCompleted
        }
      })

      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          [idUser]: {
            ...oldInfo,
            tasks: updatedTasks
          }
        }
      }
    }
    case `${UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS}_FAILED`: {
      const { idUser, idTask } = action.meta
      const oldInfo = state.userTasks[idUser]

      const updatedTasks = oldInfo.tasks.map(task => {
        return {
          ...task,
          isCompleted: idTask === task.id ? false : task.isCompleted
        }
      })

      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          [idUser]: {
            ...oldInfo,
            tasks: updatedTasks
          },
          updateError: true
        }
      }
    }
    case `${UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS}_RESPONSE`: {
      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          updateError: false,
          authError: false,
          loading: false
        }
      }
    }
    case TOGGLE_SHOW_OFFBOARDING_MESSAGE: {
      const { isOpen, message } = action.payload
      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          isOpen,
          message
        }
      }
    }
    default: {
      return state
    }
  }
}

export default instantAccessReducer
