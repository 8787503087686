import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import numeral from 'numeral'
import { getInsightsStats, isLoadedInsightsStats, isLoadingInsightsStats } from '@selectors/stats'
import InfoBox from '../infoBox'
import emptyImage from '@media/dance.svg'
import { SCOPES } from '@root/constants'
import { Spacer, Tooltip, Icon, Button, ButtonType } from '@toriihq/design-system'
import RelativeTeamLink from '@components/relativeTeamLink'
import * as Style from './style'
import { isLoadingUserTypeSources, getUserTypeSources as getUserTypeSourcesSelector, getCurrentOrg } from '@selectors/org'
import { getUserTypeSources } from '@actions/org'
import Analytics from '@helpers/analytics'
import { useHistory } from 'react-router-dom'

const ActiveUsers = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { users, monthlyUsers, type } = useSelector(getInsightsStats)
  const isLoaded = useSelector(isLoadedInsightsStats)
  const isLoadingStats = useSelector(isLoadingInsightsStats)
  const isLoadingSources = useSelector(isLoadingUserTypeSources)
  const isLoading = isLoadingStats || isLoadingSources
  const sources = useSelector(getUserTypeSourcesSelector)
  const isSourcesNotConnected = sources?.filter(source => !source.isConnected).length > 0
  const loading = !isLoaded || (isLoading && users === 0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (idOrg) {
      dispatch(getUserTypeSources({ idOrg }))
    }
  }, [idOrg, dispatch])

  return <ActiveUsersComponent
    type={type}
    users={users}
    monthlyUsers={monthlyUsers}
    idOrg={idOrg}
    loading={loading}
    isSourcesNotConnected={isSourcesNotConnected}
  />
}

export const ActiveUsersComponent = React.memo(function ActiveUsersComponent ({ type, users, monthlyUsers, idOrg, loading, isSourcesNotConnected }) {
  const history = useHistory()

  const isEmployees = type === 'employees'

  const onClickUsers = () => {
    Analytics.track('Click to go to services', {
      from: 'Empty state total users'
    })
    history.push(`/team/${idOrg}/services/all`)
  }
  const emptyStateUsers = {
    orientation: 'horizontal',
    size: 'small',
    align: 'center',
    title: 'Discover your users',
    image: <img src={emptyImage} alt='No users' />,
    buttons: [<Button type={ButtonType.secondary} onClick={onClickUsers} label='Connect service providers' />]
  }

  const onClickEmployees = () => {
    Analytics.track('Click to go to HR integration', {
      from: 'Empty state total employees'
    })
    history.push(`/team/${idOrg}/settings/usersAndEmployees`)
  }

  const emptyStateEmployees = {
    orientation: 'horizontal',
    size: 'small',
    align: 'center',
    title: 'Discover your employees',
    image: <img src={emptyImage} alt='No employees' />,
    buttons: [<Button type={ButtonType.secondary} onClick={onClickEmployees} label='Connect HR integration' />]
  }

  const usersDisplay = numeral(users).format('0,0')
  const monthlyUsersDisplay = numeral(monthlyUsers).format('0,0')
  const emptyStateInfo = (isEmployees && isSourcesNotConnected) ? emptyStateEmployees : emptyStateUsers
  const Description = (
    <Style.FlexCenterWrapper>
      {monthlyUsers > 0 ? <Style.Text><strong>+{monthlyUsersDisplay}</strong> Since last month</Style.Text> : <Style.Text>Same as last month</Style.Text>}
      {isEmployees === false ? (
        <>
          <Style.VerticalRule />
          <Style.FlexCenterWrapper>
            <Spacer right={'space-050'}>
              <RelativeTeamLink to={`/settings/usersAndEmployees`}>
                <Style.Link>Define employees</Style.Link>
              </RelativeTeamLink>
            </Spacer>
            <Tooltip
              placement='top'
              label='Set the source system used in your organization to manage employees.'
            >
              <Icon name='Info' />
            </Tooltip>
          </Style.FlexCenterWrapper>
        </>) : null}
    </Style.FlexCenterWrapper>
  )
  return (
    <InfoBox
      header={users || (isEmployees && !isSourcesNotConnected) ? <Style.Heading>{usersDisplay}</Style.Heading> : null}
      subHeader={isEmployees ? 'EMPLOYEES' : 'USERS'}
      description={Description}
      emptyStateInfo={emptyStateInfo}
      loading={loading}
      navTo='/users'
      navToNeededScopes={[SCOPES.USERS_READ]}
      pageName='Insights'
      boxName={isEmployees ? 'Employees' : 'Users'}
    />
  )
})

ActiveUsersComponent.propTypes = {
  type: PropTypes.oneOf(['employees', 'users']),
  users: PropTypes.number.isRequired,
  monthlyUsers: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  idOrg: PropTypes.number
}

ActiveUsersComponent.defaultProps = {
  loading: false
}

export default ActiveUsers
