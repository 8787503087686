import View from './view'
import { connect } from 'react-redux'
import { getLicensesTypes as getLicensesTypesSelector, isLoadedLicensesTypes, isLoadingLicensesTypes } from '@selectors/licenses'
import { getLicensesTypes } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { EMPTY_ARRAY } from '../../constants'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { idApp } = ownProps

  const licensesInfoByIdApp = getLicensesTypesSelector(state)
  const licenseTypes = licensesInfoByIdApp[idApp] || EMPTY_ARRAY
  const loading = isLoadingLicensesTypes(state)
  const isLoaded = isLoadedLicensesTypes(state)

  return {
    loading: !isLoaded || (loading && licenseTypes.length === 0),
    licenses: licenseTypes,
    idOrg
  }
}

const mapDispatchToProps = {
  getLicensesTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
