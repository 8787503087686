import React, { JSX } from 'react'
import { Tooltip, Icon, Body2, Stack } from '@toriihq/design-system'
import Placeholder from '../../placeholder'
import Select from '../../select'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { SettingsBox } from './style'

interface Props {
  options: Array<{ value: string; label: string }>;
  id: string;
  name: string;
  description?: string | React.ReactElement;
  frequency: string;
  onChange: (params: { prevFrequency: string; frequency: string; idField: string }) => void;
  loading: boolean;
  scopes?: Array<keyof typeof SCOPES>;
}

const Setting = ({
  options,
  id,
  name,
  description,
  frequency,
  onChange,
  loading,
  scopes = []
}: Props): JSX.Element => {
  return (
    <Placeholder loading={loading} rows={2} style={{ maxWidth: '80%', marginTop: '10px' }}>
      <SettingsBox>
        <Stack direction='row' gap='space-0' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' gap='space-050'>
            <Body2>{name}</Body2>
            {description && (
              <Tooltip label={description} placement='right'>
                <Icon name='Info' color='secondary' />
              </Tooltip>
            )}
          </Stack>
          <EnableFor scopes={scopes}>
            <Select
              options={options}
              value={frequency}
              clearable={false}
              openOnFocus
              onChange={(selected) => {
                const value = (selected as Props['options'][number])?.value
                onChange({ prevFrequency: frequency, frequency: value, idField: id })
              }}
            />
          </EnableFor>
        </Stack>
      </SettingsBox>
    </Placeholder>
  )
}

export default Setting
