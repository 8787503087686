import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { Spacer, Spinner, SpinnerColor, Subtitle2 } from '@toriihq/design-system'
import React from 'react'
import { Content } from './styles'
import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { pick } from 'lodash'
import { SharedState, SharedProps, WIZARD_STEPS } from '../types'
import useEffectOnce from '@root/shared/hooks/useEffectOnce'
import { UsePolling } from '../usePolling'
import { useDispatch } from 'react-redux'
import { PARSING_STATUS } from '../../constants'
import { getUsersImportPreviewChanges, saveMappingAndParse } from '@actions/'
import Analytics from '../../analytics'

const LoadingAfterMapColumns = ({
  onCancel, navigateToStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const stepNameForAnalytics = 'Loading after map columns'
  const dispatch = useDispatch()
  const { importMode } = sharedProps

  const onFailedParsing = () => {
    clearPolling()
  }

  const { startPolling, clearPolling } = UsePolling({
    onPollFailure: onFailedParsing
  })

  const pollLogic = async ({ idOrg, idApp, idUsersImportParsing, idAppAccount }) => {
    const response = await dispatch(getUsersImportPreviewChanges({ idOrg, idApp, idUsersImportParsing, idAppAccount }))
    const { parsingStatus, users } = response
    if (parsingStatus === PARSING_STATUS.parsingSucceeded) {
      const { validUsers, invalidUsers, missingUsers, newUsersWithLicenses } = users
      setState({ ...sharedState, parsingPreviewChanges: { validUsers, invalidUsers, missingUsers, newUsersWithLicenses } })
      clearPolling()
      navigateToStep(WIZARD_STEPS.REVIEW_IMPORT_CHANGES)
    } else if (parsingStatus === PARSING_STATUS.parsingFailed) {
      clearPolling()
      navigateToStep(WIZARD_STEPS.FAILURE)
    }
  }

  useEffectOnce(() => {
    const fetchData = async () => {
      const { idOrg, idApp } = sharedProps
      const { idUsersImportParsing, idAppAccount, previewDataMapping: selectedMapping, selectedDateFormat, selectedColumnsToImport } = sharedState
      try {
        const mapping = pick(selectedMapping, selectedColumnsToImport)
        await dispatch(saveMappingAndParse({ idOrg, idApp, idUsersImportParsing, mapping, dateFormat: selectedDateFormat }))
        await startPolling(() => pollLogic({ idOrg, idUsersImportParsing: idUsersImportParsing!, idApp, idAppAccount: idAppAccount! }))
      } catch (error) {
        throw error
      }
    }
    fetchData()
  })

  const onCancelClick = () => {
    Analytics.onCloseImportPopup(importMode, stepNameForAnalytics, 'Cancel')
    onCancel()
  }

  const onBackClick = () => {
    Analytics.onBackAction(importMode, stepNameForAnalytics)
    navigateToStep(WIZARD_STEPS.MAP_COLUMNS)
  }

  return (
    <>
      <ToriiPopup.Header header='Review and complete your import' subHeader={sharedProps.appAccountName} />
      <ToriiPopup.Content>
        <Content>
          <Spinner color={SpinnerColor.active} />
          <Spacer top='space-200'>
            <Subtitle2>Generating preview...</Subtitle2>
          </Spacer>
        </Content>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonAction={onCancelClick}
        cancelButtonText='Cancel'
        mainButtonText='Import'
        isMainButtonDisabled
        showBackButton
        backButtonAction={onBackClick}
      />
    </>
  )
}

export default LoadingAfterMapColumns
