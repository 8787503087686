import React, { useState } from 'react'
import TableActionButton from '@root/components/table/tableActionButton/tableActionButton'
import { getUser, inviteUser } from '@actions/'
import { useSelector, useDispatch } from 'react-redux'
import Analytics from '@helpers/analytics'
import { SCOPES, USER_STATUS } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { getIsAppOwnerActive } from '@selectors/org'
import { toast, ToastType } from '@toriihq/design-system'

const AppOwnerActions = (props) => {
  const { user, idOrg } = props
  const dispatch = useDispatch()
  const [inviteButtonDisabled, setReinviteButtonState] = useState(false)
  const isAppOwnerActive = useSelector(getIsAppOwnerActive)

  const isPendingInvitation = user && user.status === USER_STATUS.INVITED && isAppOwnerActive
  const isActiveUser = user.lifecycleStatus === USER_LIFECYCLE_STATUS.ACTIVE

  const displayReinviteToast = (user) => {
    if (!user) return
    toast({
      message: `${user.fullName || 'The user'} has received a new email invite to access Torii`,
      type: ToastType.SUCCESS
    })
  }

  const inviteAppOwner = (idOrg, user, roleId) => {
    dispatch(inviteUser(idOrg, user.email, roleId))
      .then((res) => {
        const { user: resUser } = res
        if (!resUser) return
        displayReinviteToast(user)
        setReinviteButtonState(false)
      })
    setReinviteButtonState(true)
  }

  const onClickReinvite = (idOrg, user) => {
    if (!user || !idOrg) return
    Analytics.track('Click re-invite app owner', {
      from: 'App owners list page'
    })
    dispatch(getUser({ idOrg, idUser: user.id, fields: ['idRole'] }))
      .then((data) => {
        const { user: resUser } = data

        if (!resUser || !resUser.idRole) return

        const idRole = resUser.idRole
        inviteAppOwner(idOrg, user, idRole)
      })
  }

  const actionButtons = [
    {
      icon: 'Mail',
      tooltipProps: {
        label: 'Re-invite to Torii'
      },
      isActionDisabled: !isPendingInvitation || inviteButtonDisabled || !isActiveUser,
      enableForScopes: [SCOPES.MEMBERS_AND_ROLES_WRITE],
      isVisible: isPendingInvitation,
      actionFunc: () => {
        onClickReinvite(idOrg, user)
      },
      dataTestId: `re-invite-app-owner-${user.id}`
    }
  ]

  return (
    <div data-testid={`app-owner-actions-${user.id}`}>
      {
        actionButtons.map(btn => btn.isVisible !== false && <TableActionButton {...btn} key={`app-owner-action-btns-${user.id}`} />)
      }
    </div>
  )
}

export default AppOwnerActions
