import React from 'react'
import { Button, ButtonType, Tooltip } from '@toriihq/design-system'
import PropTypes from 'prop-types'
import Analytics from '../../helpers/analytics'
import { Wrapper, Flashing } from './style'

class ProductUpdatesButton extends React.Component {
  state = { hasProductUpdates: this.props.hasProductUpdates, isClicked: false }

  componentDidMount () {
    const { getProductUpdates } = this.props
    getProductUpdates()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.hasProductUpdates !== this.props.hasProductUpdates) {
      this.setState({ hasProductUpdates: this.props.hasProductUpdates })
    }
  }

  onClick = () => {
    const { updateUserLastSeenProductUpdatesTime } = this.props
    Analytics.track('Clicked to see product updates')
    updateUserLastSeenProductUpdatesTime()
    const newWindow = window.open('https://product-updates.toriihq.com', '_blank')
    newWindow.opener = null
    this.setState({ hasProductUpdates: false, isClicked: true })
  }

  render () {
    const { hasProductUpdates, isClicked } = this.state
    const shouldFlashIcon = (!isClicked && hasProductUpdates)
    return (<Tooltip
      placement='bottom'
      label='Product updates'>
      <Wrapper>
        <Button type={ButtonType.tertiary} icon='Gift' dataTestId='product updates button' onClick={this.onClick} />
        {shouldFlashIcon && <Flashing />}
      </Wrapper>
    </Tooltip>)
  }
}

ProductUpdatesButton.propTypes = {
  hasProductUpdates: PropTypes.bool
}

export default ProductUpdatesButton
