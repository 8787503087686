import React, { ReactElement } from 'react'
import { NodeProps } from 'reactflow'
import { WorkflowNodeData } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { useParamSelector } from '@shared/utils'
import { getAppActionsConfig } from '@selectors/workflows'
import ActionIconUrl from './actionIcon.svg'
import { AppActionsConfig } from '@selectors/workflows/types'
import { ActionNodeBase } from '../actionNodeBase'
import { useActionConfiguration } from '@pages/workflow_v2/hooks/actionConfiguration/useActionConfiguration'

type ActionNodeProps = NodeProps<WorkflowNodeData>

export const ActionNode = ({ data }: ActionNodeProps): ReactElement => {
  const { idWorkflowNode } = data
  const actionConfiguration = useActionConfiguration({ idAction: idWorkflowNode })
  const actionApp: AppActionsConfig | undefined = useParamSelector(getAppActionsConfig, { idApp: actionConfiguration?.idApp })
  const appIconUrl = actionApp?.imageUrl ?? actionConfiguration?.imageUrl ?? ActionIconUrl

  return (
    <ActionNodeBase
      data={data}
      iconUrl={appIconUrl}
    />
  )
}
