import { GET_ALL_ORGS } from '@root/constants'

const orgsReducer = (state = [], action) => {
  switch (action.type) {
    case `${GET_ALL_ORGS}_RESPONSE`: {
      return action.payload.orgs
    }
    default:
      return state
  }
}

export default orgsReducer
