import React from 'react'
import { WORKFLOW_ACTION_TYPES, WORKFLOW_STEP_TYPES, WORKFLOW_VALIDATION_TYPES } from '@root/constants'
import { ActionWithIdNode } from '../types'
import {
  ActionNodeModel,
  WorkflowActionModel,
  WorkflowActionsModel,
  Workflow,
  IdWorkflowNode,
  WorkflowNodeModel
} from '@shared/workflows/types'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getActionsConfigByType, getFieldsDefaultValues, getFlattenActionsConfig, getTriggerBranchingEntityOptions, getVisibleActionTypes, getWorkflowActionDynamicFieldsOption } from '@selectors/workflows'
import { isEmpty, keyBy } from 'lodash'
import { updateActionFieldValue } from '@shared/workflows/updateActionFieldValue'
import { getStepsAndFieldsWithDefaultValues } from '@shared/workflows/getStepsAndFieldsWithDefaultValues'
import {
  updateWorkflow,
  updateWorkflowLocally,
  clearActionFieldsOptions
} from '@actions/workflows'
import { ActionTypeSelection } from './actionTypeSelection'
import StepSetup from './stepSetup'
import omit from 'lodash/omit'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { OnActionSelectionChangeData, OnChangeData, OnStepSetupChangeData } from './type'
import { UpdateWorkflowParams } from '@actions/workflows/types'
import { getNewBranchActionNode } from './utils/getNewBranchActionNode'
import { isBranchActionType } from './utils/isBranchActionType'
import { dfsTraverseActions } from '@shared/workflows/actions/utils/dfsTraverseActions'
import { HandleActionParamsBase } from '@shared/workflows/actions/utils/dfsTraverseActions.types'
import { useParamSelector } from '@shared/utils'
import { getNewActionWithFixedBranchesNode } from './utils/getNewActionWithFixedBranches'
import { isActionHasFixedBranches } from './utils/isActionHasFixedBrnaches'

const getFieldData = ({ fieldsById, fieldConfig, dynamicFieldsOptions, loading }) => {
  const isRequired = fieldConfig.validations.includes(WORKFLOW_VALIDATION_TYPES.REQUIRED)
  const actionField = fieldsById[fieldConfig.id] || { isValid: !isRequired }
  const options = fieldConfig.options || (dynamicFieldsOptions.find(f => f.id === fieldConfig.id) || {}).options

  return { ...fieldConfig, ...actionField, isRequired, options, loading }
}

const getPrevActions = ({ idWorkflowNode, actions }: { idWorkflowNode: string, actions: WorkflowActionsModel }): ActionWithIdNode[] => {
  let prevActions: ActionWithIdNode[] = []
  const { idRootNode, nodes } = actions

  if (!idRootNode) {
    return prevActions
  }

  const handleAction = ({ idNode, idAncestors }: HandleActionParamsBase) => {
    if (idNode === idWorkflowNode) {
      prevActions = idAncestors.map(idAncestorNode => {
        const node = actions.nodes[idAncestorNode]
        return { ...node.action, id: idAncestorNode }
      })
    }
  }

  dfsTraverseActions({
    idCurrentNode: idRootNode,
    nodes,
    handleAction,
    handleBranchAction: handleAction,
    handleActionWithFixedBranches: handleAction
  })

  return prevActions
}

const typeToComponent = {
  [WORKFLOW_STEP_TYPES.SELECT]: ActionTypeSelection,
  [WORKFLOW_STEP_TYPES.STEP_SETUP]: StepSetup
}

interface Props {
  workflow: Workflow,
  idAction: IdWorkflowNode,
  stepType: string,
  stepIndex: number
  disabled?: boolean
}

export const ActionConfiguration = ({
  workflow,
  idAction,
  stepType,
  stepIndex,
  disabled = false
}: Props) => {
  const dispatch = useDispatch()
  const { idOrg } = useParams()
  const { goToNextStep } = useWorkflowNavigation()

  const actionsConfig = useSelector(getFlattenActionsConfig)
  const fieldsDefaultValues = useSelector(getFieldsDefaultValues)
  const actionsConfigByType = useSelector(getActionsConfigByType)

  const dynamicFieldsOptionsByActionId = useSelector(getWorkflowActionDynamicFieldsOption)
  const visibleActionTypes = useSelector(getVisibleActionTypes)

  const branchingEntityOptions = useParamSelector(getTriggerBranchingEntityOptions, { triggerType: workflow.triggerType })

  const configuredWorkflowAction = ((workflow.actions?.nodes || {})[idAction] as ActionNodeModel).action ?? {}
  const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === configuredWorkflowAction.type)
  const prevActions = getPrevActions({ idWorkflowNode: idAction, actions: workflow.actions })

  const enrichFieldsData = (stepFields: any[]) => {
    const dynamicFieldsOptions = dynamicFieldsOptionsByActionId[idAction] || []
    const configuredWorkflowActionFieldsById = keyBy(configuredWorkflowAction.fields || [], 'id')

    return stepFields.map(fieldConfig => {
      const field = getFieldData({
        fieldsById: configuredWorkflowActionFieldsById,
        fieldConfig,
        dynamicFieldsOptions,
        loading: dynamicFieldsOptionsByActionId.loading
      })

      if (fieldConfig.fields) {
        const fieldsById = keyBy(field.fields, 'id')
        const subFields = fieldConfig.fields.map(fieldConfig => getFieldData({
          fieldsById: fieldsById,
          fieldConfig,
          dynamicFieldsOptions,
          loading: dynamicFieldsOptionsByActionId.loading
        }))

        return { ...field, fields: subFields }
      }

      return field
    })
  }

  const getActionData = () => {
    const actionTypesNames = [
      WORKFLOW_ACTION_TYPES.SELECT,
      ...visibleActionTypes
    ]
    const isLoaded = workflow.idApp && !isEmpty(configuredWorkflowAction) && configuredWorkflowAction.type && actionsConfig.length > 0
    const isActionSelectionStep = actionTypesNames.includes(stepType)

    let fields: any[] = []

    if (isActionSelectionStep) {
      return { componentType: WORKFLOW_STEP_TYPES.SELECT }
    }

    const { steps: enrichedActionSteps = [] } = isLoaded
      ? getStepsAndFieldsWithDefaultValues(workflow.triggerType, actionConfig, fieldsDefaultValues, actionsConfigByType)
      : { }

    const currentStep = enrichedActionSteps[stepIndex - 1] || {}
    if (currentStep.fields) {
      fields = enrichFieldsData(currentStep.fields)
    }

    return { componentType: WORKFLOW_STEP_TYPES.STEP_SETUP, fields }
  }

  const onChange = (data: OnChangeData, locallyOnly: boolean = false) => {
    // We are presuming actions exist in workflow since we're updating a specific action within it:
    const actions = workflow.actions!
    const nodes = { ...actions.nodes }

    if (componentType === WORKFLOW_STEP_TYPES.SELECT) {
      dispatch(clearActionFieldsOptions({ idAction }))
      const { idApp, type } = data as OnActionSelectionChangeData
      const isBranchAction = isBranchActionType(type)
      const existingActionNode = nodes[idAction] as ActionNodeModel

      if (isBranchAction) {
        nodes[idAction] = getNewBranchActionNode(existingActionNode, branchingEntityOptions)
      } else {
        const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === type)
        const { fields } = getStepsAndFieldsWithDefaultValues(workflow.triggerType, actionConfig, fieldsDefaultValues, actionsConfigByType)
        const action = {
          type,
          idApp,
          fields,
          isValid: false,
          errorMsg: ''
        }

        const isFixedBranchesAction = isActionHasFixedBranches(fields)

        if (isFixedBranchesAction) {
          nodes[idAction] = getNewActionWithFixedBranchesNode({
            currentActionNode: existingActionNode,
            newAction: action
          })
        } else {
          nodes[idAction] = {
            ...existingActionNode,
            action
          }
        }
      }

      const updateWorkflowData: UpdateWorkflowParams = {
        idOrg,
        idWorkflow: workflow.id,
        workflow: {
          ...workflow,
          actions: { ...actions, nodes }
        }
      }

      return dispatch(updateWorkflow(updateWorkflowData))
    } else {
      const { fieldId, selectedValue } = data as OnStepSetupChangeData
      const updatedAction: WorkflowActionModel = updateActionFieldValue(configuredWorkflowAction, selectedValue, fieldId)

      nodes[idAction] = {
        ...nodes[idAction],
        action: omit(updatedAction, ['id'])
      } as WorkflowNodeModel

      const updateWorkflowData: UpdateWorkflowParams = {
        idOrg,
        idWorkflow: workflow.id,
        workflow: {
          ...workflow,
          actions: { ...actions, nodes }
        }
      }

      return locallyOnly || !workflow.id
        ? dispatch(updateWorkflowLocally(updateWorkflowData))
        : dispatch(updateWorkflow(updateWorkflowData))
    }
  }

  const { componentType, fields } = getActionData()
  const Component = typeToComponent[componentType]

  return <>
    {Component && <Component
      key={idAction}
      actionConfig={actionConfig}
      action={{ ...configuredWorkflowAction, id: idAction }}
      prevActions={prevActions}
      fields={fields}
      triggerType={workflow.triggerType}
      onChange={onChange}
      onNext={goToNextStep}
      workflowType={workflow.type}
      noBorder
      workflow={workflow}
      disabled={disabled}
    />}
  </>
}
