import {
  GET_SIMILAR_APPS
} from '@root/constants'

const initialState = {
  loading: false,
  similarApps: {}
}

const similarApps = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SIMILAR_APPS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_SIMILAR_APPS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_SIMILAR_APPS}_RESPONSE`: {
      const { app, similarApps } = action.payload
      return {
        ...state,
        loading: false,
        similarApps: { ...state.similarApps, [app.id]: similarApps }
      }
    }
    default: {
      return state
    }
  }
}

export default similarApps
