import { USER_FIELDS_TYPES } from '@root/constants'
import {
  USER_DETAIL_CHANGE_ANYTHING_OPTION,
  USER_DETAIL_CHANGE_OTHER_OPTION
} from '../consts'
import { UserFieldInputDropdownOption } from '../types'
import { shouldOnlyShowAnythingOptions } from '../utils'
import { SimpleDropdown, SimpleDropdownProps } from './simpleDropdown'

const USER_FIELD_TYPES_TO_EXCLUDE_OTHER_OPTION = [USER_FIELDS_TYPES.user, USER_FIELDS_TYPES.datePicker]

interface Props extends SimpleDropdownProps {
  selectedUserField: UserFieldInputDropdownOption
  innerSelectKey: string
}

export const UserDetailDropdown = ({
  options,
  value,
  onChange,
  disabled,
  loading,
  selectedUserField,
  innerSelectKey
}: Props) => {
  const { type: userFieldType } = selectedUserField.value
  const shouldHaveOtherOption = !USER_FIELD_TYPES_TO_EXCLUDE_OTHER_OPTION.includes(userFieldType)
  const specialOptions = shouldHaveOtherOption
    ? [USER_DETAIL_CHANGE_ANYTHING_OPTION, USER_DETAIL_CHANGE_OTHER_OPTION]
    : [USER_DETAIL_CHANGE_ANYTHING_OPTION]

  const isDisabled = shouldOnlyShowAnythingOptions(selectedUserField)

  // This `selectKeyWithValue` is a workaround for multiple rendering issues:
  // 1. `ToriiSelect` uses `options` as the `key` for the inner `Select` component.
  //    This causes unexpected resets when `options` change, which leads to the dropdown
  //    closing itself unexpectedly. To prevent this, we provide an `id` to use as the key instead.
  // 2. However, using a static `id` prevents the dropdown from updating its displayed value
  //    when changed after the initial render.
  // 3. To address both issues, we create a dynamic `id` that includes the current value.
  //    This prevents unexpected resets while also forcing a re-render when the value changes.
  const selectKeyWithValue = `${innerSelectKey}-${value?.value}`

  return <SimpleDropdown
    options={[...specialOptions, ...options]}
    value={value}
    onChange={onChange}
    disabled={isDisabled || disabled}
    loading={loading}
    innerSelectKey={selectKeyWithValue}
  />
}
