import React, { useState, useEffect } from 'react'
import ToriiSelect from '../select'
import orderBy from 'lodash/orderBy'
import { getAllOrgs } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getOrgs } from '@selectors/orgs'
import * as style from './style'
import Spinner from '@media/spinner.svg'

const SelectOrgs = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const orgs = useSelector(getOrgs)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(getAllOrgs({ fields: 'id,companyName' }))
  }, [dispatch])

  const spinner = <img alt='Loading...' src={Spinner} width='30px' height='30px' />

  const onChangeSwitchOrgPage = (event) => {
    setIsLoading(true)
    window.location.href = `/team/${event ? event.value : ''}`
  }

  const getOptions = () => {
    const allOrgs = orgs
      .filter(org => org.companyName)
      .map((org) => ({
        label: `${org.companyName} - ${org.id}`,
        value: org.id
      }))
    return orderBy(allOrgs, [org => org.label.toLowerCase()], ['asc'])
  }

  return (
    <div {...style.Main}>
      <ToriiSelect
        options={getOptions()}
        onChange={onChangeSwitchOrgPage}
        clearable={false}
        placeholder=''
        value={idOrg}
        backspaceRemoves={false}
        tabSelectsValue={false}
        borderless
        menuWidth={250}
      />
      {isLoading && spinner}
    </div>
  )
}

export default SelectOrgs
