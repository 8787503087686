import React from 'react'
import { css } from 'glamor'
import Placeholder from '@components/placeholder'
import { FormFieldV2 } from '@pages/workflow_v2/formField'
import { Form, Field } from 'react-final-form'
import noop from 'lodash/noop'
import { formFieldTypes, SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import config from '@root/config'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import { getScopeByIdOrgAndIdApp } from '@lenses/scopes'
import { CSS } from './style'
import { OnChangeData } from '../type'
import { PreviousActionType } from './type'
import { formFieldTypeToConfigMap } from '@pages/workflow_v2/formField/mapping/fieldMapping'
import { ActionWithIdNode } from '@pages/workflow_v2/types'
import {
  WORKFLOW_ACTION_TYPE,
  WorkflowActionType
} from '@shared/workflows/types'
import { Link, AlertBox, AlertBoxType, Stack } from '@toriihq/design-system'
import { debounce } from 'lodash'
import { shouldLoadDynamicFieldsOptions } from '@actions/shouldLoadDynamicFieldsOptions'
import { WorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'

interface OwnProps {
  action: ActionWithIdNode
  prevActions: ActionWithIdNode[]
  triggerType: string
  onChange: (data: OnChangeData, locallyOnly?: boolean) => void
  header: string
  workflowType: string
  triggerIdAppAccount: number
  triggerAppAndAccount: { idApp: number, idAppAccount: number}
  actionConfig: any
  disabled: boolean
  hiddenFieldsIds: string[]
  noBorder: boolean
  loading: boolean
  supportedFeatures: Record<string, any>
  idWorkflow: number
}

interface DispatchProps {
  getActionFieldsOptions: ({
    idOrg,
    action,
    triggerType,
    prevActionTypes }: { idOrg: number, action: ActionWithIdNode, triggerType: string, prevActionTypes: PreviousActionType[] }) => void
  getWorkflowsPersonalizationsConfig: ({ idOrg, idApp }: { idOrg: number, idApp?: number }) => void,
  getWorkflowsDynamicPersonalizationsConfig: ({ idOrg, idWorkflow }: { idOrg: number, idWorkflow: number }) => void
}

type Props = OwnProps & DispatchProps & {
  fields: any[]
  idOrg: number
  signedInUserId: number
  personalization: { id: string, display: string}[]
  isLoadingFieldOptions: boolean
  prevActionTypes: PreviousActionType[]
}

class StepSetup extends React.Component<Props> {
  static contextType = WorkflowEditorContext
  isFirstEdit = Boolean(this.context.idNewNode)
  touched: { [key: string]: boolean } = {}
  fieldsSetDefaultValue = {
    [formFieldTypes.account]: (field) => {
      const { options, value } = field
      if (options && options.length) {
        if (value) {
          const updatedSelectedOption = options.find(option => option.id === value.id) || value
          if (!isEqual(value, updatedSelectedOption)) {
            this.onFieldChange(field, updatedSelectedOption)
          }
        } else {
          const { triggerAppAndAccount } = this.props
          const { idAppAccount: triggerIdAppAccount } = triggerAppAndAccount || {}
          const triggerAppAccountOption =
            triggerIdAppAccount &&
            options.find(option => option.id === triggerIdAppAccount)
          if (triggerAppAccountOption) {
            this.onFieldChange(field, triggerAppAccountOption)
          } else if (options.length === 1) {
            this.onFieldChange(field, options[0])
          }
        }
      }
    },
    [formFieldTypes.user]: (field) => {
      const { signedInUserId } = this.props

      if (field.autoSelectTriggerUser && !field.value) {
        this.onFieldChange(field, 'triggerUser')
      } else if (field.autoSelectSignedInUser && !field.value) {
        this.onFieldChange(field, signedInUserId)
      }
    },
    [formFieldTypes.appAndAccount]: (field, triggerAppAndAccount) => {
      if (field.options && !field.value) {
        const specialOption = field.options.find(option => option.isSpecial)
        const app = triggerAppAndAccount && field.options.find(option => option.id === triggerAppAndAccount.idApp && option.idAppAccount === triggerAppAndAccount.idAppAccount)
        if (app) {
          this.onFieldChange(field, app)
        } else if (specialOption) {
          this.onFieldChange(field, specialOption)
        }
      }
    },
    [formFieldTypes.app]: (field) => {
      if (field.options && !field.value) {
        const specialOption = field.options.find(option => option.isSpecial)
        if (specialOption) {
          this.onFieldChange(field, specialOption)
        }
      }
    }
  }

  componentDidMount () {
    const { idOrg } = this.props
    if (idOrg) {
      this.loadDynamicFieldsOptions()
      this.loadPersonalizationsOptions()
    }
  }

  componentDidUpdate (prevProps) {
    const { fields, idOrg, action, triggerAppAndAccount, actionConfig } = this.props

    if (!isEqual(fields, prevProps.fields)) {
      fields.forEach(field => {
        this.fieldsSetDefaultValue[field.type] && this.fieldsSetDefaultValue[field.type](field, triggerAppAndAccount)
      })

      const shouldLoadFieldsOptions = fields.some(field => shouldLoadDynamicFieldsOptions({
        currentField: field,
        prevFields: prevProps.fields,
        actionConfig
      }))

      if (shouldLoadFieldsOptions) {
        this.loadDynamicFieldsOptions()
      }
    }

    if (idOrg && (prevProps.idOrg !== idOrg || action.type !== prevProps.action.type)) {
      this.loadDynamicFieldsOptions()
      this.loadPersonalizationsOptions()
    }
  }

  loadDynamicFieldsOptions = debounce(() => {
    const { getActionFieldsOptions, idOrg, action, triggerType, prevActionTypes } = this.props
    const excludedActionTypes: WorkflowActionType[] = [
      // Exclude `IF_ELSE_BRANCH` from loading options because:
      // 1. This action type doesn't use the `getActionFieldsOptions` mechanism to load its options
      // 2. Calling `getActionFieldsOptions` with multiple branch filters might cause a 413 (Payload Too Large) error.
      WORKFLOW_ACTION_TYPE.IF_ELSE_BRANCH
    ]
    if (!excludedActionTypes.includes(action.type)) {
      getActionFieldsOptions({ idOrg, action, triggerType, prevActionTypes })
    }
  }, 50)

  loadPersonalizationsOptions = () => {
    const { getWorkflowsPersonalizationsConfig, getWorkflowsDynamicPersonalizationsConfig, idOrg, idWorkflow } = this.props
    const idApp = get(this.props, ['action', 'idApp'], undefined)
    getWorkflowsPersonalizationsConfig({ idOrg, idApp })
    getWorkflowsDynamicPersonalizationsConfig({ idOrg, idWorkflow })
  }

  getMetaForField = (field) => {
    let errorMsg = field.errorMsg
    if (field.fields) {
      const fieldWithError = field.fields.find((subField) => subField.errorMsg)
      errorMsg = fieldWithError ? `${fieldWithError.errorMsg}: ${fieldWithError.id}` : ''
    }
    const error = (field.isValid === false) && errorMsg
    const touched = this.isFirstEdit ? this.touched[field.id] : true

    return { touched, error }
  }

  renderField = (props) => {
    const { input, field } = props
    const meta = this.getMetaForField(field)

    return <FormFieldV2
      {...props}
      meta={meta}
      input={{
        ...input,
        disabled: props.disabled,
        onBlur: e => {
          const selectedValue = e?.target ? e.target.value : (e || '')
          this.touched[field.id] = true
          input.onBlur(e)
          this.props.onChange({ fieldId: field.id, selectedValue })
        },
        onChange: (e, locallyOnly = false) => {
          const selectedValue = e && e.target ? e.target.value : (e || '')
          input.onChange(e)
          this.onFieldChange(field, selectedValue, locallyOnly)
        }
      }} />
  }

  onFieldChange = (field, selectedValue, locallyOnly = false) => {
    this.props.onChange({ fieldId: field.id, selectedValue }, locallyOnly)
  }

  isDisabled = (field) => {
    const { actionConfig, fields, disabled } = this.props
    const fieldConfig = get(actionConfig, ['inputSchema', field.id]) || {}
    const isValid = fieldConfig.disabledUntilFieldsAreValid ? fieldConfig.disabledUntilFieldsAreValid.every(fieldId => fields.find(field => field.id === fieldId).isValid) : true
    return disabled || !isValid
  }

  isHidden = (field) => {
    const { actionConfig, fields, hiddenFieldsIds = [], supportedFeatures } = this.props
    const fieldConfig = get(actionConfig, ['inputSchema', field.id]) || {}

    if (fieldConfig.isHidden) {
      return true
    }

    if (hiddenFieldsIds.includes(field.id)) {
      return true
    }

    if (fieldConfig.featureFlag && !supportedFeatures[fieldConfig.featureFlag]) {
      return true
    }

    if (!fieldConfig.showOnFieldSpecificValue) {
      return false
    }

    const dependsOnField = fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
    const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField.value
    return dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
  }

  shouldClearOptions = (field) => {
    const { actionConfig } = this.props
    const fieldConfig = get(actionConfig, ['inputSchema', field.id]) || {}
    return Boolean(fieldConfig.disabledUntilFieldsAreValid)
  }

  renderForm = (fields) => {
    const { action, triggerType, personalization, actionConfig, idOrg, triggerAppAndAccount } = this.props
    const stepsDescription = get(actionConfig, ['uiConfig', 'stepsDescription'])
    const appBaseUrlWithOrg = `${config.appBaseUrl}/team/${idOrg}`
    const initialValues = fields.reduce((result, field) => {
      result[field.id] = field.value
      return result
    }, {})

    return <div {...CSS.formContainer}>
      {stepsDescription && <div {...CSS.stepsDescription} dangerouslySetInnerHTML={{ __html: stepsDescription.replace('{appBaseUrl}', appBaseUrlWithOrg) }} />}
      <Form
        onSubmit={noop}
        initialValues={initialValues}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              {fields.filter(Boolean).map((field, index) => {
                const isHidden = this.isHidden(field)

                if (isHidden) {
                  return null
                }

                const typeMapping = formFieldTypeToConfigMap[field.type] || {}
                const validate = typeMapping.validators
                const disabled = this.isDisabled(field)
                const loading = this.props.isLoadingFieldOptions
                const configuredField = {
                  ...field,
                  loading,
                  options: loading && this.shouldClearOptions(field) ? [] : field.options
                }
                return (
                  <EnableFor scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_WRITE, idOrg, action.idApp)]} key={field.id}>
                    <Field
                      className={field.sensitive ? 'fs-exclude' : ''}
                      addFieldIndex={false}
                      index={index}
                      validate={validate}
                      key={configuredField.id}
                      name={configuredField.id}
                      field={configuredField}
                      component={this.renderField}
                      format={typeMapping.format}
                      parse={typeMapping.parse}
                      action={action}
                      triggerType={triggerType}
                      personalization={personalization}
                      disabled={disabled}
                      refreshDynamicFieldsOptions={this.loadDynamicFieldsOptions}
                      triggerAppAndAccount={triggerAppAndAccount} />
                  </EnableFor>
                )
              })}
            </div>
          </form>
        }}
      />
    </div>
  }

  render () {
    const { loading, fields, actionConfig, noBorder } = this.props
    const requirementsMessage = get(actionConfig, ['uiConfig', 'requirementsMessage'])

    return (
      <Placeholder loading={loading} type='text' rows={4} style={{ height: '100px', width: '50px' }}>
        <Stack gap='space-200'>
          {requirementsMessage && (
            <AlertBox
              type={AlertBoxType.INFORMATIVE}
              description={parse(requirementsMessage, {
                replace: ({ attribs, children }: any) => {
                  if (attribs && attribs.href) {
                    const props = attributesToProps(attribs)
                    return (
                      <Link {...props}>
                        {domToReact(children)}
                      </Link>
                    )
                  }
                }
              })}
            />
          )}

          <div {...css(!noBorder && CSS.container)}>
            {this.renderForm(fields)}
          </div>
        </Stack>
      </Placeholder>
    )
  }
}

export default StepSetup
