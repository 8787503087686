import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAppsCategory as getAppsCategorySelector, isLoadingApps } from '@selectors/apps'

const mapStateToProps = (state) => {
  const appsCategoryList = getAppsCategorySelector(state)
  const hasData = appsCategoryList && appsCategoryList.length > 0
  const loading = isLoadingApps(state) && !hasData

  return {
    loading,
    hasData,
    appsCategoryList
  }
}

export default withRouter(connect(mapStateToProps, null)(View))
