import {
  GET_PRODUCT_UPDATES
} from '@root/constants'

const initialState = {
  loading: false,
  productUpdates: []
}

const productUpdatesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_PRODUCT_UPDATES}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_PRODUCT_UPDATES}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_PRODUCT_UPDATES}_RESPONSE`: {
      const { productUpdates } = action.payload
      return {
        ...state,
        loading: false,
        productUpdates
      }
    }
    default: {
      return state
    }
  }
}

export default productUpdatesReducer
