import { useState, JSX } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import { Props } from './types'
import Analytics from './analytics'

export default function WizardPopup<TSharedState, TSharedProps, TStepNames> ({
  name,
  isOpen,
  width,
  stepsConfig,
  sharedProps,
  initialSharedState,
  onCancel,
  onClose,
  onSubmitSuccess,
  onNavigateToStep
}: Props<TSharedState, TSharedProps, TStepNames>): JSX.Element {
  const [sharedState, setSharedState] = useState<TSharedState>(initialSharedState)
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const currentStepName = stepsConfig[currentStepIndex].stepName
  const analytics = Analytics<TStepNames>(name)

  const navigateToStep = (stepName: TStepNames) => {
    const stepIndex = stepsConfig.findIndex(step => step.stepName === stepName)
    if (stepIndex !== -1) {
      analytics.onMovingToStep(currentStepName, stepName)
      setCurrentStepIndex(stepIndex)
      onNavigateToStep?.()
    }
  }

  const onCloseAction = () => {
    analytics.onClose(currentStepName)
    onClose?.()
  }

  const onCancelAction = () => {
    analytics.onCancel(currentStepName)
    onCancel?.()
  }

  const { stepRenderer: StepRenderer, stepWidth } = stepsConfig[currentStepIndex]

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onCloseAction} width={stepWidth || width}>
      <StepRenderer
        sharedState={sharedState}
        sharedProps={sharedProps}
        setState={setSharedState}
        navigateToStep={navigateToStep}
        onCancel={onCancelAction}
        onSubmitSuccess={() => onSubmitSuccess?.()}
      />
    </ToriiPopup>
  )
}
