import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Flashing = styled.span`
  position: absolute;
  pointer-events: none;
  bottom: 2px;
  left: 3px;
  display: inline-block;
  height: 11px;
  width: 11px;
  background-color: ${({ theme }) => theme.palette.icon.emphasis};
  border-radius: 50%;
  z-index: 10;
  opacity: 0;
  animation-name: ${fadeInOut};
  animation-duration: 4s;
  animation-fill-mode: both;
`
