import React, { Fragment, useEffect, useState } from 'react'
import * as Style from './style'
import { capitalize } from 'lodash'
import { ShowMore } from './showMore'
import { SelectAppAccount } from './selectAppAccount'
import { APP_USER_STATUS } from '@shared/types'
import analytics from '@root/helpers/analytics'
import { ConnectedAppUsersStatusesBreakdown, UsersStatusesBreakdownStatus } from '@reducers/app.types'
import { Component2, Link, Subtitle1, Divider } from '@toriihq/design-system'

const ALL_APP_ACCOUNTS_LABEL = 'Users from all accounts'

export default function UsersStatusesBreakdown ({
  connectedAppUsersStatusesBreakdown,
  integrationDocumentationLink
}: {
  connectedAppUsersStatusesBreakdown: ConnectedAppUsersStatusesBreakdown,
  integrationDocumentationLink: string
}) {
  useEffect(() => {
    analytics.track('Opened UsersStatusesBreakdown popover')
  }, [])

  const {
    allAppAccountsBreakdown,
    breakdownByAppAccountName,
    distinctAppUsers
  } = connectedAppUsersStatusesBreakdown

  const allAppAccountNames = breakdownByAppAccountName?.appAccountName?.map(({ value }) => value) || []

  const [selectedAppAccountName, setSelectedAppAccountName] = useState<string>(ALL_APP_ACCOUNTS_LABEL)
  const [statusesToRender, setStatusesToRender] = useState<UsersStatusesBreakdownStatus[]>(allAppAccountsBreakdown?.status || [])

  useEffect(() => {
    if (selectedAppAccountName === ALL_APP_ACCOUNTS_LABEL) {
      setStatusesToRender(allAppAccountsBreakdown?.status || [])
    } else {
      const appAccountBreakdown = breakdownByAppAccountName?.appAccountName.find(({ value }) => value === selectedAppAccountName)?.status
      setStatusesToRender(appAccountBreakdown || [])
    }
  }, [
    allAppAccountsBreakdown?.status,
    breakdownByAppAccountName?.appAccountName,
    selectedAppAccountName
  ])

  const orderedStatusesToRender = statusesToRender.sort((first, second) => {
    return first.value === APP_USER_STATUS.ACTIVE ? -1 : 1
  })

  const unexpandedExternalStatusesAmountToShow = 3
  const shouldDisplayUniqueUsers = allAppAccountNames.length > 1

  return (
    <Style.Wrapper>
      <Style.Header>
        {allAppAccountNames.length <= 1 ? <Style.KeyValueTitle>Users of the application</Style.KeyValueTitle> : (
          <SelectAppAccount
            allAppAccountsLabel={ALL_APP_ACCOUNTS_LABEL}
            appAccountNames={allAppAccountNames}
            selectedAppAccountName={selectedAppAccountName}
            onSelected={(name) => {
              analytics.track('Selected app account at UsersStatusesBreakdown popover', {
                'show all accounts selected': name === ALL_APP_ACCOUNTS_LABEL
              })
              setSelectedAppAccountName(name)
            }}
          />
        )
        }
      </Style.Header>
      <Divider orientation='Vertical' />
      <Style.BreakdownWrapper>
        {orderedStatusesToRender.map(({ value, count, externalStatus: externalStatuses }, index) => (
          <Fragment key={value}>
            {index >= 1 && <Style.Spacer><Divider orientation='Vertical' /></Style.Spacer>}
            <Style.FlexContainer>
              <Style.Key><Subtitle1 color='primary'>Users reported as {value !== APP_USER_STATUS.ACTIVE ? 'not ' : ''}having access</Subtitle1></Style.Key>
              <Subtitle1 color='primary'>{count}</Subtitle1>
            </Style.FlexContainer>
            <ShowMore
              items={externalStatuses}
              itemKey={'value'}
              unexpandedItemsAmount={unexpandedExternalStatusesAmountToShow}
              renderer={({ value, count }) => (
                <Fragment>
                  <Style.KeyValueLine key={value}>
                    <Style.Key><Component2 color='tertiary'>{capitalize(value)}</Component2></Style.Key>
                    <Component2 color='tertiary'>{count}</Component2>
                  </Style.KeyValueLine>
                </Fragment>
              )} />
          </Fragment>))}
        {distinctAppUsers && shouldDisplayUniqueUsers &&
          <Fragment>
            <Style.Spacer><Divider orientation='Vertical' /></Style.Spacer>
            <Style.KeyValueTitle>
              <Style.Key>Unique users across accounts</Style.Key>
              <Style.Value>{distinctAppUsers}</Style.Value>
            </Style.KeyValueTitle>
          </Fragment>
        }
      </Style.BreakdownWrapper>
      {integrationDocumentationLink && (
        <>
          <Divider orientation='Vertical' />
          <Link href={integrationDocumentationLink} target='_blank'>
            <Link.Icon name='Info' /> Learn more about this integration
          </Link>
        </>
      )}
    </Style.Wrapper>
  )
}
