import View from './view'
import { connect } from 'react-redux'
import { getUserDataAccessByCategory } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { getUserDataAccessByCategory as getUserDataAccessByCategorySelector, isLoadedUserDataAccessByCategory, isLoadingUserDataAccessByCategory } from '@selectors/apps'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { pageName, showTwoRows } = ownProps
  const categories = getUserDataAccessByCategorySelector(state)
  const isLoaded = isLoadedUserDataAccessByCategory(state)
  const loading = !isLoaded || (isLoadingUserDataAccessByCategory(state) && categories.length === 0)

  return {
    loading,
    categories,
    idOrg,
    pageName,
    showTwoRows
  }
}

const mapDispatchToProps = {
  getUserDataAccessByCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
