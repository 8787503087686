import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { WIZARD_STEPS, SharedProps, SharedState } from '../types'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { Counter, Icon, ExpandableSection, Subtitle2, Spacer, AlertBox, AlertBoxType, Link } from '@toriihq/design-system'
import Table from '@root/components/table'
import { AlertBoxWrapper, ExpandableSectionWrapper } from './style'
import pluralize from 'pluralize'
import { useDispatch } from 'react-redux'
import { syncUsers } from '@actions/usersImport'
import Analytics from '@components/popups/importUsersPopup/analytics'
import { invalidUsersColumns, missingUsersColumns, validUsersColumns, newUsersWithLicensesColumns } from './columns'
import { startCase } from 'lodash'
import { IMPORT_TYPE } from '@selectors/importUsers/types'

const ReviewImportChanges = ({
  onCancel, navigateToStep, sharedState, sharedProps
}: PopupRendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const stepNameForAnalytics = 'Preview'
  const { idOrg, idApp, isFirstTimeImport, appName, importMode } = sharedProps
  const { parsingPreviewChanges, customParser } = sharedState
  const { invalidUsers, validUsers, missingUsers, newUsersWithLicenses } = parsingPreviewChanges
  const dispatch = useDispatch()

  const renderPopupTable = ({ columns, data }) => {
    return (
      <Table
        data={data}
        columns={columns}
        style={{ minHeight: 'unset', borderTop: 'none' }}
        tableHeaderStyle={{ padding: 0 }}
        draggable={false}
      />)
  }

  const renderInvalidUsersExpandable = () => {
    return invalidUsers.length ? (
      <ExpandableSectionWrapper>
        <ExpandableSection
          key={'invalidUsers'}
          title={<>Users with errors<Counter value={invalidUsers.length} /><Icon name='Alert' color='warning' /></>}
          subtitle={'These users will be skipped'}
          onToggle={() => Analytics.onClickSkipUsersPanel(importMode)}>
          {renderPopupTable({ columns: invalidUsersColumns, data: invalidUsers })}
        </ExpandableSection>
      </ExpandableSectionWrapper>
    ) : null
  }

  const renderUsersThatWontBeAddedExpandable = () => {
    return newUsersWithLicenses.length ? (
      <ExpandableSectionWrapper>
        <ExpandableSection
          key={'newUsersWithLicenses'}
          title={<>New users<Counter value={newUsersWithLicenses.length} /></>}
          subtitle={'New users cannot be added via adjust licenses action'}
          onToggle={() => Analytics.onClickMissingUsersPanel(importMode)}>
          {renderPopupTable({ columns: newUsersWithLicensesColumns, data: newUsersWithLicenses })}
        </ExpandableSection>
      </ExpandableSectionWrapper>
    ) : null
  }

  const renderMissingUsersExpandable = () => {
    const title = importMode === IMPORT_TYPE.USERS ? 'Users that will be removed' : 'Users that will have their licenses removed'
    const subtitle = importMode === IMPORT_TYPE.USERS ? 'These users will be removed from the app user list ' : 'Existing users not included in the file will have their licenses removed'
    return missingUsers.length ? (
      <ExpandableSectionWrapper>
        <ExpandableSection
          key={'missingUsers'}
          title={<>{title}<Counter value={missingUsers.length} /></>}
          subtitle={subtitle}
          onToggle={() => Analytics.onClickMissingUsersPanel(importMode)}>
          {renderPopupTable({ columns: missingUsersColumns, data: missingUsers })}
        </ExpandableSection>
      </ExpandableSectionWrapper>
    ) : null
  }

  const renderValidUsersTable = () => {
    const { customParser, selectedColumnsToImport } = sharedState
    const { columnsToImport: parserColumnsToImport, uiConfig } = customParser || {}
    const topSpace = [...invalidUsers, ...missingUsers].length > 0 ? 'space-200' : 'space-0'
    const columnsToShow = parserColumnsToImport || selectedColumnsToImport
    const columnTooltips = uiConfig?.columnTooltips || {}

    const columns = validUsersColumns({ columnsToShow, isFirstTimeImport, columnTooltips })
    const tableDescription = importMode === IMPORT_TYPE.USERS
      ? `${pluralize('user', validUsers.length, true)} will be added${!isFirstTimeImport ? ' or updated' : ''}`
      : `${pluralize('user', validUsers.length, true)} will be updated`
    return validUsers.length ? (
      <>
        <Spacer top={topSpace} bottom='space-150'>
          <Subtitle2>{tableDescription}</Subtitle2>
        </Spacer>
        {renderPopupTable({ columns, data: validUsers })}
      </>
    ) : null
  }

  const customParserAlertBox = () => {
    const onMapColumnsManuallyClick = () => {
      navigateToStep(WIZARD_STEPS.MAP_COLUMNS)
    }

    return customParser ? (
      <AlertBoxWrapper>
        <AlertBox
          type={AlertBoxType.POSITIVE}
          title={`File was identified as a standard ${startCase(appName)} user report and was automatically parsed by Torii.`}
          description={<><Link onClick={onMapColumnsManuallyClick}>Manually map columns</Link> if the outcome is not what you expected.</>}
        />
      </AlertBoxWrapper>
    ) : null
  }

  const onImportClick = async () => {
    const { idUsersImportParsing } = sharedState

    Analytics.onClickImportUsers(importMode)
    dispatch(syncUsers({ idOrg, idApp, idUsersImportParsing, mode: importMode }))
    navigateToStep(WIZARD_STEPS.LOADING_AFTER_IMPORT)
  }

  const onBackClick = () => {
    Analytics.onBackAction(importMode, stepNameForAnalytics)

    if (customParser) {
      navigateToStep(WIZARD_STEPS.UPLOAD_FILE)
    } else {
      navigateToStep(WIZARD_STEPS.MAP_COLUMNS)
    }
  }

  const onCancelClick = () => {
    Analytics.onCloseImportPopup(importMode, stepNameForAnalytics, 'Cancel')
    onCancel()
  }

  return (
    <>
      <ToriiPopup.Header header='Review and complete your import' subHeader={sharedProps.appAccountName} />
      <ToriiPopup.Content>
        {customParserAlertBox()}
        {renderInvalidUsersExpandable()}
        {renderUsersThatWontBeAddedExpandable()}
        {renderMissingUsersExpandable()}
        {renderValidUsersTable()}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonAction={onCancelClick}
        cancelButtonText='Cancel'
        mainButtonText='Import'
        mainButtonAction={onImportClick}
        isMainButtonDisabled={!validUsers.length}
        showBackButton
        backButtonAction={onBackClick}
      />
    </>
  )
}

export default ReviewImportChanges
