import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import BoxHeader from './BoxHeader'
import colors from '../../shared/style/colors'
import Placeholder from '../placeholder'
import RelativeTeamLink from '../relativeTeamLink'
import { fontSize } from '../../shared/style/sizes'
import Analytics from '@helpers/analytics'
import VisibleFor from '@components/visibleFor'
import { Link, EmptyState, theme } from '@toriihq/design-system'

const CSS = {
  container: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    padding: '20px 37px 14px 31px',
    '@media(max-width: 600px)': {
      padding: '26px 37px'
    }
  }),
  description: css({
    height: '24px',
    display: 'flex',
    marginTop: '12px',
    color: colors.grey2,
    fontSize: fontSize.small,
    ' strong': {
      color: colors.black,
      fontSize: fontSize.large,
      marginRight: '3px'
    },
    ' div': {
      alignSelf: 'flex-end'
    }
  }),
  main: css({
    position: 'relative',
    width: '100%'
  }),
  emptyState: css({
    border: `1px solid ${theme.palette.border.primary}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    padding: '20px 37px 14px 31px',
    flexWrap: 'wrap'
  })
}

CSS.navTo = css({
  position: 'absolute',
  top: 0,
  right: 0,
  fontSize: fontSize.small,
  opacity: 0,
  transition: 'opacity .2s',
  [`.${CSS.main}:hover &, [data-${CSS.main}]:hover &`]: {
    opacity: 1
  }
})

const onClick = (pageName, boxName) => {
  pageName && Analytics.track(`Click on See All / ${pageName}`, boxName && {
    'Block': boxName
  })
}

const InfoBox = ({ overrideStyle, loading, center, header, subHeader, description, emptyStateInfo, navTo, navToNeededScopes, pageName, boxName }) => {
  const renderInfoBox = (
    <div {...css(CSS.container, overrideStyle)} >
      <Placeholder loading={loading} type='rect' style={{ height: '55px', margin: center ? 'auto' : '' }}>
        <div {...CSS.main}>
          <BoxHeader header={header} subHeader={subHeader} />
          <div {...CSS.description}>{description}</div>
          {navTo && (
            <VisibleFor scopes={navToNeededScopes}>
              <RelativeTeamLink to={navTo} className={CSS.navTo.toString()} onClick={() => onClick(pageName, boxName)}>
                <Link>See all</Link>
              </RelativeTeamLink>
            </VisibleFor>
          )}
        </div>
      </Placeholder>
    </div>
  )
  if (loading || header) {
    return renderInfoBox
  } else {
    return <div {...CSS.emptyState}><EmptyState {...emptyStateInfo} /></div>
  }
}

InfoBox.propTypes = {
  navTo: PropTypes.string,
  overrideStyle: PropTypes.object,
  header: PropTypes.node,
  subHeader: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  emptyStateInfo: PropTypes.shape({
    header: PropTypes.string.isRequired,
    img: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }),
    buttonText: PropTypes.string,
    linkText: PropTypes.string,
    subHeader: PropTypes.string,
    onClick: PropTypes.shape({
      navTo: PropTypes.string,
      callback: PropTypes.func,
      analytics: PropTypes.shape({
        event: PropTypes.string,
        properties: PropTypes.object
      })
    })
  })
}

export default InfoBox
