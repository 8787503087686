import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WORKFLOW_TRIGGER_TYPES, WORKFLOW_TYPES } from '@root/constants'
import WorkflowActions from '@components/workflows/workflowActions'
import useDeleteLogic from '@components/workflows/workflowActions/hooks/useDeleteConfirmation'
import AppCatalogPolicyActions from '@components/workflows/appCatalogPolicyActions'
import { useParams } from 'react-router'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { getWorkflowPageReferrer } from '@pages/workflow_v2/workflowEditor/utils/utils'
import { getShowHiddenWorkflows } from '@selectors/ui'
import { deleteWorkflow } from '@actions/'
import { getPolicyDeleteLogicProps } from '@lenses/workflows'
import { isToriiAdmin } from '@selectors/me'
import { getWorkflows } from '@actions/workflows'

export const WorkflowActionsButtons = (): ReactElement => {
  const { idOrg: idOrgString, idWorkflow: idWorkflowString } = useParams()
  const idOrg = Number(idOrgString)
  const idWorkflow = Number(idWorkflowString)
  const workflow = useSelectedWorkflow()
  const { isAppCatalogPoliciesView } = useWorkflowEditorContext()
  const hasToriiAdminPermissions = useSelector(isToriiAdmin)
  const showHiddenWorkflows = useSelector(getShowHiddenWorkflows)
  const dispatch = useDispatch()
  const referrer = getWorkflowPageReferrer(isAppCatalogPoliciesView)
  const dispatchDeleteWorkflow = ({ idOrg, idWorkflow, type, triggerType }) => dispatch(deleteWorkflow({
    idOrg,
    idWorkflow,
    type,
    triggerType
  }))
  const dispatchGetWorkflows = () => dispatch(getWorkflows({
    idOrg,
    type: WORKFLOW_TYPES.regular,
    isHidden: showHiddenWorkflows && hasToriiAdminPermissions
  }))

  let ActionsComponent = WorkflowActions
  let useDeleteLogicParams = {
    idOrg,
    deleteWorkflow: dispatchDeleteWorkflow,
    getUpdatedWorkflowsList: dispatchGetWorkflows,
    referrer
  }

  if (isAppCatalogPoliciesView) {
    ActionsComponent = AppCatalogPolicyActions
    const policyDeleteLogicProps = getPolicyDeleteLogicProps({ idOrg, dispatch })
    useDeleteLogicParams = {
      ...useDeleteLogicParams,
      ...policyDeleteLogicProps
    }
  }

  const { deleteConfirmation, onOpen } = useDeleteLogic(useDeleteLogicParams)

  const {
    name,
    triggerType,
    isValid,
    type: workflowType
  } = workflow

  const handleDeleteConfirmationOpen = () => onOpen({
    idWorkflow,
    workflowName: name,
    workflowType,
    workflowTriggerType: triggerType
  })

  return (<>
    <ActionsComponent
      idOrg={idOrg}
      idWorkflow={idWorkflow}
      name={name}
      workflowType={workflowType}
      triggerType={triggerType}
      isValid={isValid}
      referrer={referrer}
      onDeleteConfirmationOpen={handleDeleteConfirmationOpen}
      pageName={isAppCatalogPoliciesView ? 'policy page' : 'workflow page'}
      showDuplicateAction={!(triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP)}
    />
    {deleteConfirmation}
  </>)
}
