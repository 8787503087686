import { get } from 'lodash'
import { SubMenuItemState } from '@toriihq/design-system'
import { getFinalActionLabelV2 } from '@lenses/workflows'
import { WORKFLOW_ACTION_TYPES, WORKFLOW_VALIDATION_TYPES } from '@root/constants'
import { WORKFLOW_STEP_TYPE } from '@shared/types'
import { Action, IdWorkflowNode, WORKFLOW_NODE_TYPE, WorkflowNodeModel } from '@shared/workflows/types'
import { ActionConfiguration, ActionStep, ActionUiConfigStep } from './types'

export const createActionConfiguration = (
  idWorkflowNode: IdWorkflowNode,
  actionNode: WorkflowNodeModel,
  actionsConfigByType: any
): ActionConfiguration => {
  const workflowAction: Action = actionNode.action
  const workflowActionType = workflowAction.type

  const isActionSelected = Boolean(workflowActionType)
  const actionErrorMsg = (!isActionSelected && workflowAction.errorMsg) || ''

  const actionConfig = workflowActionType ? actionsConfigByType[workflowActionType] : {}
  const actionConfigurationSteps: ActionStep[] = []

  const configStepDisabled = isConfigStepDisabled(actionNode.type)
  const actionSelectionStep: ActionStep = {
    type: workflowActionType || WORKFLOW_ACTION_TYPES.SELECT,
    label: 'Action',
    isValid: isActionSelected,
    errorMsg: actionErrorMsg,
    getState: (isSelectedNodeStep) => getNodeStepState(isSelectedNodeStep, configStepDisabled)
  }
  actionConfigurationSteps.push(actionSelectionStep)

  const actionUiConfig = actionConfig.uiConfig || defaultActionConfig
  const actionInputSchema = actionConfig.inputSchema || {}

  const numberOfActionSteps = actionUiConfig.steps.length
  actionUiConfig.steps.forEach((step: ActionUiConfigStep, index) => {
    const isValidFields = isFieldsValid({ step, actionInputSchema, workflowAction })
    const actionValidationObj = getActionValidationObj({ isValidFields, workflowAction })

    const configurationStepIndex = index + 1
    const configurationStep: ActionStep = {
      type: step.type,
      label: getStepLabel({
        currentStep: step,
        currentStepIndex: configurationStepIndex,
        totalNumberOfSteps: numberOfActionSteps
      }),
      ...actionValidationObj,
      getState: (isSelectedNodeStep) => getNodeStepState(isSelectedNodeStep, !isActionSelected)
    }
    actionConfigurationSteps.push(configurationStep)
  })

  const hasInvalidStep = actionConfigurationSteps.some(step => !step.isValid)
  const uiConfig = actionConfig.uiConfig
  const idApp = workflowAction.idApp
  const errorMsg = workflowAction.errorMsg
  return {
    id: idWorkflowNode,
    type: actionNode.type,
    label: getFinalActionLabelV2(actionConfig, workflowAction),
    steps: actionConfigurationSteps,
    thenText: get(uiConfig, ['thenText'], ''),
    isValid: !hasInvalidStep,
    continueOnError: workflowAction.continueOnError,
    branchesConfiguration: get(uiConfig, ['branchesConfiguration']),
    imageUrl: uiConfig?.imageUrl,
    idApp,
    errorMsg,
    actionNode
  }
}

const defaultActionConfig = {
  steps: [{
    type: WORKFLOW_STEP_TYPE.STEP_SETUP,
    label: 'Setup',
    fields: []
  }]
}

const isFieldsValid = ({ step, actionInputSchema, workflowAction }: { step: ActionUiConfigStep, actionInputSchema: any, workflowAction: Action }) => {
  return step.fields.every(fieldId => {
    const fieldInputSchema = actionInputSchema[fieldId]
    const isRequired = fieldInputSchema?.validations.includes(WORKFLOW_VALIDATION_TYPES.REQUIRED)
    const configuredWorkflowActionField = workflowAction.fields.find(field => field.id === fieldId) || { isValid: !isRequired }
    return configuredWorkflowActionField.isValid
  })
}

const getStepLabel = ({ currentStep, currentStepIndex, totalNumberOfSteps }) => {
  return totalNumberOfSteps === 1
    ? 'Setup'
    : `${currentStepIndex}. ${currentStep.label}`
}

const getActionValidationObj = ({ isValidFields, workflowAction }: { isValidFields: boolean, workflowAction: Action }) => {
  const isValidData = isValidFields
    ? { isValid: true, errorMsg: '' }
    : { isValid: false, errorMsg: 'Some fields are missing or invalid' }

  return isValidFields && !workflowAction.isValid
    ? { isValid: false, errorMsg: workflowAction.errorMsg }
    : isValidData
}

export const getNodeStepState = (isSelectedNodeStep: boolean, isDisabled: boolean): SubMenuItemState =>
  isDisabled ? SubMenuItemState.disabled : (isSelectedNodeStep ? SubMenuItemState.active : SubMenuItemState.default)

const isConfigStepDisabled = (workflowNodeType: WORKFLOW_NODE_TYPE) => {
  // 2023-10-01 - we currently do not allow action reselection in branch actions,
  // due to implementation complexities.
  return [WORKFLOW_NODE_TYPE.BRANCH_ACTION, WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES].includes(workflowNodeType)
}
