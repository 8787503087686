export enum WIZARD_STEPS {
  ADD_OR_EDIT_CONTRACT = 'addOrEditContract',
  NEW_CONTRACT_AI = 'newContractAI'
}

export interface ParsedContractDetails {
  startDate: Date
  endDate: Date
  billingEmail: string
  paymentTerm: string
  supplier: string
  amount: { value: number, currency: string }
  status: string
  idApp: number
  name: string
  documents: { fileName: string, idUpload: number }[]
  autoRenewal: string
  cancellationNoticePeriodDays: number
}

export interface SharedState {
  idUpload: number | null,
  isNewContract: boolean,
  contractDetails: ParsedContractDetails | null,
  isAiRan: boolean
  successfullyParsedAmount: number
  shouldShowBackButton: boolean
  idAuditLog: number | null,
  isAlreadyForcedToBeDirty: boolean
}

export interface SharedProps {
  idContract: number,
  idApp: number,
  onSuccess: (...args: any) => any,
  initialValues,
  idOrg: number
  isDuplicated: boolean
  isAppMatchingMode: boolean
}
