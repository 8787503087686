import React, { ReactElement, useEffect, useState } from 'react'
import Page from '@components/page'
import { useSelector, useDispatch } from 'react-redux'
import { isToriiAdmin } from '@selectors/me'
import { getWorkflowsTriggersConfig } from '@actions/'
import * as Style from './style'
import TriggerSelector from '@pages/createWorkflow/triggerSelector'
import { useParams } from 'react-router-dom'
import CreateNewWorkflowButton from '@pages/createWorkflow/createWorkflowButton'
import { useIsMounted } from '@shared/hooks'
import { getShowHiddenWorkflows } from '@selectors/ui'
import BackLink from '@components/backLink'
import { analytics } from '@shared/services/workflows/analytics'

type SelectedTriggerState = { type: string | null, idApp: number | null }

const CreateWorkflowPage = (): ReactElement => {
  const [selectedTrigger, setSelectedTrigger] = useState<SelectedTriggerState>({ type: null, idApp: null })
  const isMounted = useIsMounted()

  const { idOrg } = useParams()
  const dispatch = useDispatch()

  const hasToriiAdminPermissions = useSelector(isToriiAdmin)
  const showHiddenWorkflows = useSelector(getShowHiddenWorkflows)

  const isHidden = showHiddenWorkflows && hasToriiAdminPermissions

  useEffect(() => {
    if (isMounted.current) {
      dispatch(getWorkflowsTriggersConfig())
    }
  }, [dispatch, isMounted])

  const onTriggerSelect = ({ idApp, type }) => {
    setSelectedTrigger({ type, idApp })
    analytics.create.selectedTrigger({ triggerType: type })
  }

  return (<Page title={isHidden ? 'New hidden workflow' : 'New workflow'}>
    <Style.MainWrapper>
      <Style.HeaderWrapper>
        <BackLink linkText='Back to workflows' navigateTo='/workflows' />
      </Style.HeaderWrapper>
      <Style.ContentWrapper>
        <Style.ContentHeader>
          <Style.ContentHeaderTitle>Choose trigger type</Style.ContentHeaderTitle>
          <CreateNewWorkflowButton
            idOrg={idOrg}
            triggerType={selectedTrigger.type}
            triggerTypeIdApp={selectedTrigger.idApp}
            isHidden={isHidden}
          />
        </Style.ContentHeader>
        <TriggerSelector onSelect={onTriggerSelect} triggerType={selectedTrigger.type} />
      </Style.ContentWrapper>
    </Style.MainWrapper>
  </Page>)
}

export default CreateWorkflowPage
