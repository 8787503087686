import View from './view'
import { connect } from 'react-redux'
import { getSSOAuditReport } from '@actions/'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import { deprecatedGetAppsByIds } from '@selectors/apps'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { ssoAudit } = state.reports
  const data = ssoAudit.report
  const loading = ssoAudit.loading && data.length === 0
  const apps = deprecatedGetAppsByIds(state) || {}

  return {
    data,
    apps,
    idOrg,
    loading
  }
}

const mapDispatchToProps = {
  getSSOAuditReport
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
