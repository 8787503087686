import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import TabsPage from '@pages/tabs'
import TeamUsers from '@components/teamUsers'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { getAppOwners, getRoles, getTeamMembers, isParentUserFilter } from '@actions/'
import { getAppOwnersList, isLoadingAppOwners } from '@selectors/apps'
import { getTeamMembersES, isTeamMembersLoadingES } from '@selectors/users'
import AppOwners from '@components/appOwners'
import { ops } from '@lenses/filters'
import useEffectAfterMount from '@root/shared/hooks/useEffectAfterMount'
import { FEATURES } from '@root/shared/features'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { H4, Spacer } from '@toriihq/design-system'

const FETCH_APP_OWNERS_INTERVAL_IN_SECONDS = 5

const MembersPage = withRouter(({ match }) => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg) || parseInt(match.params.idOrg, 10)
  const teamMembers = useSelector(getTeamMembersES)
  const isTeamMembersLoading = useSelector(isTeamMembersLoadingES)
  const appOwners = useSelector(getAppOwnersList)
  const isAppOwnersLoading = useSelector(isLoadingAppOwners)

  const [autoReloadAppOwners, setAutoReloadAppOwners] = useState(false)
  const [appOwnersAutoReloadInterval, setAppOwnersAutoReloadInterval] = useState(null)

  const fetchToriiUsersInfo = () => {
    const DEFAULT_FIELDS_TO_FETCH = ['firstName', 'lastName', 'email', 'isExternal', 'idRole', 'idOrg', 'photoUrl', 'role', 'status', 'isAdmin']
    const DEFAULT_FILTERS = [{ key: 'isAdmin', op: ops.equals, value: true }, {
      key: 'status',
      op: ops.anyOf,
      value: ['active', 'invited']
    }, isParentUserFilter]

    dispatch(getTeamMembers({ idOrg, fields: DEFAULT_FIELDS_TO_FETCH, reset: true, filters: DEFAULT_FILTERS }))
    dispatch(getRoles({ idOrg }))
  }

  const fetchAppOwners = () => {
    if (!idOrg || isAppOwnersLoading) {
      return
    }
    dispatch(getAppOwners({ idOrg }))
  }

  useEffectAfterMount(() => {
    if (autoReloadAppOwners) {
      const interval = setInterval(fetchAppOwners, FETCH_APP_OWNERS_INTERVAL_IN_SECONDS * 1000)
      setAppOwnersAutoReloadInterval(interval)
    } else {
      clearInterval(appOwnersAutoReloadInterval)
      setAppOwnersAutoReloadInterval(null)
    }
  }, [autoReloadAppOwners])

  useEffectAfterMount(() => {
    return () => {
      if (appOwnersAutoReloadInterval) {
        clearInterval(appOwnersAutoReloadInterval)
      }
    }
  }, [appOwnersAutoReloadInterval])

  useEffectOnce(() => {
    fetchAppOwners()
    fetchToriiUsersInfo()
  })

  const membersTabs = [
    {
      name: 'users',
      header: 'Torii Users',
      counter: teamMembers?.length,
      isLoading: isTeamMembersLoading,
      content: <TeamUsers idOrg={idOrg} />,
      feature: FEATURES.SETTINGS.TABS.MEMBERS.TABS.TORII_USERS
    },
    {
      name: 'appOwners',
      header: 'App Owners',
      counter: appOwners?.length,
      isLoading: (isAppOwnersLoading && isNaN(appOwnersAutoReloadInterval)) || !appOwners,
      content: <AppOwners
        appOwners={appOwners}
        loading={(isAppOwnersLoading && isNaN(appOwnersAutoReloadInterval)) || !appOwners}
        setAutoReloadAppOwners={setAutoReloadAppOwners} />,
      feature: FEATURES.SETTINGS.TABS.MEMBERS.TABS.APP_OWNERS
    }
  ]

  const tabsName = membersTabs.map(tab => tab.name)
  const tabsHeader = membersTabs.map(tab => ({ header: tab.header, counter: tab.counter, isLoading: tab.isLoading }))
  const tabsContent = membersTabs.map(tab => tab.content)
  const tabsFeature = membersTabs.map(tab => tab.feature)

  return (
    <TabsPage
      pageTitle='Members'
      pageHeader={<Spacer bottom={'space-050'}><H4>Members</H4></Spacer>}
      pageName='Members'
      path={`/team/${idOrg}/settings/members`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      tabsFeature={tabsFeature}
      isSecondary
    />
  )
})

export default MembersPage
