import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getChargeBackReportSelector } from '@selectors/licenses'
import { getChargebackReport, toggleConfigureAppChargeback } from '@actions/'
import { EMPTY_ARRAY } from '../../constants'
import { getCurrency } from '@selectors/org'
import { filterNoCostChargebackData } from '../../shared/chargeback'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const currency = getCurrency(state)
  const chargeback = getChargeBackReportSelector(state)

  const data = filterNoCostChargebackData(chargeback.data || EMPTY_ARRAY)

  return {
    idOrg,
    data,
    loading: chargeback.loading && data.length === 0,
    currency
  }
}

const mapDispatchToProps = {
  getChargebackReport,
  toggleConfigureAppChargeback
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
