import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getIsSmallScreen } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  return {
    idOrg,
    isSmallScreen: getIsSmallScreen(state)
  }
}

export default withRouter(connect(mapStateToProps)(View))
