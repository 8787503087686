import React from 'react'
import { toggleActivateWorkflowPopup, getTriggerPreview } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import ToriiToggle from '@components/toriiToggle'
import EnableFor from '@components/enableFor'
import { SCOPES, WORKFLOW_TYPES } from '@root/constants'
import Analytics from '@helpers/analytics'
import { getTriggersByType } from '@selectors/workflows'
import { getCurrentOrg } from '@selectors/org'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Tooltip, toast, ToastType } from '@toriihq/design-system'
import { updateWorkflow } from '@actions/workflows'

const WorkflowActivationToggle = ({
  loading,
  referrer,
  isAppCatalogPoliciesView,
  isRequestNewAppPoliciesView,
  workflowInfo
}) => {
  const appsTriggersByType = useSelector(getTriggersByType)
  const { id: idOrg } = useSelector(getCurrentOrg)

  const dispatch = useDispatch()

  const { id: idWorkflow, name: workflowName, type: workflowType, triggerType: workflowTriggerType, triggerIdApp: workflowTriggerIdApp } = workflowInfo
  const isActive = Boolean(workflowInfo.isActive)
  const isValid = Boolean(workflowInfo.isValid)

  const isSupportsWorkflowRetroactivelyRun = Boolean(get(appsTriggersByType, [workflowTriggerType, 'supportWorkflowRetroactivelyRun']))

  const isActivatedDefaultPolicy = isAppCatalogPoliciesView && workflowType === WORKFLOW_TYPES.appCatalog && !workflowTriggerIdApp && isActive
  const shouldDisableToggle = (!isActive && !isValid) || isActivatedDefaultPolicy

  const tooltipProps = isActivatedDefaultPolicy
    ? { label: 'Default policy cannot be deactivated', maxWidth: 180 }
    : { label: `The ${(isAppCatalogPoliciesView || isRequestNewAppPoliciesView) ? 'policy' : 'workflow'} has invalid configuration and cannot be activated` }

  const onToggleClick = () => {
    Analytics.track('Update workflow-state', {
      'Trigger name': workflowTriggerType,
      'Button location': referrer,
      'Workflow ID': idWorkflow,
      'State': isActive ? 0 : 1,
      'Open activate-workflow-dialog': Boolean(isSupportsWorkflowRetroactivelyRun && !isActive)
    })

    if (isSupportsWorkflowRetroactivelyRun && !isActive) {
      dispatch(getTriggerPreview({ idOrg, idWorkflow }))
      dispatch(toggleActivateWorkflowPopup({
        isOpen: true,
        idWorkflow,
        workflowName,
        triggerType: workflowTriggerType
      }))
    } else {
      dispatch(updateWorkflow({
        idOrg,
        idWorkflow,
        workflow: { isActive: Number(!isActive) }
      }))
      if (!isActive) {
        toast({
          message: (isAppCatalogPoliciesView || isRequestNewAppPoliciesView) ? 'The policy was activated. It will be applied on app requests that will be submitted in the future.' : 'The workflow was activated. It will run on entities that will meet the trigger criteria in the future.',
          type: ToastType.SUCCESS
        })
      }
    }
  }

  return (
    <Tooltip
      hide={!shouldDisableToggle}
      {...tooltipProps}>
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        <ToriiToggle
          id='activation-toggle'
          checked={Boolean(isActive)}
          onToggle={onToggleClick}
          loading={loading}
          disabled={shouldDisableToggle}
        />
      </EnableFor>
    </Tooltip>
  )
}

WorkflowActivationToggle.propTypes = {
  loading: PropTypes.bool,
  referrer: PropTypes.string,
  workflowInfo: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isActive: PropTypes.number,
    isValid: PropTypes.number,
    triggerType: PropTypes.string,
    type: PropTypes.string,
    triggerIdApp: PropTypes.number
  }),
  isAppCatalogPoliciesView: PropTypes.bool,
  isRequestNewAppPoliciesView: PropTypes.bool
}

WorkflowActivationToggle.defaultProps = {
  workflowInfo: {},
  isAppCatalogPoliciesView: false,
  isRequestNewAppPoliciesView: false
}

export default WorkflowActivationToggle
