import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useDispatch } from 'react-redux'
import { updateOrgAppOwnerSettings } from '@actions/'
import {
  provideAccessInfoList,
  revokeAccessInfoList,
  content,
  emailItem
} from '@components/popups/appOwnersToriiAccessPopup/style'
import { AppOwnersToriiAccessPopupActions } from '@components/popups/appOwnersToriiAccessPopup/constants'
import PropTypes from 'prop-types'
import { SUPPORT_ARTICLES } from '@root/articles'
import { Link, LinkSize, ButtonType, toast, ToastType } from '@toriihq/design-system'

const AppOwnersToriiAccessPopup = (props) => {
  const { isOpen, onClose, numberOfAppOwners, idOrg, isAppOwnerActive } = props
  const appOwnersNumberDisplay = numberOfAppOwners > 0 ? `(${numberOfAppOwners})` : ''
  const isAppOwnerActiveRef = React.useRef(isAppOwnerActive)
  const action = isAppOwnerActiveRef.current ? AppOwnersToriiAccessPopupActions.REVOKE_ACCESS : AppOwnersToriiAccessPopupActions.PROVIDE_ACCESS
  let popupHeader, actionButtonText, actionButtonType, orgIsAppOwnerActiveNewValue, notificationText, popupContent
  const dispatch = useDispatch()

  const provideAccessPopupContent = (
    <ul className={provideAccessInfoList}>
      <li>
        {`All app owners ${appOwnersNumberDisplay} will be able to log into Torii to manage the apps they own`}
      </li>
      <li>
        <div className={emailItem}>
          <span>App owners will receive email invites to access Torii</span>
          <Link size={LinkSize.Medium} href={SUPPORT_ARTICLES.APP_OWNER_ACCESS_FOR_TORII_ADMINS_EMAIL_PREVIEW} target='_blank'>
            Email Preview
            <Link.Icon name='ExternalLink' />
          </Link>
        </div>
      </li>
      <li>
        You can track your app owners’ Torii usage from this screen
      </li>
    </ul>
  )

  const revokeAccessPopupContent = (
    <ul className={revokeAccessInfoList}>
      <li>
        {`All app owners ${appOwnersNumberDisplay} will lose access to Torii`}
      </li>
      <li>
        No emails will be sent about this change
      </li>
    </ul>
  )

  if (action === AppOwnersToriiAccessPopupActions.PROVIDE_ACCESS) {
    popupHeader = 'Provide Torii access for all app owners'
    popupContent = provideAccessPopupContent
    actionButtonText = 'Provide access'
    actionButtonType = ButtonType.primary
    notificationText = 'Access provided to all app owners'
    orgIsAppOwnerActiveNewValue = 1
  } else {
    popupHeader = 'Revoke Torii access for all app owners'
    popupContent = revokeAccessPopupContent
    actionButtonText = 'Revoke access'
    actionButtonType = ButtonType.destructive
    notificationText = 'Access revoked for all app owners'
    orgIsAppOwnerActiveNewValue = 0
  }

  const onConfirm = async () => {
    const { org } = await dispatch(updateOrgAppOwnerSettings({ idOrg, isAppOwnerActive: orgIsAppOwnerActiveNewValue }))
    if (org && org.isAppOwnerActive === orgIsAppOwnerActiveNewValue) {
      toast({
        message: notificationText,
        type: ToastType.SUCCESS
      })
      onClose()
    }
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onClose}
    >
      <ToriiPopup.Header header={popupHeader} />
      <ToriiPopup.Content contentAreaStyle={content}>
        {popupContent}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonText='Cancel'
        mainButtonAction={onConfirm}
        mainButtonText={actionButtonText}
        mainButtonType={actionButtonType}
      />
    </ToriiPopup>
  )
}

AppOwnersToriiAccessPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  idOrg: PropTypes.number.isRequired,
  numberOfAppOwners: PropTypes.number.isRequired
}

export default AppOwnersToriiAccessPopup
