import analytics from '@root/helpers/analytics'

const Analytics = {
  onHoverExpenseInActiveContractDurationPopup: (appName) => {
    analytics.track('Hover Expenses-in-contract-info', { appName })
  },
  clickOnViewActiveContracts: (appName) => {
    analytics.track('Click on View-active-contracts-link', { appName })
  },
  clickOnViewExpensesInContractDuration: (appName) => {
    analytics.track('Click on View-expenses-in-contract-duration-link', { appName })
  }
}

export default Analytics
