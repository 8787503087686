import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getIsOnlyAppOwner } from '@selectors/me'

const mapStateToProps = (state) => {
  const isOnlyAppOwner = getIsOnlyAppOwner(state)
  return {
    isOnlyAppOwner
  }
}

export default withRouter(connect(mapStateToProps)(View))
