import React from 'react'
import { EMPTY_OBJECT, EMPTY_ARRAY, TRIGGER_TYPE_TO_ITEM, TABLES } from '@root/constants'
import Table from '@components/table'
import pluralize from 'pluralize'
import Placeholder from '@components/placeholder'
import { useSelector } from 'react-redux'
import {
  getTriggerPreview as getTriggerPreviewSelector,
  hasUnsavedChanges as hasUnsavedChangesSelector,
  isLoadingTriggerPreview,
  isUpdatingWorkflows
} from '@selectors/workflows'
import * as Style from './style'
import PropTypes from 'prop-types'
import getColumns from '@components/popups/triggerPreviewPopup/triggerPreviewTable/columns'
import { Body2 } from '@toriihq/design-system'

const TriggerPreviewTable = ({ idWorkflow, showTableTitle, getTheadThProps, overrideTdStyle, overrideTableStyle, usePaging }) => {
  const workflowsTriggerPreview = useSelector(getTriggerPreviewSelector)
  const { data: previewData, triggerType, isPreviewFailed } = workflowsTriggerPreview[idWorkflow] || { data: EMPTY_ARRAY }
  const isPreviewDataLoading = useSelector(isLoadingTriggerPreview)
  const isWorkflowUpdateInProgress = useSelector(isUpdatingWorkflows)
  const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector)
  const loading = isPreviewDataLoading || isWorkflowUpdateInProgress || hasUnsavedChanges

  const hasData = Boolean(previewData && previewData.length)

  const previewDataItem = (TRIGGER_TYPE_TO_ITEM[triggerType] || TRIGGER_TYPE_TO_ITEM.default).toLowerCase()
  const pluralizedDataItems = pluralize(previewDataItem)

  return <div>
    {isPreviewFailed
      ? <Body2 color='error'>The preview could not be loaded at the moment. We're working to resolve the issue. Please try again later.</Body2>
      : <>
        {showTableTitle ? <Placeholder loading={loading} type='text' rows={1} style={{ width: '50px' }}>
          {hasData && <div {...Style.TableTitle}>{previewData.length} {pluralize(previewDataItem, previewData.length)}</div>}
        </Placeholder> : undefined }
        <Table
          tableKey={TABLES.triggerPreviewPopup.key}
          data={previewData}
          columns={getColumns({ triggerType }) || Style.defaultColumns}
          loading={loading}
          style={{ minHeight: 250, ...overrideTableStyle }}
          tableHeaderStyle={showTableTitle ? EMPTY_OBJECT : Style.PreviewTableHeader}
          emptyStateMessage={`No ${pluralizedDataItems} meet the criteria now.`}
          manual={usePaging}
          clientPaging={usePaging}
          pageSize={usePaging ? 50 : undefined}
          totalCount={previewData?.length}
          getTheadThProps={getTheadThProps}
          overrideTdStyle={overrideTdStyle}
          scrollObjectId='contentArea'
        />
      </>
    }
  </div>
}

TriggerPreviewTable.propTypes = {
  idWorkflow: PropTypes.number.isRequired,
  showTableTitle: PropTypes.bool,
  overrideTdStyle: PropTypes.object,
  overrideTableStyle: PropTypes.object,
  usePaging: PropTypes.bool,
  getTheadThProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

TriggerPreviewTable.defaultProps = {
  showTableTitle: true,
  usePaging: true,
  overrideTdStyle: {},
  overrideTableStyle: {},
  getTheadThProps: () => Style.PreviewTableTheadTh
}

export default TriggerPreviewTable
