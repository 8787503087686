import View from './view'
import { connect } from 'react-redux'
import {
  updateUsersLifecycleStatus,
  toggleOffboardingPreviewPopup,
  toggleOffboardingStartedPopup,
  getOffboardingAppsOfUsers,
  getServicesSyncData,
  getServicesConfig,
  getOffboardingStatusOfUser,
  getOffboardingTodoUsers,
  getOffboardingInProgressUsers,
  getOffboardingDoneUsers,
  getOffboardingApps,
  getDefaultOffboardingWorkflow,
  getOffboardingTodoUsersCount,
  getOffboardingDoneUsersCount,
  getOffboardingInProgressUsersCount
} from '@actions/'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import {
  getOffboardingAppsOfUsersIdUsers,
  getOffboardingConfiguredAppsOfUsers,
  getOffboardingNeedsAttentionAppsOfUsers,
  isOffboardingAppsOfUsersLoading,
  getDefaultOffboardingWorkflow as getDefaultOffboardingWorkflowSelector
} from '@selectors/offboarding'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { delegateOffboardingActions } from '@lenses/workflows'
import { OFFBOARDING_METHOD_TYPE, WORKFLOW_ACTION_TYPES } from '@root/constants'
import uniqWith from 'lodash/uniqWith'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const { users = [], isOpen } = state.ui.offboardingPreviewPopup

  const idUsers = users.map(user => user.id)

  const uniqApps = (app1, app2) => app1.id === app2.id && app1.idAppAccount === app2.idAppAccount
  const needsAttentionApps = uniqWith(getOffboardingNeedsAttentionAppsOfUsers(state), uniqApps)
  const configuredApps = uniqWith(getOffboardingConfiguredAppsOfUsers(state), uniqApps)
  const loadedIdUsers = getOffboardingAppsOfUsersIdUsers(state)
  const { workflow: defaultOffboardingWorkflow, loading: isDefaultOffboardingWorkflowIsLoading } = getDefaultOffboardingWorkflowSelector(state) || {}
  const loading = isOffboardingAppsOfUsersLoading(state) && isDefaultOffboardingWorkflowIsLoading && (!loadedIdUsers.length || !isEqual([...idUsers].sort(), [...loadedIdUsers].sort()))
  const grouped = configuredApps.reduce((grouped, app) => {
    const actionType = get(app, ['actions', 0, 'type'])
    if (actionType === WORKFLOW_ACTION_TYPES.IGNORE) {
      grouped[OFFBOARDING_METHOD_TYPE.IGNORE].push(app)
    } else if (delegateOffboardingActions.includes(actionType)) {
      grouped[OFFBOARDING_METHOD_TYPE.DELEGATE].push(app)
    } else {
      grouped[OFFBOARDING_METHOD_TYPE.AUTOMATIC].push(app)
    }

    return grouped
  }, { [OFFBOARDING_METHOD_TYPE.IGNORE]: [], [OFFBOARDING_METHOD_TYPE.DELEGATE]: [], [OFFBOARDING_METHOD_TYPE.AUTOMATIC]: [] })
  const { [OFFBOARDING_METHOD_TYPE.IGNORE]: ignoredApps = [], [OFFBOARDING_METHOD_TYPE.DELEGATE]: delegateApps = [], [OFFBOARDING_METHOD_TYPE.AUTOMATIC]: automaticApps = [] } = grouped

  return {
    isOpen,
    users,
    idUsers,
    idOrg,
    needsAttentionApps,
    ignoredApps,
    delegateApps,
    automaticApps,
    loading,
    isDefaultOffboardingWorkflowIsActive: defaultOffboardingWorkflow.isActive
  }
}

const mapDispatchToProps = {
  toggleOffboardingPreviewPopup,
  updateUsersLifecycleStatus,
  toggleOffboardingStartedPopup,
  getOffboardingAppsOfUsers,
  getServicesSyncData,
  getServicesConfig,
  getOffboardingStatusOfUser,
  getOffboardingTodoUsers,
  getOffboardingInProgressUsers,
  getOffboardingDoneUsers,
  getOffboardingApps,
  getDefaultOffboardingWorkflow,
  getOffboardingTodoUsersCount,
  getOffboardingDoneUsersCount,
  getOffboardingInProgressUsersCount
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleOffboardingPreviewPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleOffboardingPreviewPopup({ isOpen: false, isByUser: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
