import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getLifecycleApps, toggleUserLifecycleSettings } from '@actions/'
import keyBy from 'lodash/keyBy'
import AppDetails from '@components/appDetails/view'
import {
  getLifecycleApps as lifecycleApps,
  getLifecycleSettings,
  isLoadingUserLifecycleApps
} from '@selectors/org'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { Tooltip, Button, theme } from '@toriihq/design-system'
import sortBy from 'lodash/sortBy'

const CSS = {
  appDetailsRow: css(texts.subheading, {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: '14px 18px',
    borderBottom: `1px solid ${theme.palette.border.primary}`
  }),
  appDetails: css({
    flexGrow: 1
  }),
  info: css({
    flexGrow: 1,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: colors.grey2
  }),
  editButton: css({
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  })
}

const UserLifecycleApps = ({ idOrg }) => {
  const isLoadingApps = useSelector(isLoadingUserLifecycleApps)
  const userLifecycleConfig = useSelector(getLifecycleSettings)
  const apps = useSelector(lifecycleApps)
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [appsById, setAppsById] = useState([])

  useEffect(() => {
    if (idOrg) {
      dispatch(getLifecycleApps({ idOrg }))
    }
  }, [dispatch, idOrg])

  useEffect(() => {
    if (userLifecycleConfig) {
      const rows = sortBy(userLifecycleConfig, app => app.source)
      setData(rows)
    }
  }, [userLifecycleConfig])

  useEffect(() => {
    if (!isLoadingApps && apps) {
      setAppsById(keyBy(apps, 'idApp'))
    }
  }, [isLoadingApps, apps])

  const AppDetailsRow = ({ idApp, loading }) => {
    const app = appsById[idApp] || {}
    return (
      <Fragment>
        <div {...CSS.appDetails}>
          <AppDetails
            id={app.idApp}
            name={app.name}
            category={app.category}
            imageUrl={app.imageUrl}
            component='User Lifecycle'
            loading={loading}
            overrideStyle={{ width: '190px' }}
          />
        </div>
        {(!loading && !app.isConnected) && (
          <Tooltip
            label={<span>Connect account at the Integrations page</span>}
          >
            <span {...CSS.info}>The account is not connected</span>
          </Tooltip>
        )}
      </Fragment>
    )
  }

  const onEdit = () => {
    dispatch(toggleUserLifecycleSettings(true))
  }

  return (
    <div>
      {data.map((lifecycle, index) => (
        <div key={lifecycle.idApp} {...CSS.appDetailsRow}>
          <AppDetailsRow idApp={lifecycle.idApp} loading={isLoadingApps} />
        </div>
      ))}
      <div {...CSS.editButton}>
        <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
          <Button onClick={onEdit} label={'Manage sources'} />
        </EnableFor>
      </div>
    </div>
  )
}

UserLifecycleApps.propTypes = {
  idOrg: PropTypes.number.isRequired
}

export default UserLifecycleApps
