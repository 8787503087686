import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAppCatalogSettings, getIdOrg, getOrgNickName, isOrgLoading as isOrgLoadingSelector } from '@selectors/org'
import { Container, CatalogLink, Link, ToggleContainer, IconWrapper } from './style'
import EnableFor from '../enableFor'
import ToriiToggle from '@components/toriiToggle'
import { SCOPES } from '@root/constants'
import { updateAppCatalogSettings } from '@actions/'
import copy from 'copy-to-clipboard'
import Analytics from '@root/helpers/analytics'
import Placeholder from '../placeholder'
import { Tooltip, Icon } from '@toriihq/design-system'

const AppCatalogHeader = () => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const orgNickName = useSelector(getOrgNickName)
  const { isCatalogEnabledForMembers } = useSelector(getAppCatalogSettings) || {}
  const isOrgLoading = useSelector(isOrgLoadingSelector)

  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const copyToClipboard = (link) => {
    copy(link)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
    Analytics.track('Click on copy-link-button')
  }

  const reportClick = () => {
    Analytics.track('Click on app-catalog-link')
  }

  const onToggle = () => {
    dispatch(updateAppCatalogSettings({ idOrg, isCatalogEnabledForMembers: !isCatalogEnabledForMembers }))
    Analytics.track('Enable app-catalog', {
      State: Number(!isCatalogEnabledForMembers)
    })
  }

  const catalogUrl = `https://${process.env.REACT_APP_TORII_DOMAIN_CATALOG || 'catalog.toriihq.com'}/${orgNickName}`
  return (
    <Placeholder loading={isOrgLoading}>
      <Container>
        <CatalogLink>
          Catalog Link:
          <Link target={'_blank'} href={catalogUrl} rel={'noopener noreferrer'} onClick={reportClick}>{catalogUrl}</Link>
          <Tooltip label={isLinkCopied ? 'Link copied' : 'Copy link'}>
            <IconWrapper onClick={() => !isLinkCopied && copyToClipboard(catalogUrl)} showHandCursor={!isLinkCopied}>
              <Icon color={isLinkCopied ? 'success' : 'interactive'} name={isLinkCopied ? 'Check' : 'Copy'} />
            </IconWrapper>
          </Tooltip>
        </CatalogLink>
        <ToggleContainer>
          <div>Enable for all employees</div>
          <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
            <ToriiToggle
              checked={isCatalogEnabledForMembers}
              onToggle={onToggle}
              id={'enable-catalog-for-all-toggle'}
            />
          </EnableFor>
        </ToggleContainer>
      </Container>
    </Placeholder>
  )
}

export default AppCatalogHeader
