import SelectState from '@components/selectState'
import { SCOPES } from '@root/constants'
import { CSS } from '@pages/workflow_v2/formField/mapping/styles'
import ToriiSelect from '@components/select'
import React from 'react'
import { DropdownOption } from '@pages/workflow_v2/formField/mapping/components/dropdowns/types'
import { TextBadge } from '@toriihq/design-system'

export interface Props {
  field: {
    options?: DropdownOption[]
    clearable?: boolean
    loading?: boolean
    systemKey?: string
    placeholder?: string
  }
  input: {
    value?: DropdownOption
    onBlur?: (value: any) => void
    onChange: (value: any) => void
    disabled?: boolean
  }
  overrideStyle?: Partial<CSSStyleDeclaration>
  useSelectState?: boolean
  innerSelectKey?: string
}

export const Dropdown = ({
  field,
  input,
  overrideStyle,
  useSelectState,
  innerSelectKey
}: Props) => {
  if (field.systemKey === 'state' && useSelectState) {
    return <SelectState
      overrideStyle={overrideStyle}
      options={field.options}
      value={input.value}
      allowHideApp={false}
      useDefaultSelectCSS
      onBlur={() => input.onBlur?.(input.value)}
      onChange={(selectedOption) => selectedOption ? input.onChange(selectedOption) : null}
      clearable={false}
      disabled={input.disabled}
      allowedScopes={[SCOPES.AUTOMATION_WRITE]}
    />
  }

  const renderOption = (props) => {
    const option = props.data
    const shouldShowInvalidOptionMessage = option?.config?.isValid === false
    const { badgeText } = option
    return <div key={option.value} {...CSS.valueContainer}>
      <div>{option.label}</div>
      {badgeText && <TextBadge size='Small' color='blue'>{badgeText}</TextBadge>}
      {shouldShowInvalidOptionMessage && <div {...CSS.invalidValueMessage}> {option.config.message} </div>}
    </div>
  }

  const isLoading = !input.disabled && field.loading && (!field.options || !field.options.length)

  return <ToriiSelect
    options={field.options}
    value={input.value}
    onChange={(selectedOption) => selectedOption || field.clearable ? input.onChange(selectedOption) : null}
    clearable={Boolean(field.clearable)}
    disabled={input.disabled}
    placeholder={field.placeholder}
    isLoading={isLoading}
    valueRenderer={renderOption}
    optionRenderer={renderOption}
    innerSelectKey={innerSelectKey}
  />
}
