import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  deleteWorkflow,
  toggleManuallyRunWorkflowPopup,
  toggleAddAppCatalogPolicyPopup,
  getSupportedFeatures
} from '@actions/'
import {
  getWorkflows,
  updateWorkflow
} from '@actions/workflows'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const { workflows } = ownProps
  const title = workflows.length > 0 ? `Workflows (${workflows.length})` : 'Workflows'

  return {
    idOrg,
    title
  }
}

const mapDispatchToProps = {
  updateWorkflow,
  deleteWorkflow,
  getWorkflows,
  toggleManuallyRunWorkflowPopup,
  toggleAddAppCatalogPolicyPopup,
  getSupportedFeatures
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
