import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import PieChart from '../../pieChartBox/pieChart'
import HoverableHeader from '../../hoverableHeader'
import CustomTooltip from './customTooltip'
import pluralize from 'pluralize'
import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import RiskIcon, { permissionsLegend } from '../../riskIcon'
import { theme } from '@toriihq/design-system'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const CSS = {
  main: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  header: css({
    padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
    borderBottom: `1px solid ${theme.palette.border.primary}`
  }),
  chartsContainer: css({
    height: `calc(100% - ${HEADER_HEIGHT})`
  })
}

const className = 'riskLevelChart'

class RiskLevel extends React.Component {
  getDataForChart = () => {
    const { idApp, sourcesWithApps, riskLevel } = this.props
    if (!idApp) {
      return riskLevel
    }
    const connectedAppsPermissions = flatten(
      sourcesWithApps
        .filter(source => !idApp || (source.idApp === idApp))
        .map(source => source.apps)
    )

    const basicChartInfo = sortBy(map(permissionsLegend, (permission, index) => { return { name: permission.text, color: permission.innerColor, riskLevel: parseInt(index) } }), permission => -permission.riskLevel)
    return basicChartInfo
      .map(permissionOption => ({
        ...permissionOption,
        value: connectedAppsPermissions.filter(app => app.riskLevel === permissionOption.riskLevel).length,
        icon: <RiskIcon riskLevel={permissionOption.riskLevel} />
      }))
  }

  render () {
    const { loading, hasData } = this.props
    const data = loading ? [] : this.getDataForChart()

    return (
      <div className={className} {...CSS.main}>
        <div {...CSS.header}>
          <HoverableHeader hoverClass={className} header='Risk analysis' outerHoverStyle={'#appsCategory:hover &'} subHeader='Analyze 3rd party applications permissions' />
        </div>
        <div {...CSS.chartsContainer}>
          <PieChart data={data} loading={loading} hasData={hasData} customToolTip={<CustomTooltip valueDisplayFunction={(value) => pluralize('App', value, true)} />} />
        </div>
      </div>
    )
  }
}

RiskLevel.propTypes = {
  hasData: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  appsCategoryList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }))
}
export default RiskLevel
