import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getIsSmallScreen, getNavigationForceCollapse } from '@selectors/ui'
import { getSelf, getIsOnlyAppOwner } from '@selectors/me'
import { getCurrentOrg, isTrial as isTrialSelector } from '@selectors/org'
import NavigationWithOverlay from '@components/navigation/NavigationWithOverlay'
import NavigationWithoutOverlay from '@components/navigation/NavigationWithoutOverlay'

const Navigation = React.forwardRef((props, ref) => {
  const { style, headerSpace = 0 } = props

  const currentOrg = useSelector(getCurrentOrg)
  const me = useSelector(getSelf)
  const isOnlyAppOwner = useSelector(getIsOnlyAppOwner)
  const forceNavigationCollapse = useSelector(getNavigationForceCollapse)

  const isTrial = useSelector(isTrialSelector)
  const isSmallScreen = useSelector(getIsSmallScreen)

  const [email, setEmail] = useState()
  const [isWhiteLabel, setIsWhiteLabel] = useState()
  const [companyName, setCompanyName] = useState()
  const [logoUrl, setLogoUrl] = useState()

  useEffect(() => {
    setEmail(me.email)
  }, [me])

  useEffect(() => {
    if (currentOrg) {
      setIsWhiteLabel(Boolean(currentOrg.isWhiteLabel))
      setCompanyName(currentOrg.companyName)
      setLogoUrl(currentOrg.logoUrl)
    }
  }, [currentOrg])

  return isSmallScreen
    ? <NavigationWithOverlay isOnlyAppOwner={isOnlyAppOwner} ref={ref} companyName={companyName} whiteLabelLogoUrl={logoUrl} isWhiteLabel={isWhiteLabel} email={email} isTrial={isTrial} headerSpace={headerSpace} />
    : <NavigationWithoutOverlay forceNavigationCollapse={forceNavigationCollapse} isOnlyAppOwner={isOnlyAppOwner} ref={ref} companyName={companyName} whiteLabelLogoUrl={logoUrl} isWhiteLabel={isWhiteLabel} email={email} isTrial={isTrial} style={style} />
})

Navigation.propTypes = {
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerSpace: PropTypes.number
}

export default Navigation
