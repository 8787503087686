import React, { useState } from 'react'
import { APP_OWNERS_FIELDS, SCOPES } from '@root/constants'
import { getOwnersByIdApp, isAppDetailsValuesLoading as isAppDetailsValuesLoadingSelector } from '@selectors/apps'
import { getSelf } from '@selectors/me'
import { useParamSelector } from '@shared/utils'
import { Tooltip, Icon, Spacer, AddButton, Button, ButtonType } from '@toriihq/design-system'
import { useSelector, useDispatch } from 'react-redux'
import * as Style from './style'
import AppOwnerRow from './ownerRow'
import { assignOwners, getAppDetailsValues } from '@actions/'
import EnableFor from '@components/enableFor'
import AssignAppOwners from '@components/popups/assignAppOwners'
import { get, isEmpty } from 'lodash'

const Owners = ({ idApp, idOrg }) => {
  const [isAssignAppOwnersPopupOpen, setIsAssignAppOwnersPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isAppDetailsValuesLoading = useSelector(isAppDetailsValuesLoadingSelector)
  const currentUser = useSelector(getSelf)
  const appOwners = useParamSelector(getOwnersByIdApp, { idApp })
  const dispatch = useDispatch()

  const assignOwner = async ({ fieldsValues }) => {
    setIsLoading(true)
    await dispatch(assignOwners({ fieldsValues, idApp, idOrg, user: currentUser }))
    await dispatch(getAppDetailsValues({ idOrg, idApps: [idApp] }))
    setIsLoading(false)
  }

  const areThereOwners = () => {
    const allAppOwners = get(appOwners, [APP_OWNERS_FIELDS.appOwners, 'values'], []).filter(owner => !isEmpty(owner))

    return !isEmpty(allAppOwners)
  }

  const onAssignPrimaryOwner = (user) => {
    if (!get(user, ['id'])) {
      return
    }

    const oldPrimaryOwnerId = get(appOwners, [APP_OWNERS_FIELDS.primary, 'values', 0, 'id'])
    const oldOwners = get(appOwners, [APP_OWNERS_FIELDS.appOwners], { values: [] })
    const oldOwnersIds = (oldOwners?.values || []).reduce((ownersIds, owner) => {
      if (owner && Number(owner.id) !== Number(user.id)) {
        ownersIds.push(owner.id)
      }

      return ownersIds
    }, [])
    const newOwnersArr = [...new Set([...oldOwnersIds, oldPrimaryOwnerId, user.id])].filter(Boolean)

    const fieldsValues = [
      {
        idField: appOwners[APP_OWNERS_FIELDS.primary].idField,
        values: [user.id]
      },
      {
        idField: appOwners[APP_OWNERS_FIELDS.appOwners].idField,
        values: newOwnersArr
      }
    ]
    return assignOwner({ fieldsValues })
  }

  const onRemoveOwner = (user, field) => {
    if (field.systemKey === APP_OWNERS_FIELDS.primary) {
      return assignOwner({
        fieldsValues: [
          { idField: appOwners[APP_OWNERS_FIELDS.primary].idField, values: [null] }
        ]
      })
    }

    const allOwners = get(appOwners, [APP_OWNERS_FIELDS.appOwners, 'values'], []).reduce((ownersIds, owner) => {
      if (owner.id !== user.id) {
        ownersIds.push(owner.id)
      }

      return ownersIds
    }, [])

    return assignOwner({
      fieldsValues: [
        { idField: field.idField, values: allOwners.length ? allOwners : [null] }
      ]
    })
  }

  const getTableContent = () => {
    if (!areThereOwners()) {
      return (
        <div {...Style.Body}>
          <Spacer right={'space-150'}>
            <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}>
              <AddButton onClick={() => setIsAssignAppOwnersPopupOpen(true)} />
            </EnableFor>
          </Spacer>
          <div>Assign owners</div>
        </div>
      )
    }

    const idPrimaryOwner = get(appOwners, [APP_OWNERS_FIELDS.primary, 'values', 0, 'id'])

    return Object.values(appOwners).map(field => {
      const isPrimaryField = field.systemKey === APP_OWNERS_FIELDS.primary
      return field.values.map(owner => {
        const isValidOwner = !isEmpty(owner)
        const isPrimaryUserFromAppOwners = isValidOwner && idPrimaryOwner && owner.id === idPrimaryOwner

        if (isValidOwner && (isPrimaryField || !isPrimaryUserFromAppOwners)) {
          return (
            <AppOwnerRow
              key={owner.id}
              owner={owner}
              isPrimary={isPrimaryField}
              onAssignPrimaryOwner={onAssignPrimaryOwner}
              onRemoveOwner={(owner) => onRemoveOwner(owner, field)}
              isLoading={isLoading}
            />
          )
        }
        return null
      }
      )
    })
  }

  return (
    <div {...Style.Main}>
      <div {...Style.HeaderWrapper}>
        <div {...Style.HeaderLabel}>
          <header {...Style.Header}>APP OWNERS</header>
          <Tooltip
            placement='top'
            label='The owner is the go-to person in your organization for issues related to this application'>
            <Icon name='Info' />
          </Tooltip>
        </div>
        <div {...Style.HeaderAction}>
          {(areThereOwners()) ? (
            <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}>
              <Button disabled={isAppDetailsValuesLoading || isLoading} onClick={() => setIsAssignAppOwnersPopupOpen(true)} type={ButtonType.secondary} label='Manage owners' />
            </EnableFor>
          ) : null}
        </div>
      </div>
      {getTableContent()}
      {isAssignAppOwnersPopupOpen &&
      <AssignAppOwners
        idApp={idApp}
        isOpen
        onCancel={() => setIsAssignAppOwnersPopupOpen(false)}
        onSubmitSuccess={() => setIsAssignAppOwnersPopupOpen(false)} />}
    </div>
  )
}

export default Owners
