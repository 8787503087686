import React, { ReactElement } from 'react'
import { IdWorkflowUiNode } from '@shared/workflows/actions/types'
import { useActionConfiguration } from '@pages/workflow_v2/hooks/actionConfiguration/useActionConfiguration'
import { IfThenLabelContainer } from './styles'
import capitalize from 'lodash/capitalize'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'

interface Props {
  idParentNode: IdWorkflowUiNode
}

export const ActionIfThenLabelRenderer = ({ idParentNode }: Props): ReactElement => {
  const isParentNodeAction = idParentNode !== ID_TRIGGER_NODE ? idParentNode : undefined
  const actionConfiguration = useActionConfiguration({ idAction: isParentNodeAction })

  const { thenText } = actionConfiguration || {}

  return (<>{thenText &&
    <IfThenLabelContainer>
      {capitalize(thenText)}
    </IfThenLabelContainer>
  }</>)
}
