import styled from 'styled-components'

export const Wrapper = styled.div`
   min-height: 500px;
`

export const Section = styled.div`
  width: 50%;
`

export const Header = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.palette.text.primary}
`

const inputWidth = '200px'
export const placeholderStyle = { height: '32px', width: inputWidth }
