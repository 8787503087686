import React, { JSX } from 'react'
import EnableFor from '../../../components/enableFor'
import Placeholder from '../../../components/placeholder'
import { Props } from './types'
import { Styles } from './styles'
import { Body2, Subtitle1 } from '@toriihq/design-system'

function UsersAndEmployeesPageSection ({
  children,
  title,
  subheader,
  loading,
  scopes,
  learnMoreLink,
  learnMoreText = 'Learn more',
  onLearnMoreClick
}: Props): JSX.Element {
  return (
    <Styles.Section>
      <Styles.SectionHeader>
        <Styles.SectionTitle><Subtitle1 color='primary'>{title}</Subtitle1></Styles.SectionTitle>
        {learnMoreLink && <Styles.Link href={learnMoreLink} target='_blank' rel='noopener noreferrer' onClick={onLearnMoreClick}>
          {learnMoreText}
        </Styles.Link>}
      </Styles.SectionHeader>
      <Styles.SectionSubheader><Body2 color='secondary'>{subheader}</Body2></Styles.SectionSubheader>
      <Placeholder loading={loading} type='rect'>
        <EnableFor scopes={scopes}>
          {children}
        </EnableFor>
      </Placeholder>
    </Styles.Section>
  )
}

export default UsersAndEmployeesPageSection
