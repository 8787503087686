import { css } from 'glamor'
import { fontSize, fontWeight } from '@shared/style/sizes'
import colors from '@shared/style/colors'
import { theme } from '@toriihq/design-system'

export const AddRuleButton = css({
  marginTop: '20px',
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal,
  color: colors.darkText,
  verticalAlign: 'middle'
})

export const AddIcon = css({
  fontSize: fontSize.extraSmall,
  fontWeight: fontWeight.light,
  marginRight: '5px',
  transform: 'translateY(-0.1px)'
})

export const TopTitleContainer = css({
  position: 'sticky',
  top: '0px',
  backgroundColor: 'white',
  zIndex: 4
})

export const MatchingRulesTableContainer = css({
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  display: 'inline-block'
})

export const MatchingRulesTableContent = css({
  minWidth: '1050px',
  overflowY: 'hidden',
  paddingBottom: '2px'
})

export const TopTitle = ({ backgroundColor = colors.white, borderColor = theme.palette.border.primary }) => css({
  marginTop: '32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${borderColor}`,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  padding: 16,
  backgroundColor: `${backgroundColor}`,
  zIndex: 5,
  fontSize: fontSize.small
})

export const TitleCotainer = css({
  display: 'flex',
  alignItems: 'center'
})

export const Title = css({
  marginTop: '2px'
})

export const ButtonsContainer = css({
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: '16px'
})

export const HeaderColors = ({ isMatchingRulesChanged }) => ({
  backgroundColor: isMatchingRulesChanged ? colors.lightBlue5 : colors.white,
  borderColor: isMatchingRulesChanged ? theme.palette.border.active : theme.palette.border.primary
})

export const ErrorHeaderColors = {
  backgroundColor: 'rgba(245,48,89,0.1)',
  borderColor: theme.palette.border.error
}
