import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sortTable, exportThirdPartyCSV, getAppsPermissions } from '@actions/'
import {
  getAppStateOrder,
  getSourcesWithApps,
  isAppPermissionsLoaded,
  isAppPermissionsLoading
} from '@selectors/apps'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)

  const isLoaded = isAppPermissionsLoaded(state)
  const loading = isAppPermissionsLoading(state) && !isLoaded
  const sourcesWithApps = getSourcesWithApps(state)

  const stateOrder = getAppStateOrder(state)

  return {
    sourcesWithApps,
    isLoaded,
    loading,
    idOrg,
    stateOrder
  }
}

const mapDispatchToProps = {
  getAppsPermissions,
  sortTable,
  onExportCSV: exportThirdPartyCSV
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
