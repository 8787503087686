import View from './view'
import { connect } from 'react-redux'
import { toggleUploadAppDetailsAttachments, uploadAppAttachment } from '@actions/'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  return {
    idOrg
  }
}

const mapDispatchToProps = {
  uploadAppAttachment,
  toggleUploadAppDetailsAttachments
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
