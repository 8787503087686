import View from './view'
import { connect } from 'react-redux'
import { setAppsDetails } from '@actions/'

const mapDispatchToProps = {
  setAppsDetails
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  async onSelectUser ({ idApp, idOrg, id, user }) {
    await dispatchProps.setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField: ownProps.idField, values: [id] }], user })
  }
})

export default connect(null, mapDispatchToProps, mergeProps)(View)
