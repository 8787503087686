export const SUPPORT_ARTICLES = {
  APP_OWNER_ACCESS_FOR_TORII_ADMINS: 'https://support.toriihq.com/hc/en-us/articles/10234789921051',
  APP_OWNER_ACCESS_FOR_TORII_ADMINS_EMAIL_PREVIEW: 'https://support.toriihq.com/hc/en-us/articles/10234789921051#heading-3',
  HELP_CENTER: 'https://support.toriihq.com/hc/en-us',
  APP_OWNER_INTRODUCTION: 'https://support.toriihq.com/hc/en-us/categories/11291385256859-Application-Owners',
  USER_LIFECYCLE: 'https://support.toriihq.com/hc/en-us/articles/5129476000411-Current-Users-Past-Users-and-External-Users-Definition',
  EMPLOYEES: 'https://support.toriihq.com/hc/en-us/articles/16492202512539',
  MERGE_USERS: 'https://support.toriihq.com/hc/en-us/articles/23665174442651',
  UPLOADING_CONTRACTS_VIA_EMAIL: 'https://support.toriihq.com/hc/en-us/articles/21597568139291',
  RENEWAL_CALENDAR_SHARE: 'https://support.toriihq.com/hc/en-us/articles/21588563337243',
  BILLING: 'https://support.toriihq.com/hc/en-us/articles/22291596956955-Subscription-Management',
  WORKFLOWS_5_TO_START_FROM: 'https://support.toriihq.com/hc/en-us/sections/24406201917467-5-workflows-to-start-from',
  CUSTOM_INTEGRATION: 'https://support.toriihq.com/hc/en-us/articles/5164699821979-How-to-Connect-Custom-Integrations',
  CUSTOM_INTEGRATION_DEV_COMMUNITY: 'https://developers.toriihq.com/docs/introduction-to-custom-applications'
}
