import { UserType, UserTypeSource } from '@root/components/userTypeSources/types'
import { App } from '@root/components/selectAppGroupDivided/types'
import { ActionForUserTypePreview } from '@actions/org.types'

export enum WIZARD_STEPS {
  EDIT_USER_TYPE_SOURCES = 'editUserTypeSources',
  PREVIEW_CHANGES = 'previewChanges',
  CHANGES_APPLIED = 'changesApplied'
}

export type ChangesPreview = {
  currentUsersCount: number
  currentPastUsersCount: number
  sourceMatchedUsersFoundCount: number
  sourceMatchedPastUsersFoundCount: number
  totalMatchedUsersFoundCount: number
  totalMatchedPastUsersFoundCount: number
  triggeredWorkflowsCount: number
  workflowAffectedUsersCount: number
  totalCurrentChanges: number
  totalPastChanges: number
}

export type ChangesPreviewResponse = {
  isSuccess: boolean
  results?: ChangesPreview
  errorType?: string
  errorMessage?: string
}

export type SharedState = {
  changesPreview?: ChangesPreviewResponse
  userTypeSource?: UserTypeSource
  action?: ActionForUserTypePreview
  isDeleteSource?: boolean
  selectedApp?: App
}

export type SharedProps = {
  userType: UserType
  usersLabel: string
  formHeader: string
  formSubHeader: string
  sourceToEdit: UserTypeSource | null
}
