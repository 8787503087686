import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getOrgSecretCreationError, getOrgSecretsInfo, isLoadedOrgSecrets, isLoadingOrgSecrets } from '@selectors/orgSecrets'
import Table from '@components/table'
import { DATE_FORMAT, SCOPES, TABLES } from '@root/constants'
import * as Style from '@components/orgSecrets/style'
import ButtonActions from '@components/_shared/buttonActions'
import { getIsSmallScreen } from '@selectors/ui'
import Analytics from '@helpers/analytics'
import { getSecrets, deleteSecret, createSecret, updateSecret } from '@actions/'
import CreateOrEditSecretPopup from '@components/orgSecrets/createOrEditSecretPopup'
import EmptyStateImage from './images/emptyState.svg'
import moment from 'moment'
import LinkableAvatar from '@components/_shared/linkableAvatar'
import Confirmation from '@components/confirmation'
import { ButtonType, H4, Spacer, Body2, EmptyState } from '@toriihq/design-system'

type Secret = {
  id: number | undefined,
  name: string,
  maskedValue: string
}

const emptySecret: Secret = { id: undefined, name: '', maskedValue: '' }

const OrgSecrets = (): JSX.Element => {
  const dispatch = useDispatch()

  const [isOpenCreateSecretPopup, setIsOpenCreateSecretPopup] = useState(false)
  const [isOpenEditSecretPopup, setIsOpenEditSecretPopup] = useState(false)
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false)
  const [secretToDelete, setSecretToDelete] = useState(emptySecret)
  const [secretToEdit, setSecretToEdit] = useState(emptySecret)

  const { id: idOrg } = useSelector(getCurrentOrg)
  const { secrets, usersMap } = useSelector(getOrgSecretsInfo)
  const isLoading = useSelector(isLoadingOrgSecrets)
  const isLoaded = useSelector(isLoadedOrgSecrets)
  const isSmallScreen = useSelector(getIsSmallScreen)
  const nameError = useSelector(getOrgSecretCreationError)
  const showLoading = (!isLoaded || isLoading) && (secrets.length === 0)

  useEffect(() => {
    if (idOrg) {
      dispatch(getSecrets({ idOrg }))
    }
  }, [dispatch, idOrg])

  const toggleDeleteConfirmation = ({ secret = emptySecret, isClosedByUser = false } = {}): void => {
    const isOpenNewState = !isOpenDeleteConfirmation

    if (isOpenNewState) {
      Analytics.track(`Clicked to open popup: delete secret`, {
        'Secret name': secret.name,
        'Secret ID': secret.id
      })
    } else if (isClosedByUser) {
      Analytics.track(`Closed popup: delete secret`, {
        'Secret name': secretToDelete.name,
        'Secret ID': secretToDelete.id
      })
    }

    setIsOpenDeleteConfirmation(isOpenNewState)
    setSecretToDelete(secret)
  }

  const onDeleteSecret = async () => {
    await dispatch(deleteSecret({ idOrg, idOrgSecret: secretToDelete.id }))

    Analytics.track(`Delete secret`, {
      'Secret name': secretToDelete.name,
      'Secret ID': secretToDelete.id
    })

    toggleDeleteConfirmation()
  }

  const deleteSecretConfirmation = <Confirmation
    isOpen={isOpenDeleteConfirmation}
    header={`Delete '${secretToDelete.name}' secret?`}
    confirmText='Delete'
    declineText='Cancel'
    decline={() => toggleDeleteConfirmation({ isClosedByUser: true })}
    confirm={onDeleteSecret}
    close={() => toggleDeleteConfirmation({ isClosedByUser: true })}
    mainButtonType={ButtonType.destructive}
    text={`This secret might be used in workflow actions. Deleting the secret may cause those actions to fail.`}
  />

  const toggleCreatePopup = ({ isClosedByUser = false } = {}): void => {
    const isOpenNewState = !isOpenCreateSecretPopup

    if (isOpenNewState) {
      Analytics.track('Clicked to open popup: create secret')
    } else if (isClosedByUser) {
      Analytics.track('Closed popup: create secret')
    }

    setIsOpenCreateSecretPopup(isOpenNewState)
  }

  const onCreateSecret = async ({ name, value }) => {
    await dispatch(createSecret({ idOrg, name, value }))

    Analytics.track(`Create secret`, {
      'Secret name': secretToDelete.name
    })

    toggleCreatePopup()
  }

  const createSecretPopup = <CreateOrEditSecretPopup
    isOpen={isOpenCreateSecretPopup}
    onClose={() => toggleCreatePopup({ isClosedByUser: true })}
    onSubmit={({ name, value }) => onCreateSecret({ name, value })}
    isEditMode={false}
    nameError={nameError}
  />

  const toggleEditPopup = ({ secret = emptySecret, isClosedByUser = false } = {}): void => {
    const isOpenNewState = !isOpenEditSecretPopup

    if (isOpenNewState) {
      Analytics.track('Clicked to open popup: edit secret')
    } else if (isClosedByUser) {
      Analytics.track('Closed popup: edit secret')
    }

    setIsOpenEditSecretPopup(isOpenNewState)
    setSecretToEdit(secret)
  }

  const onEditSecret = async ({ value }) => {
    await dispatch(updateSecret({ idOrg, idOrgSecret: secretToEdit.id, value }))

    Analytics.track(`Edit secret`, {
      'Secret name': secretToEdit.name,
      'Secret ID': secretToDelete.id
    })

    toggleEditPopup()
  }

  const editSecretPopup = <CreateOrEditSecretPopup
    isOpen={isOpenEditSecretPopup}
    onClose={() => toggleEditPopup({ isClosedByUser: true })}
    onSubmit={({ value }) => onEditSecret({ value })}
    isEditMode
    name={secretToEdit.name}
    maskedValue={secretToEdit.maskedValue}
  />

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100
    },
    {
      Header: 'Value',
      accessor: 'maskedValue',
      minWidth: 100
    },
    {
      Header: 'Created by',
      accessor: 'createdBy',
      Cell: ({ value: idUser }) => {
        const user = usersMap[idUser] || {}
        return <LinkableAvatar user={user} />
      },
      maxWidth: 130
    },
    {
      Header: 'Created at',
      accessor: 'creationTime',
      Cell: ({ value: date }) => moment.utc(date).format(DATE_FORMAT),
      maxWidth: 250
    },
    {
      Header: 'Last updated by',
      accessor: 'lastUpdatedBy',
      Cell: ({ value: idUser }) => {
        const user = usersMap[idUser] || {}
        return <LinkableAvatar user={user} />
      },
      maxWidth: 145
    },
    {
      Header: 'Last updated at',
      accessor: 'lastUpdateTime',
      Cell: ({ value: date }) => moment.utc(date).format(DATE_FORMAT),
      maxWidth: 250
    },
    {
      accessor: 'action',
      Header: '',
      Cell: ({ row: { id, name, maskedValue } }) => <Style.ActionsContainer isSmallScreen={isSmallScreen}>
        <ButtonActions
          showDeleteAction
          showEditAction
          onDeleteClick={() => toggleDeleteConfirmation({ secret: { id, name, maskedValue } })}
          onEditClick={() => toggleEditPopup({ secret: { id, name, maskedValue } })}
          enableForScopes={[SCOPES.AUTOMATION_WRITE]}
        />
      </Style.ActionsContainer>,
      width: 150
    },
    {
      accessor: 'id',
      show: false
    }
  ]

  const renderEmptyState = () => {
    return <EmptyState
      image={<img src={EmptyStateImage} alt='No secrets found' />}
      title='No secrets found'
    />
  }

  return <Style.Wrapper>
    <div>
      <Spacer bottom={'space-100'}><H4>Secrets Vault</H4></Spacer>
    </div>
    <Spacer bottom={'space-300'}>
      <Body2>Secrets let you add sensitive data (e.g. API keys) and use them in your workflows as personalization tokens. The value of the secrets cannot be seen once they are set.</Body2>
    </Spacer>
    {isOpenDeleteConfirmation && deleteSecretConfirmation}
    {isOpenCreateSecretPopup && createSecretPopup}
    {isOpenEditSecretPopup && editSecretPopup}
    <Table
      tableKey={TABLES.secretsTable.key}
      sortable={false}
      data={secrets}
      loading={showLoading}
      columns={columns}
      customButton={{
        onClick: toggleCreatePopup,
        text: 'Add secret to vault',
        scopes: [SCOPES.AUTOMATION_WRITE]
      }}
      emptyStateMessage={renderEmptyState()}
    />
  </Style.Wrapper>
}

export default OrgSecrets
