import {
  RESET
} from '@root/constants'
import ParseValidation from '@helpers/parse-validation'

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {}
}

const resetReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${RESET}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${RESET}_FAILED`:
    case `${RESET}_RESPONSE`: {
      const validation = ParseValidation(action.payload)

      return {
        ...state,
        loading: false,
        success: !action.error,
        error: validation.error,
        hasError: validation.hasError,
        help: validation.help
      }
    }
    default: {
      return state
    }
  }
}

export default resetReducer
