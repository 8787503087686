import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  deprecatedGetApps,
  getSimilarApps,
  getAppDetailsValues,
  getUsageTrends,
  getActiveUsersTrends,
  getSingleApp,
  getStateDetails,
  getAppV2,
  getServicesSyncData,
  getServicesConfig,
  getAppCatalogPolicies,
  getAppContracts,
  getContractsFields,
  getContractsGroups,
  getRecommendations as getAppRecommendations,
  getConnectedAppUsersStatusesBreakdown,
  getLast12MonthsTransactionsAggregations,
  getLastYearTotalExpenses,
  toggleConnectSource
} from '@actions/'
import { getMyOrg } from '@selectors/me'
import { getIsSmallScreen } from '@selectors/ui'
import { getRecommendations } from '@selectors/recommendations'
import isEmpty from 'lodash/isEmpty'
import { getCurrentApp } from '@selectors/apps'
import { getSupportedFeatures, getIsAppOwnerActive } from '@selectors/org'
import AccessControl from '@lenses/accessControl'
import { getImportAppUsersStatus } from '@selectors/appUsers'
import { getAppConnectedIntegration } from '@selectors/services'
import keyBy from 'lodash/keyBy'
import { DISCOVERED_APPS_SOURCES, SOURCE_TYPES } from '@root/sourcesConfig'
import find from 'lodash/find'
import { EMPTY_OBJECT } from '@root/constants'
import { hasTestConnectionInUrl } from '@components/testConnection/utils'
import { getTestConnectionInfo } from '@actions/testConnection'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const isDemoMode = getMyOrg(state) !== idOrg
  const { app = EMPTY_OBJECT, loading: isLoadingApp } = getCurrentApp(state) || EMPTY_OBJECT
  const supportedFeatures = getSupportedFeatures(state)
  let { recommendations } = getRecommendations(state)
  const isCostSavingFeatureSupported = Boolean(supportedFeatures['costSaving'])
  const isTabEnabled = (scopes, mustHaveAllScopes) => AccessControl.isAccessibleWithState({ scopes, state, mustHaveAllScopes })
  const sourceTypeArray = Object.values(SOURCE_TYPES)
  const sourceTypeByIdApp = keyBy(sourceTypeArray, 'idApp')
  const sourceAppName = sourceTypeByIdApp[idApp] && sourceTypeByIdApp[idApp].id
  const discoveredSourceAppName = sourceAppName && find(DISCOVERED_APPS_SOURCES, (value) => value === sourceAppName)
  const { name = '', doesAppSupportRecommendations = false } = app || {}
  const isAppOwnerActive = getIsAppOwnerActive(state)
  const importAppUsersStatus = getImportAppUsersStatus(state)
  const isConnected = Boolean(getAppConnectedIntegration(state, { idApp }))
  const afterRedirect = hasTestConnectionInUrl()

  recommendations = recommendations.filter(r => !(r.idApp === 214 && r.type === 'licenseDowngrade'))

  return {
    idOrg,
    idApp,
    name,
    isInUse: !isLoadingApp && !isEmpty(app),
    loading: isLoadingApp,
    isSmallScreen: getIsSmallScreen(state),
    isDemoMode,
    supportedFeatures,
    isCostSavingFeatureSupported,
    doesAppSupportRecommendations,
    discoveredSourceAppName,
    totalRecommendations: recommendations.filter(recommendation => recommendation.idApp === idApp).length,
    isTabEnabled,
    isAppOwnerActive,
    importAppUsersStatus,
    isConnected,
    afterRedirect
  }
}

const mapDispatchToProps = {
  getSingleApp,
  deprecatedGetApps,
  getSimilarApps,
  getAppDetailsValues,
  getUsageTrends,
  getActiveUsersTrends,
  getStateDetails,
  getAppV2,
  getServicesSyncData,
  getServicesConfig,
  getAppCatalogPolicies,
  getAppContracts,
  getContractsFields,
  getContractsGroups,
  getAppRecommendations,
  getConnectedAppUsersStatusesBreakdown,
  getLast12MonthsTransactionsAggregations,
  getLastYearTotalExpenses,
  getTestConnectionInfo,
  toggleConnectSource
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
