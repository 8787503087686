import React, { JSX } from 'react'
import { Props } from './types'
import WizardPopup from '../wizardPopup'
import { SharedProps, SharedState, WIZARD_STEPS } from './stepsConfig/types'

export default function EditUserTypeSourcesPopup ({
  initialSharedState,
  sharedProps,
  isOpen = true,
  onCancel,
  onSubmitSuccess,
  wizardSteps
}: Props): JSX.Element {
  return (
    <WizardPopup<SharedState, SharedProps, WIZARD_STEPS>
      name={'editUserTypeSources'}
      isOpen={isOpen}
      width={'750px'}
      stepsConfig={wizardSteps}
      initialSharedState={initialSharedState}
      sharedProps={sharedProps}
      onCancel={() => onCancel()}
      onClose={() => onCancel()}
      onSubmitSuccess={() => onSubmitSuccess()}
    />
  )
}
