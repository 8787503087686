import React, { useState } from 'react'
import { Styles } from './style'
import { Button, ButtonType } from '@toriihq/design-system'
import UpgradePlanPopup from '@components/popups/upgradePlanPopup'
import Confirmation from '@components/confirmation'
import successImage from '@components/popups/workflowStartedPopup/images/success.svg'
import { askToUpgrade } from '@actions/org'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Analytics from '@helpers/analytics'

interface Props {
  buttonLabel: string,
  feature: string,
  buttonType: typeof ButtonType[keyof typeof ButtonType],
  match: { params: { idOrg: string }}
}

const UpgradePlanButton = ({
  buttonLabel = 'Upgrade plan',
  buttonType = ButtonType.primary,
  feature,
  match
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const idOrg = parseInt(match.params.idOrg, 10)

  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false)
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false)

  const onCancel = () => {
    setIsUpgradePlanPopupOpen(false)

    Analytics.track('Close plan-upgrade-dialog', {
      feature
    })
  }

  const onConfirmUpgrade = async () => {
    await dispatch(askToUpgrade({ idOrg, feature }))
    setIsConfirmationPopupOpen(true)
    setIsUpgradePlanPopupOpen(false)

    Analytics.track('Click contact-for-plan-upgrade', {
      feature
    })
  }

  const onClickUpgradeButton = () => {
    setIsUpgradePlanPopupOpen(true)

    Analytics.track('Open plan-upgrade-dialog', {
      feature
    })
  }

  return (
    <div>
      <Button type={buttonType} onClick={onClickUpgradeButton} label={buttonLabel} />
      { isUpgradePlanPopupOpen && <UpgradePlanPopup onCancel={onCancel} onConfirm={onConfirmUpgrade} /> }
      { isConfirmationPopupOpen && <Confirmation
        modalWidth='518px'
        isOpen={isConfirmationPopupOpen}
        header={`We've got it`}
        text={<Styles.ConfirmationPopup>
          <span>Your submission has been received! One of our sales team will be in touch with you shortly</span>
          <img src={successImage} alt='Success' />
        </Styles.ConfirmationPopup>}
        confirmText='Close'
        confirm={() => setIsConfirmationPopupOpen(false)}
        close={() => setIsConfirmationPopupOpen(false)}
        showCancelButton={false}
      />}
    </div>
  )
}

export default withRouter(UpgradePlanButton)
