import React from 'react'
import { OFFBOARDING_APPS_STATUS, TORII_BOT_ID_USER, SCOPES, WORKFLOW_ACTION_TYPES } from '@root/constants'
import ErrorMessage from '@components/offboarding/errorMessage'
import { css } from 'glamor'
import get from 'lodash/get'
import RelativeTeamLink from '@components/relativeTeamLink'
import pluralize from 'pluralize'
import EnableFor from '@components/enableFor'
import { Icon, Tooltip, Button, ButtonType, ButtonSize, Link } from '@toriihq/design-system'
import { getDisplayName } from '@lenses/users'
import TicketLink from '@components/ticketLink'
import Analytics from '@root/helpers/analytics'
import { getFormattedDate } from '@lenses/utils'
import { getActionFromExecutions, getIgnoredExecution } from '@lenses/offboarding'

const CSS = {
  offboardingIgnoreContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  }),
  offboardingIgnoreSpace: css({
    marginRight: 4
  }),
  offboardingActionNotes: css({
    whiteSpace: 'normal'
  })
}

const formattedDate = date => {
  if (date) {
    return <b>{getFormattedDate({ date })}</b>
  }
}

const linkableUser = ({ user, appOffboardingState }) => {
  const onClick = () => {
    Analytics.track('Click on user-name', {
      'State': appOffboardingState
    })
  }

  if (user) {
    if (user.id === TORII_BOT_ID_USER) {
      return <b>Torii</b>
    }

    return <RelativeTeamLink to={`/user/${user.id}`} onClick={onClick}><Link>{getDisplayName(user)}</Link></RelativeTeamLink>
  }
}

const handleRemoveUserAction = (action, { appStatus }) => {
  const runtimeInfo = action.runtimeInfo
  const to = runtimeInfo.to || runtimeInfo.emailSetup.to
  const recipient = to[0] || runtimeInfo.submissionsTo[0] || runtimeInfo.from
  const runTime = formattedDate(action.runTime)
  const moreUsersAmount = to.length - 1

  return <div>
    A request to offboard was sent to {linkableUser({ user: recipient, appOffboardingState: appStatus })}
    {moreUsersAmount > 0 ? ` and ${moreUsersAmount} more ${pluralize('user', moreUsersAmount)} ` : ' '}
    on {runTime}
  </div>
}

const handleJiraCreateIssue = (action, { assignedUsersToTicketByEmail, appStatus }) => {
  const { ticketKey, ticketUrl } = action.details || {}
  const { ticketCreationTime, ticketAssigneeEmail, idTicket } = action

  if (!idTicket) {
    return <span>In progress</span>
  }

  if (!ticketKey) {
    return ''
  }

  const boldWrapper = (text) => <strong>{text}</strong>

  const ticket = <TicketLink url={ticketUrl} content={ticketKey} analyticsOptions={{ isOffboardingAudit: true, appOffboardingState: appStatus }} />
  const assignedToTicketUser = assignedUsersToTicketByEmail[ticketAssigneeEmail]
  const ticketAssignedToSpan = assignedToTicketUser ? linkableUser({ user: assignedToTicketUser, appOffboardingState: appStatus }) : boldWrapper(ticketAssigneeEmail)
  const ticketAssigneeText = ticketAssigneeEmail ? <span> and assigned to {ticketAssignedToSpan}</span> : <span>. It was not assigned yet. </span>

  return <div>{ticket} Jira issue was created on {boldWrapper(formattedDate(ticketCreationTime))}{ticketAssigneeText}</div>
}

const handleCompletedJiraCreateIssue = ({ action, appOffboardingState }) => {
  const { ticketKey, ticketUrl } = action.details || {}
  const { ticketCompletionTime, ticketStatusName } = action

  const ticketIdSpan = <TicketLink url={ticketUrl} content={ticketKey} analyticsOptions={{ isOffboardingAudit: true, appOffboardingState }} />
  const ticketCompletionTimeSpan = <strong>{formattedDate(ticketCompletionTime)}</strong>
  const ticketStatusSpan = <strong>{ticketStatusName}</strong>

  return <span>{ticketIdSpan} Jira issue was marked as {ticketStatusSpan} on {ticketCompletionTimeSpan}.</span>
}

const ACTION_TO_HANDLER = {
  [WORKFLOW_ACTION_TYPES.REMOVE_USER]: handleRemoveUserAction,
  [WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK]: handleRemoveUserAction,
  [WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE]: handleJiraCreateIssue
}

class OffboardingUserMessage extends React.Component {
  getUserById = (idUser) => {
    if (idUser) {
      if (idUser === TORII_BOT_ID_USER) {
        return { id: TORII_BOT_ID_USER }
      }

      const { usersById } = this.props
      return usersById[idUser]
    }
  }

  getIgnoreReasonTooltip (executions) {
    let text = ''
    const isDefaultOffbaording = get(executions, '0.isDefaultOffboarding')
    const reason = get(executions, '0.actions.0.runtimeInfo.reason')
    if (isDefaultOffbaording) {
      text = `App ignored during offboarding because no offboarding method was configured`
    } else if (reason) {
      text = `Reason: ${reason}`
    }

    return (reason || isDefaultOffbaording)
      ? <Tooltip label={text}>
        <Icon name='Info' color='secondary' />
      </Tooltip>
      : <></>
  }

  getCustomActionTooltip (action) {
    const description = get(action, 'runtimeInfo.requestDescription')
    const method = get(action, 'runtimeInfo.method.label')
    const url = get(action, 'runtimeInfo.url')
    return <Tooltip
      label={`${description || 'Custom action (HTTP request)'}: A ${method} request was sent to ${url}`}>
      <Icon name='Info' color='secondary' />
    </Tooltip>
  }

  getCustomActionErrorMessage (action) {
    const { onViewCustomActionResponse } = this.props
    const method = get(action, 'runtimeInfo.method.label')
    const url = get(action, 'runtimeInfo.url')
    return <div>
      {`A ${method} request sent to ${url} failed`}<Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => onViewCustomActionResponse(action)} label='View details' />
    </div>
  }

  render () {
    const { isOffboarded, isOffboardingIgnored, isUserRemovedFromApp, appRemovedBy, removedTime, ignoredTime, status, executions, executionErrorType, onConfigureAppClick, name, isExecuteWorkflow, assignedUsersToTicketByEmail, appStatus } = this.props
    const appRemovedByUser = appRemovedBy ? this.getUserById(appRemovedBy) : null

    const action = getActionFromExecutions(executions)

    if (isOffboardingIgnored) {
      return <div {...CSS.offboardingIgnoreContainer}>
        <div {...CSS.offboardingIgnoreSpace}>Offboarding skipped. The user was removed only from the app’s user list</div>
        <div>in Torii on {formattedDate(ignoredTime)} {this.getIgnoreReasonTooltip(executions)}</div>
      </div>
    } else if (isUserRemovedFromApp) {
      let tooltip = null
      const actionType = action?.actionType
      if (actionType === WORKFLOW_ACTION_TYPES.SEND_HTTP_REQUEST) {
        tooltip = this.getCustomActionTooltip(action)
      }

      if (action?.details?.Note) {
        return <span {...CSS.offboardingActionNotes}>{action.details.Note}</span>
      }

      if (actionType === WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE && action.idTicket) {
        return handleCompletedJiraCreateIssue({ action, appOffboardingState: appStatus })
      }

      return <span>Marked as completed {appRemovedByUser ? <span>by {linkableUser({ user: appRemovedByUser, appOffboardingState: appStatus })}</span> : null} {removedTime ? <span>on {formattedDate(removedTime)}</span> : ''} {tooltip}</span>
    } else if (isOffboarded) {
      return <span>Not completed</span>
    } else if (isExecuteWorkflow) {
      return <span>In progress</span>
    } else if (status === OFFBOARDING_APPS_STATUS.needsAttention && !executionErrorType) {
      return <span>Offboarding method is not configured. Please <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}><Button type={ButtonType.compact} size={ButtonSize.small} onClick={onConfigureAppClick} label='configure' /></EnableFor></span>
    } else if (executionErrorType) {
      if (get(action, 'actionType') === WORKFLOW_ACTION_TYPES.SEND_HTTP_REQUEST) {
        return this.getCustomActionErrorMessage(action)
      }
      return <span>
        <ErrorMessage errorType={executionErrorType} appName={name} />
      </span>
    } else if (action) {
      if (!action.isRun) {
        return <span>In progress</span>
      }

      const handler = ACTION_TO_HANDLER[action.actionType] || (() => <span>In progress</span>)
      return handler(action, { assignedUsersToTicketByEmail, appStatus })
    } else if (executions && executions.length) {
      const ignoredExecution = getIgnoredExecution(executions)
      if (ignoredExecution) {
        const ignoredAction = ignoredExecution.actions[0]
        const handler = ACTION_TO_HANDLER[ignoredAction.actionType]
        return handler && handler(ignoredAction, { assignedUsersToTicketByEmail, appStatus })
      }
    }

    return <span>Sending request to offboard was skipped</span>
  }
}

export default OffboardingUserMessage
