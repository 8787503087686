import View from './view'
import { connect } from 'react-redux'
import { getContractDetailsHistoryValues, toggleContractDetailHistoryPopup, toggleContractPopup } from '@actions/'
import { withRouter } from 'react-router-dom'
import { EMPTY_ARRAY, EMPTY_OBJECT, TABLES } from '@root/constants'
import { getContractDetailsFieldsHistory, isContractDetailsFieldsHistoryLoading } from '@selectors/contracts'
import get from 'lodash/get'
import { getCurrencySymbol, getCurrentOrg } from '@selectors/org'
import { getUserPreferences, getCurrencySymbols } from '@selectors/ui'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const { idContract, detail, isOpen, idApp } = state.ui.contractDetailHistoryPopup
  const fieldHistory = getContractDetailsFieldsHistory(state)
  const data = get(fieldHistory, [idContract, detail.systemKey], EMPTY_ARRAY)
  const { users: usersMap = EMPTY_OBJECT } = get(fieldHistory, [idContract, 'resources'], EMPTY_OBJECT)
  const loading = isContractDetailsFieldsHistoryLoading(state) && !data.length
  const currencySymbol = getCurrencySymbol(state)
  const currencySymbolsByCode = getCurrencySymbols(state)
  const userPreferences = getUserPreferences(state)
  const { defaultSort = EMPTY_ARRAY } = userPreferences[TABLES.contractDetailHistoryPopupTable.key] || EMPTY_OBJECT

  return {
    isOpen,
    idOrg,
    idContract,
    detail,
    data,
    currencySymbol,
    currencySymbolsByCode,
    usersMap,
    defaultSort,
    loading,
    idApp
  }
}

const mapDispatchToProps = {
  toggleContractDetailHistoryPopup,
  toggleContractPopup,
  getContractDetailsHistoryValues
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleContractDetailHistoryPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleContractDetailHistoryPopup({ isOpen: false, isByUser: false })
    dispatchProps.toggleContractPopup({ isOpen: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
