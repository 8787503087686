import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  toggleConfigureAppForOffboarding,
  deleteWorkflow,
  getOffboardingApp
} from '@actions/'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)

  return {
    idOrg
  }
}

const mapDispatchToProps = {
  toggleConfigureAppForOffboarding,
  deleteWorkflow,
  getOffboardingApp
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
