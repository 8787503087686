import React, { Fragment } from 'react'
import { TABLES, EXPENSES_RANGE_OPTIONS, DATE_FORMAT } from '@root/constants'
import AppExpensesChartBox from '../../../components/appExpensesChartBox'
import ExpensesTable from '../../../components/expensesTable'
import { css } from 'glamor'
import moment from 'moment'

const CSS = {
  separator: css({
    marginBottom: '30px'
  })
}
class Expenses extends React.Component {
  render () {
    const { idApp, idOrg, currencySymbol, expensesAggs } = this.props
    const { viewName, fromDate, toDate } = expensesAggs || {}
    const expensesChartBoxDescription = fromDate && toDate ? `${moment(fromDate).format(DATE_FORMAT)} - ${moment(toDate).format(DATE_FORMAT)}` : 'All expenses'

    const EXPENSES_GRAPH_HEADER_BY_VIEW = {
      [EXPENSES_RANGE_OPTIONS.ALL]: 'All time',
      [EXPENSES_RANGE_OPTIONS.LAST_12_MONTHS]: 'Last 12 months',
      [EXPENSES_RANGE_OPTIONS.THIS_YEAR]: 'This year',
      [EXPENSES_RANGE_OPTIONS.ACTIVE_CONTRACT_TERM]: 'Active contract duration'
    }

    const expensesGraphHeader = `Expenses in ${EXPENSES_GRAPH_HEADER_BY_VIEW[viewName]}`

    return (
      <Fragment>
        <AppExpensesChartBox header={expensesGraphHeader} description={expensesChartBoxDescription} showSources={false} barSize={50} idApp={idApp} overrideStyle={CSS.separator} currencySymbol={currencySymbol} expensesAggs={expensesAggs} />
        <ExpensesTable idApp={idApp} idOrg={idOrg} tableKey={TABLES.appExpensesTable.key} />
      </Fragment>
    )
  }
}

export default Expenses
