import EnableFor from '@components/enableFor'
import React from 'react'
import { Tooltip, Grid, Row, Col, Button, ButtonType, Spacer, Link, AlertBox, AlertBoxType } from '@toriihq/design-system'
import TabsPage from '../tabs'
import RelativeTeamLink from '@components/relativeTeamLink'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import AnnualRenewals from '@components/charts/annualContractsRenewals'
import AnnualRenewalsByCategory from '@components/charts/annualContractsRenewalsByCategory'
import RenewalsCalendar from '@components/contractsRenewalsCalendar'
import Contracts from '@components/contracts'
import { TABLES, SCOPES } from '@root/constants'
import { FEATURES } from '@root/shared/features'
import { ORG_CALENDAR_TYPE } from '@reducers/calendars/types'
import CopyTextPopup from '@components/copyTextPopup'
import { SUPPORT_ARTICLES } from '@root/articles'
import RenewalsIntegrationsAlert from './renewalsIntegrationsAlert'
import RenewalsSummary from '@components/renewalsSummary'

const SMALL_SCREEN_WIDTH = '992px'
const MEDIUM_SCREEN_WIDTH = '1310px'

const CSS = {
  row: css({
    marginBottom: '40px'
  }),
  noRenewals: css(texts.subheading, {
    backgroundColor: colors.lightBlue2,
    padding: '17px 20px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    color: colors.grey1
  }),
  alertBox: css({
    marginBottom: 20
  }),
  chartsContainer: css({
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'row',
    height: '315px',
    ' .col': {
      height: '100%'
    },
    [`@media(max-width: ${MEDIUM_SCREEN_WIDTH})`]: {
      height: '366px'
    },
    [`@media(max-width: ${SMALL_SCREEN_WIDTH})`]: {
      display: 'flex',
      height: 'auto',
      ' .col': {
        height: '315px',
        marginBottom: '24px'
      }
    }
  }),
  rightContent: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px'
  })
}

class RenewalsPage extends React.Component {
  componentDidMount () {
    const { getContracts, getContractsFields, getContractsGroups, getUnmatchedContracts, getCalendars, getRenewalsSummary, idOrg } = this.props
    getContracts({ idOrg })
    getContractsFields({ idOrg })
    getContractsGroups({ idOrg })
    getUnmatchedContracts({ idOrg })
    getRenewalsSummary({ idOrg })
    getCalendars({ idOrg, type: ORG_CALENDAR_TYPE.RENEWAL })
  }

  render () {
    const { isEmptyState, loading, idOrg, idContractToOpenPopup } = this.props

    const tabsName = ['calendar', 'renewalsSummary', 'contracts']
    const tabsHeader = [
      { header: 'Calendar' },
      { header: 'Renewals Summary' },
      { header: 'Contracts' }
    ]
    const calendarTab = <Grid>
      {isEmptyState ? <div {...CSS.alertBox}>
        <AlertBox
          type={AlertBoxType.INFORMATIVE}
          title='Your renewal calendar is empty'
          description={<><RelativeTeamLink to={`/renewals/contracts`}><Link> View your contracts</Link></RelativeTeamLink> and update their end dates to see them on the calendar.</>}
        />
      </div> : null}
      <Row {...CSS.chartsContainer}>
        <Col sm={6}>
          <AnnualRenewals loadingRenewals={loading} />
        </Col>
        <Col sm={6}>
          <AnnualRenewalsByCategory loadingRenewals={loading} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <RenewalsCalendar loading={loading} isEmptyState={isEmptyState} rowStyle={CSS.row} />
        </Col>
      </Row>
    </Grid>
    const tableTab = <Contracts loading={loading} tableKey={TABLES.contractsTable.key} supportViews filterable idContractToOpenPopup={idContractToOpenPopup} />
    const renewalsSummaryTab = <RenewalsSummary />
    const tabsContent = [calendarTab, renewalsSummaryTab, tableTab]
    const tabsFeature = [FEATURES.RENEWALS.TABS.CALENDAR, FEATURES.RENEWALS.TABS.RENEWALS_SUMMARY, FEATURES.RENEWALS.TABS.CONTRACTS]

    return (
      <TabsPage
        pageHeader='Renewals'
        pageTitle='Renewals'
        pageName='Renewals'
        path={`/team/${idOrg}/renewals`}
        tabsName={tabsName}
        tabsHeader={tabsHeader}
        contentBelow={<RenewalsIntegrationsAlert />}
        tabsContent={tabsContent}
        tabsFeature={tabsFeature}
        contentRight={
          <div {...CSS.rightContent}>
            <Tooltip label='Import contracts'>
              <EnableFor scopes={[SCOPES.CONTRACTS_WRITE]}>
                <Button type={ButtonType.secondary} onClick={this.props.onImportContracts} icon='Import' />
              </EnableFor>
            </Tooltip>
            <Spacer top={'space-050'}>
              <CopyTextPopup
                icon='Mail'
                label='Email contract'
                textToCopy='contracts@toriihq.com'
                analyticsText='Click on Copy contracts email link'
                bodyText='Forward your contract PDF, and let Magic Auto-Fill seamlessly add it to your contract list.'
                supportArticleLink={SUPPORT_ARTICLES.UPLOADING_CONTRACTS_VIA_EMAIL}
                enableForFeature={FEATURES.RENEWALS.COMPONENTS.EMAIL_TO_CONTRACT}
              />
            </Spacer>
            <EnableFor scopes={[SCOPES.CONTRACTS_WRITE]}>
              <Button onClick={this.props.onAddContract} label='Add contract' />
            </EnableFor>
          </div>
        }
      />
    )
  }
}

export default RenewalsPage
