import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'
import some from 'lodash/some'
import isNil from 'lodash/isNil'
import reduce from 'lodash/reduce'
import { getUsedApps } from './apps'
import { INACTIVE_LICENSE_REASON, LICENSES_CUSTOM_SELECT_OPTIONS } from '@root/constants'
import pluralize from 'pluralize'
import { fieldsValuesToOptions } from '@lenses/utils'
import { createViewParameterSelector, isLicenseTypePaid } from '@shared/utils'

export const isLoadingLicensesTypes = createSelector(
  state => get(state, ['licenses', 'loadingTypes']),
  identity
)

export const isLoadedLicensesTypes = createSelector(
  state => get(state, ['licenses', 'loadedTypes']),
  identity
)

export const getLicensesTypes = createSelector(
  state => get(state, ['licenses', 'licensesTypes']),
  identity
)

export const getLicenseTrends = createSelector(
  state => get(state, ['licenses', 'licenseTrends']),
  identity
)

export const getLicenseFilterData = createSelector(
  [getLicenseTrends],
  licenseTrends =>
    licenseTrends.appLicenses.map(appLicense => ({
      id: `app-${appLicense.idApp}`,
      label: appLicense.appName,
      children: appLicense.licenses.map(license => ({
        id: license.idLicense,
        label: license.licenseName
      }))
    }))
)

export const getLicenseTrendFilters = createSelector(
  state => get(state, ['licenses', 'licenseTrendFilters']),
  identity
)

export const getAllIdLicenses = createSelector(
  [getLicenseFilterData],
  licenseFilterData =>
    licenseFilterData.reduce((acc, appLicense) => {
      appLicense.children.forEach(license => acc.push(license.id))
      return acc
    }, [])
)

export const getOrgLicenses = createSelector(
  [getLicensesTypes, getUsedApps, createViewParameterSelector],
  (licensesTypes, usedApps, view) => {
    const isPaidView = (view.value === LICENSES_CUSTOM_SELECT_OPTIONS.paid.value)
    const usedAppsByIdApp = keyBy(usedApps, 'id')
    const licenses = map(licensesTypes, allAppLicenses => {
      const idApp = allAppLicenses[0].idApp
      const currency = allAppLicenses[0].currency
      const app = usedAppsByIdApp[idApp] || {}
      const appLicenses = isPaidView ? allAppLicenses.filter(isLicenseTypePaid) : allAppLicenses
      const validTotalAmount = sumBy(appLicenses, 'validTotalAmount')
      const activeAmount = sumBy(appLicenses, 'activeAmount')
      const inactiveAmount = sumBy(appLicenses, 'inactiveAmount')

      const licensesWithPriceSet = appLicenses.filter(license => !isNil(license.pricePerUser))
      const priceNotSetOnAllSubLicense = licensesWithPriceSet.length === 0
      const paidLicenses = appLicenses.filter(license => license.pricePerUser > 0)

      const numberOfLicensesWithoutPrice = appLicenses.length - licensesWithPriceSet.length
      const doesAnySetLicensesHaveTotalCost = licensesWithPriceSet.some(license => !isNil(license.totalCost))
      const showNote = licensesWithPriceSet.length !== appLicenses.length && licensesWithPriceSet.length > 0 && doesAnySetLicensesHaveTotalCost
      const priceNote = showNote ? `Missing cost for ${numberOfLicensesWithoutPrice} ${pluralize('license', numberOfLicensesWithoutPrice)}` : null

      const licenseSumByField = (field) => licensesWithPriceSet.length > 0 ? sumBy(licensesWithPriceSet, field) : null
      const totalCost = licenseSumByField('totalCost')
      const totalCostConverted = licenseSumByField('totalCostConverted')
      const savings = licenseSumByField('savings')
      const savingsConverted = licenseSumByField('savingsConverted')

      let pricePerUser = null
      let pricePerUserConverted = null
      if (paidLicenses.length > 0) {
        if (doesAnySetLicensesHaveTotalCost) {
          pricePerUser = sumBy(paidLicenses, 'totalCost') / sumBy(paidLicenses, 'validTotalAmount')
          pricePerUserConverted = sumBy(paidLicenses, 'totalCostConverted') / sumBy(paidLicenses, 'validTotalAmount')
        }
      } else if (!priceNotSetOnAllSubLicense) {
        pricePerUser = 0
        pricePerUserConverted = 0
      }

      const haveUnassignedInfo = some(appLicenses, license => !isNil(license.unassignedAmount))
      const unassignedAmount = haveUnassignedInfo ? sumBy(appLicenses, license => license.unassignedAmount || 0) : null
      const inactiveReasons = reduce(INACTIVE_LICENSE_REASON, (reasons, reason) => {
        reasons[reason] = sumBy(appLicenses, license => get(license, ['inactiveReasons', reason], 0))
        return reasons
      }, {})

      const isBenchmarkDataExist = some(appLicenses, license => license.benchmark?.isExist)
      const isTotalAmountEnteredByUser = some(appLicenses, { isTotalAmountEnteredByUser: true })
      const isAppTotalAmountPartialCalculated = some(appLicenses, license => isNil(license.unassignedAmount) && !license.isTotalAmountEnteredByUser)

      const licenseTypes = appLicenses.map(appLicense => {
        return ({
          idApp,
          appName: app.name,
          licenseName: appLicense.name,
          ...pick(appLicense, ['id', 'isTotalAmountEnteredByUser', 'validTotalAmount', 'totalAmount', 'assignedAmount', 'unassignedAmount', 'activeAmount', 'inactiveAmount', 'inactiveReasons', 'currency', 'conversionDate', 'isPaid', 'pricePerUser', 'pricePerUserConverted', 'totalCost', 'totalCostConverted', 'savings', 'savingsConverted', 'benchmark', 'isUpdatingLicense']),
          isParent: false
        })
      })
      return ({
        idApp,
        ...pick(app, ['name', 'imageUrl', 'category']),
        savings,
        savingsConverted,
        validTotalAmount,
        activeAmount,
        inactiveAmount,
        unassignedAmount,
        pricePerUser,
        pricePerUserConverted,
        currency,
        inactiveReasons,
        licenseTypes,
        totalCost,
        totalCostConverted,
        priceNote,
        isParent: true,
        isBenchmarkDataExist,
        isTotalAmountEnteredByUser,
        isAppTotalAmountPartialCalculated
      })
    })

    return isPaidView
      ? licenses.filter(license => license.licenseTypes?.length)
      : licenses
  }
)

export const isLoadingAppInactiveLicences = createSelector(
  state => state.licenses.inactiveAppLicenses.loading,
  identity
)

export const getChargeBackReportSelector = createSelector(
  state => get(state, ['licenses', 'chargeBackReport']),
  identity
)

export const getAppInactiveLicenses = createSelector(
  state => state.licenses.inactiveAppLicenses.licenses,
  identity
)

export const getAllAppInactiveLicensesState = createSelector(
  state => state.licenses.inactiveAppLicenses.state,
  identity
)

export const getAppLicenseRecommendationUserList = createSelector(
  state => state.licenses.licenseRecommendations,
  identity
)

export const getAppUserLicenseFieldValue = createSelector(
  state => state.licenses.fieldValuesPerAppUserLicenses,
  fieldsValuesToOptions
)
