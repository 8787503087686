import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAppsLicenses } from '@actions/'
import { getAppsLicensesLoadingSelector, getAppsLicensesSelector } from '@selectors/apps'
import { TABLES } from '../../../constants'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const appsLicenses = getAppsLicensesSelector(state)
  const tableInfo = TABLES.workflowAppsLicensesTable.key
  appsLicenses.forEach(app => {
    app.anyLicenseSelected = false
    app.licenses.forEach(license => {
      license.isSelected = ownProps.value.includes(license.idLicense)
      if (license.isSelected) {
        app.anyLicenseSelected = true
      }
    })
  })
  const loadingAppsLicenses = getAppsLicensesLoadingSelector(state)
  const loading = loadingAppsLicenses && appsLicenses.length === 0

  return {
    idOrg,
    appsLicenses: [...appsLicenses],
    tableInfo,
    loading
  }
}

const mapDispatchToProps = {
  getAppsLicenses
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
