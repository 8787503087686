import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAppDetailsFields, deprecatedGetApps } from '@selectors/apps'
import { getContracts, getContractsFields } from '@selectors/contracts'

const mapStateToProps = (state, ownProps) => {
  const { idOrg } = ownProps.match.params
  return {
    apps: deprecatedGetApps(state),
    contracts: getContracts(state),
    appDetails: getAppDetailsFields(state),
    contractDetails: getContractsFields(state),
    idOrg
  }
}

export default withRouter(connect(mapStateToProps)(View))
