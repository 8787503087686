import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'
import flatten from 'lodash/flatten'
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@root/constants'
import { isToriiAdmin } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'
import groupBy from 'lodash/groupBy'
import { isFeatureEnabled } from '@lenses/features'
import reduce from 'lodash/reduce'
import { getFormattedDate } from '@lenses/utils'
import { getDisplayName } from '@lenses/users'
import { getActionDescriptionByAuditType } from '@lenses/workflows.t'
import { capitalize } from 'lodash'
import { ACTION_CATEGORY } from './types'

export const isLoadingWorkflows = createSelector(
  state => get(state, ['workflows', 'loading']),
  identity
)

export const getNotifyOnErrorsWorkflowsSelector = createSelector(
  state => get(state, ['workflows', 'notifyOnErrorsWorkflows'], []),
  identity
)

export const getWorkflowsResources = createSelector(
  state => get(state, ['workflows', 'resources'], {}),
  identity
)

export const getWorkflowsTemplates = createSelector(
  state => groupBy(get(state, ['workflows', 'workflowsTemplates'], []), 'category'),
  identity
)

export const getAppsTriggers = createSelector(
  state => get(state, ['workflows', 'appsTriggers'], []),
  identity
)

export const isAppsTriggersLoaded = createSelector(
  [getAppsTriggers],
  appsTriggers => appsTriggers?.length > 0
)

export const getTriggersByType = createSelector(
  [getAppsTriggers],
  appsTriggers => {
    return appsTriggers.reduce((allTriggers, app) => {
      const appTriggersByType = keyBy(app.triggers, 'type')
      return { ...allTriggers, ...appTriggersByType }
    }, {})
  }
)

export const getVisibleAppsTriggers = createSelector(
  [getAppsTriggers, isToriiAdmin, state => state],
  (appsTriggers, hasToriiAdminPermissions, state) => {
    return appsTriggers.map(appTriggers => {
      const showOnlyForToriiAdmins = (trigger) => !trigger.uiConfig.isVisibleOnlyToToriiAdmin || isFeatureEnabled(state, trigger.uiConfig.type) || hasToriiAdminPermissions
      const isHiddenBehindFeatureFlag = (trigger) => trigger.featureFlag && !isFeatureEnabled(state, trigger.featureFlag)

      return {
        ...appTriggers,
        triggers: appTriggers.triggers.filter(trigger =>
          !trigger.uiConfig.isHidden &&
          showOnlyForToriiAdmins(trigger) &&
          !isHiddenBehindFeatureFlag(trigger)
        )
      }
    })
  }
)

export const getTriggersOptionsByApp = createSelector(
  [getVisibleAppsTriggers, getTriggersByType, getCurrentOrg],
  (appTriggers, triggerTypes, { appNotInUsePeriod: days }) => {
    return appTriggers
      .map(appTriggers => ({
        ...pick(appTriggers, ['idApp', 'name', 'imageUrl', 'isDisabled']),
        options: appTriggers.triggers
          .filter(trigger => !trigger.uiConfig.isHidden)
          .map(trigger => {
            const triggerUiConfig = trigger.uiConfig || {}
            return {
              value: `${appTriggers.idApp}_${trigger.type}`,
              idApp: appTriggers.idApp,
              type: trigger.type,
              label: triggerUiConfig.label,
              description: (triggerUiConfig.description).replace('{appName}', trigger.name).replace('{days}', days || 30),
              src: triggerUiConfig.imageSrc,
              isDisabled: triggerUiConfig.isDisabled,
              badge: triggerUiConfig.badge
            }
          })
      }))
  }
)

export const getAppsActionsConfig = createSelector(
  state => get(state, ['workflows', 'config', 'appsActions'], []),
  identity
)

export const getGlobalActionsConfig = createSelector(
  state => get(state, ['workflows', 'config', 'globalActions'], []),
  identity
)

export const getGlobalActionTypes = createSelector(
  [getGlobalActionsConfig],
  globalActionsConfig => globalActionsConfig.map(action => action.type)
)

export const getTriggersPersonalizationConfig = createSelector(
  state => get(state, ['workflows', 'config', 'triggersPlaceholders'], {}),
  identity
)

export const getActionsPersonalizationConfig = createSelector(
  state => get(state, ['workflows', 'config', 'actionsPlaceholders'], {}),
  identity
)

export const getDynamicPersonalizationConfig = createSelector(
  state => get(state, ['workflows', 'config', 'dynamicPlaceholders'], {}),
  identity
)

export const getActionsUserOptions = createSelector(
  state => get(state, ['workflows', 'config', 'userOptions'], []),
  identity
)

export const getActionsEmailOptions = createSelector(
  state => get(state, ['workflows', 'config', 'emailOptions'], []),
  identity
)

const shouldShowAction = (action, hasPermissions, state) => {
  if (action.uiConfig.isHidden) {
    return false
  }

  if (action.uiConfig.isVisibleOnlyToToriiAdmin && !hasPermissions && !isFeatureEnabled(state, action.uiConfig.type)) {
    return false
  }
  return true
}

const getVisibleActions = (actions, hasPermissions, state) => {
  return actions.filter(action => shouldShowAction(action, hasPermissions, state))
}

export const getVisibleActionsConfig = createSelector(
  [getAppsActionsConfig, isToriiAdmin, state => state],
  (appsActions, hasToriiAdminPermissions, state) => {
    const permissions = hasToriiAdminPermissions

    return appsActions
      .filter(appActions => !appActions.isDeprecated)
      .map(appActions => ({
        ...appActions,
        actions: getVisibleActions(appActions.actions, permissions, state)
      }))
      .filter(app => app.actions.length > 0)
  }
)

export const getVisibleGlobalActions = createSelector(
  [getGlobalActionsConfig, isToriiAdmin, state => state],
  (globalActions, hasToriiAdminPermissions, state) => {
    const permissions = hasToriiAdminPermissions
    const filteredGlobalActions = getVisibleActions(globalActions, permissions, state)
    return filteredGlobalActions
  }
)

export const getFlattenActionsConfig = createSelector(
  [getAppsActionsConfig, getGlobalActionsConfig],
  (appsActions, globalActions) => {
    const appsActionsFlatten = flatten(appsActions.map(app => {
      return app.actions.map(action => ({ ...action, idApp: app.idApp }))
    }))

    return [...appsActionsFlatten, ...globalActions]
  }
)

export const getFieldsDefaultValues = createSelector(
  state => get(state, ['workflows', 'config', 'fieldsDefaultValues'], {}),
  identity
)

export const getActionsConfigByType = createSelector(
  [getAppsActionsConfig, getGlobalActionsConfig],
  (appsActions, globalActions) => {
    const globalActionsByType = keyBy(globalActions, 'type')
    const appsActionsByType = appsActions.reduce((allActions, app) => {
      const appActionsByType = keyBy(app.actions, 'type')
      return { ...allActions, ...appActionsByType }
    }, {})

    return { ...globalActionsByType, ...appsActionsByType }
  }
)

export const getVisibleActionTypes = createSelector(
  [getActionsConfigByType],
  actions => {
    return Object.values(actions)
      .filter(actionType => !actionType.isHidden)
      .map(actionType => actionType.type)
  }
)

const mapActionsToOption = (action, idApp) => {
  const actionInfo = action.uiConfig || {}

  return {
    value: idApp ? `${idApp}_${action.type}` : `${action.type}`,
    idApp,
    label: actionInfo.label,
    description: (actionInfo.description || '').replace('{appName}', action.name),
    ...pick(actionInfo, 'type', 'isDisabled', 'isToriiAdminsOnly', 'output', 'imageUrl', 'branchesConfiguration')
  }
}

export const getActionsOptionsByApp = createSelector(
  [getVisibleActionsConfig],
  actions => {
    return actions
      .map(action => ({
        ...pick(action, ['idApp', 'name', 'imageUrl', 'isDisabled']),
        options: action.actions.map(appAction => mapActionsToOption(appAction, action.idApp))
      }))
  }
)

export const getGlobalActionsOptions = createSelector(
  [getVisibleGlobalActions],
  actions => {
    return actions.map(globalAction => mapActionsToOption(globalAction))
  }
)

export const getWorkflowExecutions = createSelector(
  state => get(state, ['workflows', 'executions'], {}),
  identity
)

export const getWorkflowExecutionsState = createSelector(
  state => get(state, ['workflows', 'executionsState'], {}),
  identity
)

export const getWorkflowActionExecutions = createSelector(
  state => get(state, ['workflows', 'workflowActionExecutions', 'actions'], {}),
  identity
)

export const getWorkflowActionExecutionsLoading = createSelector(
  state => get(state, ['workflows', 'workflowActionExecutions', 'loading'], {}),
  identity
)

export const getWorkflowActionDynamicFieldsOption = createSelector(
  state => get(state, ['workflows', 'actionDynamicFieldsOptions'], {}),
  identity
)

export const isLoadingWorkflowActionDynamicFieldsOption = createSelector(
  state => get(state, ['workflows', 'actionDynamicFieldsOptions', 'loading'], false),
  identity
)

export const getWorkflowTriggerDynamicFieldsOption = createSelector(
  state => get(state, ['workflows', 'triggerDynamicFieldsOptions'], {}),
  identity
)

export const getWorkflowTriggerDynamicFieldsOptionsValues = createSelector(
  state => get(state, ['workflows', 'triggerDynamicFieldsOptionsValues'], {}),
  identity
)

export const getSelectOptionsOfActionsByAppFor = createSelector(
  [getVisibleActionsConfig],
  (appsWithActions) => {
    return appsWithActions.reduce((acc, appWithActions) => {
      acc[appWithActions.idApp] = appWithActions.actions.map(action => {
        const actionInfo = action.uiConfig || {}
        return {
          value: action.type,
          label: actionInfo.label
        }
      })

      return acc
    }, {})
  }
)

export const getSelectOptionsOfCreateTicketActions = createSelector(
  [getVisibleActionsConfig],
  (appsWithActions) => {
    return reduce(appsWithActions, (createTicketActions, _, appIndex) => {
      const appActions = appsWithActions[appIndex].actions
      const appCreateTicketActions = appActions.filter(
        action => action.actionCategory === ACTION_CATEGORY.assignTask
      )
      if (appCreateTicketActions.length) {
        const appCreateTicketActionsSelectOptions = appCreateTicketActions
          .map(appCreateTicketAction => ({ value: appCreateTicketAction.type, label: appCreateTicketAction.uiConfig.label }))
        return [...createTicketActions, ...appCreateTicketActionsSelectOptions]
      }
      return createTicketActions
    }, [])
  }
)

export const getTriggerPreview = createSelector(
  state => get(state, ['workflows', 'triggerPreview'], {}),
  identity
)

export const isLoadingTriggerPreview = createSelector(
  state => get(state, ['workflows', 'triggerPreview', 'loading']),
  identity
)

export const getWorkflowsAuditLogsListWithResources = createSelector(
  state => get(state, ['workflows', 'workflowsAuditLogs', 'listByIdWorkflow'], {}),
  identity
)

export const isLoadingWorkflowsAuditLogsList = createSelector(
  state => get(state, ['workflows', 'workflowsAuditLogs', 'listByIdWorkflow', 'loading'], false),
  identity
)

export const getWorkflowsAuditLogsBackAndForwardNavigatorOptions = createSelector(
  [getWorkflowsAuditLogsListWithResources],
  workflowsAuditLogs => {
    return reduce(workflowsAuditLogs, (result, workflowInfo, idWorkflow) => {
      const { auditLogs = EMPTY_ARRAY, resources: { users } = EMPTY_OBJECT } = workflowInfo
      const sortedLogs = auditLogs.slice().reverse()
      const mappedLogs = sortedLogs.map(log => {
        const performedBy = getDisplayName(users[log.performedBy])
        const date = getFormattedDate({ date: log.creationTime, includeTime: true })

        const {
          type,
          isActionsChanged,
          isEnabled
        } = log

        const label = getActionDescriptionByAuditType({
          type,
          isActionsChanged,
          isEnabled
        })

        return {
          linkSuffix: log.id,
          label: `${capitalize(label)} by ${performedBy} on ${date}`
        }
      })
      result[idWorkflow] = mappedLogs
      return result
    }, {})
  }
)
