import { useSelector, useDispatch } from 'react-redux'
import { toggleImportUsers } from '@actions/'
import WizardPopup from '../wizardPopup'
import { stepsConfig } from './stepsConfig/config'
import { IMPORT_USERS_COLUMNS, WIZARD_STEPS, SharedProps, SharedState } from './stepsConfig/types'
import { getImportUsersPopupProperties } from '@selectors/importUsers/importUsers'
import { getIdOrg } from '@selectors/org'
import Analytics from '@components/popups/importUsersPopup/analytics'
import { IMPORT_TYPE } from '@selectors/importUsers/types'

const ImportUsersPopup = (): JSX.Element => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const { isOpen, idApp, appName, importMode, isFirstTimeImport, lastSyncTime, idAppAccount, appAccountName }
  : { isOpen: boolean, idApp: number, appName: string, importMode: IMPORT_TYPE, isFirstTimeImport: boolean, lastSyncTime: string, idAppAccount?: number, appAccountName?: string } = useSelector(getImportUsersPopupProperties)

  const onCloseButton = () => {
    Analytics.onCloseImportPopup(importMode, '', 'X')
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  const onCancelButton = () => {
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  const initialSharedState: SharedState = {
    filePreview: {},
    parsingPreviewChanges: {
      validUsers: [],
      invalidUsers: [],
      missingUsers: [],
      newUsersWithLicenses: []
    },
    selectedColumnsToImport: [IMPORT_USERS_COLUMNS.EMAIL, IMPORT_USERS_COLUMNS.LICENSES],
    previewDataMapping: {
      email: '',
      licenses: '',
      lastVisitTime: ''
    },
    idUsersImportParsing: null,
    idAppAccount: null
  }

  return (
    <WizardPopup<SharedState, SharedProps, WIZARD_STEPS>
      name={'importUsers'}
      isOpen={isOpen}
      stepsConfig={stepsConfig()}
      initialSharedState={initialSharedState}
      sharedProps={{ idOrg, idApp, appName, isFirstTimeImport, lastSyncTime, importMode, idAppAccount, appAccountName }}
      onCancel={onCancelButton}
      onClose={onCloseButton}
      width='710px'
      onSubmitSuccess={() => {}}
    />
  )
}

export default ImportUsersPopup
