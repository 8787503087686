import View from './view'
import { connect } from 'react-redux'
import mergePropsWithHandlers from '../../helpers/mergePropsWithHandlers'
import {
  updateAppUserRemovalStatus,
  updateUserLifecycleStatus,
  getOffboardingStatusOfUser,
  toggleConfigureAppForOffboarding,
  toggleCustomActionResponsePopup
} from '@actions/'
import moment from 'moment'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import sumBy from 'lodash/sumBy'
import { getUsageScore } from '@lenses/appUsers'
import { getOffboardingUserResources, getOffboardingUserStatus } from '@selectors/offboarding'
import { getSelf } from '@selectors/me'
import some from 'lodash/some'
import isNil from 'lodash/isNil'
import accessControl from '@lenses/accessControl'
import { getDisplayName } from '@lenses/users'
import { EMPTY_ARRAY, EMPTY_OBJECT, SCOPES } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const getTotalExpenses = monthsExpenses => (sumBy(monthsExpenses, month => month.total) || 0) / 100
  const user = ownProps.user || EMPTY_OBJECT
  const appsOfUser = ownProps.apps || EMPTY_ARRAY
  const allApps = deprecatedGetAppsByIds(state) || EMPTY_OBJECT
  const appsWithTotalExpenses = appsOfUser.map(app => ({
    ...app,
    idApp: app.idApp || app.id,
    expenses: allApps[app.idApp] && getTotalExpenses(allApps[app.idApp].expenses),
    score: getUsageScore(app.score, app.lastVisitTime)
  }))
  const hasLicenses = appsWithTotalExpenses.some(app => app.licenses && app.licenses.length)
  const hideLicenses = !hasLicenses && !ownProps.loading

  const offboardingUserStatus = getOffboardingUserStatus(state)
  const { id: triggerIdUser } = getSelf(state)

  const showCostDataColumn = some(appsWithTotalExpenses, app => !isNil(app.annualCost))

  const { users } = getOffboardingUserResources(state)

  const columnsRestrictions = {
    hasExpensesAccess: accessControl.isAccessibleWithState({ scopes: [SCOPES.EXPENSE_READ], state }) && showCostDataColumn
  }

  const exportPrefix = getDisplayName(user).replace(/ /g, '_') + '_apps'

  return {
    appsWithTotalExpenses,
    hideLicenses,
    offboardingUserStatus,
    triggerIdUser,
    usersById: users,
    columnsRestrictions,
    exportPrefix
  }
}

const mapDispatchToProps = {
  updateAppUserRemovalStatus,
  updateUserLifecycleStatus,
  getOffboardingStatusOfUser,
  toggleConfigureAppForOffboarding,
  toggleCustomActionResponsePopup
}

const mergeProps = mergePropsWithHandlers(props => ({
  onChangeRemovalStatus: async ({ idApp, isRemoved, idTask, idActionExe, idAppAccount, idTicket, idUser, originEmail }) => {
    const { updateAppUserRemovalStatus, triggerIdUser, user } = props
    const params = {
      idOrg: user.idOrg,
      idUser,
      idApp,
      isRemoved,
      triggerIdUser: isRemoved ? triggerIdUser : null,
      removedTime: isRemoved ? moment().format('YYYY-MM-DD') : null,
      idActionExe: idTask || idTicket ? idActionExe : undefined,
      idAppAccount,
      email: originEmail,
      taskType: idTask ? 'Torii task' : idTicket ? 'Jira issue' : 'Unknown'
    }
    await updateAppUserRemovalStatus(params)
  }
}))

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
