import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import styled from 'styled-components'
import { theme } from '@toriihq/design-system'

export const main = css({
  marginBottom: '28px',
  backgroundColor: 'white',
  border: '1px solid',
  borderRadius: '8px',
  borderColor: `${theme.palette.border.primary}`,
  ':hover': {
    borderColor: `${theme.palette.border.primaryHover}`,
    ' .dragIcon': {
      opacity: `1`
    }
  }
})

export const selected = css({
  borderColor: `${colors.blue} !important`
})

export const tableHeader = css({
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '20px'
})

export const tableHeaderStyle = css({
  padding: '15px 0',
  cursor: 'pointer'
})

export const noFields = css(texts.body, {
  color: colors.black,
  paddingLeft: '5px'
})

export const addFieldContainer = css({
  padding: '0 20px'
})

export const DisabledButtonTooltipLabel = styled.div`
  max-width: 230px;
`

export const ButtonsWrapperStyle = css({
  display: 'flex',
  marginLeft: '15px',
  opacity: 0,
  '.tableHeader:hover &': {
    opacity: 1
  }
})

export const icon = css({
  fontSize: '16px'
})

export const fieldActions = css({
  textAlign: 'right'
})

export const dragIcon = css({
  display: 'inline-flex',
  paddingRight: '10px',
  opacity: 0,
  width: 'auto',
  ':disabled': {
    opacity: 0.5
  }
})
