import colors from '@shared/style/colors'
import { fontSize, fontWeight } from '@shared/style/sizes'
import { css } from 'glamor'

const inputWidth = '200px'
export const placeholderStyle = { height: '32px', width: inputWidth }

export const input = css({
  width: inputWidth
})

export const popupHeadline = css({
  fontWeight: fontWeight.semiBold,
  fontSize: fontSize.medium,
  color: colors.black
})

export const popupSecondaryText = css({
  paddingTop: '10px',
  fontWeight: fontWeight.normal,
  fontSize: fontSize.small,
  lineHeight: '16px',
  color: colors.grey1
})

export const popupBody = css({
  display: 'flex'
})

export const sectionWidth = 530

export const geolocationSubSection = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: `${sectionWidth}px`
})

export const benchmarkSection = css({
  display: 'block',
  justifyContent: 'space-between',
  width: `${sectionWidth}px`
})

export const benchmarkTitlesAndToggle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: `${sectionWidth}px`
})
