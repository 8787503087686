import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import qs from 'query-string'
import { getConnectIntegrationInfo, createSourceToken } from '@actions/'
import { getTestConnectionInfo, updateTestConnectionEntry, createTestConnectionEntry, testConnection, createSourceTokenAfterTestConnection } from '@actions/testConnection'
import { getServicesConnectionStatus } from '@selectors/org'
import { isLoadingConnectIntegration, getConnectIntegrationInfo as getConnectIntegrationInfoSelector } from '@selectors/instantAccess'
import { getCapabilities, hasTestConnectionInUrl } from '@components/testConnection/utils'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idService = parseInt(ownProps.match.params.idService, 10)
  const { id, secret } = ownProps.match.params
  const token = `${id}-${secret}`
  const loading = isLoadingConnectIntegration(state)
  const connectIntegrationInfo = getConnectIntegrationInfoSelector(state) || {}
  const { app = {}, requestedBy = {}, permission, serviceConfig = {}, authError } = connectIntegrationInfo
  const { capabilities, integrationCategory } = serviceConfig || {}
  const hasData = !isEmpty(serviceConfig)

  const { location } = ownProps
  const query = qs.parse(location.search)
  const servicesConnectionStatus = getServicesConnectionStatus(state)
  const isConnectionError = query.error
  const afterRedirect = hasTestConnectionInUrl()
  const urlParams = new URLSearchParams(window.location.search)
  const idTestConnection = urlParams.get('idTestConnection')
  const hasTestConnection = serviceConfig.testConnection
  const isConnectedSuccessfully = hasTestConnection ? servicesConnectionStatus[serviceConfig.source] : servicesConnectionStatus[serviceConfig.source] || Boolean(query.connectedSuccessfully)
  const capabilityList = hasData ? getCapabilities(capabilities, integrationCategory) : []

  return {
    idOrg,
    idApp: idService,
    token,
    loading: loading && !hasData,
    app,
    serviceName: serviceConfig.name,
    requestedBy,
    permission,
    serviceConfig,
    isAuthError: authError,
    isConnectedSuccessfully,
    isConnectionError,
    afterRedirect,
    hasTestConnection,
    idTestConnection: parseInt(idTestConnection),
    capabilityList
  }
}

const mapDispatchToProps = {
  createSourceToken,
  getConnectIntegrationInfo,
  testConnection,
  getTestConnectionInfo,
  updateTestConnectionEntry,
  createTestConnectionEntry,
  createSourceTokenAfterTestConnection
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
