import View from './view'
import { connect } from 'react-redux'
import { createSourceToken, getServicesSyncData } from '@actions/'
import { getSupportedFeatures } from '@selectors/org'

const mapStateToProps = (state) => {
  const supportedFeatures = getSupportedFeatures(state)

  return {
    supportedFeatures
  }
}
const mapDispatchToProps = {
  createSourceToken,
  getServicesSyncData
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
