import send from '@shared/redux-fetch'
import { DELETE_MANUALLY_IMPORTED_LICENSES, DELETE_MANUALLY_IMPORTED_USERS } from '@root/constants'
import { DeleteManuallyImportedLicensesParams, DeleteManuallyImportedUsersParams } from '@actions/usersImport/types'

export const deleteManuallyImportedUsers = ({ idOrg, idApp }: DeleteManuallyImportedUsersParams) => (
  send(DELETE_MANUALLY_IMPORTED_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import`,
    method: 'DELETE'
  })
)

export const deleteManuallyImportedLicenses = ({ idOrg, idApp, idAppAccount }: DeleteManuallyImportedLicensesParams) => (
  send(DELETE_MANUALLY_IMPORTED_LICENSES, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import/licenses`,
    method: 'DELETE',
    body: JSON.stringify({ idAppAccount })
  })
)
