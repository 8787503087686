import { SCOPES } from '@root/constants'
import { JSX, useEffect, useRef, useState, useCallback } from 'react'
import Table from '@components/table'
import emptyStateImage from './images/emptyState.svg'
import { useSelector, useDispatch } from 'react-redux'
import { getColumns } from './columns'
import { getUnmatchedContracts as getUnmatchedContractsSelector, getUnmatchedContractsTablesInfo, isLoadingUnmatchedContracts } from '@selectors/contracts'
import {
  getUnmatchedContracts,
  deleteContracts,
  toggleContractPopup,
  setDoneContractsAppMatching
} from '@actions/'
import { ButtonType, Link, EmptyState, toast, ToastType } from '@toriihq/design-system'
import Analytics from '../analytics'
import Confirmation from '@root/components/confirmation'
import { useHistory } from 'react-router-dom'
import { map } from 'lodash'
import RelativeTeamLink from '@components/relativeTeamLink'

const UnmatchedContracts = (props: { idOrg: number, tableKey: string, idContractToOpenPopup: number }): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { idOrg, tableKey, idContractToOpenPopup } = props

  const unmatchedContracts = useSelector(getUnmatchedContractsSelector)
  const unmatchedContractsCount = unmatchedContracts.length
  const isLoading = useSelector(isLoadingUnmatchedContracts) && !unmatchedContractsCount
  const tableInfo = useSelector(getUnmatchedContractsTablesInfo)

  const [columns, setColumns] = useState<any[]>([])
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [idContractToDelete, setIdContractToDelete] = useState<number>()
  const [contractName, setContractName] = useState<string>()

  const { name, dynamicColumnsInfo, preDefinedColumnsMapping } = tableInfo
  const columnsOptions = map(preDefinedColumnsMapping, (value, key) => {
    return { value: key, label: value }
  })

  const unmatchedContractsCountRef = useRef(unmatchedContractsCount)

  const onMatchingContractSuccessfully = useCallback((contractUpdatedName: string) => {
    if (unmatchedContractsCountRef.current !== 1) {
      toast({
        message: `'${contractUpdatedName}' contract was matched successfully`,
        type: ToastType.SUCCESS
      })
    }
    dispatch(getUnmatchedContracts({ idOrg }))
  }, [dispatch, unmatchedContractsCountRef, idOrg])

  const onEditContract = useCallback((idContract: number) => {
    dispatch(toggleContractPopup({ isDuplicated: false, idContract, isOpen: true, isAppMatchingMode: true, idApp: null, initialValues: null, buttonLabel: null, onSuccessCB: onMatchingContractSuccessfully }))
  }, [dispatch, onMatchingContractSuccessfully])

  const toggleDeleteContract = useCallback(({ idContract, contractName = '', buttonLabel = '' }) => {
    const currentIsDeleteOpen = !isDeleteOpen

    Analytics.onDeleteContract(currentIsDeleteOpen ? 'Open' : 'Close', { 'Button Label': buttonLabel })
    setIdContractToDelete(idContract)
    setContractName(contractName)
    setIsDeleteOpen(currentIsDeleteOpen)
  }, [isDeleteOpen])

  const returnToMainContractsPageAndShowDoneToast = useCallback(() => {
    dispatch(setDoneContractsAppMatching(true))
    history.push(`/team/${idOrg}/renewals/contracts`)
  }, [dispatch, history, idOrg])

  useEffect(() => {
    idOrg &&
      setColumns(getColumns({ idOrg, dynamicColumnsInfo, name, onEditContract, onDeleteContract: toggleDeleteContract }))
  }, [idOrg, dynamicColumnsInfo, name, onEditContract, toggleDeleteContract])

  useEffect(() => {
    if (idContractToOpenPopup && unmatchedContracts?.find(contract => contract.id === idContractToOpenPopup)) {
      onEditContract(idContractToOpenPopup)
    }
  }, [idContractToOpenPopup, unmatchedContracts, onEditContract])

  useEffect(() => {
    if (unmatchedContractsCountRef.current > 0 && unmatchedContractsCount === 0) {
      returnToMainContractsPageAndShowDoneToast()
    }
    unmatchedContractsCountRef.current = unmatchedContractsCount
  }, [unmatchedContractsCount, returnToMainContractsPageAndShowDoneToast])

  const deleteContract = async () => {
    const { idOrg } = props
    await dispatch(deleteContracts({ idOrg, idContracts: [idContractToDelete], idApp: null }))
    Analytics.onDeleteContract('Delete', { 'Button label': 'Delete' })

    dispatch(getUnmatchedContracts({ idOrg }))
    toggleDeleteContract({ idContract: idContractToDelete })
  }

  const deleteContractConfirmation = <Confirmation
    isOpen={isDeleteOpen}
    header={`Delete ${contractName} contract?`}
    text={`It will be permanently removed.`}
    confirmText='Delete'
    declineText='Cancel'
    decline={() => toggleDeleteContract({ idContract: idContractToDelete, buttonLabel: 'Cancel' })}
    confirm={deleteContract}
    close={() => toggleDeleteContract({ idContract: idContractToDelete, buttonLabel: 'Close' })}
    mainButtonType={ButtonType.destructive}
  />

  const renderEmptyState = () => {
    return <EmptyState
      image={<img src={emptyStateImage} alt='All contracts matched' />}
      title='All contracts matched'
      link={<RelativeTeamLink to={'/renewals/contracts'}><Link>Back to renewals</Link></RelativeTeamLink>}
    />
  }

  return (
    <>
      {isDeleteOpen && deleteContractConfirmation}
      <Table
        tableKey={tableKey}
        data={unmatchedContracts}
        columns={columns}
        loading={isLoading}
        emptyStateMessage={renderEmptyState()}
        allowedScopes={[SCOPES.CONTRACTS_WRITE]}
        forceShowNumberOfResults
        configurableColumnsOptions={columnsOptions}
        clientPaging
        pageSize={50}
      />
    </>
  )
}

export default UnmatchedContracts
