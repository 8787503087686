import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getUserDataAccessOfCategoryForTable, getUserDataAccessOfCategorySelector } from '@selectors/apps'
import { getUserDataAccessOfCategory } from '@actions/'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const apps = getUserDataAccessOfCategoryForTable(state)
  const { loading } = getUserDataAccessOfCategorySelector(state)

  return ({
    idOrg,
    apps,
    loading
  })
}

const mapDispatchToProps = {
  getUserDataAccessOfCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
