import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import { theme } from '@toriihq/design-system'

export const Main = css(texts.body, {
  position: 'relative',
  flexDirection: 'left',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  background: colors.white,
  color: colors.darkText
})

export const Header = css(texts.subheading, {
  color: colors.black,
  fontSize: '14px',
  lineHeight: '22px',
  marginRight: '10px'
})

export const Details = css({
  textAlign: 'center'
})

export const HeaderWrapper = css({
  width: '100%',
  padding: '20px',
  borderBottom: `1px solid ${theme.palette.border.primary}`,
  display: 'flex'
})

export const OwnerButtons = css({
  gridColumn: 3
})

export const HoverContainer = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 25%) 1fr 70px',
  height: '65px',
  alignItems: 'center',
  ':not(:last-child)': {
    borderBottom: `solid 1px ${colors.lightBlue2}`
  }
})

export const EditButton = css({
  opacity: 0,
  [`.${HoverContainer}:hover &, [data-${HoverContainer}]:hover &`]: {
    opacity: 1
  }
})
