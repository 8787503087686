import React, { useEffect } from 'react'
import WorkflowsTable from '../workflowsTable'
import {
  getAppCatalogPolicies,
  getCatalogApps,
  getSingleApp,
  toggleAddAppCatalogPolicyPopup
} from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppCatalogSettings,
  getIdOrg,
  isLoadedAppCatalogSettings
} from '@selectors/org'
import {
  getAppCatalogPoliciesResourcesSelector,
  getAppCatalogPoliciesSelector,
  isLoadingAppCatalogPoliciesSelector
} from '@selectors/workflows'
import { APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS, EMPTY_OBJECT, SCOPES, TORII_APP_ID, WORKFLOW_TYPES } from '@root/constants'
import { getCatalogApps as getCatalogAppsSelector, getCatalogPoliciesInfo, isLoadingCatalogApps as isLoadingCatalogAppsSelector } from '@selectors/apps'
import { Button, Link, AlertBox, AlertBoxType, ButtonType } from '@toriihq/design-system'
import * as Style from './style'
import { getPolicyDeleteLogicProps, isDefaultAppCatalogPolicy, getWorkflowPathPrefix } from '@lenses/workflows'
import get from 'lodash/get'
import RelativeTeamLink from '@components/relativeTeamLink'
import keyBy from 'lodash/keyBy'
import Analytics from '@helpers/analytics'
import VisibleFor from '@root/components/visibleFor'
import EnableFor from '@root/components/enableFor'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'

const onNavToCatalogConfigurationPage = ({ configurationState }) => {
  Analytics.track('Click on edit-configuration-link', {
    'Configuration state': configurationState
  })
}

const getCatalogConfigurationAlertBox = ({ isCatalogEnabledForMembers, isRequestLicenseEnabled, requestAccessButtonSelectedAction }) => {
  let description = null

  if (!isCatalogEnabledForMembers) {
    description = 'The app catalog is not visible to all employees. The policies will only be applied when the catalog is live and access requests are submitted.'
  } else if (!isRequestLicenseEnabled) {
    const configurationState = 'Request access button hidden'
    description = <div>The “Request access” button is hidden from the catalog. <RelativeTeamLink to={`/appCatalog/settings`} onClick={() => onNavToCatalogConfigurationPage({ configurationState })}><Link>Enable the button</Link></RelativeTeamLink> to have the policies applied. </div>
  } else if (requestAccessButtonSelectedAction !== APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.TRIGGER_WORKFLOW) {
    const configurationState = 'Request access action is not automation'
    description = <div>
      The “Request access” action is not configured to run policies. The policies below will not be applied to access request. <RelativeTeamLink
        to={`/appCatalog/settings`}
        onClick={() => onNavToCatalogConfigurationPage({ configurationState })}>
        <Link>Edit configuration</Link>
      </RelativeTeamLink>
    </div>
  }

  return description ? <Style.AlertBox><AlertBox type={AlertBoxType.NOTICE} description={description} /></Style.AlertBox> : null
}

const AppCatalogPoliciesTable = () => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const catalogSettings = useSelector(getAppCatalogSettings)
  const isLoadedCatalogSettings = useSelector(isLoadedAppCatalogSettings)
  const { isCatalogEnabledForMembers, isRequestLicenseEnabled, requestLicenseConfig = [] } = catalogSettings
  const configurationFieldsById = keyBy(requestLicenseConfig, 'id')
  const requestAccessButtonSelectedAction = get(configurationFieldsById, ['actionSelection', 'value'])

  const appCatalogPolicies = useSelector(getAppCatalogPoliciesSelector)
  const isLoadingCatalogApps = useSelector(isLoadingCatalogAppsSelector)
  const catalogApps = useSelector(getCatalogAppsSelector)
  const catalogAppsById = keyBy(catalogApps, 'id')

  const policiesType = get(appCatalogPolicies, ['0', 'type'], WORKFLOW_TYPES.appCatalog)

  const loading = useSelector(isLoadingAppCatalogPoliciesSelector)
  const { usersMap, appsMap } = useSelector(getAppCatalogPoliciesResourcesSelector)
  const { idAppsWithDefaultPolicy } = useSelector(getCatalogPoliciesInfo) || EMPTY_OBJECT

  useEffect(() => {
    if (idOrg) {
      dispatch(getAppCatalogPolicies({ idOrg }))
      dispatch(getSingleApp({ idOrg, idApp: TORII_APP_ID }))
      dispatch(getCatalogApps({ idOrg }))
    }
  }, [dispatch, idOrg])

  const tableHeader = <Style.TableHeader>{'Configure automated policies for application access requests'}</Style.TableHeader>

  const addCustomPolicyButton = (<>
    <ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.ACCESS_REQUESTS_POLICIES} />
    <VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
      <Style.ButtonContainer>
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <Button
            type={ButtonType.primary}
            onClick={() => dispatch(toggleAddAppCatalogPolicyPopup({ isOpen: true, isByUser: true }))}
            label='Add new custom policy'
          />
        </EnableFor>
      </Style.ButtonContainer>
    </VisibleFor>
  </>)

  const isPolicyAppInCatalog = (idApp) => Boolean(catalogAppsById[idApp])

  const getPolicyNameBadgeText = ({ type, triggerIdApp }) => isDefaultAppCatalogPolicy({ workflow: { type, triggerIdApp } }) ? 'Default'
    : (!isLoadingCatalogApps && !isPolicyAppInCatalog(triggerIdApp) ? 'App removed from catalog' : undefined)

  const catalogConfigurationAlertBox = isLoadedCatalogSettings ? getCatalogConfigurationAlertBox({ isCatalogEnabledForMembers, isRequestLicenseEnabled, requestAccessButtonSelectedAction }) : null

  const pathPrefix = getWorkflowPathPrefix({ workflowType: policiesType })

  return <div>
    {catalogConfigurationAlertBox}
    <WorkflowsTable
      isAppCatalogPoliciesView
      usersMap={usersMap}
      appsMap={appsMap}
      emptyStateMessage={'No request access policies'}
      workflows={appCatalogPolicies}
      loading={loading}
      tableHeader={tableHeader}
      extraHeaderComponent={addCustomPolicyButton}
      referrer={'App catalog policies list'}
      defaultTriggerIdApps={idAppsWithDefaultPolicy}
      deleteLogicProps={getPolicyDeleteLogicProps({ idOrg, dispatch })}
      pathPrefix={pathPrefix}
      getWorkflowNameBadgeText={getPolicyNameBadgeText}
    />
  </div>
}

export default AppCatalogPoliciesTable
