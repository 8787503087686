import { pick } from 'lodash'
import React, { ReactElement, useState } from 'react'
import { SCOPES } from '@root/constants'
import AppAndAccountSelect from '@components/appAndAccountSelect'
import SelectAccount from '@components/workflows/selectAccount'
import FormGroup from '@components/form/formGroup'
import { DropdownOption } from '../dropdowns/types'
import { AppData, AppWithValue, InputValue } from './types'
import { SelectAccountOption } from '@components/workflows/selectAccount/types'
import { EMPTY_USER_DETAIL_VALUES, USER_DETAIL_CHANGE_ANYTHING_OPTION, USER_DETAIL_CHANGE_OTHER_OPTION } from './consts'
import { FetchTriggerValuesParams } from '@pages/workflow_v2/triggerConfiguration/triggerStepSetup/types'
import Input from '@components/form/input'
import { SimpleDropdown } from './components/simpleDropdown'
import { UserDetailDropdown } from './components/userDetailDropdown'
import { shouldOnlyShowAnythingOptions } from './utils'

interface Props {
  field: {
    id: string
    options?: AppData[]
    values: any[]
    loading?: boolean
  }
  input: {
    onChange: (data: InputValue) => void
    value: InputValue
    disabled?: boolean
  }
  refreshDynamicFieldsOptions: () => void
  fetchTriggerValues: (params?: FetchTriggerValuesParams) => void
}

export const UserDetailChange = ({
  field,
  input,
  refreshDynamicFieldsOptions,
  fetchTriggerValues
}: Props): ReactElement => {
  const { value, disabled } = input
  const { options: appOptions, values, loading } = field ?? {}
  const [
    appValue,
    accountValue,
    userFieldValue,
    changedFromValue,
    changedFromOtherInputValue,
    changedToValue,
    changedToOtherInputValue
  ] = value
  const { onChange } = input

  const [changedFromOtherLocalValue, setChangedFromOtherLocalValue] = useState(changedFromOtherInputValue?.value)
  const [changedToOtherLocalValue, setChangedToOtherLocalValue] = useState(changedToOtherInputValue?.value)

  const handleChange = ({
    app = appValue.value,
    account = accountValue.value,
    userField = userFieldValue.value,
    changedFrom = changedFromValue.value,
    changedFromOtherValue = changedFromOtherLocalValue,
    changedTo = changedToValue.value,
    changedToOtherValue = changedToOtherLocalValue,
    shouldLoadUserDetailOptions = false
  }: {
    app?: AppWithValue | null
    account?: SelectAccountOption | null
    userField?: DropdownOption | null
    changedFrom?: DropdownOption | null
    changedFromOtherValue?: string | null
    changedTo?: DropdownOption | null
    changedToOtherValue?: string | null
    shouldLoadUserDetailOptions?: boolean
  }) => {
    const updatedValue: InputValue = [
      { id: 'app', value: app },
      { id: 'account', value: account },
      { id: 'userField', value: userField },
      { id: 'changedFrom', value: changedFrom },
      { id: 'changedFromOtherValue', value: changedFromOtherValue },
      { id: 'changedTo', value: changedTo },
      { id: 'changedToOtherValue', value: changedToOtherValue }
    ]
    onChange(updatedValue)
    shouldLoadUserDetailOptions && fetchTriggerValues({
      field: {
        id: field.id,
        value: updatedValue
      }
    })
  }

  const selectedAppData = (appValue?.value && appOptions?.length)
    ? appOptions.find(option => option.id === appValue.value!.id)
    : null

  const handleAppChange = (app: AppWithValue | null) => {
    if (selectedAppData?.id === app?.id) {
      return
    }

    const {
      accountOptions,
      userFieldOptions
    } = appOptions?.find(option => option.id === app?.id) ?? {}

    const account = accountOptions?.length === 1
      ? accountOptions[0]
      : null

    const userField = userFieldOptions?.length === 1
      ? userFieldOptions[0]
      : null

    handleChange({
      app: app ? pick(app, ['id', 'name', 'url', 'imageUrl', 'value']) : null,
      account,
      userField,
      ...EMPTY_USER_DETAIL_VALUES,
      shouldLoadUserDetailOptions: Boolean(account && userField)
    })
  }

  const handleAccountChange = (account: SelectAccountOption) => {
    if (account?.value === accountValue.value?.value) {
      return
    }

    handleChange({
      account: account,
      ...EMPTY_USER_DETAIL_VALUES,
      shouldLoadUserDetailOptions: Boolean(userFieldValue?.value)
    })
  }

  const handleUserFieldChange = (userField: DropdownOption) => {
    const shouldPopulateAnyOption = shouldOnlyShowAnythingOptions(userField)
    const userDetailsValues: {
      changedFrom: DropdownOption | null
      changedTo: DropdownOption | null
    } = {
      ...(shouldPopulateAnyOption ? {
        changedFrom: USER_DETAIL_CHANGE_ANYTHING_OPTION,
        changedTo: USER_DETAIL_CHANGE_ANYTHING_OPTION
      } : EMPTY_USER_DETAIL_VALUES)
    }

    handleChange({
      userField,
      ...userDetailsValues,
      shouldLoadUserDetailOptions: true
    })
  }

  const handleChangedFromChange = (changedFrom: DropdownOption) => {
    setChangedFromOtherLocalValue('')
    handleChange({ changedFrom, changedFromOtherValue: '' })
  }

  const handleChangedToChange = (changedTo: DropdownOption) => {
    setChangedToOtherLocalValue('')
    handleChange({ changedTo, changedToOtherValue: '' })
  }

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldKey: 'changedFromOtherValue' | 'changedToOtherValue') => {
    const inputValue: string = e.target.value
    const setStateFunction = fieldKey === 'changedFromOtherValue' ? setChangedFromOtherLocalValue : setChangedToOtherLocalValue
    setStateFunction(inputValue)
  }

  const handelOtherInputBlur = (e: React.ChangeEvent<HTMLInputElement>, fieldKey: 'changedFromOtherValue' | 'changedToOtherValue') => {
    const inputValue: string = e.target.value
    const originalValue = fieldKey === 'changedFromOtherValue' ? changedFromOtherInputValue.value : changedToOtherInputValue.value

    if (inputValue !== originalValue) {
      handleChange({ [fieldKey]: inputValue })
    }
  }

  const shouldShowAccountSelector = Boolean(selectedAppData)
  const shouldShowUserFieldSelector = shouldShowAccountSelector && Boolean(accountValue.value)
  const shouldShowUserDetailSelectors = shouldShowUserFieldSelector && Boolean(userFieldValue.value)
  const shouldShowChangedFromOtherInput = shouldShowUserDetailSelectors && changedFromValue.value?.value === USER_DETAIL_CHANGE_OTHER_OPTION.value
  const shouldShowChangedToOtherInput = shouldShowUserDetailSelectors && changedToValue.value?.value === USER_DETAIL_CHANGE_OTHER_OPTION.value

  const getUserDetailDropdownSharedProps = () => {
    const selectedUserFieldValue = userFieldValue.value
    if (!selectedUserFieldValue) {
      return undefined
    }

    const currentUserFieldKey = selectedUserFieldValue.value.key
    const currentUserDetailOptions = currentUserFieldKey && values?.[currentUserFieldKey]
    return {
      options: currentUserDetailOptions ?? [],
      disabled,
      loading,
      selectedUserField: selectedUserFieldValue
    }
  }

  return <>
    <FormGroup
      label='Select an app where the change happened'
    >
      <AppAndAccountSelect
        selectedApp={appValue.value}
        options={appOptions}
        onChange={handleAppChange}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
      />
    </FormGroup>
    {shouldShowAccountSelector && <FormGroup
      label='Select an account'
    >
      <SelectAccount
        options={selectedAppData!.accountOptions}
        selectedOptionValue={accountValue.value}
        onChange={handleAccountChange}
        idApp={selectedAppData!.id}
        disabled={disabled}
        refreshDynamicFieldsOptions={refreshDynamicFieldsOptions}
      />
    </FormGroup>}
    {shouldShowUserFieldSelector && <FormGroup
      label='Select an attribute that changed'
    >
      <SimpleDropdown
        options={selectedAppData!.userFieldOptions}
        value={userFieldValue.value}
        onChange={handleUserFieldChange}
        disabled={disabled}
      />
    </FormGroup>}
    {shouldShowUserDetailSelectors && <>
      <FormGroup
        label='Changed from'
      >
        <UserDetailDropdown
          value={changedFromValue.value}
          onChange={handleChangedFromChange}
          innerSelectKey={changedFromValue.id}
          {...getUserDetailDropdownSharedProps()!}
        />
      </FormGroup>
      {shouldShowChangedFromOtherInput && <FormGroup label='Enter value'>
        <Input
          value={changedFromOtherLocalValue ?? undefined}
          onChange={e => handleOtherInputChange(e, 'changedFromOtherValue')}
          onBlur={e => handelOtherInputBlur(e, 'changedFromOtherValue')}
          disabled={disabled}
        />
      </FormGroup>}
      <FormGroup
        label='Changed to'
      >
        <UserDetailDropdown
          value={changedToValue.value}
          onChange={handleChangedToChange}
          innerSelectKey={changedToValue.id}
          {...getUserDetailDropdownSharedProps()!}
        />
      </FormGroup>
      {shouldShowChangedToOtherInput && <FormGroup label='Enter value'>
        <Input
          value={changedToOtherLocalValue ?? undefined}
          onChange={e => handleOtherInputChange(e, 'changedToOtherValue')}
          onBlur={e => handelOtherInputBlur(e, 'changedToOtherValue')}
          disabled={disabled}
        />
      </FormGroup>}
    </>}
  </>
}
