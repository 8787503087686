import View from './view'
import { connect } from 'react-redux'
import { getSingleApp, unarchiveTransaction } from '@actions/'
import { TRANSACTION_MAPPING_STATUS } from '@root/constants'
const mapDispatchToProps = {
  unarchiveTransaction,
  getSingleApp
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id, idOrg, idApp } = ownProps
  const mappingStatus = idApp ? TRANSACTION_MAPPING_STATUS.MAPPED : TRANSACTION_MAPPING_STATUS.UNKNOWN
  return {
    ...stateProps,
    unarchiveTransaction: async () => {
      await dispatchProps.unarchiveTransaction({ idOrg, idTransaction: id, mappingStatus })
      idApp && await dispatchProps.getSingleApp({ idOrg, idApp })
    },
    ...ownProps
  }
}

export default connect(null, mapDispatchToProps, mergeProps)(View)
