import get from 'lodash/get'
import {
  GET_ME,
  AUTH_STATE,
  EXTENSION_INSTALLED
} from '@root/constants'
import { Reducer } from 'redux'
import { MeActions, MeState } from '@reducers/me/types'

const initialState = {
  authState: AUTH_STATE.BEFORE_AUTH,
  isInstalledExtension: Boolean(window.__TORII_EXTENSION_INSTALLED)
}

const meReducer: Reducer<MeState, MeActions> = (state = initialState, action) => {
  if (action.type.endsWith('_FAILED')) {
    const statusCode = get(action, 'payload.statusCode')
    if (statusCode === 401 && state.authState === AUTH_STATE.AUTHENTICATED) {
      return {
        ...state,
        authState: AUTH_STATE.NO_LONGER_AUTHENTICATED
      }
    }
  }

  switch (action.type) {
    case `${GET_ME}_PENDING`: {
      return {
        ...state,
        authState: AUTH_STATE.PENDING
      }
    }
    case `${GET_ME}_RESPONSE`: {
      return {
        ...state,
        ...action.payload,
        authState: AUTH_STATE.AUTHENTICATED
      }
    }
    case `${GET_ME}_FAILED`: {
      return {
        ...state,
        authState: AUTH_STATE.NOT_AUTHENTICATED
      }
    }
    case EXTENSION_INSTALLED: {
      return {
        ...state,
        isInstalledExtension: action.payload.isInstalled
      }
    }
    default: {
      return state
    }
  }
}

export default meReducer
