import React, { useEffect } from 'react'
import WorkflowsTable from '../workflowsTable'
import {
  getAppCatalogRequestNewAppPolicies,
  getCatalogApps,
  getSingleApp
} from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppCatalogSettings,
  getIdOrg,
  isLoadedAppCatalogSettings
} from '@selectors/org'
import {
  getAppCatalogNewAppPoliciesResourcesSelector,
  getAppCatalogRequestNewAppPoliciesSelector,
  isLoadingAppCatalogRequestNewAppPoliciesSelector
} from '@selectors/workflows'
import { APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS, SCOPES, TORII_APP_ID, WORKFLOW_TRIGGER_TYPES, WORKFLOW_TYPES } from '@root/constants'
import { Link, AlertBox, AlertBoxType } from '@toriihq/design-system'
import * as Style from './style'
import { getPolicyDeleteLogicProps, getWorkflowPathPrefix } from '@lenses/workflows'
import get from 'lodash/get'
import RelativeTeamLink from '@components/relativeTeamLink'
import keyBy from 'lodash/keyBy'
import Analytics from '@helpers/analytics'
import VisibleFor from '@root/components/visibleFor'
import EnableFor from '@root/components/enableFor'
import CreateNewWorkflowButton from '@root/pages/createWorkflow/createWorkflowButton'
import { WORKFLOW_TYPE } from '@root/shared/types'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'

const onNavToCatalogConfigurationPage = ({ configurationState }) => {
  Analytics.track('Click on edit-configuration-link', {
    'Configuration state': configurationState
  })
}

const getCatalogConfigurationAlertBox = ({ isCatalogEnabledForMembers, isRequestNewAppEnabled, requestNewAppButtonSelectedAction }) => {
  let description = null

  if (!isCatalogEnabledForMembers) {
    description = 'The app catalog is not visible to all employees. The policies will only be applied when the catalog is live and new app requests are submitted.'
  } else if (!isRequestNewAppEnabled) {
    const configurationState = 'Request new app button hidden'
    description = <div>The “Request new app" button is hidden from the catalog. <RelativeTeamLink to={`/appCatalog/settings`} onClick={() => onNavToCatalogConfigurationPage({ configurationState })}><Link>Enable the button</Link></RelativeTeamLink> to have the policies applied. </div>
  } else if (requestNewAppButtonSelectedAction !== APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.TRIGGER_WORKFLOW) {
    const configurationState = 'Request new app action is not automation'
    description = <div>
      The “Request new app” action is not configured to run policies. The policies below will not be applied to new app request. <RelativeTeamLink
        to={`/appCatalog/settings`}
        onClick={() => onNavToCatalogConfigurationPage({ configurationState })}>
        <Link>Edit configuration</Link>
      </RelativeTeamLink>
    </div>
  }

  return description ? <Style.AlertBox><AlertBox type={AlertBoxType.NOTICE} description={description} /></Style.AlertBox> : null
}

const AppCatalogRequestNewAppPoliciesTable = () => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const catalogSettings = useSelector(getAppCatalogSettings)
  const isLoadedCatalogSettings = useSelector(isLoadedAppCatalogSettings)
  const { isCatalogEnabledForMembers, isRequestNewAppEnabled, requestNewAppConfig = [] } = catalogSettings
  const configurationFieldsById = keyBy(requestNewAppConfig, 'id')
  const requestNewAppButtonSelectedAction = get(configurationFieldsById, ['actionSelection', 'value'])

  const requestNewAppPolicies = useSelector(getAppCatalogRequestNewAppPoliciesSelector)

  const policiesType = get(requestNewAppPolicies, ['0', 'type'], WORKFLOW_TYPES.appCatalog)

  const loading = useSelector(isLoadingAppCatalogRequestNewAppPoliciesSelector)
  const { usersMap } = useSelector(getAppCatalogNewAppPoliciesResourcesSelector)

  useEffect(() => {
    if (idOrg) {
      dispatch(getAppCatalogRequestNewAppPolicies({ idOrg }))
      dispatch(getSingleApp({ idOrg, idApp: TORII_APP_ID }))
      dispatch(getCatalogApps({ idOrg }))
    }
  }, [dispatch, idOrg])

  const tableHeader = <Style.TableHeader>{'Configure automated policies for new app requests'}</Style.TableHeader>

  const addNewPolicyButton = (<>
    <ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.NEW_APP_REQUEST_POLICIES} />
    <VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
      <Style.ButtonContainer>
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <CreateNewWorkflowButton
            idOrg={idOrg}
            workflowType={WORKFLOW_TYPE.appCatalog}
            triggerType={WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP}
            triggerTypeIdApp={TORII_APP_ID}
            label={'Add new policy'}
          />
        </EnableFor>
      </Style.ButtonContainer>
    </VisibleFor>
  </>)

  const catalogConfigurationAlertBox = isLoadedCatalogSettings ? getCatalogConfigurationAlertBox({ isCatalogEnabledForMembers, isRequestNewAppEnabled, requestNewAppButtonSelectedAction }) : null

  const pathPrefix = getWorkflowPathPrefix({ workflowType: policiesType, triggerType: WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP })

  return <div>
    {catalogConfigurationAlertBox}
    <WorkflowsTable
      isRequestNewAppPoliciesView
      usersMap={usersMap}
      emptyStateMessage={'No request new app policies'}
      workflows={requestNewAppPolicies}
      loading={loading}
      tableHeader={tableHeader}
      extraHeaderComponent={addNewPolicyButton}
      referrer={'App catalog policies list'}
      deleteLogicProps={getPolicyDeleteLogicProps({ idOrg, dispatch, triggerType: WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP })}
      pathPrefix={pathPrefix}
    />
  </div>
}

export default AppCatalogRequestNewAppPoliciesTable
