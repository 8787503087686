import React from 'react'
import UploadFilePopup, { UPLOAD_FILE_POPUPS } from '@components/popups/uploadFilePopup'
import { UPLOAD_TYPES } from '@root/constants'
import { useDispatch } from 'react-redux'
import { createSamlConfiguration } from '@actions/'
import { UPLOAD_FILE_POST_PROCESS_STATUS } from '@components/uploadFileArea'

const UNPROCESSABLE_ENTITY = 422

const UploadSamlFilePopup = () => {
  const VALID_FILE_TYPES = ['text/xml', 'application/xml']

  const dispatch = useDispatch()

  const onSuccessfulUpload = async (idOrg, idUpload) => {
    try {
      const result = await dispatch(createSamlConfiguration({ idOrg, idUpload }))
      return { status: result.enabled ? UPLOAD_FILE_POST_PROCESS_STATUS.SUCCESS : UPLOAD_FILE_POST_PROCESS_STATUS.ERROR }
    } catch (error) {
      const statusCode = JSON.parse(error.message).statusCode

      if (statusCode === UNPROCESSABLE_ENTITY) {
        return { status: UPLOAD_FILE_POST_PROCESS_STATUS.PARSING_ERROR }
      }

      window.Sentry && window.Sentry.captureException(error)
      return { status: UPLOAD_FILE_POST_PROCESS_STATUS.ERROR }
    }
  }

  return (
    <UploadFilePopup
      popupName={UPLOAD_FILE_POPUPS.UPLOAD_SAML_FILE_POPUP}
      popupHeader='Configure SSO'
      popupSubHeader='Setting up SSO login for your Torii account'
      popupInstructions='Please upload the SAML metadata XML file provided by your identity provider'
      validFileTypes={VALID_FILE_TYPES}
      fileDescription={'XML SAML configuration'}
      uploadedFileType={UPLOAD_TYPES.SAML_CONFIGURATION}
      postUploadAction={onSuccessfulUpload}
      successMessage={(
        <div>
          Your account is now configured to login both with email/password and SSO. Please test your login using your
          SSO provider.<br />
          After you have verified a successful login, you can disable email/password logins and make SSO the only login
          option by enforcing it
        </div>
      )}
      uploadEventType='SAML metadata XML file'
    />
  )
}

export default UploadSamlFilePopup
