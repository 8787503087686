import View from './view'
import { connect } from 'react-redux'
import { getProductUpdates, updateUserLastSeenProductUpdatesTime } from '@actions/'
import { getProductUpdates as getProductUpdatesSelector } from '@selectors/productUpdates'

const mapStateToProps = (state) => {
  const productUpdates = getProductUpdatesSelector(state)
  const hasProductUpdates = productUpdates.length > 0
  return {
    hasProductUpdates
  }
}

const mapDispatchToProps = {
  getProductUpdates,
  updateUserLastSeenProductUpdatesTime
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
