import View from './view'
import { connect } from 'react-redux'
import { updateUserPreferences, addTableView, deleteTableView, editTableView, applyCustomFilterOnTable } from '@actions/'
import { getTablesViews, getTablesViewsOptions, getTablesViewsUsers } from '@selectors/ui'
import { getTableSelectedViewID, getUserTablePreferences } from '../../../lenses/table'
import { getSelf } from '@selectors/me'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants'

const mapStateToProps = (state, ownProps) => {
  const { tableKey } = ownProps
  const userTablePreferences = getUserTablePreferences(state, tableKey)

  const self = getSelf(state)
  const views = getTablesViews(state)[tableKey] || EMPTY_OBJECT
  const viewOptions = getTablesViewsOptions(state)[tableKey] || EMPTY_ARRAY

  const selectedViewId = getTableSelectedViewID(state, tableKey)

  const usersById = getTablesViewsUsers(state)

  return {
    views,
    viewOptions,
    selectedViewId,
    userTablePreferences,
    self,
    usersById
  }
}

const mapDispatchToProps = {
  updateUserPreferences,
  addTableView,
  deleteTableView,
  editTableView,
  applyCustomFilterOnTable
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
