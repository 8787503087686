import React, { Component } from 'react'
import { Select, AsyncSelect, CreatableSelect, createFilter, SelectProps, AsyncProps, CreatableProps } from '@toriihq/design-system'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'

interface V1Props {
  clearable?: boolean
  disabled?: boolean
  searchable?: boolean
  openOnFocus?: boolean
  cache?: boolean
  noResultsText?: string
  loadingPlaceholder?: string
  backspaceRemoves?: boolean
  multi?: boolean
  removeSelected?: boolean
  closeOnSelect?: boolean
  maxItemsToShow?: number
  innerSelectKey?: string
  value?: any
  defaultValue?: any
  options?: any[]
  optionsWithGroups?: { label: string, options: any[] }[]
  valueKey?: string
  autoFocus?: boolean
  labelKey?: string
  loadOptions?: <T>(inputValue: string, callback: (options: T[]) => void) => Promise<{ options?: T[], optionsWithGroups?: { label: string, options: T[] }[] }>
}

const optionMapper = (option: Record<string, any>, labelKey: string, valueKey: string) => {
  if ('options' in option) {
    const { options, ...rest } = option
    return { ...rest, _options: options, label: option[labelKey] ?? option.label, value: option[valueKey] ?? option.value }
  }

  return { ...option, label: option[labelKey] ?? option.label, value: option[valueKey] ?? option.value }
}

export const extractOptionsFromProps = ({
  options,
  optionsWithGroups,
  labelKey = 'label',
  valueKey = 'value'
}: { options?: V1Props['options'], optionsWithGroups?: V1Props['optionsWithGroups'], labelKey?: V1Props['labelKey'], valueKey?: V1Props['valueKey'] }) => {
  if (options) {
    return options.map(option => optionMapper(option, labelKey, valueKey))
  }

  if (optionsWithGroups) {
    return optionsWithGroups.map(group => ({
      ...group,
      options: group.options.map(option => optionMapper(option, labelKey, valueKey))
    }))
  }

  return undefined
}

function mapOldPropsToNewProps (props: V1Props & SelectProps) {
  const { options, optionsWithGroups, clearable, disabled, searchable, openOnFocus, cache, noResultsText, loadingPlaceholder, backspaceRemoves, multi, removeSelected, closeOnSelect, maxItemsToShow, onChange, loadOptions, formatOptionLabel, getOptionLabel, getOptionValue, isOptionDisabled, isOptionSelected, filterOption, ...rest } = props
  const mappedProps: SelectProps & AsyncProps<any, any, any> = {
    isClearable: clearable,
    isSearchable: searchable,
    isDisabled: disabled,
    openMenuOnFocus: openOnFocus,
    backspaceRemovesValue: backspaceRemoves,
    isMulti: multi,
    hideSelectedOptions: removeSelected,
    closeMenuOnSelect: closeOnSelect,
    cacheOptions: cache,
    loadingMessage: () => loadingPlaceholder,
    noOptionsMessage: ({ inputValue }: { inputValue: string }) => inputValue ? noResultsText : 'Type to search...',
    pageSize: maxItemsToShow,
    options: extractOptionsFromProps({ options, optionsWithGroups: optionsWithGroups, labelKey: props.labelKey, valueKey: props.valueKey }),
    onChange: (newValue, meta) => {
      /*
        this is a workaround for the issue where all values are removed when removing a value in multi select.
        this is happening when multiple values are selected, then the select is closed (unmounted), opened again, and one of the values is removed.
       */
      if (multi) {
        if (meta.action === 'remove-value') {
          const workaroundValues = (rest.value ?? []).filter(selectedOption => selectedOption[props.valueKey ?? 'value'] !== meta.removedValue[props.valueKey ?? 'value'])
          return onChange?.(workaroundValues, meta)
        }
      }
      if ('_options' in (newValue ?? {})) {
        const { _options, ...rest } = newValue
        const option = { ...rest, options: _options }
        onChange?.(option, meta)
      } else {
        onChange?.(newValue, meta)
      }
    },
    ...rest
  }

  if (formatOptionLabel) {
    mappedProps.formatOptionLabel = (option, formatOptionLabelMeta) => {
      if ('_options' in option) {
        const { _options, ...rest } = option
        return formatOptionLabel?.({ ...rest, options: _options }, formatOptionLabelMeta)
      } else {
        return formatOptionLabel?.(option, formatOptionLabelMeta)
      }
    }
  }

  if (getOptionLabel) {
    mappedProps.getOptionLabel = option => {
      if ('_options' in option) {
        const { _options, ...rest } = option
        return getOptionLabel?.({ ...rest, options: _options })
      } else {
        return getOptionLabel?.(option)
      }
    }
  }

  if (getOptionValue) {
    mappedProps.getOptionValue = option => {
      if ('_options' in option) {
        const { _options, ...rest } = option
        return getOptionValue?.({ ...rest, options: _options })
      } else {
        return getOptionValue?.(option)
      }
    }
  }

  if (isOptionDisabled) {
    mappedProps.isOptionDisabled = (option, selectValue) => {
      if ('_options' in option) {
        const { _options, ...rest } = option
        return isOptionDisabled?.({ ...rest, options: _options }, selectValue)
      } else {
        return isOptionDisabled?.(option, selectValue)
      }
    }
  }

  if (isOptionSelected) {
    mappedProps.isOptionSelected = (option, selectValue) => {
      if ('_options' in option) {
        const { _options, ...rest } = option
        return isOptionSelected?.({ ...rest, options: _options }, selectValue)
      } else {
        return isOptionSelected?.(option, selectValue)
      }
    }
  }

  if (filterOption) {
    mappedProps.filterOption = (option, inputValue: string) => {
      if ('_options' in option) {
        const { _options, ...rest } = option
        return filterOption?.({ ...rest, options: _options }, inputValue)
      } else {
        return filterOption?.(option, inputValue)
      }
    }
  }

  return mappedProps
}

const extractActualSelectedObject = (value: any | undefined, options: any[] | undefined, valueKey: string) => {
  if (!value || !options || isPlainObject(value)) {
    return value
  }

  if (isArray(value)) {
    const isItemsNotObjects = Boolean(value.filter(v => v && !isPlainObject(v)).length)
    if (isItemsNotObjects) {
      return value.map(v => extractActualSelectedObject(v, options, valueKey))
    }

    return value
  }

  return options.find(o => o[valueKey] === value) || value
}

const ToriiSelect = (props: V1Props & SelectProps) => {
  const value = extractActualSelectedObject(props.value ?? props.defaultValue, props.options, props.valueKey ?? 'value')
  return (
    <Select
      key={props.innerSelectKey ?? JSON.stringify(props.options)}
      filterOption={createFilter({
        ignoreAccents: true,
        ignoreCase: true
      })}
      {...mapOldPropsToNewProps(props)}
      value={value}
    />
  )
}

type AsyncSelectProps = V1Props & AsyncProps<any, any, any>
export class ToriiSelectAsync extends Component<AsyncSelectProps> {
  render () {
    return (
      <AsyncSelect
        key={this.props.innerSelectKey ?? JSON.stringify(this.props.options)}
        {...mapOldPropsToNewProps(this.props)}
        loadOptions={async (inputValue, callback) => {
          const { options, optionsWithGroups } = await this.props.loadOptions?.(inputValue, callback) ?? { options: [] }
          return extractOptionsFromProps({ options, optionsWithGroups, labelKey: this.props.labelKey, valueKey: this.props.valueKey }) ?? []
        }}
      />
    )
  }
}

type SelectCreatableProps = V1Props & CreatableProps<any, any, any>
export class ToriiSelectCreatable extends Component<SelectCreatableProps> {
  render () {
    const value = extractActualSelectedObject(this.props.value, this.props.options, this.props.valueKey ?? 'value')
    return (
      <CreatableSelect
        key={this.props.innerSelectKey ?? JSON.stringify(this.props.options)}
        {...mapOldPropsToNewProps(this.props)}
        value={value}
      />
    )
  }
}

export default ToriiSelect
