import {
  FORGOT
} from '@root/constants'
import ParseValidation from '@helpers/parse-validation'

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {}
}

const forgotReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FORGOT}_PENDING`
    : {
      return {
        ...state,
        loading: true
      }
    }

    case `${FORGOT}_FAILED`:
    case `${FORGOT}_RESPONSE`: {
      const validation = ParseValidation(action.payload)
      return {
        ...state,
        loading: false,
        success: !action.error,
        error: validation.error,
        hasError: validation.hasError,
        help: validation.help
      }
    }
    default: {
      return state
    }
  }
}

export default forgotReducer
