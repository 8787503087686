import React, { ReactElement } from 'react'
import WizardPopup from '../wizardPopup'
import {
  createStepsConfig,
  revokeStepsConfig
} from '@components/popups/configureMergeUsersRulesPopup/stepConfig/config'
import { SharedProps, SharedState, WIZARD_STEPS } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import { getMergeUsersRules } from '@selectors/org'
import get from 'lodash/get'
import Analytics from '@components/popups/configureMergeUsersRulesPopup/analytics'
import { useSelector } from 'react-redux'
import { getSuccessfullyConnectedNativeIntegrations } from '@selectors/services'
import keyBy from 'lodash/keyBy'
import {
  APP_RULES_BY_ID_APP,
  AVAILABLE_APP_RULES
} from '@components/popups/configureMergeUsersRulesPopup/stepConfig/selectRule/ruleSelector/constants'
import { getSelectedRuleIdApp } from '@components/popups/configureMergeUsersRulesPopup/utils/utils'

export type Props = {
    isOpen?: boolean
    onCancel: () => void,
    sharedProps: SharedProps,
    onSubmitSuccess: () => void
}

export const ConfigureMergeUsersRulesPopup = ({
  isOpen = true,
  onCancel,
  sharedProps,
  onSubmitSuccess
}: Props): ReactElement => {
  const { idOrg } = sharedProps

  const onCancelClick = () => {
    Analytics.onCancelClick({ idOrg })
    onCancel()
  }

  const mergeUsersRules = useSelector(getMergeUsersRules)
  const orgRule = mergeUsersRules[0]

  const connectedIntegrations = useSelector(getSuccessfullyConnectedNativeIntegrations)
  const connectedIntegrationsByIdApp = keyBy(connectedIntegrations, 'idApp')

  const availableRulesWithConnectionData = AVAILABLE_APP_RULES.map(rule => {
    return {
      ...rule,
      isEnabled: connectedIntegrationsByIdApp[rule.idApp]
    }
  })

  const idMergeUsersRule = get(orgRule, 'id')
  const selectedRuleIdAppFromDB = get(orgRule, ['config', 'idApp'])
  const selectedRuleIdApp = getSelectedRuleIdApp({ rulesDataByIdApp: keyBy(availableRulesWithConnectionData, 'idApp'), selectedRuleIdApp: selectedRuleIdAppFromDB })
  const selectedRuleUserFieldKey = get(APP_RULES_BY_ID_APP, [selectedRuleIdApp as number, 'mergeUsersFieldKey'])

  return (
    <WizardPopup<SharedState, SharedProps, WIZARD_STEPS>
      name={'configureMergeUsersRules'}
      isOpen={isOpen}
      stepsConfig={sharedProps.isRevokeMode ? revokeStepsConfig : createStepsConfig}
      initialSharedState={{
        selectedRuleIdApp,
        selectedRuleUserFieldKey,
        idMergeUsersRule,
        availableRules: availableRulesWithConnectionData
      }}
      sharedProps={sharedProps}
      onCancel={onCancelClick}
      onClose={onCancel}
      onSubmitSuccess={onSubmitSuccess}
    />
  )
}
