import KeyOpValueVerticalFilter from '@components/filters/keyOpValueVerticalFilter'
import { BranchFilter as BranchFilterType, BRANCH_FILTER_ENTITY } from '@shared/workflows/types'
import UserVerticalFilter from '@components/filters/userVerticalFilter'
import { UserKeyOption } from '@components/filters/userVerticalFilter/types'
import { UserEntityCriterion } from '@shared/filters/userFilters/types'
import VerticalFilterWrapper from '@components/filters/verticalFilterWrapper'
import React, { ReactElement } from 'react'
import { isNil } from 'lodash'
import Select from '@components/select'
import {
  EntityOption,
  KeyOptionsByEntity,
  KeyOptionValueByEntity,
  OnChangeBranchFilterProps
} from '@components/filters/ifElseBranchFilters/types'
import { getValue } from '@root/shared/utils'

interface Props {
  filter: BranchFilterType
  disabled: boolean
  fieldsAutoFocus: boolean
  onRemove: (any) => void
  entityOptions?: EntityOption[]
  keyOptionsByEntityType?: KeyOptionsByEntity
  onChange: ({ key, newValue }: Pick<OnChangeBranchFilterProps, 'key' | 'newValue'>) => void,
  keyOptionValuesByEntityType?: KeyOptionValueByEntity
}

const BranchFilter = ({
  filter: branchFilter,
  disabled,
  onRemove,
  onChange,
  fieldsAutoFocus,
  entityOptions = [],
  keyOptionsByEntityType,
  keyOptionValuesByEntityType
}: Props): ReactElement => {
  const { entity, filter } = branchFilter
  const hasEntityValue = !isNil(entity)
  const shouldRenderEntitySelector = entityOptions.length > 1 || !hasEntityValue

  let filterComponent = <></>

  if (hasEntityValue) {
    const keyOptionValues = keyOptionValuesByEntityType?.[entity!]

    switch (entity) {
      case BRANCH_FILTER_ENTITY.CONTRACT:
      case BRANCH_FILTER_ENTITY.APP:
        const filterKey = getValue(filter.key)
        const appOptionValues = keyOptionValues?.[filterKey]

        filterComponent = <KeyOpValueVerticalFilter
          filter={filter}
          optionsKey={keyOptionsByEntityType?.[entity]}
          optionsValues={appOptionValues}
          onChange={(filter) => onChange({ newValue: filter })}
          disabled={disabled}
          fieldsAutoFocus={fieldsAutoFocus}
          allowCustomValuesInMultiDropdown
        />
        break
      case BRANCH_FILTER_ENTITY.USER:
        filterComponent = <UserVerticalFilter
          criterion={filter as UserEntityCriterion}
          optionsKey={keyOptionsByEntityType?.[entity] as UserKeyOption[] | undefined}
          optionsValuesPerKey={keyOptionValues}
          onCriterionChange={(criterion) => onChange({ newValue: criterion })}
          disabled={disabled}
        />
        break
    }
  }

  return (
    <VerticalFilterWrapper
      disabled={disabled}
      onRemove={onRemove}
    >
      <>
        {shouldRenderEntitySelector && <Select
          autoFocus={!hasEntityValue && fieldsAutoFocus}
          // className={CSS.select.toString()}
          options={entityOptions}
          value={entity}
          onChange={(entity) => onChange({ key: 'entity', newValue: entity })}
          clearable={false}
          searchable
          openOnFocus
          disabled={disabled}
        />}
        {filterComponent}
      </>
    </VerticalFilterWrapper>
  )
}

export default BranchFilter
