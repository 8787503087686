import View from './view'
import { connect } from 'react-redux'
import { toggleContractPopup } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { getContractDetailsFromAI } from '@actions/ai'
import AccessControl from '@lenses/accessControl'
import { FEATURES } from '@root/shared/features'

const mapStateToProps = state => {
  const { isOpen, idContract, idApp, initialValues, isDuplicated, isAppMatchingMode, onSuccessCB } = state.ui.contractPopup
  const { id: idOrg } = getCurrentOrg(state)
  const hasContractAIInPlan = AccessControl.isFeatureEnabledInPlan({ feature: FEATURES.RENEWALS.COMPONENTS.CONTRACT_AI, state })

  return {
    isOpen,
    idContract,
    idApp,
    initialValues,
    idOrg,
    isDuplicated,
    hasContractAIInPlan,
    isAppMatchingMode,
    onSuccessCB
  }
}

const mapDispatchToProps = {
  toggleContractPopup,
  getContractDetailsFromAI
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleContractPopup({ isOpen: false, idContract: stateProps.idContract, initialValues: stateProps.initialValues, buttonLabel: 'Cancel' })
  },
  close () {
    dispatchProps.toggleContractPopup({ isOpen: false, initialValues: stateProps.initialValues, buttonLabel: 'Close' })
  },
  onSuccess ({ contractUpdatedName }) {
    dispatchProps.toggleContractPopup({ isOpen: false, isByUser: false })
    stateProps.onSuccessCB && stateProps.onSuccessCB(contractUpdatedName)
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
