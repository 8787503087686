import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TABLES } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { getOffboardingInProgressUsers, updateUsersLifecycleStatus } from '@actions/'
import omit from 'lodash/omit'
import { getUserPreferences } from '@selectors/ui'
import { getOffboardingInProgressUsersSelector } from '@selectors/offboarding'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const tableInfo = TABLES.offboardingInprogressTable

  const userPreferences = getUserPreferences(state)
  const { defaultSort = [] } = userPreferences[TABLES.offboardingInprogressTable.key] || {}

  const inProgressUsers = getOffboardingInProgressUsersSelector(state)
  const numberOfUsersThatNeedsAttention = inProgressUsers.users.filter(user => user.needsAttention).length

  return {
    idOrg,
    tableInfo,
    numberOfUsersThatNeedsAttention,
    defaultSort,
    ...inProgressUsers
  }
}

const mapDispatchToProps = {
  getOffboardingInProgressUsers,
  updateUsersLifecycleStatus
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg } = stateProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUsersLifecycleStatus']),
    cancelOffboarding: (idUsers) =>
      dispatchProps.updateUsersLifecycleStatus({ idOrg, idUsers, lifecycleStatus: USER_LIFECYCLE_STATUS.ACTIVE }),
    ...ownProps,
    markAsDone: (idUsers) =>
      dispatchProps.updateUsersLifecycleStatus({ idOrg, idUsers, lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDED })
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
