import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import {
  Spacer,
  AppIcon,
  Grid,
  Col,
  RowWithoutMargin,
  Tooltip,
  Button,
  ButtonType,
  ButtonSize,
  H3,
  Stack,
  Subtitle2
} from '@toriihq/design-system'
import texts from '../../shared/style/texts'
import Placeholder from '../../components/placeholder'
import colors from '../../shared/style/colors'
import FormFieldMapping from '../../components/workflows/formField'
import { Form, Field } from 'react-final-form'
import mapKeys from 'lodash/mapKeys'
import { WORKFLOW_ACTION_TYPES } from '../../constants'
import SafeTemplate from '@components/safeTemplate'
import LogoWithWhiteText from '@media/logo_with_text_white.svg'
import SubmitButton from '@components/submitButton'
import NotFoundImg from '@media/404.svg'
import CheckmarkImg from '@media/checkmark.svg'
import FormGroup from '@components/form/formGroup'

const CSS = {
  intro: css(texts.headers.small, {
    backgroundColor: 'rgba(255,255,255,0.15)',
    padding: '20px',
    whiteSpace: 'pre-line',
    borderRadius: '4px'
  }),
  fullwidth: css({
    maxWidth: 'unset !important'
  }),
  left: css({
    color: colors.white,
    background: colors.blue,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '65px 25px',
    minHeight: '100vh',
    height: '100%',
    [`@media(max-width: 1024px)`]: {
      height: 'auto',
      minHeight: 0
    }
  }),
  right: css({
    padding: '80px 0',
    marginLeft: '160px',
    maxWidth: '500px',
    width: '100%',
    [`@media(max-width: 1024px)`]: {
      padding: '25px',
      marginLeft: 0
    }
  }),
  appTitle: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px'
  }),
  appTitleText: css(texts.headers.large, {
    color: colors.black
  }),
  textAndIconBox: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
  }),
  footer: css(texts.subheading, {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '30px',
    left: '25px',
    color: colors.white,
    '> img': { marginLeft: '5px', height: '13px' }
  }),
  userIcon: css({
    marginBottom: '15px'
  }),
  description: css(texts.body, {
    marginTop: '16px'
  })
}

const FORM_FIELD_PREFIX = 'idField-'

const FormField = ({ field, input, index, ...props }) => {
  const labelPrefix = `${index + 1}. `
  const hasLabel = field.formQuestion || field.name || field.label
  const label = hasLabel ? `${labelPrefix}${field.formQuestion || field.name || field.label}` : null
  const component = FormFieldMapping.mapping[field.type] && FormFieldMapping.mapping[field.type].component({ field, input, ...props })

  return (
    <Stack gap='space-100'>
      <Subtitle2 color='primary'>{label}</Subtitle2>
      <FormGroup>
        {component}
      </FormGroup>
    </Stack>
  )
}
class WorkflowFormPage extends React.Component {
  componentDidMount () {
    const { getWorkflowFormInfoFromServer, idActionExe, idOrg, isPreviewMode } = this.props
    !isPreviewMode && getWorkflowFormInfoFromServer({ idOrg, idActionExe })
  }

  renderTextAndIcon (element, header) {
    return <div {...CSS.textAndIconBox}>
      {element}
      <H3>{header}</H3>
    </div>
  }

  renderAuthError () {
    return this.renderTextAndIcon(
      <img alt='Not found' src={NotFoundImg} width='110' />,
      'You don\'t have the required permissions')
  }

  renderThanksMessage () {
    return this.renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      'That\'s awesome, thanks!')
  }

  renderDoneMessage () {
    return this.renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      'This form was already filled, thanks!')
  }

  onSubmit = async (submitValuesObj) => {
    const formFieldsValues = mapKeys(submitValuesObj, function (value, key) {
      return key.replace(FORM_FIELD_PREFIX, '')
    })
    const { submitWorkflowFormInfo } = this.props
    return submitWorkflowFormInfo({ formFieldsValues })
  }

  cantHelp = async () => {
    const { submitWorkflowFormInfo } = this.props
    return submitWorkflowFormInfo({ formFieldsValues: {}, rejectForm: true })
  }

  renderForm ({ app, formFields }) {
    const { isPreviewMode, idActionExe, id, secret } = this.props
    return <div>
      <div {...CSS.appTitle} >
        <Spacer right={'space-250'}>
          <AppIcon size='large' appName={app.name} appImageUrl={app.imageUrl} />
        </Spacer>
        <span {...CSS.appTitleText}> {app.name} - App details </span>
      </div>

      <Form
        onSubmit={this.onSubmit}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              {formFields.filter(Boolean).map((field, index) => {
                const typeMapping = FormFieldMapping.mapping[field.type] || {}
                const validate = typeMapping.validators
                return (
                  <Field
                    index={index}
                    validate={validate}
                    key={field.idField}
                    name={`${FORM_FIELD_PREFIX}${field.idField}`}
                    field={field}
                    component={FormField}
                    format={typeMapping.format}
                    parse={typeMapping.parse}
                    idActionExe={idActionExe}
                    id={id}
                    secret={secret}
                  />
                )
              })}
            </div>
            <div {...CSS.actions}>
              <div>
                <Tooltip
                  placement='top'
                  label='Submission is not allowed in a preview mode'
                  hide={!isPreviewMode}
                >
                  <SubmitButton form={formProps} disabled={isPreviewMode} label='Submit' />
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  placement='top'
                  label='Submission is not allowed in a preview mode'
                  hide={!isPreviewMode}
                >
                  <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.cantHelp} disabled={isPreviewMode} label='I can’t help you with these questions' />
                </Tooltip>
              </div>
            </div>
          </form>
        }}
      />
    </div>
  }

  renderContractForm ({ contract, formFields }) {
    const { isPreviewMode, idActionExe, id, secret } = this.props
    return <div>
      <div {...CSS.appTitle} >
        <span {...CSS.appTitleText}> {contract.name} - Contract details </span>
      </div>

      <Form
        onSubmit={this.onSubmit}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              {formFields.filter(Boolean).map((field, index) => {
                const typeMapping = FormFieldMapping.mapping[field.type] || {}
                const validate = typeMapping.validators
                return (
                  <Field
                    index={index}
                    validate={validate}
                    key={field.id}
                    name={`${FORM_FIELD_PREFIX}${field.id}`}
                    field={field}
                    component={FormField}
                    format={typeMapping.format}
                    parse={typeMapping.parse}
                    idActionExe={idActionExe}
                    id={id}
                    secret={secret}
                  />
                )
              })}
            </div>
            <div {...CSS.actions}>
              <div>
                <Tooltip
                  placement='top'
                  label='Submission is not allowed in a preview mode'
                  hide={!isPreviewMode}
                >
                  <SubmitButton form={formProps} disabled={isPreviewMode} label='Submit' />
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  placement='top'
                  label='Submission is not allowed in a preview mode'
                  hide={!isPreviewMode}
                >
                  <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.cantHelp} disabled={isPreviewMode} label='I can’t help you with these questions' />
                </Tooltip>
              </div>
            </div>
          </form>
        }}
      />
    </div>
  }

  render () {
    const {
      loading,
      isAuthError,
      workflowFormInfo,
      submitSuccess,
      isPreviewMode
    } = this.props
    const formFields = workflowFormInfo.formFields || []
    const { app = {}, contract = {}, actionType } = workflowFormInfo
    const formIntro = workflowFormInfo.formIntro || ''
    const showFormFields = !isAuthError && !submitSuccess && !workflowFormInfo.isCompleted
    const showThanksMessage = !isAuthError && submitSuccess
    const showDoneMessage = !isAuthError && Boolean(workflowFormInfo.isCompleted)

    return (
      <Grid {...CSS.fullwidth}>
        <RowWithoutMargin nogutter>
          <Col sm={3}>
            <div {...CSS.left}>
              {formIntro
                ? isPreviewMode
                  ? <div {...CSS.intro}><SafeTemplate template={formIntro} data={workflowFormInfo} /></div>
                  : <div {...CSS.intro} dangerouslySetInnerHTML={{ __html: formIntro }} />
                : null
              }
              <footer {...CSS.footer}>
                Powered by <img alt='Torii Logo' src={LogoWithWhiteText} />
              </footer>
            </div>
          </Col>
          <Col sm={9}>
            <div {...CSS.right}>
              <Placeholder loading={loading} type='text' rows={4} style={{ height: '100px', width: '50px' }}>
                {isAuthError && this.renderAuthError()}
                {showFormFields && (actionType === WORKFLOW_ACTION_TYPES.SEND_CONTRACT_FORM ? this.renderContractForm({ contract, formFields }) : this.renderForm({ app, formFields }))}
                {showThanksMessage && this.renderThanksMessage()}
                {showDoneMessage && this.renderDoneMessage()}
              </Placeholder>
            </div>
          </Col>
        </RowWithoutMargin>
      </Grid>
    )
  }
}

WorkflowFormPage.propTypes = {
  loading: PropTypes.bool,
  workflowFormInfo: PropTypes.object.isRequired,
  isAuthError: PropTypes.bool
}

WorkflowFormPage.defaultProps = {
  workflowFormInfo: {}
}

export default WorkflowFormPage
