import { noop } from 'lodash'
import React, { ReactElement } from 'react'
import { Button, ButtonType, EmptyState } from '@toriihq/design-system'
import RelativeTeamLink from '@components/relativeTeamLink'
import emptyImage from '@media/cage.svg'
import { EmptyStateContainer } from './styles'

interface Props {
  isAppCatalogPoliciesView: boolean
  isRequestNewAppPoliciesView: boolean
}

export const WorkflowEmptyState = ({
  isAppCatalogPoliciesView,
  isRequestNewAppPoliciesView
} : Props): ReactElement => {
  const entityName = isAppCatalogPoliciesView || isRequestNewAppPoliciesView
    ? 'policy' : 'workflow'
  const title = `The ${entityName} was not found`
  const description = `The ${entityName} does not exist. It might have been deleted.`

  const { buttonPath, buttonLabel } = isAppCatalogPoliciesView
    ? {
      buttonPath: '/appCatalog/accessPolicies',
      buttonLabel: 'Go to access request policies list'
    }
    : isRequestNewAppPoliciesView
      ? {
        buttonPath: '/appCatalog/newAppPolicies',
        buttonLabel: 'Go to new app request policies'
      }
      : {
        buttonPath: '/workflows',
        buttonLabel: 'Go to workflows list'
      }

  return <EmptyStateContainer>
    <EmptyState
      image={<img src={emptyImage} alt={title} />}
      title={title}
      description={description}
      buttons={[
        <RelativeTeamLink to={buttonPath}>
          <Button
            type={ButtonType.primary}
            label={buttonLabel}
            onClick={noop}
          />
        </RelativeTeamLink>
      ]}
    />
  </EmptyStateContainer>
}
