import View from './view'
import { connect } from 'react-redux'
import { toggleAddUser, createUserReporting, searchUsersByEmail } from '@actions/'
import { getSelf } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const { email: creator } = getSelf(state)
  return {
    idOrg,
    creator
  }
}
const mapDispatchToProps = {
  searchUsersByEmail,
  toggleAddUser,
  createUserReporting
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
