import View from './view'
import { connect } from 'react-redux'
import { getSelf } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'
import { withRouter } from 'react-router-dom'
import { getUnsafeMode, getViewOnlyMode } from '@selectors/ui'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, ownProps) => {
  const { scopes = [], allowForToriiAdmin = false, disabled } = ownProps
  const currentUser = getSelf(state)
  const currentProvisionedOrg = getCurrentOrg(state)
  const isUserFromProvisionedOrg = (currentUser.idOrg === currentProvisionedOrg.id)
  const unsafeMode = getUnsafeMode(state)
  const viewOnlyMode = getViewOnlyMode(state)

  const isEnableForScopes = !disabled && !viewOnlyMode && (((isEmpty(scopes) || scopes.some(scope => (currentUser.scopes || []).includes(scope))) && isUserFromProvisionedOrg) ||
    (allowForToriiAdmin && currentUser.isToriiAdmin) ||
    (unsafeMode && currentUser.isToriiAdmin))

  return {
    isEnableForScopes
  }
}

export default withRouter(connect(mapStateToProps)(View))
