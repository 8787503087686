import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  toggleManuallyRunWorkflowPopup,
  toggleWorkflowAuditLogs,
  toggleAddAppCatalogPolicyPopup,
  toggleEditWorkflowTagsPopup
} from '@actions/'
import { getTriggersByType } from '@selectors/workflows'
import get from 'lodash/get'
import { isFeatureEnabled } from '@lenses/features'
import { duplicateWorkflow, getWorkflow } from '@actions/workflows'
import { WORKFLOW_TRIGGER_TYPES } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  return ({
    idOrg,
    triggerTypes: getTriggersByType(state),
    isWorkflowEditHistoryEnabled: isFeatureEnabled(state, 'workflowEditHistory')
  })
}

const mapDispatchToProps = {
  duplicateWorkflow,
  toggleManuallyRunWorkflowPopup,
  toggleWorkflowAuditLogs,
  toggleAddAppCatalogPolicyPopup,
  toggleEditWorkflowTagsPopup,
  getWorkflow
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  duplicateWorkflowClicked: async ({ idWorkflow, name, triggerType, buttonLocation }) => {
    const { idOrg, triggerTypes } = stateProps
    const { history } = ownProps

    const triggerName = get(triggerTypes, [triggerType, 'uiConfig', 'label'], '')

    const { duplicateWorkflow } = dispatchProps
    const { workflow } = await duplicateWorkflow({ idOrg, idWorkflow, name, triggerName, buttonLocation })

    const basePath = `/team/${idOrg}`
    const pagePathByTriggerType = workflow.triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP ? 'appCatalog/newAppPolicy' : 'workflow'
    const workflowPagePath = `${basePath}/${pagePathByTriggerType}/${workflow.id}`
    history.push(workflowPagePath)
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
