import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import { ImportUsersPopup } from './types'
import { DeleteImportedUsersPopupState } from '@reducers/ui/types'

export const getImportUsersPopupProperties = createSelector(
  (state: { ui: { importUsersPopup: ImportUsersPopup }}) => state.ui.importUsersPopup as ImportUsersPopup,
  identity
)

export const getDeleteImportedUsersPopupProperties = createSelector(
  (state: { ui: { deleteImportedUsersPopup: DeleteImportedUsersPopupState }}) => state.ui.deleteImportedUsersPopup as DeleteImportedUsersPopupState,
  identity
)
