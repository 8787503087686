import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { TRANSACTION_MAPPING_STATUS } from '../../../constants'
import Select from '../../select'
import Input from '../../form/input'
import FormGroup from '../../form/formGroup'
import { mappingStatusOptions, operatorOptions, TRANSACTION_MATCHING_RULE_SEARCH_APP_LIMIT } from './constants'
import SelectApps from '../../selectApps'
import { Field, Form } from 'react-final-form'
import noop from 'lodash/noop'
import pick from 'lodash/pick'
import debounce from 'lodash/debounce'
import { fontWeight } from '@root/shared/style/sizes'

const mandatoryFieldValidator = value => {
  return !value ? 'Mandatory field' : undefined
}
const LARGE_SCREEN_WIDTH = '1444px'

const CSS = {
  main: css({
    ':first-child': {
      marginTop: 0
    },
    ':last-child': {
      marginBottom: 0
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.transparent,
    padding: 0
  }),
  inputs: css({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: `-20px`,
    gap: '20px'
  }),
  space: css({
    width: '60px'
  }),
  then: css({
    marginBottom: '20px',
    alignSelf: 'center',
    fontWeight: fontWeight.semiBold
  }),
  disabled: css({
    opacity: 0.5
  }),
  input: css({
    width: '160px',
    marginBottom: '5px',
    backgroundColor: colors.white
  }),
  noBorderInput: ({ borderColor = colors.transparent }) => css({
    border: `1px solid ${borderColor}`,
    backgroundColor: colors.white,
    [`@media(min-width: ${LARGE_SCREEN_WIDTH})`]: {
      width: '250px'
    },
    textOverflow: 'ellipsis',
    fontWeight: fontWeight.semiBold,
    color: colors.grey1,
    ':hover': {
      backgroundColor: colors.white
    },
    ':focus': {
      backgroundColor: colors.white
    }
  }),
  buttonOverlay: css({
    all: 'unset'
  })
}

const checkIfHasValue = value => (value !== undefined && value !== null && value !== '')

class TransactionMatchingRule extends React.Component {
  state = {
    term: this.props.rule.term,
    isOpen: false
  }

  divRef = React.createRef()

  componentDidUpdate (prevProps) {
    const { rule } = this.props
    if (prevProps.rule.term !== rule.term) {
      this.setState({ term: this.props.rule.term })
    }
  }
  onChangeOperator = (op) => {
    const { onChange } = this.props
    const opValue = (op && op.value) || null
    const rule = { ...this.props.rule, op: opValue }

    onChange && onChange(rule)
  }

  onChangeRule = debounce(term => {
    const { onChange } = this.props
    const rule = { ...this.props.rule, term }

    onChange && onChange(rule)
  }, 750)

  onChangeTerm = (term) => {
    this.setState({ term })
    this.onChangeRule(term)
  }

  onChangeMappingStatus = (mappingStatus) => {
    const { onChange, updateErrorsStatus } = this.props
    const mappingValue = (mappingStatus && mappingStatus.value) || null
    const prevMappingStatus = this.props.rule.mappingStatus
    const prevApp = this.props.rule.app
    const app = mappingValue === TRANSACTION_MAPPING_STATUS.IGNORED || prevMappingStatus !== mappingValue ? null : prevApp
    const rule = { ...this.props.rule, mappingStatus: mappingValue, app }
    if (mappingValue === TRANSACTION_MAPPING_STATUS.IGNORED) {
      updateErrorsStatus && updateErrorsStatus({ id: rule.id, error: false, field: 'app' })
    }

    onChange && onChange(rule)
  }

  onChangeApp = (app) => {
    const { onChange } = this.props
    const rule = { ...this.props.rule, app: pick(app, ['id', 'name', 'imageUrl']) }

    onChange && onChange(rule)
  }

  opComponent = ({ input, meta, rule, disabled }) => {
    const hasValue = checkIfHasValue(rule.op)
    return (
      <FormGroup error={meta.touched && meta.error}>
        <button {...CSS.buttonOverlay} onClick={noop}>
          <Select
            options={operatorOptions}
            clearable={false}
            searchable
            borderless={this.props.removeFieldsBorders}
            {...input}
            openOnFocus
            onChange={selection => {
              input.onChange(selection ? selection.value : null)
              this.onChangeOperator(selection)
            }}
            autoFocus={!hasValue}
            disabled={disabled}
          />
        </button>
      </FormGroup>
    )
  }

  renderOp () {
    const { rule, disabled } = this.props

    return <Field
      label=''
      name='op'
      component={this.opComponent}
      rule={rule}
      disabled={disabled}
      validate={mandatoryFieldValidator}
    />
  }

  termComponent = ({ input, disabled, rule, meta }) => {
    const { updateErrorsStatus } = this.props
    const error = meta.touched && meta.error

    const noBorderStyle = CSS.noBorderInput({ borderColor: error ? colors.red : colors.transparent })
    return <FormGroup error={error}>
      <Input
        {...input}
        autoFocus={this.props.autofocusTerm}
        maxLength={800}
        overrideStyle={{
          ...css(CSS.input, this.props.removeFieldsBorders ? noBorderStyle : {})
        }}
        onChange={(e) => {
          input.onChange(e)
          const value = e.currentTarget.value
          const hasValue = checkIfHasValue(value)
          this.onChangeTerm(hasValue ? value : null)
        }}
        onBlur={(e) => {
          input.onBlur(e)
          updateErrorsStatus && updateErrorsStatus({ id: rule.id, error: !meta.valid, field: 'term' })
          this.setState({ termFieldTouched: true })
        }}
        type='text'
        disabled={disabled}
      />
    </FormGroup>
  }

  renderTerm () {
    const { rule, disabled } = this.props
    return <Field
      label=''
      name='term'
      component={this.termComponent}
      rule={rule}
      disabled={disabled}
      validate={mandatoryFieldValidator}
    />
  }

  renderThen () {
    const { disabled } = this.props

    return <div {...css(CSS.then, disabled && CSS.disabled)}>then</div>
  }

  mappingStatusComponent = ({ input, meta, rule, disabled }) => {
    return <FormGroup error={meta.touched && meta.error}>
      <button {...CSS.buttonOverlay} onClick={noop}>
        <Select
          options={mappingStatusOptions}
          clearable={false}
          searchable
          {...input}
          openOnFocus
          onChange={selection => {
            input.onChange(selection ? selection.value : null)
            this.onChangeMappingStatus(selection)
          }}
          borderless={this.props.removeFieldsBorders}
          disabled={disabled}
        />
      </button>
    </FormGroup>
  }

  renderMappingStatus () {
    const { rule, disabled, disabledAllAfterThen } = this.props

    return <Field
      label=''
      name='mappingStatus'
      component={this.mappingStatusComponent}
      rule={rule}
      disabled={disabled || disabledAllAfterThen}
      validate={mandatoryFieldValidator}
    />
  }

  appComponent = ({ input, meta, rule, disabled }) => {
    const { updateErrorsStatus } = this.props
    const error = (meta.touched) && meta.error
    return <FormGroup error={error}>
      <button {...CSS.buttonOverlay} onClick={noop}>
        <SelectApps
          placeholder='Select...'
          clearable={false}
          disableHiddenApps
          searchable
          {...input}
          openOnFocus
          autoFocus={!rule.app && !!rule.term}
          onChange={selection => {
            input.onChange(selection)
            this.onChangeApp(selection)
            updateErrorsStatus && updateErrorsStatus({ id: rule.id, error: !selection, field: 'app' })
          }}
          onBlur={(e) => {
            input.onBlur(e)
            this.setState({ appFieldTouched: true })
            updateErrorsStatus && updateErrorsStatus({ id: rule.id, error: !meta.valid, field: 'app' })
          }}
          disabled={disabled}
          limit={TRANSACTION_MATCHING_RULE_SEARCH_APP_LIMIT}
          borderless={this.props.removeFieldsBorders}
          menuWidth={250}
        />
      </button>
    </FormGroup>
  }

  renderApp () {
    const { rule, disabled, disabledAllAfterThen } = this.props

    if (!rule.mappingStatus || rule.mappingStatus === TRANSACTION_MAPPING_STATUS.IGNORED) {
      return null
    }

    return <Field
      label=''
      name='app'
      component={this.appComponent}
      rule={rule}
      disabled={disabled || disabledAllAfterThen}
      validate={mandatoryFieldValidator}
    />
  }

  render () {
    const { rule, reset, spacesBetweenFields } = this.props
    return (
      <div {...CSS.main}>
        <Form
          initialValuesEqual={() => !reset}
          initialValues={rule}
          onSubmit={noop}
          render={(formProps) => {
            const { handleSubmit } = formProps
            return <form onSubmit={handleSubmit}>
              <div {...css(CSS.inputs, spacesBetweenFields && { gap: 60 })}>
                {this.renderOp()}
                {this.renderTerm()}
                {this.renderThen()}
                {this.renderMappingStatus()}
                {this.renderApp()}
              </div>
            </form>
          }}
        />
      </div>
    )
  }
}

TransactionMatchingRule.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.any.isRequired,
    op: PropTypes.string,
    term: PropTypes.string,
    mappingStatus: PropTypes.string,
    app: PropTypes.shape({
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  }),
  onChange: PropTypes.func,
  autofocusTerm: PropTypes.bool,
  removeFieldsBorders: PropTypes.bool,
  spacesBetweenFields: PropTypes.bool
}

TransactionMatchingRule.defaultProps = {
  rule: { id: 'default' }
}

export default TransactionMatchingRule
