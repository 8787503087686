import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import {
  getActionsConfigByType,
  getWorkflowActionNodeSelector
} from '@selectors/workflows'
import { useParamSelector } from '@shared/utils'
import { IdWorkflowNode } from '@shared/workflows/types'
import { ActionConfiguration } from '../types'
import { createActionConfiguration } from '../utils'

interface Props {
  idAction?: IdWorkflowNode | null
}

export const useActionConfiguration = ({ idAction }: Props): ActionConfiguration | null => {
  const { idWorkflow } = useParams()

  const actionsConfigByType = useSelector(getActionsConfigByType)

  const {
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView
  } = useWorkflowEditorContext()

  const actionNode = useParamSelector(getWorkflowActionNodeSelector, {
    idWorkflow,
    idAction,
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView
  })

  if (!idAction || !actionNode) {
    return null
  }

  return createActionConfiguration(idAction, actionNode, actionsConfigByType)
}
