import { EMAIL_NOTIFICATION_FREQUENCY, SCOPES } from '../../constants'

export const USER_SETTINGS_TYPES = {
  'EMAIL_NOTIFICATIONS': {
    id: 'EMAIL_NOTIFICATIONS',
    title: 'My email notifications'
  }
}

const userSettingsFrequencyOptions = [
  {
    label: 'Disabled',
    value: EMAIL_NOTIFICATION_FREQUENCY.DISABLED
  },
  {
    label: 'Daily digest',
    value: EMAIL_NOTIFICATION_FREQUENCY.DAILY
  },
  {
    label: 'Weekly digest',
    value: EMAIL_NOTIFICATION_FREQUENCY.WEEKLY
  }
]

const userSettingsWithWeeklyOnlyOptions = [
  {
    label: 'Disabled',
    value: EMAIL_NOTIFICATION_FREQUENCY.DISABLED
  },
  {
    label: 'Weekly digest',
    value: EMAIL_NOTIFICATION_FREQUENCY.ONLY_WEEKLY
  }
]

const userSettingsBasicOptions = [
  {
    label: 'Disabled',
    value: EMAIL_NOTIFICATION_FREQUENCY.DISABLED
  },
  {
    label: 'Enabled',
    value: EMAIL_NOTIFICATION_FREQUENCY.DAILY
  }
]

export const USER_SETTINGS = [
  {
    id: 'emailNotification_newApp',
    name: 'New application detected',
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsFrequencyOptions,
    scopes: [SCOPES.BASIC_ADMIN]
  },
  {
    id: 'emailNotification_renewal',
    name: 'Upcoming renewal',
    description: 'Emails will be sent 2 months, 1 month, 2 weeks, and 1 week before the contract end date. For contracts with an auto-renewal clause, these emails will be sent before the cancellation deadline instead.',
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsBasicOptions,
    scopes: [SCOPES.BASIC_ADMIN, SCOPES.APP_OWNER_READ]
  },
  {
    id: 'emailNotification_contractsWithoutApp',
    name: 'Unmatched contracts',
    description: 'A weekly digest email will be sent notifying on the number of unmatched contracts that require matching to apps',
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsWithWeeklyOnlyOptions,
    scopes: [SCOPES.CONTRACTS_READ]
  },
  {
    id: 'emailNotification_deletedOwner',
    name: 'Owner of an application or contract left the organization',
    description: `Email will be sent when an owner of an application or contract is no longer active or present in your user lifecycle source of truth`,
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsFrequencyOptions,
    scopes: [SCOPES.BASIC_ADMIN]
  },
  {
    id: 'emailNotification_offboardingUser',
    name: 'Employee left the organization',
    description: `Email will be sent when an employee is no longer active or present in your user lifecycle source of truth`,
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsFrequencyOptions,
    scopes: [SCOPES.OFFBOARDING_READ]
  },
  {
    id: 'emailNotification_offboardingStatus',
    name: 'Offboarding status',
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsWithWeeklyOnlyOptions,
    scopes: [SCOPES.OFFBOARDING_READ]
  },
  {
    id: 'emailNotification_integrationStatus',
    name: 'Integration connected',
    description: `Email will be sent when a new integration is successfully connected`,
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsBasicOptions,
    scopes: [SCOPES.BASIC_ADMIN]
  },
  {
    id: 'emailNotification_failedIntegrations',
    name: 'Failed Integrations',
    description: `A weekly digest email will be sent notifying on the number of failed integrations that require attention`,
    type: USER_SETTINGS_TYPES.EMAIL_NOTIFICATIONS.id,
    options: userSettingsWithWeeklyOnlyOptions,
    scopes: [SCOPES.BASIC_ADMIN]
  }
]
