import analytics from '@root/helpers/analytics'

const Analytics = <TStepNames>(wizardName: string) => {
  return {
    onClose: (stepName: TStepNames) => {
      analytics.track(`Close ${wizardName} wizard`, { 'Step name': stepName })
    },
    onCancel: (stepName: TStepNames) => {
      analytics.track(`Cancel ${wizardName} wizard`, { 'Step name': stepName })
    },
    onMovingToStep: (fromStepName: TStepNames, toStepName: TStepNames) => {
      analytics.track(`Moving between steps on ${wizardName} wizard`, { 'From step name': fromStepName, 'To step name': toStepName })
    }
  }
}

export default Analytics
