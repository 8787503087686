import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getUser, deprecatedGetApps, getAppsOfUserFromServer, getUserOffboardingAuditLogs, updateUserLifecycleStatus, getOffboardingStatusOfUser } from '@actions/'
import { getLazyLoadedUsers, getLoadingLazyLoadedUsers, isUsersLoadingES } from '@selectors/users'
import { getUsedApps, isAppsLoading, shouldShowNewBadge } from '@selectors/apps'
import { getAppsOfUserES, isLoadingAppUsers } from '@selectors/appUsers'
import { isNew } from '@lenses/apps'
import { APP_USER_STATUS, USER_LIFECYCLE_STATUS } from '@shared/types'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { getOffboardingUserStatus } from '@selectors/offboarding'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idUser = parseInt(ownProps.match.params.idUser, 10)
  const users = getLazyLoadedUsers(state)
  const loading = getLoadingLazyLoadedUsers(state)
  const user = get(users, [idUser], {})
  const startedByUser = get(user, ['offboardingStartedByUser'])

  const isOffboarding = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
  const isOffboarded = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDED
  const { apps, loadingApps } = getAppsByUserStatus(state, user, isOffboarding, isOffboarded)

  return {
    idOrg,
    idUser,
    apps,
    user,
    startedByUser,
    loadingBasicInfo: loading && !user.id,
    loadingApps
  }
}

const mapDispatchToProps = {
  deprecatedGetApps,
  getAppsOfUserFromServer,
  updateUserLifecycleStatus,
  getUserOffboardingAuditLogs,
  getOffboardingStatusOfUser,
  getUser
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idUser } = stateProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUserLifecycleStatus']),
    updateUserLifecycleStatus: (lifecycleStatus) =>
      dispatchProps.updateUserLifecycleStatus({ idOrg, idUser, lifecycleStatus }),
    ...ownProps
  }
}

const isPrimaryOwner = (app, idUser) => {
  const isPrimaryOwner = app.owner === idUser || Number(app.primaryOwner) === idUser
  return isPrimaryOwner
}

const isOwner = (app, idUser) => {
  const isOwner = isPrimaryOwner(app, idUser) || app.appOwners?.some(idOwner => Number(idOwner) === idUser)
  return isOwner
}

const getAppsByUserStatus = (state, user, isOffboarding, isOffboarded) => {
  const loadingAppUsers = isLoadingAppUsers(state)
  const usersLoading = isUsersLoadingES(state)
  const appsLoading = isAppsLoading(state)

  if (isOffboarding || isOffboarded) {
    const offboardingUserStatus = getOffboardingUserStatus(state)

    return { apps: offboardingUserStatus.apps, loadingApps: offboardingUserStatus.loading }
  }

  const idUser = user && user.id
  const allAppsES = getAppsOfUserES(state) || {}
  const allAppsOfUser = allAppsES[idUser] || []
  const loadingApps = appsLoading || usersLoading || loadingAppUsers
  let appsOfUser = allAppsOfUser
    .map(appUser => {
      return {
        ...appUser,
        isNew: shouldShowNewBadge(state) && isNew(appUser),
        isOwner: user?.allIdUsers?.some(idUser => isOwner(appUser, idUser)),
        isPrimaryOwner: user?.allIdUsers?.some(idUser => isPrimaryOwner(appUser, idUser)),
        score: appUser.score || 0,
        sources: appUser.sources || [],
        lastVisitTime: appUser.lastVisitTime,
        id: `${appUser.idApp}_${appUser.idAppAccount}`
      }
    })

  const allApps = getUsedApps(state)
  appsOfUser = appsOfUser.filter(appUser => appUser.status === APP_USER_STATUS.ACTIVE)
  const additionalOwnedApps = allApps.filter(app => user.allIdUsers?.some(idUser => isOwner(app, idUser)) && !appsOfUser.find(appUser => appUser.idApp === app.id))
  const cleandAdditionalOwnedApps = additionalOwnedApps.map(app => ({
    ...app,
    score: 0,
    isOwner: true,
    isPrimaryOwner: user.allIdUsers?.some(idUser => isPrimaryOwner(app, idUser)),
    sources: [],
    idApp: app.id,
    isNew: isNew(app),
    originEmail: user.allUsers?.find(({ idUser }) => idUser === user.allIdUsers?.find(idUser => isOwner(app, idUser)))?.originEmail ?? null,
    lastVisitTime: null
  }))
  appsOfUser = appsOfUser.concat(cleandAdditionalOwnedApps)

  return { apps: appsOfUser, loadingApps }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
