import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getLicenseTrendsChart, setLicenseTrendFilterAction } from '@actions/'
import { getLicenseTrends, getLicenseFilterData, getAllIdLicenses, getLicenseTrendFilters } from '@selectors/licenses'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const licenseTrends = getLicenseTrends(state)

  const licenseFilterData = getLicenseFilterData(state)
  const allLicenseFilterDataIds = getAllIdLicenses(state)

  const { selectedUsageIdsFilter, selectedLicenseIdsFilter, selectedTimePeriod } = getLicenseTrendFilters(state)

  return {
    idOrg,
    loading: licenseTrends.loading,
    licenseFilterData,
    licenses: licenseTrends.licenses,
    allLicenseFilterDataIds,
    selectedUsageIdsFilter,
    selectedLicenseIdsFilter,
    selectedTimePeriod
  }
}

const mapDispatchToProps = {
  getLicenseTrendsChart,
  setLicenseTrendFilterAction
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
