import {
  GET_ALL_PLANS
} from '@root/constants'

const initialState = {
  loading: false,
  plans: []
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ALL_PLANS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_ALL_PLANS}_FAILED`: {
      return {
        ...state,
        loading: false,
        plans: []
      }
    }
    case `${GET_ALL_PLANS}_RESPONSE`: {
      const { plans } = action.payload
      return {
        ...state,
        loading: false,
        plans
      }
    }
    default: {
      return state
    }
  }
}

export default settingsReducer
