import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Table from '@components/table'
import UserDetails from '../../userDetails'
import { getDisplayName } from '@lenses/users'
import { getInactiveLicensesColumnsOptions } from '@selectors/userDetails'
import * as Style from './style'
import { getUserDetailsFields, getAppLicenseRecommendationUserList, toggleConfigureExecuteActionOnUsers, getAppUserLicenseFieldValues } from '@actions/'
import { getUserTablesInfo } from '@selectors/tables'
import { MANUAL_WORKFLOW_EXECUTION_FLOW, NO_APP_ACCOUNT_ID, SCOPES, TABLES } from '@root/constants'
import getColumnByFieldType from '../../table/columnFieldTypes'
import { getAppLicenseRecommendationUserList as getAppLicenseRecommendationUserListSelector, getAppUserLicenseFieldValue } from '@selectors/licenses'
import { startCase, get, debounce, mapKeys, uniq, isEmpty } from 'lodash'
import { sortStringFromArray } from '@shared/utils'
import { getUserPreferences } from '@selectors/ui'
import EnableFor from '../../enableFor'
import { generateDrillDownTexts, getActionButtonText } from '../generateTextForType'
import config from '@root/config'
import Analytics from './analytics'
import { ITEMS_PER_PAGE } from '../../../constants'
import { getFormattedDate } from '@lenses/utils'
import SalesforceUsageExplanationPopup from '../salesforceUsageExplanationPopup/index'
import { Button, ButtonType, ButtonSize, AlertBox, AlertBoxType, Spacer } from '@toriihq/design-system'
import useEffectOnce from '@shared/hooks/useEffectOnce'

const DEFAULT_FIELDS_TO_FETCH = ['firstName', 'lastName', 'idUser', 'photoUrl', 'license', 'email', 'originEmail', 'userDetails', 'idAppAccount', 'docId', 'role', 'externalStatus', 'lifecycleStatus', 'isExternal', 'isDeletedInIdentitySources', 'allLicenseNames', 'originIdUser']

const SALESFORCE_ID_APP = 258

const LicenseRecommendationUserList = withRouter((props) => {
  const { idOrg = parseInt(props.match.params.idOrg, 10), recommendation = {}, appName, loadingRecommendation, match } = props
  const { id: idRecommendation = parseInt(match.params.idRecommendation, 10), idApp = parseInt(match.params.idApp, 10), type: recommendationType } = recommendation
  const dispatch = useDispatch()

  const configurableColumnsOptions = useSelector(getInactiveLicensesColumnsOptions)
  const tableInfo = useSelector(getUserTablesInfo)[TABLES.licenseRecommendationsTable.key]
  const { hasLoaded, loading: loadingUsers, loadingMore, licenses, total } = useSelector(getAppLicenseRecommendationUserListSelector)
  const { columnsConfiguration, dynamicColumnsInfo } = tableInfo
  const userPreferences = useSelector(getUserPreferences)
  const { defaultSort = [] } = userPreferences[TABLES.licenseRecommendationsTable.key] || {}
  const fieldValues = useSelector(getAppUserLicenseFieldValue)

  const [sort, setSort] = useState(defaultSort)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isSalesforceUsageExplanationModalOpen, setSalesforceUsageExplanationModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getUserDetailsFields({ idOrg }))
  }, [dispatch, idOrg])

  const fetchData = debounce(({ reset = false, filters = tableInfo.filters || [], q = undefined, limit = ITEMS_PER_PAGE, newSort = sort }) => {
    const selectedFields = Object.keys(columnsConfiguration)
    const dynamicFields = selectedFields.filter(field => !Object.keys(TABLES.licenseRecommendationsTable.preDefinedColumnsMapping).includes(field))
    dispatch(getAppLicenseRecommendationUserList({ idOrg, idRecommendation, reset, limit, offset: reset ? 0 : licenses.length, sort: sortStringFromArray(newSort), idApp, fields: DEFAULT_FIELDS_TO_FETCH.concat(dynamicFields), filters, q }))
  }, 500)

  useEffectOnce(() => {
    fetchData({ reset: true })
    fetchFieldValues()
  })

  const APPS_TO_SHOW_USAGE_POPUP = {
    [SALESFORCE_ID_APP]: {
      licenseDowngrade: {
        renderUsageExplanation: () => {
          return <div {...Style.UsageExplanation}>
            <Button type={ButtonType.compact} size={ButtonSize.small}
              onClick={() => toggleSalesforceUsageExplanationPopup({ isOpen: true, appName, recommendationType })}
              label='Learn how Torii maps usage to license tiers'
            />
          </div>
        },
        renderPopup: () => <SalesforceUsageExplanationPopup isOpen={isSalesforceUsageExplanationModalOpen} onClose={() => toggleSalesforceUsageExplanationPopup({ isOpen: false, appName, recommendationType })} />
      }
    }
  }

  const getNameColumn = () => ({
    Header: 'User',
    id: 'fullName',
    minWidth: 220,
    maxWidth: 250,
    Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, lifecycleStatus, isDeletedInIdentitySources } }) => {
      return <UserDetails
        firstName={firstName}
        lastName={lastName}
        email={email}
        isExternal={isExternal}
        idUser={idUser}
        photoUrl={photoUrl}
        lifecycleStatus={lifecycleStatus}
        isDeletedInIdentitySources={isDeletedInIdentitySources}
        showPastUserBadge
      />
    },
    textValue: ({ user: { firstName, lastName, email } }) => getDisplayName({ firstName, lastName, email })
  })

  const onSortedChange = (newSort) => {
    const column = newSort[0].id
    Analytics.onSort(appName, recommendation.type, column)
    setSort(newSort)
    fetchData({ reset: true, newSort })
  }

  const toggleSalesforceUsageExplanationPopup = ({ isOpen, appName, recommendationType }) => {
    Analytics.onUsageExplanation({ isOpen, appName, recommendationType })
    setSalesforceUsageExplanationModalOpen(isOpen)
  }

  const onFilterChange = debounce((filters) => {
    const columns = filters.map(filter => filter?.key?.label ?? null).filter(Boolean)
    Analytics.onFilterUsers(appName, recommendation.type, columns.join(', '))
    fetchData({ reset: true, filters })
  }, 250)

  const onSearch = debounce((q) => {
    Analytics.onSearch(appName, recommendation.type, q)
    fetchData({ q, reset: true })
  }, 250)

  const columns = useMemo(() => {
    if (!tableInfo || isEmpty(tableInfo)) {
      return []
    }
    return [
      getNameColumn(),
      {
        Header: 'Email',
        id: 'originEmail',
        accessor: 'originEmail'
      },
      {
        Header: 'Last date of license full utilization',
        accessor: 'license.lastTimeLicenseFeatureUsed',
        id: 'license.lastTimeLicenseFeatureUsed',
        Cell: ({ value: lastUsageTime }) => getFormattedDate({ date: lastUsageTime }),
        sortMethod: Table.sortMethods.date,
        minWidth: 260,
        maxWidth: 290
      },
      {
        Header: 'App last used date',
        accessor: 'license.calculatedLastUsageTime',
        id: 'license.calculatedLastUsageTime',
        Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
        minWidth: 170,
        maxWidth: 200
      },
      {
        Header: 'Role',
        id: 'role',
        accessor: ({ role }) => {
          const roleSplit = (role || '-').split(',')
          return roleSplit.map(role => startCase(role).replace('_', ' ')).join(', ')
        },
        showFunc: () => Boolean(columnsConfiguration.role)
      },
      {
        Header: 'Licenses',
        id: 'allLicenseNames',
        accessor: 'allLicenseNames',
        Cell: ({ value: allLicenseNames }) => {
          if (!allLicenseNames?.length) {
            return '-'
          }
          return allLicenseNames.map((licenseName, index) => <div key={index}>{licenseName}</div>)
        },
        showFunc: () => Boolean(columnsConfiguration.allLicenseNames),
        sortable: false
      },
      {
        Header: 'Status in app',
        id: 'externalStatus',
        accessor: 'externalStatus'
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'idUser',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'lifecycleStatus',
        show: false
      },
      {
        accessor: 'isDeletedInIdentitySources',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      },
      {
        accessor: 'idAppAccount',
        show: false
      },
      {
        accessor: 'docId',
        show: false
      }
    ].concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
  }, [columnsConfiguration.allLicenseNames, columnsConfiguration.role, dynamicColumnsInfo, tableInfo])

  const actionButton = () => {
    const selectedUserLicenseInfo = licenses.filter(user => selectedUsers.includes(user.docId))
    const idAppAccounts = uniq(selectedUserLicenseInfo.map(user => user.idAppAccount))
    const isValidAccountSelection = idAppAccounts.length === 1 && idAppAccounts[0] !== NO_APP_ACCOUNT_ID

    const { type: actionType, fields: defaultActionFields } = recommendation.recommendationConfig?.action || {}
    const [idAppAccount] = idAppAccounts
    const extraFieldsDefaultValues = mapKeys(defaultActionFields, (_, key) => `${actionType}_${key}`)
    const buttonText = getActionButtonText(recommendation)

    return ({
      button:
  <span style={{ minWidth: 'max-content' }}>
    <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
      <Button
        onClick={() => {
          Analytics.onActionClick(appName, recommendation.type, buttonText, selectedUsers.length)
          dispatch(toggleConfigureExecuteActionOnUsers({ isOpen: true, idApp, idOrg, users: selectedUserLicenseInfo, actionToRun: recommendation.recommendationConfig.action?.type, extraFieldsDefaultValues, fieldsToValue: { idAppAccount }, executionFlow: MANUAL_WORKFLOW_EXECUTION_FLOW.recommendations, flowType: 'Recommendation', idRecommendation }))
        }}
        disabled={!isValidAccountSelection}
        label={buttonText}
      />
    </EnableFor>
  </span>
    })
  }

  const onSelectChange = (selection) => {
    setSelectedUsers(selection)
  }

  const fetchFieldValues = (field) => {
    dispatch(getAppUserLicenseFieldValues({ idOrg, idApp, fields: [field] }))
  }

  const exportToCsv = ({ query, sort, filters }) => {
    Analytics.onExportCSV(appName, recommendation.type)
    const fieldsParam = `fields=${columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',').replace('fullName', 'firstName,lastName,email')}`
    const queryParam = `q=${query}`
    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`
    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/${idApp}/recommendations/${idRecommendation}/users/csv?${fieldsParam}&${sortParam}&${queryParam}&${filtersParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  const { headerText } = generateDrillDownTexts(recommendation, appName)

  const NoActionInfoBar = appName && recommendation && !recommendation.recommendationConfig?.action ? (
    <Spacer top='space-250'>
      <AlertBox
        type={AlertBoxType.INFORMATIVE}
        title={`Take action in ${appName}`}
        description={`${appName} does not support changing users’ license types via API. To act on this recommendation, log in to the ${appName} admin console.`}
      />
    </Spacer>
  ) : null

  const usageExplanation = idApp && get(APPS_TO_SHOW_USAGE_POPUP, [idApp, recommendationType])
  const hasSubHeader = recommendation.recommendationConfig?.recommendationConfigurationText?.length || usageExplanation
  return (
    <div {...Style.Container}>
      <div {...Style.HeaderContainer}>
        <div {...Style.Header}>{headerText}</div>
        { hasSubHeader && <div {...Style.SubHeader}>
          <div {...Style.SubHeaderText}>
            {recommendation.recommendationConfig?.recommendationConfigurationText?.map(text => <div dangerouslySetInnerHTML={{ __html: text }} />)}
            { usageExplanation?.renderUsageExplanation()}
            { usageExplanation?.renderPopup()}
          </div>
        </div>}
        {NoActionInfoBar}
      </div>
      <Table
        tableKey={TABLES.licenseRecommendationsTable.key}
        data={licenses}
        columns={columns}
        loading={(!hasLoaded || loadingUsers || loadingRecommendation) && !licenses.length}
        loadingMore={loadingMore}
        configurableColumns
        configurableColumnsOptions={configurableColumnsOptions}
        onSortedChangeCB={onSortedChange}
        fetchData={() => fetchData({ reset: false })}
        totalCount={total}
        manual
        selectId='docId'
        selectable
        onSelectChange={onSelectChange}
        searchable
        forceShowSearch
        onSearch={onSearch}
        exportable
        exportFunction={exportToCsv}
        filterable
        filtersOptions={tableInfo.filtersOptions}
        onFilterChange={onFilterChange}
        fetchFieldValues={fetchFieldValues}
        filterOptionsValuesPerKey={fieldValues}
        customButton={recommendation.recommendationConfig?.action ? actionButton() : null}
      />
    </div>
  )
})

export default LicenseRecommendationUserList
