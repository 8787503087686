import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Spacer, Tooltip, Icon, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import UsersIconsList from '@components/usersIconsList'
import UserDetails from '@components/userDetails'
import { formFieldTypes, SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import AssignUserAppDetailButton from '@components/assignUserAppDetailButton'
import {
  getAppContactsDetails,
  getAppContactsUsers,
  isAppDetailsFieldsLoading
} from '@selectors/apps'
import { setAppsDetails, getAppDetailsValues, toggleAssignUser } from '@actions/'
import { getScopeByIdOrgAndIdApp } from '@lenses/scopes'
import { useParamSelector } from '@shared/utils'
import * as CSS from './style'
import { getSelf } from '@selectors/me'
import compact from 'lodash/compact'
import { isEmpty } from 'lodash'

const Contacts = ({
  idOrg,
  idApp
}) => {
  const details = useParamSelector(getAppContactsDetails, { idApp })
  const usersById = useSelector(getAppContactsUsers)
  const isLoading = useSelector(isAppDetailsFieldsLoading)
  const dispatch = useDispatch()
  const currentUser = useSelector(getSelf)

  const assignUserCB = async () => {
    await dispatch(getAppDetailsValues({ idOrg, idApps: idApp }))
  }

  const handleUsersByIdLoading = () => {
    if (!usersById) {
      return true
    }

    const usersIds = (details || []).reduce((ids, field) => {
      if (field.values && Array.isArray(field.values)) {
        ids.push(...field.values)
      }

      return ids
    }, [])

    if (isEmpty(usersIds)) {
      return false
    }

    return usersIds.some(id => (id && !usersById[id]))
  }

  const usersByIdLoading = useCallback(handleUsersByIdLoading, [details, usersById])

  const onSelectUser = async ({ idApp, idOrg, idField, user = {} }) => {
    await dispatch(setAppsDetails({
      idOrg,
      idApps: [idApp],
      fieldsValues: [{ idField, values: [user.id || null] }],
      currentUser
    }))
  }

  const onSelectUsers = async ({ idApp, idOrg, idField, users = [] }) => {
    users.forEach(u => {
      if (!usersById[u.id]) {
        usersById[u.id] = u
      }
    })
    const ids = users.map(user => user.id)
    await dispatch(setAppsDetails({
      idOrg,
      idApps: [idApp],
      fieldsValues: [{ idField, values: ids }],
      currentUser
    }))
  }

  const onRemove = async (idField) => {
    await onSelectUser({ idOrg, idApp, idField, user: { id: null } })
    await assignUserCB()
  }

  const onEdit = (field) => {
    const isMultipleSelect = field.type === formFieldTypes.usersDropdownMulti
    const handleSelectUsers = async ({ idApp, idOrg, users }) => onSelectUsers({ idApp, idOrg, idField: field.idField, users })
    const handleSelectUser = async ({ idApp, idOrg, id: idUser }) => onSelectUser({ idApp, idOrg, user: { id: idUser }, idField: field.idField })
    dispatch(toggleAssignUser(true, idApp, (isMultipleSelect ? handleSelectUsers : handleSelectUser), false, `Edit ${field.name}`, assignUserCB, 'Application Overview Tab / App Contact', '', isMultipleSelect))
  }

  const UserRow = ({ field, user }) => {
    return (
      <Fragment>
        <UserDetails key={user.id} firstName={user.firstName} lastName={user.lastName} photoUrl={user.photoUrl}
          email={user.email} isExternal={user.isExternal} idUser={user.id} />
        <RowActionButtons field={field} />
      </Fragment>
    )
  }

  const MultipleUserRow = ({ field, users }) => (
    <Fragment>
      {users && users[0] ? <UsersIconsList users={users} maxUsers={10} showUserTooltip /> : null}
      <RowActionButtons field={field} />
    </Fragment>
  )

  const RowActionButtons = ({ field }) => {
    if (field.systemKey === 'contractOwners') {
      return null
    }
    return (
      <div {...CSS.OwnerButtons}>
        <div {...CSS.EditButton}>
          <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.APPLICATIONS_WRITE, idOrg, idApp)]}>
            <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={async () => onRemove(field.idField)} icon='Trash' />
          </EnableFor>
          <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.APPLICATIONS_WRITE, idOrg, idApp)]}>
            <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={() => onEdit(field)} icon='Edit' />
          </EnableFor>
        </div>
      </div>
    )
  }

  const renderFieldWithValue = ({ field, users }) => {
    if (field.type === formFieldTypes.usersDropdown) {
      return (<UserRow field={field} user={users[0]} />)
    } else if (field.type === formFieldTypes.usersDropdownMulti) {
      return (<MultipleUserRow field={field} users={users} />)
    }
  }

  const Row = ({ field }) => {
    const users = compact(field.values.map(id => usersById[id]))
    const isMultipleUsers = field.type === formFieldTypes.usersDropdownMulti

    return <div {...CSS.HoverContainer} key={field.idField}>
      <Spacer left={'space-250'}>
        {field.name}
        {field.systemKey === 'contractOwners' ? <Spacer left={'space-050'}>
          <Tooltip
            placement='top'
            label='Editing contract owners can be done in the App Contracts tab.'>
            <Icon name='Info' />
          </Tooltip>
        </Spacer> : null}
      </Spacer>
      {!users[0] && !isMultipleUsers ? <Spacer right={'space-150'}>
        <AssignUserAppDetailButton idApp={idApp} idField={field.idField} label={field.name} size='small' assignUserCB={assignUserCB} />
      </Spacer> : renderFieldWithValue({ field, users })}
    </div>
  }

  if (isLoading || !details.length || usersByIdLoading()) {
    return null
  }

  return (
    <div {...CSS.Main}>
      <div {...CSS.HeaderWrapper}>
        <header {...CSS.Header}>APP CONTACTS</header>
        <Tooltip
          placement='top'
          label='All app stakeholders, including budget, access and contracts'>
          <Icon name='Info' />
        </Tooltip>
      </div>
      <div>
        {(details || []).map(field => <Row field={field} key={field.idField} />)}
      </div>
    </div>
  )
}

Contacts.propTypes = {
  idOrg: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired
}

export default Contacts
