import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import './index.less'
import Cookie from 'cookie'
import { configure } from './shared/redux-fetch'
import config from './config'
import { LOAD_USER_PREFERENCES } from './constants'
import { DragDropContext } from 'react-beautiful-dnd'
import DraggableOptions from './components/draggableOptions'
import { ThemeProvider } from '@toriihq/design-system'

configure({
  successSuffix: '_RESPONSE',
  baseUrl: config.proxyApiRequests ? (config.proxyApiRequestsPath || '') : config.apiBaseUrl
})

const defaultHeaders : {
 'Content-Type': string
 'x-csrf-token'?: string
} = {
  'Content-Type': 'application/json'
}

const cookies = Cookie.parse(document.cookie)
if (cookies.crumb) {
  defaultHeaders['x-csrf-token'] = cookies.crumb
}

configure({
  defaultHeaders
})

store.dispatch({ type: LOAD_USER_PREFERENCES })

ReactDOM.render(
  <DragDropContext onDragEnd={DraggableOptions.onDragEnd}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </DragDropContext>,
  document.getElementById('root')
)
