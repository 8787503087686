import {
  GET_UPDATES
} from '@root/constants'

const initialState = {
  loading: false,
  updates: []
}

const updatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_UPDATES}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_UPDATES}_FAILED`: {
      return {
        ...state,
        loading: false,
        updates: []
      }
    }
    case `${GET_UPDATES}_RESPONSE`: {
      return {
        ...state,
        loading: false,
        updates: action.payload.updates
      }
    }
    default: {
      return state
    }
  }
}

export default updatesReducer
