import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize } from '../../../shared/style/sizes'
import { clearButton } from '../../../shared/style/mixins'
import plug from './images/plug.svg'
import EnableFor from '../../enableFor'
import { SCOPES } from '../../../constants'
import { Icon } from '@toriihq/design-system'

const CSS = {
  main: css({
    position: 'relative',
    width: '197px',
    height: '207px',
    border: `2px dashed ${colors.border}`,
    borderRadius: '4px',
    backgroundColor: colors.white,
    transition: 'box-shadow .2s',
    ':hover': {
      boxShadow: '0 9px 12px 0 rgba(0,0,0,0.05)'
    }
  }),
  button: css(clearButton, {
    width: '100%',
    height: '100%',
    padding: 0,
    '[disabled]': {
      cursor: 'not-allowed'
    }
  }),
  top: css({
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background
  }),
  bottom: css({
    height: '53px',
    display: 'flex',
    justifyContent: 'left',
    fontSize: fontSize.small,
    padding: '16px 18px',
    borderTop: `solid 1px ${colors.border}`
  })
}

class ServiceConnectButton extends React.Component {
  render () {
    const {
      disabled,
      onClick,
      overrideStyle,
      isCustomIntegrationEnabledInPlan
    } = this.props

    return (
      <div {...css(overrideStyle)}>
        <div {...CSS.main}>
          <EnableFor scopes={[SCOPES.INTEGRATIONS_WRITE]}>
            <button {...CSS.button} disabled={disabled || !isCustomIntegrationEnabledInPlan} onClick={onClick}>
              <div {...CSS.top}><img src={plug} alt='Connect new integration' /></div>
              <div {...CSS.bottom}>
                Build a custom integration
                {isCustomIntegrationEnabledInPlan ? '' : <Icon name='Lock' color='inherit' />}
              </div>
            </button>
          </EnableFor>
        </div>
      </div>
    )
  }
}

ServiceConnectButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default ServiceConnectButton
