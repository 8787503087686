import View from './view'
import { connect } from 'react-redux'
import { getContracts, getUnmatchedContracts, getContractsFields, getContractsGroups, toggleContractPopup, toggleImportContracts } from '@actions/'
import { getCalendars } from '@actions/calendars'
import { withRouter } from 'react-router-dom'
import { getContracts as getContractsSelector, isLoadingContracts } from '@selectors/contracts'
import { getRenewalsSummary } from '@actions/contracts'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idContractToOpenPopup = parseInt(ownProps.match.params.idContract, 10)
  const contracts = getContractsSelector(state)
  const loading = isLoadingContracts(state) && contracts.length === 0
  const isEmptyState = !loading && contracts.length === 0

  return {
    idOrg,
    idContractToOpenPopup,
    loading,
    isEmptyState
  }
}

const mapDispatchToProps = {
  getContracts,
  getContractsFields,
  getContractsGroups,
  getUnmatchedContracts,
  getCalendars,
  getRenewalsSummary,
  toggleContractPopup,
  toggleImportContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onAddContract: () => {
    dispatchProps.toggleContractPopup({ isOpen: true })
  },
  onImportContracts: () => {
    dispatchProps.toggleImportContracts(true)
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
