import React from 'react'
import { Grid, Row, Col, Stack, Body1 } from '@toriihq/design-system'
import Setting from './setting'

class Settings extends React.Component {
  componentDidMount () {
    const { getUserSettings } = this.props
    getUserSettings()
  }

  render () {
    const { userSettingsTypes, userSettings, idOrg, loading, updateUserSettings } = this.props
    return (
      <>
        {Object.keys(userSettingsTypes).map(key => {
          const type = userSettingsTypes[key]
          return (
            <Grid key={type.id}>
              <Row>
                <Col>
                  <Body1>{type.title}</Body1>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={7}>
                  <Stack direction='column' gap='space-200'>
                    {userSettings
                      .filter(setting => setting.type === type.id && !setting.disabled)
                      .map(setting => <Setting key={setting.id} {...setting} idOrg={idOrg} loading={loading} onChange={updateUserSettings} />)
                    }
                  </Stack>
                </Col>
              </Row>
            </Grid>
          )
        })}
      </>
    )
  }
}

export default Settings
