export enum WIZARD_STEPS {
  SELECT_RULE = 'selectRule',
  APPLY_MERGE = 'applyMerge',
  CHANGE_APPLIED = 'changeApplied',
  REVOKE_MERGE = 'revokeMerge'
}

export type AvailableRule = {
  idApp: number
  isEnabled: boolean
  name: string
  mergeUsersFieldName: string
  mergeUsersFieldKey: string
}

export type SharedState = {
  selectedRuleIdApp?: number
  selectedRuleUserFieldKey?: string
  idMergeUsersRule?: number,
  availableRules: AvailableRule[]
}

export type SharedProps = {
  idOrg: number,
  isRevokeMode: boolean
}
