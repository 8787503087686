import { useSelector } from 'react-redux'
import { getSelf } from '@selectors/me'
import { getOrgFeatures, getCurrentOrg } from '@selectors/org'
import isEmpty from 'lodash/isEmpty'

const useIsAccessible = ({ scopes, mustHaveAllScopes = false }) => {
  const currentUser = useSelector(getSelf)
  const currentProvisionedOrg = useSelector(getCurrentOrg)

  return isUserGotAccess({ currentUser, currentProvisionedOrg, scopes, mustHaveAllScopes })
}

const useIsFeatureEnabledInPlan = ({ feature }) => {
  const orgFeatures = useSelector(getOrgFeatures)
  return isFeatureInOrgFeatures({ orgFeatures, feature })
}

const isAccessibleWithState = ({ scopes, state, mustHaveAllScopes }) => {
  const currentUser = getSelf(state)
  const currentProvisionedOrg = getCurrentOrg(state)

  return isUserGotAccess({ currentUser, currentProvisionedOrg, scopes, mustHaveAllScopes })
}

const isFeatureEnabledInPlan = ({ feature, state }) => {
  const orgFeatures = getOrgFeatures(state)
  return isFeatureInOrgFeatures({ orgFeatures, feature })
}

export const isFeatureInOrgFeatures = ({ orgFeatures, feature }) => {
  return feature && orgFeatures.length > 0 ? orgFeatures.includes(feature) : true
}

const isUserGotAccess = ({ currentUser, currentProvisionedOrg, scopes, mustHaveAllScopes }) => {
  const regularUserAccess = userFromProvisionedOrg(currentUser, currentProvisionedOrg) && (noScopesDefined(scopes) || userGotNeededScopes(currentUser, scopes, mustHaveAllScopes))
  return Boolean(regularUserAccess || currentUser.isToriiAdmin)
}

const userFromProvisionedOrg = (currentUser, currentProvisionedOrg) => currentUser.idOrg === currentProvisionedOrg.id
const noScopesDefined = (scopes) => isEmpty(scopes)
const userGotNeededScopes = (currentUser, scopes, mustHaveAllScopes = false) => {
  if (mustHaveAllScopes) {
    return scopes.every(scope => (currentUser.scopes || []).includes(scope))
  } else {
    return scopes.some(scope => (currentUser.scopes || []).includes(scope))
  }
}

const exported = {
  useIsAccessible,
  isAccessibleWithState,
  useIsFeatureEnabledInPlan,
  isFeatureEnabledInPlan
}

export default exported
