import {
  CREATE_SECRET,
  DELETE_SECRET,
  UPDATE_SECRET,
  GET_SECRETS
} from '@root/constants'

const initialState = {
  loaded: false,
  loading: false,
  secrets: [],
  creationErrorMessage: null
}

const orgSecretsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_SECRETS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_SECRETS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_SECRETS}_RESPONSE`: {
      const { orgSecrets, resources: { users } } = action.payload
      return {
        ...state,
        loading: false,
        loaded: true,
        secrets: orgSecrets,
        usersMap: users
      }
    }
    case `${DELETE_SECRET}_RESPONSE`: {
      const { orgSecrets } = action.payload
      return {
        ...state,
        secrets: orgSecrets
      }
    }
    case `${CREATE_SECRET}_PENDING`: {
      return {
        ...state,
        creationErrorMessage: null
      }
    }
    case `${CREATE_SECRET}_RESPONSE`: {
      const { orgSecret, resources: { users } } = action.payload
      const secrets = state.secrets.concat(orgSecret)
      const usersMap = { ...state.usersMap, ...users }

      return {
        ...state,
        secrets,
        usersMap,
        creationErrorMessage: null
      }
    }
    case `${CREATE_SECRET}_FAILED`: {
      const { message } = action.payload
      return {
        ...state,
        creationErrorMessage: message
      }
    }
    case `${UPDATE_SECRET}_RESPONSE`: {
      const { orgSecret, resources: { users } } = action.payload
      const secrets = state.secrets.map(secret => secret.id === orgSecret.id ? orgSecret : secret)
      const usersMap = { ...state.usersMap, ...users }

      return {
        ...state,
        secrets,
        usersMap
      }
    }
    default: {
      return state
    }
  }
}

export default orgSecretsReducer
