import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteWorkflowTag, getWorkflowTags } from '@actions/workflows'
import { getCurrentOrg } from '@selectors/org'
import { getWorkflowTags as getWorkflowTagsSelector, isLoadingWorkflowTags } from '@selectors/workflows'
import Table from '@root/components/table'
import * as Styles from './styles'
import { Button, ButtonSize, ButtonType, IconName, Tooltip, toast, ToastType } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { WORKFLOW_TAG_ROW_CLASSNAME } from './consts'
import Confirmation from '@components/confirmation'
import { toggleCreateWorkflowTagsPopup, toggleEditWorkflowTagPopup } from '@actions/'
import { analytics } from '@shared/services/workflows/analytics'
import { getSortedTags } from '@lenses/workflows.t'
import { Tag } from '@shared/workflows/types'

const getTrGroupClassName = (): { className: string } => ({
  className: WORKFLOW_TAG_ROW_CLASSNAME
})

const WorkflowTagsTable = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const tags = useSelector(getWorkflowTagsSelector)
  const loading = useSelector(isLoadingWorkflowTags)
  const dispatch = useDispatch()

  const defaultDeletePopup: { isOpen: boolean, tag: Tag } = {
    isOpen: false,
    tag: {
      id: 0,
      label: '',
      idOrg: null,
      usedByCount: 0
    }
  }

  const [deleteTagPopup, setDeleteTagPopup] = useState(defaultDeletePopup)

  const closeDeletePopup = (): void => {
    setDeleteTagPopup(defaultDeletePopup)
  }

  const onClose = () => {
    analytics.tags.closeDeleteCustomTagDialog({ buttonLabel: 'X' })
    closeDeletePopup()
  }

  const onCancel = () => {
    analytics.tags.closeDeleteCustomTagDialog({ buttonLabel: 'Cancel' })
    closeDeletePopup()
  }

  const onDeleteTag = async () => {
    analytics.tags.deleteCustomTag()
    await dispatch(deleteWorkflowTag({ idOrg, idTag: deleteTagPopup.tag.id }))
    setTimeout(() => {
      closeDeletePopup()
      toast({
        message: `Successfully deleted "${deleteTagPopup.tag.label}" tag.`,
        type: ToastType.SUCCESS
      })
    }, 500)
  }

  useEffect(() => {
    if (idOrg) {
      dispatch(getWorkflowTags({ idOrg }))
    }
  }, [dispatch, idOrg])

  const TagActionButton = ({
    label,
    icon,
    onClick,
    idOrg
  }: {
    label: string,
    icon: IconName,
    onClick: () => any,
    idOrg: number | null
  }) => (
    <Tooltip label={label}>
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        <Button
          icon={icon}
          type={ButtonType.tertiary}
          size={ButtonSize.small}
          htmlButtonType={'button'}
          onClick={onClick}
          disabled={!idOrg}
          dataTestId={`tag-action-${icon.toLowerCase()}${idOrg ? '' : '-predefined'}`}
        />
      </EnableFor>
    </Tooltip>
  )

  const getActionsColumn = (tag: Tag) => (
    <Styles.ActionsContainer>
      {
        TagActionButton({
          label: tag.idOrg ? 'Edit' : 'This tag was created automatically and cannot be edited.',
          icon: 'Edit',
          onClick: () => {
            analytics.tags.openEditTagDialog()
            dispatch(toggleEditWorkflowTagPopup({ isOpen: true, idTag: tag.id }))
          },
          idOrg: tag.idOrg
        })
      }
      {
        TagActionButton({
          label: tag.idOrg ? 'Delete' : ' This tag is defined by Torii and cannot be deleted.',
          icon: 'Trash',
          onClick: () => {
            analytics.tags.openDeleteCustomTagDialog()
            setDeleteTagPopup({
              isOpen: true,
              tag
            })
          },
          idOrg: tag.idOrg
        })
      }
    </Styles.ActionsContainer>
  )

  const getColumns = (): Array<{
    Header?: string
    accessor?: string
    Cell?: (params: any) => JSX.Element
    width?: number
    sortable?: boolean
  }> => {
    return [
      {
        Header: 'Name',
        accessor: 'label',
        sortable: false,
        width: 210
      },
      {
        Header: 'Tag type',
        accessor: 'idOrg',
        Cell: ({ value }) => (
          value ? 'Custom' : 'Torii'
        ),
        sortable: false,
        width: 210
      },
      {
        Header: 'Workflows tagged',
        accessor: 'usedByCount',
        Cell: ({ value }) => (
          value ?? 0
        ),
        width: 210,
        sortable: false,
        ...Table.numericFieldProps
      },
      {
        id: 'actions',
        Header: '',
        sortable: false,
        Cell: ({ row: tag }) => getActionsColumn(tag)
      },
      {
        accessor: 'id',
        show: false
      }
    ]
  }

  // TODO: add a storybook story for this component after the action is implemented
  const customButton = {
    button: <Button
      icon={'Plus'}
      type={ButtonType.secondary}
      size={ButtonSize.small}
      htmlButtonType={'button'}
      onClick={() => {
        analytics.tags.openAddTagDialog()
        dispatch(toggleCreateWorkflowTagsPopup({ isOpen: true }))
      }}
      label={'Add tag'}
    />,
    scopes: [SCOPES.AUTOMATION_WRITE]
  }

  const onDeleteConfirmPopup = <Confirmation
    isOpen={deleteTagPopup.isOpen}
    header='Delete tag'
    text={<div>You are about to delete the "{deleteTagPopup.tag.label}" tag from all of the workflows.</div>}
    confirmText='Delete'
    declineText='Cancel'
    decline={onCancel}
    confirm={onDeleteTag}
    close={onClose}
    mainButtonType={ButtonType.destructive}
    modalWidth={'518px'}
  />

  return (
    <div>
      {onDeleteConfirmPopup}
      <Table
        data={getSortedTags(tags)}
        columns={getColumns()}
        loading={loading}
        header={'Edit or delete tags'}
        getTrGroupProps={getTrGroupClassName}
        customButton={customButton}
        style={{ minHeight: 'unset' }}
      />
    </div>
  )
}

export default WorkflowTagsTable
