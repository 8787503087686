import View from './view'
import { isToriiAdmin } from '@selectors/me'
import { connect } from 'react-redux'
import { getShowHiddenWorkflows } from '@selectors/ui'

const mapStateToProps = state => {
  const isHidden = getShowHiddenWorkflows(state) && isToriiAdmin(state)

  return {
    isHidden
  }
}

export default connect(mapStateToProps)(View)
