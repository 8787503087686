import View from './view'
import { connect } from 'react-redux'
import { getSelf } from '@selectors/me'
import { getPersonalizationByTriggerAndAction } from '@lenses/workflows'
import { getActionFieldsOptions, getWorkflowsPersonalizationsConfig } from '@actions/'
import { isLoadingWorkflowActionDynamicFieldsOption } from '@selectors/workflows'
import { WORKFLOW_TYPES } from '@root/constants'
import { getSupportedFeatures, getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { id: signedInUserId } = getSelf(state)
  const { triggerType, action, prevActions, workflowType } = ownProps

  const supportedFeatures = getSupportedFeatures(state)
  const personalization = getPersonalizationByTriggerAndAction(state, triggerType, action.type, prevActions)
  const isLoadingFieldOptions = isLoadingWorkflowActionDynamicFieldsOption(state)
  const prevActionTypes = prevActions
    .map((action, index) => ({ id: action.id, type: action.type, index: index + 1 }))
    .filter(action => action.type)
  const fields = ownProps.fields
    .filter(field => workflowType !== WORKFLOW_TYPES.notifyOnErrors || !field.hideInNotifyOnErrorsWorkflows)
    .filter(field => workflowType !== WORKFLOW_TYPES.offboarding || !field.hideInOffboardingWorkflows)

  return {
    idOrg,
    signedInUserId,
    personalization,
    isLoadingFieldOptions,
    prevActionTypes,
    supportedFeatures,
    fields
  }
}

const mapDispatchToProps = {
  getActionFieldsOptions,
  getWorkflowsPersonalizationsConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
