import View from './view'
import { connect } from 'react-redux'
import { toggleSentEmailsPopup } from '@actions/'
import { EMPTY_ARRAY } from '../../../constants'
import { getCurrentOrg } from '@selectors/org'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => {
  const { sampleUsers = EMPTY_ARRAY, isOpen, usersCount, idWorkflow } = state.ui.sentEmailsPopup
  const { id: idOrg } = getCurrentOrg(state)

  return {
    idOrg,
    isOpen,
    sampleUsers,
    usersCount,
    idWorkflow
  }
}

const mapDispatchToProps = {
  toggleSentEmailsPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleSentEmailsPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleSentEmailsPopup({ isOpen: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
