import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getSelf } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'
import { getInsightsStats, getTransactionsAggsByAppCategory } from '@actions/'
import AccessControl from '@lenses/accessControl'
import { SCOPES } from '@root/constants'

const mapStateToProps = (state) => {
  const { firstName } = getSelf(state)
  const { id: idOrg, isGeoLocationAllowed } = getCurrentOrg(state)
  const isExpensesVisible = AccessControl.isAccessibleWithState({
    scopes: [SCOPES.EXPENSE_READ],
    state
  })

  return {
    firstName,
    idOrg,
    isExpensesVisible,
    isGeoLocationAllowed
  }
}

const mapDispatchToProps = {
  getInsightsStats,
  getTransactionsAggsByAppCategory
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
