import View from './view'
import { connect } from 'react-redux'
import { getServicesConfig, toggleConfigureExecuteActionOnUsers } from '@actions/'
import { getConstantServicesByIdApp } from '@selectors/services'
import AccessControl from '@lenses/accessControl'
import { SCOPES } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps
  const servicesConfig = getConstantServicesByIdApp(state)[idApp] || {}
  const isSupportsWritePermission = Boolean(servicesConfig.supportsWritePermission)

  const hasLicensesCostAndChargebackAccess = AccessControl.isAccessibleWithState({ state, scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ] })

  return {
    isSupportsWritePermission,
    idApp,
    hasLicensesCostAndChargebackAccess
  }
}

const mapDispatchToProps = {
  toggleConfigureExecuteActionOnUsers,
  getServicesConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
