import View from './view'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, TABLES } from '../../constants'
import { getAppChargeback, toggleConfigureAppChargeback } from '@actions/'
import { getAppChargeback as getAppChargebackSelector } from '@selectors/apps'
import { getUserPreferences } from '@selectors/ui'
import { getCurrency, getCurrentOrg } from '@selectors/org'
import { filterNoCostChargebackData } from '../../shared/chargeback'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const currency = getCurrency(state)

  const tableInfo = TABLES.appChargebackTable

  const userPreferences = getUserPreferences(state)
  const { defaultSort = EMPTY_ARRAY } = userPreferences[TABLES.appChargebackTable.key] || EMPTY_OBJECT

  const appChargeback = getAppChargebackSelector(state)[idApp] || EMPTY_OBJECT

  const data = filterNoCostChargebackData(appChargeback.data || EMPTY_ARRAY)

  return {
    idApp,
    idOrg,
    tableInfo,
    defaultSort,
    data,
    currency,
    loading: (appChargeback.loading === undefined || appChargeback.loading) && !appChargeback.data
  }
}

const mapDispatchToProps = {
  getAppChargeback,
  toggleConfigureAppChargeback
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
