import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateUserLifecycleStatus } from '@actions/'
import omit from 'lodash/omit'
import { AUDIT_LOG_TYPES, TORII_BOT_ID_USER } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { getUsersAuditLogs, getUsersAuditLogsResources } from '@selectors/auditLogs'

import { getDisplayName, getOffboardingByUserFromAudit } from '@lenses/users'

const mapStateToProps = (state, ownProps) => {
  const { idUser } = ownProps
  const userAuditLogs = getUsersAuditLogs(state)[idUser] || []
  const allOffBoardingDoneAudit = userAuditLogs.filter(audit => audit.type === AUDIT_LOG_TYPES.UPDATE_USER_LIFECYCLE_STATUS) || []
  const { users: usersById } = getUsersAuditLogsResources(state)
  const audit = getOffboardingByUserFromAudit(allOffBoardingDoneAudit)
  const performedByUser = audit && Number(audit.performedBy) !== TORII_BOT_ID_USER && (usersById[audit.performedBy] || {})
  const performedBy = audit && (audit.performedBy === TORII_BOT_ID_USER ? 'Torii Bot'
    : getDisplayName({ firstName: performedByUser.firstName, lastName: performedByUser.lastName, email: performedByUser.email }))
  return {
    audit: audit ? { date: audit.creationTime, performedBy } : null
  }
}
const mapDispatchToProps = {
  updateUserLifecycleStatus
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idUser } = ownProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUserLifecycleStatus']),
    reopen: () =>
      dispatchProps.updateUserLifecycleStatus({ idOrg, idUser, lifecycleStatus: USER_LIFECYCLE_STATUS.ACTIVE }),
    ...ownProps
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
