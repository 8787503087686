import { connect } from 'react-redux'
import View from './view'
import { searchAppsTags } from '@actions/'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)

  return {
    idOrg
  }
}

const mapDispatchToProps = {
  searchAppsTags
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
