import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { css } from 'glamor'
import { Tooltip, Button, ButtonType, ButtonSize, Link, Icon, EmptyState, Popover } from '@toriihq/design-system'
import keyBy from 'lodash/keyBy'
import partition from 'lodash/partition'
import { getOverlapsAppsComparison } from '@actions/'
import texts from '@shared/style/texts'
import noLicensesImage from '@media/dance.svg'
import noUsersImage from '@media/people.svg'
import VennDiagram from './components/vennDiagram'
import OverlapsTable from './components/overlapsTable'
import { COLOR_SCHEME } from '../shared/style'
import * as Style from './style'
import Analytics from '@helpers/analytics'
import { customJoin } from '@lenses/utils'
import AppInfo from '@pages/appsComparison/components/chartWidget/appInfo'
import RelativeTeamLink from '@components/relativeTeamLink'
import { isLoadingAppsComparisonData } from '@selectors/apps'
import ReactDOM from 'react-dom'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { withRouter } from 'react-router-dom'
import useEffectOnce from '@shared/hooks/useEffectOnce'

const UsersOverlaps = withRouter((props) => {
  const { idApps, idOrg = parseInt(props.match.params.idOrg, 10) } = props
  const [sets, setSets] = useState({
    all: [],
    licenses: []
  })
  const [apps, setApps] = useState({})
  const [legendData, setLegendData] = useState({})
  const [currentTab, setCurrentTab] = useState('all')
  const [vennColorScheme, setVennColorScheme] = useState({
    all: [],
    licenses: []
  })
  const [highlightedOverlap, setHighlightedOverlap] = useState({})

  const isLoading = useSelector(isLoadingAppsComparisonData)

  const dispatch = useDispatch()

  const getData = async () => {
    const calculateSets = ({ overlaps, apps, withLicenses }) => {
      let colorScheme = {}
      const vennSets = Object.keys(overlaps).map((key) => {
        const sets = key.split(',')
        const appIndex = sets.length === 1 ? idApps.findIndex(idApp => Number(sets[0]) === idApp) : -1
        if (appIndex !== -1) {
          colorScheme[sets[0]] = COLOR_SCHEME[appIndex]
        }
        return {
          size: overlaps[key],
          sets,
          label: sets.length === 1 ? apps[key].name : '',
          tooltip: sets.map(idApp => apps[idApp].name).join(' and ').concat(`, ${overlaps[key]} users ${withLicenses ? 'with licenses' : ''}`),
          textColor: appIndex !== -1 ? COLOR_SCHEME[appIndex] : undefined
        }
      })

      return { vennSets, colorScheme }
    }

    const [allUsersResponse, licensesResponse] = await Promise.all([
      dispatch(getOverlapsAppsComparison({ idOrg, idApps, withLicenses: false })),
      dispatch(getOverlapsAppsComparison({ idOrg, idApps, withLicenses: true }))
    ])

    const { overlaps: allUsersOverlaps, legendData: legendDataAll, resources } = allUsersResponse
    const { overlaps: licensesOverlaps, legendData: legendDataLicenses } = licensesResponse
    const { vennSets: allUsersSets, colorScheme: allUsersColorScheme } = calculateSets({ overlaps: allUsersOverlaps, apps: resources.apps, withLicenses: false })
    const { vennSets: licensesSets, colorScheme: licensesColorScheme } = calculateSets({ overlaps: licensesOverlaps, apps: resources.apps, withLicenses: true })

    ReactDOM.unstable_batchedUpdates(() => {
      setApps(keyBy(resources.apps, 'id'))
      setLegendData({ all: legendDataAll, licenses: legendDataLicenses })
      setVennColorScheme({ all: allUsersColorScheme, licenses: licensesColorScheme })
      setSets({ all: allUsersSets, licenses: licensesSets })
    })
  }

  useEffectOnce(() => {
    getData()
  })

  const onHighlight = ({ overlap = {}, source } = {}) => {
    setHighlightedOverlap({ ...overlap, source })
  }

  const onTabClick = ({ name, label }) => {
    setCurrentTab(name)
    Analytics.track('Click on tab / Overlap widget / Comparison page / Compare tab / Applications', {
      tab: label
    })
  }

  const Tab = ({ label, name, children }) => {
    return <div {...css(Style.Tab, currentTab === name && Style.CurrentTab)}>
      <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => onTabClick({ name, label })} label={label} />
      {children}
    </div>
  }

  const NoData = () => {
    if (currentTab === 'all') {
      return (
        <div {...Style.EmptyStateContainer}>
          <EmptyState
            size='small'
            image={<img alt='no users' src={noUsersImage} />}
            description={<span>Torii found no users for selected applications.
              <br />It might happen when apps are added via expense files or created manually.</span>}
          />
        </div>
      )
    }

    const [integrableApps, nonIntegrableApps] = partition(legendData['licenses'], app => app.integrable)
    if (integrableApps.length === 0) {
      return (
        <div {...Style.EmptyStateContainer}>
          <EmptyState
            size='small'
            image={<img alt='no licenses' src={noLicensesImage} />}
            description={<span>Torii cannot show license overlaps for the selected apps at the moment.
              <br />We add new integrations all the time, so come back and check again soon!</span>}
            buttons={[<Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => setCurrentTab('all')}
              label='View overlapping users' />]}
          />
        </div>
      )
    }
    return (
      <div {...Style.EmptyStateContainer}>
        <EmptyState
          size='small'
          image={<img alt='no licenses' src={noLicensesImage} />}
          description={<span>Connect {customJoin(integrableApps.map(app => apps[app.idApp].name))} to discover users with license overlap.
            <br /> {nonIntegrableApps.length > 0 ? `${customJoin(nonIntegrableApps.map(app => apps[app.idApp].name))} cannot be connected yet.` : null}</span>}
          buttons={[(
            <RelativeTeamLink to={'/services'}>
              <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={() => {}}
                label='Connect integrations' />
            </RelativeTeamLink>
          )
          ]}
        />
      </div>
    )
  }

  const MissingDataLegend = () => {
    return legendData[currentTab].length ? (
      <>
        <span {...css(Style.MissingDataText, { width: '40%' })}>
          Some data is missing...
        </span>
        {legendData[currentTab].map(app => {
          const tooltipContent = currentTab === 'all' ? <div style={{ width: 230 }}>
            Torii found no users for {apps[app.idApp].name}. It might happen when an app is added via expense file or created manually.
            <br /><Link href='https://support.toriihq.com/hc/en-us/articles/5129359470363' target='_blank'>
              Read more in Torii help center
            </Link>
          </div>
            : <div style={{ width: 230 }}>{app.integrable ? `Torii does not have information about ${apps[app.idApp].name} licenses. Connect the app to get more insights!`
              : `Torii does not have integration with ${apps[app.idApp].name} yet. We add new integrations all the time, so come back to check it again soon!`}</div>
          const idAppIndex = idApps.findIndex(_idApp => app.idApp === _idApp)
          return <AppInfo
            key={app.idApp}
            loading={isLoading}
            idApp={app.idApp}
            color={COLOR_SCHEME[idAppIndex]}
            name={apps[app.idApp].name}
            tooltipObj={<Popover
              position='top'
              showArrow
              content={tooltipContent}
            >
              <Icon name='Info' color='secondary' />
            </Popover>}
            infoLine={currentTab === 'licenses' && app.integrable ? <RelativeTeamLink to={'/services'}>Connect integration</RelativeTeamLink> : null}
            imageUrl={apps[app.idApp].imageUrl}
            component='Users Overlap'
          />
        })}
      </>
    ) : null
  }

  return (
    <div {...Style.Container}>
      <div {...Style.Title}>
        <div>Users overlap</div>
        <div {...Style.TabsContainer}>
          <Tab name='all' label='All users' />
          <Tab name='licenses' label='Users with licenses'>
            <Tooltip
              placement={'bottom'}
              label='The diagram represents users with assigned licenses. Users are counted once per app, regardless of the number of licenses they have.'
            >
              <Icon name='Info' color='secondary' />
            </Tooltip>
          </Tab>
        </div>
      </div>
      <hr {...Style.TitleSeparator} />
      <span {...texts.subheading}>Current state</span>
      {isLoading ? null
        : sets[currentTab].length > 1
          ? <div {...Style.Wrapper}>
            <div {...Style.FlexColumnWrapper}>
              <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin>
                <VennDiagram sets={sets[currentTab]} apps={apps} tab={currentTab} onHighlight={onHighlight} highlightedOverlap={highlightedOverlap} colorScheme={vennColorScheme[currentTab]} />
              </EnableFor>
              <MissingDataLegend />
            </div>
            <OverlapsTable sets={sets[currentTab]} apps={apps} onHighlight={onHighlight} highlightedOverlap={highlightedOverlap} tab={currentTab} />
          </div>
          : <NoData />}
    </div>
  )
})

UsersOverlaps.propTypes = {
  idApps: PropTypes.arrayOf(PropTypes.number).isRequired,
  idOrg: PropTypes.number
}

export default UsersOverlaps
