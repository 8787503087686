import View from './view'
import { connect } from 'react-redux'
import { EMPTY_OBJECT } from '@root/constants'
import { createSourceToken, getServicesSyncData, toggleConnectService } from '@actions/'
import { getConstantServices } from '@selectors/services'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { connectService = EMPTY_OBJECT } = state.ui
  const { id: idOrg } = getCurrentOrg(state)
  const servicesConfig = getConstantServices(state)

  return {
    idOrg,
    connectService,
    initialValues: { idApp: connectService.idApp },
    servicesConfig
  }
}

const mapDispatchToProps = {
  toggleConnectService,
  onConnect: createSourceToken,
  getServicesSyncData
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
  cancel () {
    dispatchProps.toggleConnectService({ isConnectServiceOpen: false })
  },
  close () {
    dispatchProps.toggleConnectService({ isConnectServiceOpen: false, isByUser: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
