import View from './view'
import { connect } from 'react-redux'
import { getCurrencySymbol } from '@selectors/org'

const mapStateToProps = (state) => {
  const currencySymbol = getCurrencySymbol(state)
  return { currencySymbol }
}

export default connect(mapStateToProps)(View)
