import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Table from '@components/table/index'
import { ITEMS_PER_PAGE, TABLES, SCOPES } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import { getNameColumn, getStartedByColumns } from '@components/offboardingTabs/columns'
import EnableFor from '@components/enableFor'
import emptyImage from '@components/offboardingTabs/images/item-box.svg'
import { fontSize, fontWeight } from '@shared/style/sizes'
import { Icon, Button, ButtonType, ButtonSize, AlertBox, AlertBoxType, H4, Link, ProgressBar, EmptyState } from '@toriihq/design-system'
import debounce from 'lodash/debounce'
import { pluralizeWithFormat, sortStringFromArray } from '@shared/utils'
import config from '@root/config'
import { hoverActions, insideTableRowsClass } from '@shared/style/mixins'
import { getFormattedDate } from '@lenses/utils'
import RelativeTeamLink from '@components/relativeTeamLink'
import { getTdStyle } from '../../../styles'
import { isParentUserFilter } from '@actions/'

const CSS = {
  contentWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
  }),
  actionLink: css(hoverActions, {
    float: 'right'
  }),
  offboardingProgress: css(texts.caption, hoverActions, {
    display: 'flex'
  }),
  tableHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 32
  }),
  tableHeaderTitle: css({
    marginRight: '20px',
    marginLeft: '0px',
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semiBold,
    color: colors.darkText
  }),
  tableHeaderSubtitle: css({
    color: colors.grey1,
    fontSize: fontSize.small,
    marginLeft: 15
  }),
  progressBar: css({
    display: 'flex',
    width: '100%',
    margin: '5px 0',
    transform: 'translateY(100%)',
    transition: 'transform .2s',
    [insideTableRowsClass]: {
      transform: 'translateY(0)'
    }
  })
}

const OffboardingProgressBar = ({ removed, total }) => (
  <Fragment>
    <div {...CSS.progressBar}><ProgressBar value={removed} max={total} size='Large' /></div>
    <div {...CSS.offboardingProgress}>{removed}/{pluralizeWithFormat('app', total, true)}</div>
  </Fragment>
)

class InProgressUsers extends React.Component {
  state = { selectedUsers: [], q: '' }

  constructor (props) {
    super(props)
    this.columns = this.getColumns()
    this.noUsers = this.renderNoUsers()
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData(true)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData(true)
    }
  }

  getHeader = () => {
    const { total, title } = this.props

    return <H4>{`${title} (${total}) `}</H4>
  }

  getColumns = () => {
    return [
      getNameColumn({ paddingLeft: '5px' }),
      {
        Header: 'Date of departure',
        id: 'identitySourcesDeletionTime',
        accessor: 'identitySourcesDeletionTime',
        Cell: ({ value }) => getFormattedDate({ date: value }),
        width: 150
      },
      {
        accessor: 'email',
        show: false,
        textHeader: 'Email'
      },
      {
        Header: 'Apps',
        id: 'totalAppAccountsCount',
        accessor: 'totalAppAccountsCount',
        minWidth: 75
      },
      {
        Header: 'Progress',
        id: 'offboardingProgress',
        Cell: ({ row }) => {
          const { totalAppAccountsCount = 0, ignoredAndRemovedAppAccountsCount = 0, offboardingProgress = 0 } = row
          return <OffboardingProgressBar progress={offboardingProgress} total={totalAppAccountsCount} removed={ignoredAndRemovedAppAccountsCount} />
        },
        minWidth: 116
      },
      {
        Header: 'Status',
        accessor: 'needsAttention',
        style: { display: 'flex', alignItems: 'center' },
        Cell: ({ row }) => {
          if (row.needsAttention) {
            return <Icon name='CircleAlertFilled' color='error' />
          }
          return <div>In progress</div>
        },
        width: 98
      },
      ...getStartedByColumns(),
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'totalAppAccountsCount',
        show: false
      },
      {
        accessor: 'offboardingProgress',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      },
      {
        accessor: 'ignoredAndRemovedAppAccountsCount',
        show: false
      }
    ]
  }

  getUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, sort = this.props.defaultSort, q = this.state.q, reset = false }) => {
    const { idOrg, getOffboardingInProgressUsers } = this.props
    getOffboardingInProgressUsers({ idOrg, limit, offset, sort: sortStringFromArray(sort), q, reset })
  }

  fetchData = debounce((reset = false) => {
    const { users } = this.props
    this.getUsers({ offset: reset ? 0 : users.length, reset })
  }, 500, { leading: true, trailing: false })

  onSortedChange = (sort) => {
    this.getUsers({ sort, reset: true })
  }

  onSearch = debounce((q) => {
    this.setState({ q })
    this.getUsers({ reset: true, q })
  }, 250)

  exportToCsv = ({ sort, query }) => {
    const { idOrg } = this.props

    const sortParam = 'sort=' + sortStringFromArray(sort)
    const queryParam = `q=${query}`
    const fieldsParam = `fields=firstName,lastName,email,offboardingProgress,needsAttention`
    const filters = `lifecycleStatus=${USER_LIFECYCLE_STATUS.OFFBOARDING}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify([isParentUserFilter]))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${filters}&${filtersParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  renderNoUsers = () => {
    return <EmptyState
      image={<img src={emptyImage} alt='No users' />}
      title='No offboardings in progress'
      link={<RelativeTeamLink to='/offboarding/todo'><Link>View your To-do</Link></RelativeTeamLink>}
    />
  }

  searchFilterMethod (row, search) {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  customHeaderOnSelect = () => {
    const { markAsDone, cancelOffboarding, refreshAllOffboardingData } = this.props
    const { selectedUsers } = this.state
    return <div>
      <div {...css(CSS.tableHeader, { padding: 0 })}>
        <div {...css(CSS.actionLink, { opacity: 1, marginRight: 20 })}>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <Button
              onClick={async () => {
                await markAsDone(selectedUsers)
                await refreshAllOffboardingData()
                this.setState({ selectedUsers: [] })
              }}
              label='Mark as Done'
            />
          </EnableFor>
        </div>
        <div {...css(CSS.actionLink, { opacity: 1 })}>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <Button type={ButtonType.compact} size={ButtonSize.small}
              onClick={async () => {
                await cancelOffboarding(selectedUsers)
                await refreshAllOffboardingData()
                this.setState({ selectedUsers: [] })
              }}
              label='Cancel Offboarding'
            />
          </EnableFor>
        </div>
      </div>
    </div>
  }

  onSelectChange = selection => this.setState({ selectedUsers: selection })

  getNeedsAttentionMessage = () => {
    const { numberOfUsersThatNeedsAttention } = this.props

    if (numberOfUsersThatNeedsAttention === 1) {
      return 'There is one offboarding process that requires your attention'
    }

    return `There are ${numberOfUsersThatNeedsAttention} offboarding processes that require your attention.`
  }

  render () {
    const { users, total, loading, numberOfUsersThatNeedsAttention, loadingMore, idOrg } = this.props
    const { selectedUsers } = this.state
    return <div {...CSS.contentWrapper}>
      {Boolean(numberOfUsersThatNeedsAttention) && <AlertBox
        type={AlertBoxType.INFORMATIVE}
        description={
          <span>
            {this.getNeedsAttentionMessage()}
            <br />
            <span>
              Please review processes with "Needs attention" status in this page or
            </span>
            <RelativeTeamLink idOrg={idOrg} to='/offboarding/settings'> <Link>review apps in the "Needs attention" list</Link></RelativeTeamLink>.
          </span>
        }
      />}
      <Table
        tableKey={TABLES.offboardingInprogressTable.key}
        emptyStateMessage={this.noUsers}
        data={users}
        columns={this.columns}
        loading={loading}
        loadingMore={loadingMore}
        exportable
        searchable
        searchFilterMethod={this.searchFilterMethod}
        getTdProps={getTdStyle}
        selectable
        selectedItems={selectedUsers}
        onSelectChange={this.onSelectChange}
        customHeaderOnSelect={this.customHeaderOnSelect}
        manual
        fetchData={this.fetchData}
        onSortedChangeCB={this.onSortedChange}
        onSearch={this.onSearch}
        forceShowSearch
        exportFunction={this.exportToCsv}
        totalCount={total}
      />
    </div>
  }
}

InProgressUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lifecycleStatus: PropTypes.string.isRequired
  })),
  refreshAllOffboardingData: PropTypes.func
}

export default InProgressUsers
