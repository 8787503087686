import { WorkflowsToInvalidate } from '@reducers/ui/types'
import { IMPORT_TYPE } from '@selectors/importUsers/types'

export enum WIZARD_STEPS {
  CONFIRMATION = 'confirmation',
  LOADING_AFTER_DELETION = 'loadingAfterDeletion',
  FAILURE = 'failure'
}

export interface SharedProps {
  importMode: IMPORT_TYPE
  idOrg: number
  idApp: number
  idAppAccount: number
  appAccountName: string
  workflowsToInvalidate: WorkflowsToInvalidate
  lastSyncTime: string
  closePopup: () => void
}

export interface SharedState {
  isFirstLoaded: boolean
}
