import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getUsersDistributionTrends, isLoadedUsersDistributionTrends, isLoadingUsersDistributionTrends } from '@selectors/trends'
import { getUsersDistribution, getUsersDistributionV2 } from '@actions/'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const countries = getUsersDistributionTrends(state)
  const isLoaded = isLoadedUsersDistributionTrends(state)
  const loading = !isLoaded || (isLoadingUsersDistributionTrends(state) && countries.length === 0)

  return {
    idOrg,
    data: countries,
    loading
  }
}

const mapDispatchToProps = {
  getUsersDistribution,
  getUsersDistributionV2
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
