import View from './view'
import { connect } from 'react-redux'
import { toggleCustomActionResponsePopup } from '@actions/'

const mapStateToProps = state => {
  const { customActionResponsePopup: { isOpen, action } } = state.ui
  return {
    isOpen,
    action
  }
}

const mapDispatchToProps = {
  toggleCustomActionResponsePopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleCustomActionResponsePopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleCustomActionResponsePopup({ isOpen: false })
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
