import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import moment from 'moment'
import { State } from '@reducers/types'

export const getSelf = createSelector<State, State['me'], State['me']>(
  state => state.me,
  identity
)

export const getMyOrg = createSelector<State, State['me'], State['me']['idOrg']>(
  getSelf,
  me => me.idOrg
)

export const getUserScopes = createSelector<State, State['me'], State['me']['scopes']>(
  getSelf,
  me => get(me, 'scopes', [])
)

export const isToriiAdmin = createSelector<State, State['me'], boolean>(
  getSelf,
  me => Boolean(me.isToriiAdmin)
)

export const isPaidPlanExpired = createSelector<State, State['me'], boolean>(
  getSelf,
  me => {
    const paidPlanEndTime = get(me, ['org', 'paidPlanEndTime'])
    return Boolean(paidPlanEndTime && moment.utc().subtract(24, 'h') > moment(me.org!.paidPlanEndTime))
  }
)

export const getIsOnlyAppOwner = createSelector<State, State['me'], boolean>(
  getSelf,
  me => Boolean(me.isAppOwner && !me.isAdmin)
)
