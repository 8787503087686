import React from 'react'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import ExpensesBarChart from '../../expensesBarChart'
import { CONTENT_TOOLTIP_TYPES } from '@components/barsChartBox/chart/view'
import { theme } from '@toriihq/design-system'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const CSS = {
  main: css({
    height: '100%',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  header: css({
    minHeight: '50px',
    padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
    borderBottom: `1px solid ${colors.border}`
  }),
  chart: css({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    display: 'flex',
    alignItems: 'center'
  })
}

const className = 'expensesBreakdown'

class ExpensesBreakdown extends React.Component {
  render () {
    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={CSS.header} outerHoverStyle={'#mostUsedApps:hover &'} header='expenses breakdown' subHeader='Last 12 months' />
        <div {...CSS.chart}>
          <ExpensesBarChart tooltipType={CONTENT_TOOLTIP_TYPES.CATEGORIES} chartHeight={200} chartOverrideStyle={{ padding: '10px 24px' }} />
        </div>
      </div>
    )
  }
}

export default ExpensesBreakdown
