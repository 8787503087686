import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getTriggerFieldsOptions, getTriggerFieldsOptionsValues, toggleTriggerPreviewPopup } from '@actions/'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)

  return {
    idOrg
  }
}

const mapDispatchToProps = {
  getTriggerFieldsOptions,
  getTriggerFieldsOptionsValues,
  toggleTriggerPreviewPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
