import React, { ReactNode } from 'react'
import { getCurrency } from '@selectors/org'
import Select from '../select'
import { getCurrenciesOptions } from './currenciesOptions'
import { Container } from './style'
import { useSelector } from 'react-redux'
import { getCurrencySymbols, getDisplayCurrencyOptions } from '@selectors/ui'

export type CurrencySelectorProps = {
  selectedCurrency: string
  onChange: (newValue: { label: string, value: string }) => void
  label?: ReactNode
}

export const CurrencySelector = (props: CurrencySelectorProps): JSX.Element => {
  const { onChange, selectedCurrency, label } = props
  const orgDefaultCurrency = useSelector(getCurrency)
  const currencySymbols = useSelector(getCurrencySymbols)
  const displayCurrencies = useSelector(getDisplayCurrencyOptions)
  const currenciesOptions = getCurrenciesOptions({ orgDisplayCurrency: orgDefaultCurrency, displayCurrencies, currencySymbols })

  return (
    <Container>
      {label}
      <Select
        {...props}
        options={currenciesOptions}
        searchable
        value={selectedCurrency}
        noResultsText='No currencies found'
        onChange={(newValue) => onChange(newValue as { label: string, value: string })}
        clearable={false}
      />
    </Container>
  )
}

export default CurrencySelector
