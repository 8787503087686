import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getContracts } from '@selectors/contracts'
import { maxBy, minBy, partition } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getAppContracts } from '@actions/'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { AlertBox, AlertBoxType, Link } from '@toriihq/design-system'

const RenewalAlertBox = (props) => {
  const dispatch = useDispatch()
  const { appName, idOrg, idApp, history } = props
  const allContracts = useSelector(getContracts)
  const nextRenewalContract = getNextRenewalContract(idApp, allContracts)
  const alertBoxContent = getAlertBoxContent(nextRenewalContract, appName, idApp, history, idOrg)

  useEffect(() => {
    if (idOrg && idApp) {
      dispatch(getAppContracts({ idOrg, idApp }))
    }
  }, [idOrg, idApp, dispatch])

  return (alertBoxContent) ? (
    <AlertBox
      type={AlertBoxType.INFORMATIVE}
      title={alertBoxContent.title}
      description={alertBoxContent.description}
    />
  ) : null
}

const getNextRenewalContract = (idApp, allContracts) => {
  const contracts = allContracts.filter(contract => {
    return contract.idApp === idApp && contract.endDate && contract.status && contract.status.toLowerCase() !== 'closed'
  })

  const [past, future] = partition(contracts, contract => moment(contract.endDate).isBefore(moment()))

  if (future.length) {
    return minBy(future, 'endDate')
  }

  if (past.length) {
    return maxBy(past, 'endDate')
  }

  return null
}

const formatDate = (date) => date ? moment(date).format('D MMMM, YYYY') : ''

export const getAlertBoxContent = (contract, appName, idApp, history, idOrg) => {
  if (!contract || !appName) {
    return null
  }

  const { endDate: renewalDate, cancellationDeadline, autoRenewal } = contract

  const renewalAlertClicked = () => history.push(`/team/${idOrg}/app/${idApp}/contracts`)
  const link = <EnableFor scopes={[SCOPES.CONTRACTS_READ]}><Link onClick={renewalAlertClicked}>View contracts</Link></EnableFor>
  const formatedRenewalDate = formatDate(renewalDate)

  if (moment().isAfter(renewalDate)) {
    return { title: `${appName}'s renewal date has passed: ${formatedRenewalDate}`, description: link }
  }

  if (autoRenewal !== 'Yes') {
    return { title: `${appName}'s next renewal is on ${formatedRenewalDate}`, description: link }
  }

  if (!cancellationDeadline) {
    return { title: `${appName} will auto renew on ${formatedRenewalDate}`, description: link }
  }

  if (moment().isAfter(cancellationDeadline)) {
    return { title: `${appName} will auto renew on ${formatedRenewalDate}`, description: <div>Cancellation date has passed: {formatDate(cancellationDeadline)}. {link}</div> }
  }

  return { title: `${appName} will auto renew on ${formatedRenewalDate}`, description: <div>You can cancel until {formatDate(cancellationDeadline)}. {link}</div> }
}

RenewalAlertBox.propTypes = {
  idOrg: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired,
  appName: PropTypes.string
}

export default RenewalAlertBox
