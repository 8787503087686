import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getContracts,
  getContractsFields,
  isLoadingAppContracts
} from '@selectors/contracts'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const idContractToOpenPopup = parseInt(ownProps.match.params.idContract, 10)
  const contracts = getContracts(state)
  const contractFields = getContractsFields(state)
  const { id: idOrg } = getCurrentOrg(state)
  const loadingAppContracts = isLoadingAppContracts(state)

  const loading = loadingAppContracts && contracts.filter(contract => contract.idApp === idApp).length === 0
  const contractFieldsNotFetchedYet = contractFields.length === 0

  return {
    idApp,
    idOrg,
    idContractToOpenPopup,
    loading: loading || contractFieldsNotFetchedYet
  }
}

export default withRouter(connect(mapStateToProps)(View))
