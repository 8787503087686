import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImportContracts from '@components/importContracts'
import ToriiPopup from '../ToriiPopupV2'
import { useDispatch } from 'react-redux'
import { toggleImportContracts } from '@actions/'

const ImportContractsPopup = (props) => {
  const { cancel, isOpen, close, ...rest } = props

  const [showFooter, setShowFooter] = useState(false)
  const [isError, setIsError] = useState(false)

  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(toggleImportContracts(false, true))
    setShowFooter(false)
    setIsError(false)
  }
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClose} styles={{ model: { maxHeight: 'unset' } }}>
      <ToriiPopup.Header header='Import contracts' />
      <ToriiPopup.Content>
        <ImportContracts {...rest} onShowFooter={(show) => setShowFooter(show)} onIsError={(isError) => setIsError(isError)} />
      </ToriiPopup.Content>
      {showFooter && (
        <ToriiPopup.Footer
          cancelButtonText='Cancel'
          mainButtonText={isError ? 'Try again' : 'Import'}
        />
      )}
    </ToriiPopup>
  )
}

ImportContractsPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default ImportContractsPopup
