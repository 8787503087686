import {
  GET_USER_AUDIT_LOGS
} from '@root/constants'

const initialState = {
  loading: false,
  userAuditLogs: {},
  resources: { apps: {}, users: {} }
}

const auditLogReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_USER_AUDIT_LOGS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_USER_AUDIT_LOGS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_USER_AUDIT_LOGS}_RESPONSE`: {
      const { auditLogs, resources } = action.payload
      const { idUser } = action.meta
      return {
        ...state,
        loading: false,
        userAuditLogs: {
          ...state.userAuditLogs,
          [idUser]: auditLogs
        },
        resources
      }
    }
    default: {
      return state
    }
  }
}

export default auditLogReducer
