import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TABLES } from '../../constants'
import { getHiddenApps, changeAppHiddenState } from '@actions/'
import { getHiddenApps as getHiddenAppsSelector, isLoadingHiddenApps, getHiddenAppsUsersSelector } from '@selectors/apps'
import { getIsSmallScreen } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const { idOrg } = ownProps
  const usersById = getHiddenAppsUsersSelector(state)
  const apps = getHiddenAppsSelector(state)
  const loading = isLoadingHiddenApps(state) && apps.length === 0

  return {
    usersById,
    apps,
    loading,
    idOrg,
    tableKey: TABLES.hiddenAppsTable.key,
    isSmallScreen: getIsSmallScreen(state)
  }
}

const mapDispatchToProps = {
  getHiddenApps,
  changeAppHiddenState
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
