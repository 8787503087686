import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import UserDetails from '@components/userDetails'
import { getDisplayName } from '@lenses/users'
import { AppIcon, Spacer, Tooltip, Icon } from '@toriihq/design-system'
import { getOverlapsUsersAppsComparison, toggleUsersOverlapPopup } from '@actions/'
import Table from '@components/table'
import UsageIcon from '@components/usageIcon'
import colors from '@shared/style/colors'
import { getCurrentOrg } from '@selectors/org'
import { DATE_FORMAT, ITEMS_PER_PAGE, scoreSpecialValues } from '@root/constants'
import * as Style from '../style'
import { AppWrapper, HeaderWrapper, AppName, Separator } from './styles'
import { customJoin } from '@lenses/utils'
import moment from 'moment'
import config from '@root/config'
import { withRouter } from 'react-router-dom'
import useEffectOnce from '@shared/hooks/useEffectOnce'

const UsersOverlapsPopup = withRouter(({ match, cancel, close, ...rest }) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [sort, setSort] = useState([{ id: 'firstName', desc: false }])

  const query = useRef('')
  const { isOpen, apps, idUsers, withLicenses } = useSelector(state => state.ui.usersOverlapPopup)
  const { id: idOrg = parseInt(match.params.idOrg, 10) } = useSelector(getCurrentOrg)

  const dispatch = useDispatch()

  const sortStringFromArray = sortArray => {
    return sortArray.map(s => {
      const [sortType, idApp] = s.id.split('_')
      return idApp ? `${sortType}_${idApp}:${s.desc ? 'desc' : 'asc'}` : `${sortType}:${s.desc ? 'desc' : 'asc'}`
    }).join(',')
  }

  const getUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false, q = query.current }) => {
    const idApps = apps.map(app => app.id)
    setLoadingMore(offset !== 0)
    setLoading(offset === 0)
    dispatch(getOverlapsUsersAppsComparison({
      idOrg,
      idApps,
      limit,
      offset,
      q,
      reset,
      sort: sortStringFromArray(sort),
      withLicenses
    }))
      .then(result => {
        setUsers(reset ? result : users.concat(result))
        setLoading(false)
        setLoadingMore(false)
      })
  }

  useEffectOnce(() => {
    getUsers({ reset: true })
  })

  const columns = [
    {
      Header: 'Users',
      id: 'firstName',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 200,
      maxWidth: 350,
      Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl } }) => (
        <UserDetails
          onSelect={onClose}
          firstName={firstName}
          lastName={lastName}
          email={email}
          isExternal={isExternal}
          idUser={idUser}
          showExternalUserBadge={false}
          photoUrl={photoUrl}
        />
      ),
      textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email })
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    }
  ].concat(apps.map(app => (
    {
      Header: () => (
        <AppWrapper>
          <Spacer right={'space-150'}>
            <AppIcon appName={app.name} appImageUrl={app.imageUrl} />
          </Spacer>
          <span>{app.name}</span>
        </AppWrapper>
      ),
      columns: [
        {
          Header: 'Has license',
          id: `licenses_${app.id}`,
          accessor: `licenses_${app.id}`,
          width: apps.length > 2 ? 95 : undefined,
          Cell: ({ value }) => {
            if (!value) {
              return '-'
            }
            const licenses = value.split(',')
            return licenses.map(license => {
              const [id, name] = license.split(':')
              return <Tooltip
                key={id}
                placement='top'
                label={name}
              >
                <Icon name='CheckCircleFill' color='interactive' />
              </Tooltip>
            })
          }
        },
        {
          Header: 'Usage',
          id: `score_${app.id}`,
          accessor: `score_${app.id}`,
          width: apps.length > 2 ? 70 : undefined,
          style: { display: 'flex', alignItems: 'center ' },
          Cell: ({ value: score, row }) => <UsageIcon
            isUserUsage score={row[`lastVisitTime_${app.id}`] ? score : scoreSpecialValues.notCollectingUsage}
            lastVisitTime={row[`lastVisitTime_${app.id}`]}
          />
        },
        {
          Header: 'Last used date',
          id: `lastVisitTime_${app.id}`,
          accessor: `lastVisitTime_${app.id}`,
          width: apps.length > 2 ? 140 : 160,
          Cell: ({ value }) => value ? moment(value).format(DATE_FORMAT) : '-',
          getProps: () => {
            return {
              style: {
                padding: '25px 20px',
                borderRight: `1px solid ${colors.border}`
              }
            }
          }
        }
      ]
    }
  )))

  const onClose = () => {
    dispatch(toggleUsersOverlapPopup({ apps: [], idUsers: [] }))
  }

  const getHeader = () => {
    return (
      <div>
        <div {...Style.TableHeaderTitle}>{withLicenses ? 'users with licenses' : 'overlapping users'} in {customJoin(apps.map(app => app.name))} ({idUsers})</div>
      </div>
    )
  }

  const onSearch = debounce((q) => {
    query.current = q
    getUsers({ reset: true, q })
  }, 250)

  const onSortedChange = (sort) => {
    setSort(sort)
  }

  const fetchUsersData = () => {
    getUsers({ offset: users.length, reset: false })
  }

  const renderPopupHeader = () => {
    return (
      <HeaderWrapper>
        {apps.map((app, i) => (
          <>
            <AppWrapper key={app.name}>
              <Spacer right={'space-150'}>
                <AppIcon appName={app.name} appImageUrl={app.imageUrl} />
              </Spacer>
              <AppName>{app.name}</AppName>
            </AppWrapper>
            {i < apps.length - 1 && <Spacer right={'space-400'} left={'space-400'}>
              <Separator>+</Separator>
            </Spacer>}
          </>
        ))}
      </HeaderWrapper>
    )
  }

  const exportToCsv = ({ query }) => {
    const idApps = `idApps=${JSON.stringify(apps.map(app => app.id))}`
    const withLicensesParam = `withLicenses=${withLicenses}`
    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const queryParam = `q=${query}`
    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/compare/overlaps/users/csv?${idApps}&${withLicensesParam}&${sortParam}&${queryParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClose} styles={Style.Popup} contentAreaStyle={Style.MainTableArea}>
      <ToriiPopup.Header header={renderPopupHeader()} />
      <ToriiPopup.Content>
        <Table
          columns={columns}
          data={users}
          fetchData={fetchUsersData}
          sortable
          onSortedChangeCB={onSortedChange}
          searchable
          onSearch={onSearch}
          forceShowSearch
          manual
          header={getHeader}
          exportable
          exportFunction={exportToCsv}
          loading={loading}
          loadingMore={loadingMore}
          groupedHeaders
          totalCount={query.current ? users.length : idUsers}
          scrollObjectId='contentArea'
          overrideThGroupStyle={{ borderBottom: `1px solid ${colors.border}`, padding: '10px 0' }}
          overrideTheadGroupThStyle={{ textAlign: 'center' }}
          overrideTheadThStyle={{ padding: '14px 10px' }}
          tableHeaderStyle={{ paddingRight: 10 }}
          overrideResizerStyle={{ right: '-6px' }}
        />
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton={false}
        mainButtonAction={onClose}
        mainButtonText={'Close'}
      />
    </ToriiPopup>
  )
})

UsersOverlapsPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default UsersOverlapsPopup
