import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Page from '@components/page'
import PageHeader from '@components/pageHeader'
import AppsV2 from '@components/appsV2'
import AppsComparisonPage from '@pages/appsComparison'
import TabsPage from '@pages/tabs'
import {
  getTotalOrgApps,
  getShowAppsCompareTab,
  getComparisonRecommendations as getComparisonRecommendationsSelector
} from '@selectors/apps'
import { useSelector, useDispatch } from 'react-redux'
import { getComparisonRecommendations, getComparisonSSOSourcesAccounts } from '@actions/'
import DiscoveredAppsTable from '@components/discoveredAppsTable'
import { isFeatureEnabled } from '@lenses/features'
import { getIsOnlyAppOwner } from '@selectors/me'
import { FEATURES } from '@root/shared/features'
import { TABLES } from '@root/constants'
import { getIsLoadingDiscoveredApps } from '@selectors/discoveredApps'

const ApplicationsPageV2 = ({ match }) => {
  const showAppsCompareTab = useSelector(getShowAppsCompareTab)
  const isCompareAppFeatureEnabled = useSelector(state => isFeatureEnabled(state, 'applicationsComparison'))
  const totalApps = useSelector(getTotalOrgApps)
  const { comparisons, isLoaded: isComparisonLoaded } = useSelector(getComparisonRecommendationsSelector)
  const isDiscoveredAppsLoading = useSelector(getIsLoadingDiscoveredApps)
  const isAppOwnerUser = useSelector(getIsOnlyAppOwner)

  const shouldShowAppsCompareTab = (showAppsCompareTab || isCompareAppFeatureEnabled) && !isAppOwnerUser
  const dispatch = useDispatch()

  const idOrg = parseInt(match.params.idOrg, 10)

  useEffect(() => {
    if (idOrg) {
      if (shouldShowAppsCompareTab) {
        dispatch(getComparisonRecommendations({ idOrg }))
      } else {
        dispatch(getComparisonSSOSourcesAccounts({ idOrg }))
      }
    }
  }, [dispatch, idOrg, shouldShowAppsCompareTab])

  if (shouldShowAppsCompareTab) {
    const tabsName = [
      'review',
      shouldShowAppsCompareTab ? 'compare' : null,
      'refine'
    ]

    const tabsHeader = [
      { header: 'Review', counter: totalApps, isLoading: !isComparisonLoaded },
      { header: shouldShowAppsCompareTab ? 'Compare' : null, counter: comparisons.length, isLoading: !isComparisonLoaded },
      { header: 'Refine', counter: null, isLoading: isDiscoveredAppsLoading }
    ]

    const tabsContent = [
      <AppsV2 idOrg={idOrg} />,
      shouldShowAppsCompareTab ? <AppsComparisonPage /> : null,
      <DiscoveredAppsTable idOrg={idOrg} tableKey={TABLES.allDiscoveredAppsTable.key} />
    ]

    const tabsFeature = [
      FEATURES.APPLICATIONS.TABS.REVIEW,
      FEATURES.APPLICATIONS.TABS.COMPARE,
      FEATURES.APPLICATIONS.TABS.REFINE
    ]

    return (
      <TabsPage
        pageHeader='Applications'
        pageTitle='Applications'
        pageName='Applications'
        path={`/team/${idOrg}/applications`}
        tabsName={tabsName}
        tabsHeader={tabsHeader}
        tabsContent={tabsContent}
        tabsFeature={tabsFeature}
        forceShowTabs
        data_testid='applications_tabs'
      />
    )
  }

  const title = `Applications${totalApps > 0 ? ` (${totalApps})` : ''}`

  return (
    <Page title='Applications'>
      <PageHeader title={title} />
      <AppsV2 idOrg={idOrg} />
    </Page>
  )
}

export default withRouter(ApplicationsPageV2)
