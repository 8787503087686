import View from './view'
import { connect } from 'react-redux'
import { getSelf } from '@selectors/me'
import { getPersonalizationByTriggerAndActionV2 } from '@lenses/workflows'
import { getActionFieldsOptions, getWorkflowsPersonalizationsConfig, getWorkflowsDynamicPersonalizationsConfig } from '@actions/'
import { isLoadingWorkflowActionDynamicFieldsOption } from '@selectors/workflows'
import { WORKFLOW_TYPES } from '@root/constants'
import { getSupportedFeatures, getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { id: signedInUserId } = getSelf(state)
  const { workflow, triggerType, action, prevActions, workflowType } = ownProps

  const personalization = getPersonalizationByTriggerAndActionV2(state, triggerType, action.type, prevActions, workflow.id)
  const isLoadingFieldOptions = isLoadingWorkflowActionDynamicFieldsOption(state)
  const prevActionTypes = prevActions
    .map((action, index) => ({ id: action.id, type: action.type, index: index + 1 }))
    .filter(action => action.type)
  const fields = ownProps.fields
    .filter(field => workflowType !== WORKFLOW_TYPES.notifyOnErrors || !field.hideInNotifyOnErrorsWorkflows)
    .filter(field => workflowType !== WORKFLOW_TYPES.offboarding || !field.hideInOffboardingWorkflows)
  const supportedFeatures = getSupportedFeatures(state)

  return {
    idOrg,
    signedInUserId,
    personalization,
    isLoadingFieldOptions,
    prevActionTypes,
    fields,
    supportedFeatures,
    idWorkflow: workflow.id
  }
}

const mapDispatchToProps = {
  getActionFieldsOptions,
  getWorkflowsPersonalizationsConfig,
  getWorkflowsDynamicPersonalizationsConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
