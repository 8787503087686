import moment from 'moment'
import { DATE_FORMATS_OPTIONS } from './stepsConfig/types'

export const getSuggestedDateFormatsBasedOnValue = (dateValue) => {
  // The following formats and logic are based on the parse_users_import lambda
  // make sure to keep them in sync on every change
  const DATE_FORMATS = [
    'YYYYMMDD',
    'YYYY-MM-DD',
    'YYYY_MM_DD',
    'YYYY.MM.DD',
    'M/D/YY',
    'MM/DD/YY',
    'DD MMM YY',
    'DD MMM YYYY',
    'DD MMMM YYYY',
    'MMM D, YY',
    'MMM D, YYYY',
    'MMM DD, YYYY',
    'MMMM D, YYYY',
    'MMMM DD, YYYY',
    'YYYYMMDDHHmm',
    'YYYYMMDD_HHmm',
    'YYYY.MM.DD.HHmm',
    'YYYY-MM-DD-HHmm',
    'YYYY-MM-DD_HHmm',
    'YYYY.MM.DD.HH.mm',
    'YYYY-MM-DD-HH-mm',
    'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD h:mm A',
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD @ h:mm A',
    'YYYYMMDDHHmmss',
    'YYYY.MM.DD.HHmmss',
    'YYYY-MM-DD-HHmmss',
    'YYYY-MM-DD_HHmmss',
    'YYYY-MM-DD_HHmm.ss',
    'YYYY.MM.DD.HH.mm.ss',
    'YYYY-MM-DD-HH-mm-ss',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm.ss',
    'YYYY-MM-DD h:mm:ss A',
    'YYYY-MM-DD hh:mm:ss A',
    'YYYY-MM-DD @ h:mm:ss A',
    'dd MMM D YY',
    'ddd MMM D YY',
    'ddd MMM D YYYY',
    'ddd MMM DD YYYY',
    'dddd, MMM D YYYY',
    'dddd, MMMM D, YYYY',
    'dddd, MMMM DD, YYYY',
    'ddd MMM D YY h:mm A',
    'ddd MMM D YYYY h:mm A',
    'ddd MMM DD YYYY h:mm A',
    'dddd, MMM D YYYY h:mm A',
    'dddd, MMMM D, YYYY h:mm A',
    'dddd, MMMM DD, YYYY h:mm A',
    'ddd MMM D YY hh:mm A',
    'ddd MMM D YYYY hh:mm A',
    'ddd MMM DD YYYY hh:mm A',
    'dddd, MMM D YYYY hh:mm A',
    'dddd, MMMM D, YYYY hh:mm A',
    'dddd, MMMM DD, YYYY hh:mm A',
    'ddd MMM D YY @ h:mm A',
    'ddd MMM D YYYY @ h:mm A',
    'ddd MMM DD YYYY @ h:mm A',
    'dddd, MMM D YYYY @ h:mm A',
    'dddd, MMMM D, YYYY @ h:mm A',
    'dddd, MMMM DD, YYYY @ h:mm A',
    'YYYY/MM/DD',
    'MM/DD/YYYY',
    'MM-DD-YYYY',
    'MM.DD.YYYY',
    'DD/MM/YYYY',
    'DD-MM-YYYY',
    'DD.MM.YYYY',
    'MM-DD-YY',
    'MM.DD.YY',
    'DD/MM/YY',
    'DD-MM-YY',
    'DD.MM.YY',
    'M-D-YY',
    'M.D.YY',
    'D/M/YY',
    'D-M-YY',
    'D.M.YY',
    'D/M/YYYY',
    'M/D/YYYY',
    'M/D/YY H:mm'
  ]
  const FORGIVING_DATE_AND_TIME_FORMATS = ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'MM-DD-YYYY HH:mm:ss']

  const validDateFormats = DATE_FORMATS.filter(format => {
    return moment(dateValue, format, true).isValid()
  })

  if (validDateFormats.length) {
    return validDateFormats
  }

  const isFoundValidDateAndTimeFormat = moment(dateValue, FORGIVING_DATE_AND_TIME_FORMATS, false).isValid()

  if (isFoundValidDateAndTimeFormat) {
    return []
  }

  return undefined
}

export const getSelectedDateFormat = (suggestedDateFormat) => {
  if (!suggestedDateFormat) {
    return undefined
  }

  if (suggestedDateFormat.length === 0) {
    return ''
  } else if (suggestedDateFormat.length === 1) {
    return suggestedDateFormat[0]
  } else {
    return getDateFormatsByStructure(suggestedDateFormat).daysPriorMonths
  }
}

export const getDateFormatsByStructure = (dateFormats): { [DATE_FORMATS_OPTIONS.DAYS_PRIOR_MONTHS]: string, [DATE_FORMATS_OPTIONS.MONTHS_PRIOR_DAYS]: string } => {
  const [format1, format2] = dateFormats
  if (/DD.*MM/.test(format1)) {
    return { daysPriorMonths: format1, monthsPriorDays: format2 }
  } else {
    return { daysPriorMonths: format2, monthsPriorDays: format1 }
  }
}
