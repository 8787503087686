import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import { toggleUploadExpenses } from '@actions/'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)

  return {
    idOrg
  }
}

const mapDispatchToProps = {
  toggleUploadExpenses
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
