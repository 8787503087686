import View from './view'
import { connect } from 'react-redux'
import { getApiKeys, createApiKey, deleteApiKey } from '@actions/'
import { getIsSmallScreen } from '@selectors/ui'
import {
  isLoadingApiKeys,
  getApiKeys as getApiKeysSelector,
  getApiKeysResources,
  isLoadedApiKeys,
  getApiKeysConfig
} from '@selectors/apiKeys'
import { getIsScimEnabledSelector, getCurrentOrg } from '@selectors/org'
import { State } from '@reducers/types'

const mapStateToProps = (state: State) => {
  const { id: idOrg } = getCurrentOrg(state) as unknown as { id: number }
  const loading = isLoadingApiKeys(state)
  const isLoaded = isLoadedApiKeys(state)
  const apiKeys = getApiKeysSelector(state)
  const apiKeysConfig = getApiKeysConfig(state)
  const userMap = getApiKeysResources(state).users
  const isScimEnabled = getIsScimEnabledSelector(state)

  return {
    idOrg,
    loading: !isLoaded || (loading && apiKeys.length === 0),
    userMap,
    apiKeys,
    apiKeysConfig,
    isScimEnabled,
    isSmallScreen: getIsSmallScreen(state)
  }
}

const mapDispatchToProps = {
  getApiKeys,
  createApiKey,
  deleteApiKey
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
