import React from 'react'
import { ButtonType, Button, Tooltip } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'
import { SUPPORT_ARTICLES } from '@root/articles'

class HelpButton extends React.Component {
  onClick = () => {
    const { isOnlyAppOwner } = this.props
    const url = isOnlyAppOwner ? SUPPORT_ARTICLES.APP_OWNER_INTRODUCTION : SUPPORT_ARTICLES.HELP_CENTER

    Analytics.track('Clicked to open help center')
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  render () {
    return (<Tooltip
      placement='bottom'
      label='Help center'>
      <Button type={ButtonType.tertiary} icon='Help' dataTestId='help-button' onClick={this.onClick} />
    </Tooltip>)
  }
}

export default HelpButton
