import { useEffect } from 'react'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import Placeholder from '@components/placeholder'
import { deleteManuallyImportedLicenses, deleteManuallyImportedUsers, getAppUsersCount } from '@actions/'
import { getItsTheir } from '@shared/utils'
import { getAppUsersCount as getAppUsersCountSelector } from '@selectors/appUsers'
import { Subtitle, Text } from '../../styles'
import { IMPORT_TYPE } from '@selectors/importUsers/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '../types'
import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { ButtonType } from '@toriihq/design-system'
import PopupContent from '../../popupContent'
import Analytics from '@root/components/popups/importUsersPopup/analytics'

const DeletionConfirmation = ({ sharedProps, navigateToStep, onCancel }: PopupRendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const dispatch = useDispatch()

  const appUsersCount = useSelector(getAppUsersCountSelector)

  const { idOrg, idApp, idAppAccount, importMode, workflowsToInvalidate, appAccountName } = sharedProps

  const appUsersCountData = appUsersCount[idApp]
  const loading = !appUsersCountData || appUsersCountData.loading
  const usersCount = appUsersCountData?.count

  useEffect(() => {
    idOrg && idApp && dispatch(getAppUsersCount({
      idOrg,
      idApp,
      filters: [
        { key: 'idAppAccount', op: 'equals', value: JSON.stringify(idAppAccount) },
        { key: 'status', op: 'equals', value: 'active' }
      ]
    }))
  }, [dispatch, idApp, idAppAccount, idOrg])

  const getWorkflowsNotice = (workflowsToInvalidate?: { name: string }[]) => {
    if (!workflowsToInvalidate?.length) return null

    const workflowNames = workflowsToInvalidate.map(workflow => workflow.name).join(', ')
    const workflowsCount = workflowsToInvalidate.length

    if (importMode === IMPORT_TYPE.USERS) {
      return `${pluralize('workflows', workflowsCount, true)} will be invalidated since ${getItsTheir(workflowsCount)} trigger depends on the manually imported account. Workflow names: ${workflowNames}.`
    } else {
      return `${pluralize('workflows', workflowsCount, true)} may be impacted since ${getItsTheir(workflowsCount)} trigger depends on manually assigned licenses. Workflow names: ${workflowNames}.`
    }
  }
  const workflowsNotice = getWorkflowsNotice(workflowsToInvalidate)

  const importUsersDeletionMessage = <>
    <Text>
      <Subtitle>You are about to delete all {usersCount} manually imported users.</Subtitle>
      <br />
      Both active users and users who have been marked as “no longer in app” will be deleted.
      Users discovered via multiple sources will only be deleted from the “file upload” source.
      You can always import more users in the future.
    </Text>
    { workflowsNotice && (<Text><Subtitle>Notice: </Subtitle><br />{workflowsNotice}</Text>) }
  </>

  const importLicensesDeletionMessage = <>
    <Text>
      <Subtitle>You are about to delete all imported data for {usersCount} users.</Subtitle>
      <br />
      After deletion, license data will be resynced from the integration.
    </Text>
    { workflowsNotice && (<Text><Subtitle>Notice: </Subtitle><br />{workflowsNotice}</Text>) }
  </>

  const popupContent = importMode === IMPORT_TYPE.USERS ? importUsersDeletionMessage : importLicensesDeletionMessage
  const mainButtonText = importMode === IMPORT_TYPE.USERS ? 'Delete Users' : 'Reset'
  const header = importMode === IMPORT_TYPE.USERS ? 'Delete all imported users' : 'Reset manual changes'

  const handleDeleteClick = async () => {
    Analytics.onDeleteImportedUsers(importMode, usersCount)

    try {
      if (importMode === IMPORT_TYPE.USERS) {
        await dispatch(deleteManuallyImportedUsers({ idOrg, idApp }))
      } else {
        await dispatch(deleteManuallyImportedLicenses({ idOrg, idApp, idAppAccount }))
      }
      navigateToStep(WIZARD_STEPS.LOADING_AFTER_DELETION)
    } catch (e) {
      navigateToStep(WIZARD_STEPS.FAILURE)
    }
  }

  return <>
    <ToriiPopup.Header header={header} subHeader={appAccountName} />
    <ToriiPopup.Content>
      <Placeholder loading={loading} rows={10}>
        <PopupContent>{popupContent}</PopupContent>
      </Placeholder>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonType={ButtonType.destructive}
      mainButtonText={mainButtonText}
      mainButtonAction={handleDeleteClick}
      cancelButtonText='Cancel'
      cancelButtonAction={onCancel}
    />
  </>
}

export default DeletionConfirmation
