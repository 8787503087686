import View from './view'
import { connect } from 'react-redux'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import { getSSOAuditUnmanagedUsers } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { EMPTY_OBJECT } from '../../../constants'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps
  const { id: idOrg } = getCurrentOrg(state)

  let app = {}
  if (idApp) {
    app = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
  }

  const { users, loading } = state.reports.ssoAuditUnmanagedUsers

  return ({
    app,
    idApp,
    idOrg,
    users,
    loading
  })
}

const mapDispatchToProps = {
  getSSOAuditUnmanagedUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
