import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { SCOPES } from '@root/constants'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { ParsedContractDetails, SharedProps, SharedState, WIZARD_STEPS } from '../types'
import { CSS } from './style'
import EnableFor from '@root/components/enableFor'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import ContractDetails from '@root/components/contractDetails'
import { AlertBox, AlertBoxType } from '@toriihq/design-system'
import Analytics from './analytics'
import { getContractsById, getContractsResources } from '@selectors/contracts'
import { getFormattedDate } from '@root/lenses/utils'
import { CONTRACT_STATE } from './types'
import { CONTRACT_SOURCE } from '@root/constants.t'

export const AddOrEditContract = ({
  onCancel, navigateToStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const { idContract, idApp, onSuccess, initialValues, idOrg, isDuplicated, isAppMatchingMode } = sharedProps
  const { contractDetails, successfullyParsedAmount } = sharedState

  const allContracts = useSelector(getContractsById)
  const { users } = useSelector(getContractsResources)

  const autoRenewalDetected = contractDetails?.autoRenewal === 'Yes'

  const getPopupHeaders = (contractState: CONTRACT_STATE): { header: string, subHeader: string } => {
    switch (contractState) {
      case CONTRACT_STATE.APP_MATCHING:
        return { header: 'Match To App', subHeader: 'Select an application and review contract details' }
      case CONTRACT_STATE.EDIT_CONTRACT: {
        const contractDetails = allContracts[idContract]

        const shouldShowSource = contractDetails.source && contractDetails.source !== CONTRACT_SOURCE.MANUAL
        const sourceText = shouldShowSource ? ` via ${contractDetails.source}` : ''

        const shouldShowCreatedBy = (contractDetails.createdBy && users[contractDetails.createdBy])
        let createdByText: string | null = null
        if (shouldShowCreatedBy) {
          const user = users[contractDetails.createdBy]
          const userName = (user.firstName || user.lastName) ? `${user.firstName ?? ''} ${user.lastName ?? ''}` : user.email
          createdByText = shouldShowCreatedBy ? `, by ${userName}` : null
        }

        const contractInfoSubHeader = `Created on ${getFormattedDate({ date: contractDetails.creationTime })}${sourceText}${createdByText ?? ''}`
        return { header: contractDetails.name ? `Edit '${contractDetails.name}' contract` : 'Edit contract', subHeader: contractInfoSubHeader }
      }
      case CONTRACT_STATE.ADD_CONTRACT:
        return { header: 'Add Contract', subHeader: 'Add your contract details' }
    }
  }

  const contractState = isAppMatchingMode ? CONTRACT_STATE.APP_MATCHING : (idContract ? CONTRACT_STATE.EDIT_CONTRACT : CONTRACT_STATE.ADD_CONTRACT)
  const popupHeaders = getPopupHeaders(contractState)

  const successfulResultsFromAIAlert = () => {
    return <div {...CSS.AiAlertBoxSpace}>
      <AlertBox type={AlertBoxType.POSITIVE} title={'Magic auto-fill was successful'} description={`${successfullyParsedAmount} fields were auto-filled. Please Review`} />
      {autoRenewalDetected && <AlertBox type={AlertBoxType.POSITIVE} title={'Auto renewal was detected'} />}
    </div>
  }

  const failedResultsFromAIAlert = () => {
    return <div {...CSS.AiAlertBoxSpace}>
      <AlertBox type={AlertBoxType.NEGATIVE} title={'Magic auto-fill was unsuccessful'} description={'Please enter contract details manually'} />
    </div>
  }

  const handleBackButton = () => {
    Analytics.onBackClick()
    setState({
      isNewContract: true,
      idUpload: null,
      contractDetails: null,
      isAiRan: false,
      successfullyParsedAmount: 0,
      shouldShowBackButton: false,
      idAuditLog: null,
      isAlreadyForcedToBeDirty: false
    })
    navigateToStep(WIZARD_STEPS.NEW_CONTRACT_AI)
  }

  /**
   * ContractDetails component doesn't implement the React Final Form correctly,
   * React Final Form should work with "initialValues" and "values", when they are different a property called "pristine"
   * defines if the form is dirty or not.
   * but the ContractDetails use only "initialValues" to populate the fields so the "pristine" won't be affected until one of the field changes.
   * this function force a change (until we refactor the whole component).
   */
  const forceFormToBeDirty = (formProps, contractDetails: ParsedContractDetails) => {
    const { isAlreadyForcedToBeDirty } = sharedState
    if (!isAlreadyForcedToBeDirty) {
      const key = Object.keys(contractDetails).find(key => !!contractDetails[key])
      formProps.form.change(key, contractDetails[key!])
      setState({
        ...sharedState,
        isAlreadyForcedToBeDirty: true
      })
    }
  }

  return (
    <>
      <ToriiPopup.Header header={popupHeaders.header} subHeader={popupHeaders.subHeader} />
      <ToriiPopup.Form
        initialValues={contractDetails ?? initialValues}
        contentAreaStyle={CSS.mainArea}
        render={(formProps) => {
          contractDetails && forceFormToBeDirty(formProps, contractDetails)
          return (
            <>
              {sharedState.isAiRan && (sharedState.contractDetails ? successfulResultsFromAIAlert() : failedResultsFromAIAlert())}
              <EnableFor scopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}>
                <ContractDetails isDuplicated={isDuplicated} isAppMatchingMode={isAppMatchingMode} idApp={idApp} filledByAI={sharedState.isAiRan} idContract={idContract} idAuditLog={sharedState.idAuditLog} onSuccess={onSuccess} onCancel={onCancel} {...formProps} />
              </EnableFor>
            </>
          )
        }
        }
        renderFooter={(formProps) => {
          const { submitError } = formProps
          const { shouldShowBackButton } = sharedState
          return (
            <Fragment>
              {submitError && <div {...CSS.errorMessage}>{submitError}</div>}
              <ToriiPopup.Footer
                scopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}
                recoveryTime={1000}
                isMainSubmit
                mainButtonText={'Save'}
                cancelButtonText={'Cancel'}
                showBackButton={shouldShowBackButton}
                backButtonAction={handleBackButton}
                formProps={formProps}
                handleSubmit={formProps.handleSubmit}
                cancelButtonAction={onCancel}
              />
            </Fragment>
          )
        }}
      />
    </>
  )
}
