import React from 'react'
import {
  isToriiAdmin as getIsToriiAdmin
} from '@selectors/me'
import { useSelector } from 'react-redux'
import Confirmation from '@components/confirmation'
import { getCurrentOrg } from '@selectors/org'

const TORII_ADMIN_ERROR_CODE_TO_MESSAGE = {
  403: 'You do not have permissions to view this account. Please ask the customer to allow Torii Support access from the Settings page',
  404: 'This account does not exist'
}

const AuthenticationError = () => {
  const org = useSelector(getCurrentOrg)
  const isToriiAdmin = useSelector(getIsToriiAdmin)

  const goToLoginPage = () => {
    window.location.href = '/login'
  }

  let errorMessage
  if (org.errorCode) {
    if ([403, 404].includes(org.errorCode)) {
      if (isToriiAdmin) {
        errorMessage = TORII_ADMIN_ERROR_CODE_TO_MESSAGE[org.errorCode]
      } else {
        errorMessage = 'This account does not exist'
      }
    }
  }

  return (
    <Confirmation
      isOpen={Boolean(errorMessage)}
      header='Oops...'
      text={errorMessage}
      showCancelButton={false}
      confirmText='Got it'
      confirm={() => goToLoginPage(false)}
      close={() => goToLoginPage(false)}
    />
  )
}

export default AuthenticationError
