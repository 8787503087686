import styled, { css } from 'styled-components'

export const SearchBarMenuButton = styled.button<{ isOpen: boolean }>`
  outline: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  height: 36px;
  padding: 0 6px;
  border-radius: ${({ theme }) => theme.corner('radius-050')};
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.palette.button.tertiaryHover};
    color: ${({ theme }) => theme.palette.text.interactive};
  }

  &:focus {
    background: ${({ theme }) => theme.palette.button.tertiaryHover};
    color: ${({ theme }) => theme.palette.text.interactive};
  }

  &:active {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.button.tertiaryHover};
    background: ${({ theme }) => theme.palette.button.tertiaryHover};;
  }

  ${({ isOpen }) => isOpen && css`
    background: ${({ theme }) => theme.palette.button.tertiaryHover};;
    color: ${({ theme }) => theme.palette.text.interactive};
  `}
`

export const SearchBarArrowIcon = styled.span<{ isOpen: boolean }>`
  display: flex;
  margin-left: 4px;
  transition: transform 0.2s ease-in-out;

  ${({ isOpen }) => isOpen && css`transform: rotate(-180deg);`}
`
