import View from './view'
import { connect } from 'react-redux'
import { updateContractDetails } from '@actions/'

const mapDispatchToProps = {
  updateContractDetails
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSelectUser ({ id }) {
    const { idOrg, idContract, systemKey, idApp } = ownProps
    dispatchProps.updateContractDetails({ idOrg, idContract, details: { [systemKey]: id }, ...(idApp ? { belongsToIdApp: idApp } : {}) })
  }
})

export default connect(null, mapDispatchToProps, mergeProps)(View)
