import React from 'react'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import colors from '../../shared/style/colors'
import LicenseBar from './licenseBar'
import maxBy from 'lodash/maxBy'
import sumBy from 'lodash/sumBy'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import { Tooltip, Icon, Spacer, theme } from '@toriihq/design-system'
import { fontSize } from '../../shared/style/sizes'
import { formatNumber } from '@root/shared/utils'

const CSS = {
  main: css({
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    padding: '18px 21px'
  }),
  header: css(texts.heading, {
    marginBottom: '21px',
    color: colors.darkText,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center'
  }),
  infoIcon: css({
    transform: 'translateY(13%)',
    fontSize: fontSize.medium
  })
}

class LicensesChart extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idApp !== this.props.idApp || prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  fetchData () {
    const { idOrg, idApp, getLicensesTypes } = this.props
    idApp && idOrg && getLicensesTypes({ idOrg, idApp })
  }

  render () {
    const { licenses, loading, appName, idApp, idOrg } = this.props
    if (loading || licenses.length === 0) {
      return null
    }

    const LicensesWithTotalAmount = orderBy(licenses, ['validTotalAmount'], 'desc')

    const maxLicensesAmount = (maxBy(LicensesWithTotalAmount, 'validTotalAmount') || {}).validTotalAmount || 1
    const validTotalAmount = sumBy(LicensesWithTotalAmount, 'validTotalAmount')
    const isAppTotalAmountPartialCalculated = LicensesWithTotalAmount.some(l => isNil(l.unassignedAmount) && !l.isTotalAmountEnteredByUser)
    const tooltipLabel = `This number does not take into account unassigned licenses since the ${appName} integration does not support providing this data. To get the unassigned license count, please go the the Licenses page and enter the total purchased licenses.`

    return (
      <div {...CSS.main}>
        <header {...CSS.header}>
          licenses breakdown ({formatNumber(validTotalAmount)})
          {isAppTotalAmountPartialCalculated &&
          <Spacer left={'space-100'}>
            <Tooltip label={tooltipLabel}>
              <Icon name='Info' />
            </Tooltip>
          </Spacer>
          }
        </header>
        {LicensesWithTotalAmount.map(license => {
          return <LicenseBar
            key={license.id}
            idLicense={license.id}
            idApp={idApp}
            idOrg={idOrg}
            activeAmount={license.activeAmount}
            inactiveAmount={license.inactiveAmount}
            inactiveReasons={license.inactiveReasons}
            unassignedAmount={license.unassignedAmount}
            totalAmount={license.validTotalAmount}
            name={license.name}
            onInactiveClicked={this.togglePopup}
            barWidth={(license.validTotalAmount * 100) / maxLicensesAmount}
          />
        })}
      </div>
    )
  }
}

export default LicensesChart
