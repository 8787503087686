import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { deprecatedGetApps, getUserDetailsFields, getCurrentUsersAmount, getExternalUsersAmount, getUserTypeAmount } from '@actions/'
import { deprecatedGetApps as getAppsSelector, deprecatedGetAppsByIds, isAppsLoading } from '@selectors/apps'
import {
  getCurrentUsersAmount as getCurrentUsersAmountSelector,
  getEmployeeAmount,
  getExternalUsersAmount as getExternalUsersAmountSelector,
  isUsersLoadingES
} from '@selectors/users'
import { isLoadedSupportedFeatures } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  const currentUsersAmount = getCurrentUsersAmountSelector(state)
  const externalEmployeesAmount = getExternalUsersAmountSelector(state)
  const employeeAmount = getEmployeeAmount(state)
  const apps = deprecatedGetAppsByIds(state)
  const appsLength = getAppsSelector(state).length
  const loadingApps = isAppsLoading(state) && appsLength === 0
  const loading = loadingApps || (isUsersLoadingES(state) && currentUsersAmount === 0 && employeeAmount === 0 && externalEmployeesAmount === 0)
  const featuresLoading = !isLoadedSupportedFeatures(state)

  return {
    idOrg,
    currentUsersAmount,
    externalEmployeesAmount,
    employeeAmount,
    apps,
    loading,
    loadingApps,
    featuresLoading
  }
}

const mapDispatchToProps = {
  deprecatedGetApps,
  getUserDetailsFields,
  getCurrentUsersAmount,
  getExternalUsersAmount,
  getUserTypeAmount
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
