import View from './view'
import { connect } from 'react-redux'
import { isAppsLoading } from '@selectors/apps'
import { withRouter } from 'react-router-dom'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { getExpensesSummary } from '@selectors/expenses'
import { getIsSmallScreen } from '@selectors/ui'
import { deprecatedGetApps, getTransactionsAggsByAppCategory, toggleUploadExpenses } from '@actions/'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const loading = isAppsLoading(state)
  const expensesSummary = getExpensesSummary(state)
  const total = sumBy(expensesSummary, 'total')
  const showEmptyState = !loading && (total === 0)

  const now = moment()
  const { id: idOrg } = getCurrentOrg(state)
  const year = parseInt(ownProps.match.params.year, 10) || now.year()
  const month = parseInt(ownProps.match.params.month, 10) || (now.month() + 1)

  return {
    idOrg,
    year,
    month,
    showEmptyState,
    isSmallScreen: getIsSmallScreen(state)
  }
}

const mapDispatchToProps = {
  deprecatedGetApps,
  toggleUploadExpenses,
  getTransactionsAggsByAppCategory
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
