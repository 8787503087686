import View from './view'
import { connect } from 'react-redux'
import { toggleAddUser } from '@actions/'

const mapStateToProps = state => {
  const { addUser: { isOpen, email } } = state.ui
  return {
    isOpen,
    email
  }
}

const mapDispatchToProps = {
  toggleAddUser
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleAddUser(false)
  },
  close () {
    dispatchProps.toggleAddUser(false)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps)(View)
