import View from './view'
import { connect } from 'react-redux'
import { getMe } from '../../pages/login/actions'
import { getSelf, isPaidPlanExpired } from '@selectors/me'

const mapStateToProps = state => {
  const me = getSelf(state)
  const paidPlanExpired = isPaidPlanExpired(state)
  return {
    authState: me.authState,
    me,
    idOrg: state.org.id,
    paidPlanExpired
  }
}

const mapDispatchToProps = {
  getMe
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
