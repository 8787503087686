import React, { ReactElement } from 'react'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import * as Style from './style'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import ToriiToggle from '@components/toriiToggle'
import { updateWorkflow } from '@actions/workflows'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateWorkflowActionContinueOnError } from './utils/updateWorkflowActionContinueOnError'
import { Button } from '@toriihq/design-system'
import { getButtonStyleProps } from './style'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { analytics } from '@shared/services/workflows/analytics'
import { handleExitDrawerAnalytics } from '@shared/services/workflows/analytics.utils'
import { useActionConfiguration } from '@pages/workflow_v2/hooks/actionConfiguration/useActionConfiguration'
import { DrawerSteps } from '../types'

interface Props {
  steps: DrawerSteps
  hideContinueOnErrorToggle: boolean
}

export const DrawerFooter = ({ steps, hideContinueOnErrorToggle }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { idWorkflow, idOrg } = useParams()
  const { idSelectedNode, idSelectedNodeStep, unselectNode, goToNextStep } = useWorkflowNavigation()
  const workflow = useSelectedWorkflow()
  const actionConfiguration = useActionConfiguration({ idAction: idSelectedNode })

  const totalNumberOfSteps = steps?.length
  const isLastStep = totalNumberOfSteps ? (idSelectedNodeStep === String(totalNumberOfSteps - 1)) : true
  const closeDrawer = () => {
    const triggerType = workflow.triggerType!
    handleExitDrawerAnalytics({
      idNode: idSelectedNode!,
      triggerType,
      actionName: actionConfiguration?.label,
      isCloseButton: true
    })

    unselectNode()
  }
  const onMainButtonClick = (isLastStep) => isLastStep ? closeDrawer : clickOnNext

  const clickOnNext = () => {
    analytics.action.clickOnNext({ actionName: actionConfiguration!.label })
    goToNextStep()
  }

  const updateActionContinueOnError = (updatedContinueOnError: boolean): void => {
    const updatedWorkflow = updateWorkflowActionContinueOnError({
      workflow,
      idNode: idSelectedNode!,
      updatedContinueOnError
    })
    dispatch(updateWorkflow({
      idOrg: Number(idOrg),
      idWorkflow: Number(idWorkflow),
      workflow: updatedWorkflow
    }))

    analytics.action.toggleContinueOnError({
      idWorkflow,
      continueOnError: updatedContinueOnError
    })
  }

  return (
    <Style.Layout hideContinueOnErrorToggle={hideContinueOnErrorToggle}>
      {!hideContinueOnErrorToggle && (
        <Style.ContinueOnErrorWrapper>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <ToriiToggle
              id={idSelectedNode}
              checked={actionConfiguration?.continueOnError}
              onToggle={updateActionContinueOnError}
            />
          </EnableFor>
          <Style.ContinueOnErrorText>Continue on error</Style.ContinueOnErrorText>
        </Style.ContinueOnErrorWrapper>
      )}
      <Button
        {...getButtonStyleProps(isLastStep)}
        onClick={onMainButtonClick(isLastStep)}
      />
    </Style.Layout>
  )
}
