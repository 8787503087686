import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getIdOrg,
  getLifecyclePreview as lifecyclePreview,
  isLoadingUserLifecyclePreview
} from '@selectors/org'
import eyesIcon from '../images/blue_eyes_left.svg'
import useTimeout from '@shared/hooks/useTimeout'
import * as Style from './style'
import { UserLifecycleSettingsContext } from '@components/popups/userLifecycleSettingsPopup/UserLifecycleSettingsContext'
import Spinner from '@media/spinner.svg'
import { Button, ButtonType, Icon } from '@toriihq/design-system'
import { sendUserLifecycleChangesReport } from '@actions/org'
import analytics from '@root/helpers/analytics'

export const UserLifecyclePreview = ({ selectedLifecycleApps }) => {
  const dispatch = useDispatch()
  const [reportSuccess, setReportSuccess] = useState(false)
  const idOrg = useSelector(getIdOrg)
  const isLoadingPreview = useSelector(isLoadingUserLifecyclePreview)
  const preview = useSelector(lifecyclePreview)

  const { updateLifecycleSettings } = useContext(UserLifecycleSettingsContext)

  const [pastUsers, setPastUsers] = useState()
  const [currentUsers, setCurrentUsers] = useState()
  const [workflows, setWorkflows] = useState()
  const [fakeWaitingSpinner, setFakeWaitingSpinner] = useState(true)

  useTimeout(() => {
    setFakeWaitingSpinner(false)
  }, 1000, { persistRenders: true })

  useEffect(() => {
    if (preview) {
      setPastUsers(preview.pastUsers)
      setCurrentUsers(preview.currentUsers)
      setWorkflows(preview.workflows)
      updateLifecycleSettings({ preview })
    }
  }, [preview, updateLifecycleSettings])

  const showSpinner = isLoadingPreview || fakeWaitingSpinner

  const InfoLine = ({ children, show }) => {
    if (show && !fakeWaitingSpinner) {
      return (
        <div {...Style.PreviewLine}>{children}</div>
      )
    } else {
      return (<></>)
    }
  }

  const onReportClick = async () => {
    analytics.track('Click on send user lifecycle changes report button')
    await dispatch(sendUserLifecycleChangesReport({ idOrg, lifecycleApps: selectedLifecycleApps }))
    setReportSuccess(true)
  }

  return (
    <div {...Style.Content}>
      {showSpinner && (
        <div {...Style.Spinner}>
          <img alt='Loading preview...' src={Spinner} width='26px' height='26px' />
          {'Loading...'}
        </div>
      )}
      {!showSpinner && (
        <Fragment>
          <div {...Style.Left}>
            <InfoLine show={preview && pastUsers > 0}>
              <strong>{`${pastUsers} users`}</strong>&nbsp;will be moved to past users list
            </InfoLine>
            <InfoLine show={preview && currentUsers > 0}>
              <strong>{`${currentUsers} users`}</strong>&nbsp;will be moved to current users list
            </InfoLine>
            <InfoLine show={preview && workflows > 0}>
              Triggers of&nbsp;<strong>{`${workflows} workflows`}</strong>&nbsp;will be changed
            </InfoLine>
            <InfoLine show={preview && workflows > 0}>
              There might be more workflows that will be affected
            </InfoLine>
            <InfoLine show={preview && workflows === 0}>
              There might be workflows that will be affected
            </InfoLine>
            <br />
            { reportSuccess
              ? <Style.RequestSent>
                <Icon name={'CheckCircle'} color={'secondary'} />
                We’re on it! check your email in a few minutes
              </Style.RequestSent>
              : <Button icon={'Mail'} label={'Email full changes report'} onClick={onReportClick} type={ButtonType.secondary} />
            }
          </div>
          <div {...Style.Right}>
            <img src={eyesIcon} alt={'eyes'} height={Style.EyesSize} />
          </div>
        </Fragment>
      )}
    </div>
  )
}
