import styled from 'styled-components'
import { Subtitle, Text } from '../../styles'

export const ErrorSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.palette.text.error}
`

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.palette.text.error}
`
