import React from 'react'
import { Button, ButtonType, Menu } from '@toriihq/design-system'
import { OFFBOARDING_APPS_STATUS, SCOPES, TORII_BOT_ID_USER, WORKFLOW_ACTION_TYPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { getActionFromExecutions } from '@lenses/offboarding'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import noop from 'lodash/noop'

const UserAppsActionMenu = ({
  isOffboardingIgnored,
  isUserRemovedFromApp,
  appRemovedBy,
  executions,
  idApp,
  idAppAccount,
  idUser,
  originEmail,
  status,
  executionErrorType,
  changeRemovalStatus,
  onConfigureAppClick
}) => {
  const onMenuClick = (value: 'done' | 'unmark' | 'reconfigure') => {
    const sortedExecutionsByCreationTime = [...executions].sort((a, b) => Number(new Date(a.creationTime)) - Number(new Date(b.creationTime)))
    const latestExecutionAction = get(sortedExecutionsByCreationTime, [sortedExecutionsByCreationTime.length - 1, 'actions', 0], {})
    const {
      idTask,
      idWorkflowActionExecution: idActionExe,
      idTicket
    } = latestExecutionAction

    if (value === 'done') {
      changeRemovalStatus({ idApp, isRemoved: true, showPopupWhenAllAppsAreRemoved: true, idTask, idActionExe, idAppAccount, idTicket, idUser, originEmail })
    } else if (value === 'unmark') {
      changeRemovalStatus({ idApp, isRemoved: false, showPopupWhenAllAppsAreRemoved: true, idAppAccount, idUser, originEmail })
    } else if (value === 'reconfigure') {
      onConfigureAppClick({ idApp, idAppAccount })
    }
  }
  const allOptions = [
    {
      type: 'item',
      label: 'Mark as done',
      value: 'done',
      shouldShow: () => !isOffboardingIgnored && !isUserRemovedFromApp,
      onClick: () => onMenuClick('done')
    },
    {
      type: 'item',
      label: 'Mark as not-done',
      value: 'unmark',
      shouldShow: () => {
        const action = getActionFromExecutions(executions)
        if (isUserRemovedFromApp && !isOffboardingIgnored) {
          if (action?.actionType === WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE) {
            return isNil(get(getActionFromExecutions(executions), ['ticketCompletionTime']))
          }
          return appRemovedBy !== TORII_BOT_ID_USER
        }
        return false
      },
      onClick: () => onMenuClick('unmark')
    },
    {
      type: 'item',
      label: (status === OFFBOARDING_APPS_STATUS.needsAttention && !executionErrorType) ? 'Configure' : 'Reconfigure',
      value: 'reconfigure',
      shouldShow: () => !isOffboardingIgnored && !isUserRemovedFromApp,
      onClick: () => onMenuClick('reconfigure')
    }
  ]

  const options = allOptions.filter(option => option.shouldShow())

  if (!options.length) {
    return null
  }

  return (
    <Menu items={options}>
      <EnableFor scopes={[SCOPES.OFFBOARDING_WRITE]}>
        <Button type={ButtonType.secondary} icon='Dots' onClick={noop} />
      </EnableFor>
    </Menu>
  )
}

export default UserAppsActionMenu
