import send from '@shared/redux-fetch'
import { CREATE_API_KEY, DELETE_API_KEY, GET_API_KEYS } from '@root/constants'
import { CreateApiKeyProps, DeleteApiKeyProps, GetApiKeysProps } from '@actions/apiKeys/types'

export const getApiKeys = ({ idOrg }: GetApiKeysProps) => {
  return send(GET_API_KEYS, {
    url: `/api/orgs/${idOrg}/apiKeys`
  })
}

export const createApiKey = ({ idOrg, permission, expirationTime, description }: CreateApiKeyProps) => {
  return send(CREATE_API_KEY, {
    url: `/api/orgs/${idOrg}/apiKeys`,
    method: 'POST',
    body: JSON.stringify({
      permission,
      expirationTime,
      description
    })
  })
}

export const deleteApiKey = ({ idOrg, id }: DeleteApiKeyProps) => {
  return send(DELETE_API_KEY, {
    url: `/api/orgs/${idOrg}/apiKeys/${id}`,
    method: 'DELETE'
  })
}
