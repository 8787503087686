import View from './view'
import { connect } from 'react-redux'
import { getCurrencySymbol } from '@selectors/org'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { getExpensesByCategory } from '@selectors/transactions'
import { EMPTY_ARRAY } from '@root/constants'

const mapStateToProps = (state) => {
  const currencySymbol = getCurrencySymbol(state)
  const expensesSummary = getExpensesByCategory(state) || EMPTY_ARRAY
  const allCategories = {}
  const expenses = expensesSummary
    .map((expense, index, array) => {
      const prevMonth = index ? array[index - 1].total / 100 : 0
      const currMonth = expense.total / 100
      const diff = currMonth - prevMonth
      return {
        date: expense.date,
        value: expense.total,
        percentage: prevMonth ? ((currMonth - prevMonth) / prevMonth) : 0,
        diff,
        name: moment.utc(expense.date).format('MMM YY'),
        categories: expense.appCategory.reduce((categories, category) => {
          const categoryName = category.appCategory
          allCategories[categoryName] = true
          categories[categoryName] = category.total
          return categories
        }, {})
      }
    })
  const reorderAndPadCategories = expense => {
    const temp = expense.categories
    expense.categories = {}
    Object.keys(allCategories).forEach(categoryName => {
      expense.categories[categoryName] = temp[categoryName] || 0
    })
  }
  expenses.forEach(reorderAndPadCategories)
  return {
    loading: isEmpty(expenses),
    currencySymbol,
    expenses
  }
}

export default connect(mapStateToProps)(View)
