import React from 'react'
import PropTypes from 'prop-types'
import { FormElement, Link } from '@toriihq/design-system'

const LabelAction = ({ labelAction, learnMoreUrl }) => {
  if (labelAction?.component) {
    return labelAction.component
  } if (learnMoreUrl) {
    return <Link target='_blank' href={learnMoreUrl}>Learn more</Link>
  }

  return null
}
const FormGroup = ({ disabled, label, labelAction, details, error, children, isRequired, tooltip, learnMoreUrl }) => {
  return (
    <div style={{ marginBottom: '24px' }}>
      <FormElement
        label={label}
        description={details}
        labelHelper={tooltip}
        labelAction={LabelAction({ labelAction, learnMoreUrl })}
        errorMessage={error}
        required={isRequired}
        disabled={disabled}
      >
        {children}
      </FormElement>
    </div>
  )
}

FormGroup.propTypes = {
  label: PropTypes.string,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  labelAction: PropTypes.object,
  details: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  learnMoreUrl: PropTypes.string,
  tooltip: PropTypes.element
}

export default FormGroup
