import React from 'react'
import { Button, ButtonType, Tooltip } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'
import Cookie from 'cookie'

const DeleteURLCookieButton = () => {
  const deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  const removeQueryParam = (param) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.replaceState({}, document.title, url.pathname + url.search)
  }

  const onClick = () => {
    Analytics.track('Click to delete URL cookie')
    deleteCookie('API_BASE_URL')
    removeQueryParam('API_BASE_URL')
    window.location.reload()
  }
  const cookies = document ? Cookie.parse(document.cookie || '') : {}

  return cookies.API_BASE_URL ? <Tooltip
    placement='bottom'
    label={`Delete Preview URL: ${cookies.API_BASE_URL}`}>
    <Button type={ButtonType.tertiaryDestructive} icon='Danger' onClick={onClick} />
  </Tooltip> : null
}

export default DeleteURLCookieButton
