import React, { ReactElement } from 'react'
import { ReactFlowProvider } from 'reactflow'
import { Workflow } from '@shared/workflows/types'
import { CanvasContainer, Container } from './styles'
import { WorkflowAuditToolbar } from './workflowAuditToolbar'
import { WorkflowDrawer } from './workflowDrawer'
import { WorkflowGraph } from './workflowGraph'
import { WorkflowHeader } from './workflowHeader'

interface Props {
  workflow: Workflow
}

export const WorkflowEditor = ({
  workflow
}: Props): ReactElement => (<>
  <Container>
    <WorkflowAuditToolbar />
    <WorkflowHeader />
    <CanvasContainer>
      <ReactFlowProvider>
        <WorkflowGraph workflow={workflow} />
      </ReactFlowProvider>
    </CanvasContainer>
  </Container>
  <WorkflowDrawer workflow={workflow} />
</>)
