import { useSelector } from 'react-redux'
import {
  IdWorkflowNode,
  WORKFLOW_NODE_TYPE,
  WorkflowActionsModel,
  WorkflowNodes
} from '@shared/workflows/types'
import {
  ActionsConfigurationByIdAction,
  ActionsConfigurationUtils,
  Props
} from './types'
import { getActionsConfigByType } from '@selectors/workflows'
import { isNil } from 'lodash'
import { ActionConfiguration, ActionStep } from '../types'
import { createActionConfiguration } from '../utils'

export const useActionsConfiguration = ({ workflow } : Props): ActionsConfigurationUtils => {
  const actionsConfigByType = useSelector(getActionsConfigByType)

  const getActionsConfigurationByIdAction = (): ActionsConfigurationByIdAction => {
    const actionsConfigurationByIdAction = {}
    const workflowActions: WorkflowActionsModel | null = workflow.actions
    const workflowNodes: WorkflowNodes = workflowActions?.nodes || {}

    for (const [idWorkflowNode, workflowNode] of Object.entries(workflowNodes)) {
      const actionConfiguration = createActionConfiguration(idWorkflowNode, workflowNode, actionsConfigByType)
      actionsConfigurationByIdAction[idWorkflowNode] = actionConfiguration
    }

    return actionsConfigurationByIdAction
  }

  const getActionConfiguration = (idAction: IdWorkflowNode): ActionConfiguration | undefined => {
    const actionsConfigurationByIdAction = getActionsConfigurationByIdAction()
    return actionsConfigurationByIdAction[idAction]
  }

  const getActionLabel = (idAction: IdWorkflowNode): string | undefined => {
    const actionConfiguration = getActionConfiguration(idAction)
    return actionConfiguration?.label
  }

  const getActionSteps = (idAction: IdWorkflowNode): ActionStep[] | undefined => {
    const actionConfiguration = getActionConfiguration(idAction)
    return actionConfiguration?.steps
  }

  const doesActionExist = (idAction: IdWorkflowNode): boolean =>
    !isNil(getActionConfiguration(idAction))

  const isActionValid = (idAction: IdWorkflowNode): boolean | undefined => {
    const actionConfiguration = getActionConfiguration(idAction)
    return actionConfiguration?.isValid
  }

  const isActionTypeSelected = (idWorkflowNode: IdWorkflowNode): boolean => {
    const workflowNode = workflow.actions?.nodes[idWorkflowNode]
    if (!workflowNode) {
      return false
    }

    if (workflowNode.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
      return true
    }

    if (workflowNode) {
      const { action } = workflowNode
      return Boolean(action.type)
    }

    return false
  }

  const getFixedBranchesLabelsText = (idAction: IdWorkflowNode): string => {
    const actionConfiguration = getActionConfiguration(idAction)
    if (!actionConfiguration?.branchesConfiguration) {
      return ''
    }

    const { default: defaultBranch, branches } = actionConfiguration.branchesConfiguration
    return [...branches.map(branch => branch.label), defaultBranch.label]
      .map(label => `"${label}"`)
      .join(' and ')
  }

  return {
    getActionConfiguration,
    getActionLabel,
    doesActionExist,
    isActionValid,
    getActionSteps,
    isActionTypeSelected,
    getFixedBranchesLabelsText
  }
}
