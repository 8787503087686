import View from './view'
import { connect } from 'react-redux'
import { toggleWorkflowStartedPopup, toggleManuallyRunWorkflowPopup, triggerWorkflow } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { getTriggersByType, getWorkflow } from '@selectors/workflows'
import { EMPTY_OBJECT } from '../../../constants'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => {
  const { manuallyRunWorkflowPopup } = state.ui
  const { id: idOrg } = getCurrentOrg(state)
  const { outputSchema } = getTriggersByType(state)[manuallyRunWorkflowPopup.triggerType] || EMPTY_OBJECT
  const { triggerConfiguration } = getWorkflow(state, { idWorkflow: manuallyRunWorkflowPopup.idWorkflow })

  return {
    ...manuallyRunWorkflowPopup,
    triggerConfiguration,
    idOrg,
    outputSchema
  }
}

const mapDispatchToProps = {
  toggleManuallyRunWorkflowPopup,
  triggerWorkflow,
  toggleWorkflowStartedPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  close () {
    dispatchProps.toggleManuallyRunWorkflowPopup({ isOpen: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
