import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontSize, fontWeight } from '@shared/style/sizes'
import texts from '@shared/style/texts'

export const WorkflowActivationSelectionPopup = {
  modal: {
    width: '778px'
  }
}

export const LoadingOrNoEntitiesPopup = {
  modal: {
    width: '564px'
  }
}

export const Container = css({
  position: 'relative',
  height: '133px'
})

export const LoadingContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100px',
  marginBottom: '40px',
  marginTop: '16px'
})

export const LoadingContent = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const PopupContent = css({
  paddingTop: '24px',
  paddingBottom: '0'
})

export const LoadingText = css({
  marginTop: '10px',
  color: colors.grey1,
  fontSize: fontSize.small
})

export const SelectionQuestion = css({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semiBold,
  marginBottom: '24px'
})

export const SelectionsContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  gap: '8px'
})

export const SelectionBox = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${colors.lightBlue5}`,
  borderRadius: '6px',
  cursor: 'pointer',
  width: '347px',
  padding: '20px',
  fontSize: fontSize.small
})

export const PreviewCountText = css(texts.subheading, {
  color: colors.grey1,
  marginTop: '24px',
  marginBottom: '10px',
  fontSize: fontSize.small
})

export const EmptyStateTitle = css({
  fontWeight: fontWeight.bold,
  marginBottom: '8px'
})

export const EmptyStateContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  marginBottom: '50px',
  marginTop: '6px'
})

export const EmptyStateTextContainer = css({
  width: '365px',
  fontSize: fontSize.small,
  marginRight: '20px'
})

export const EmptyStateNoEntitiesSentence = css({
  marginBottom: '20px'
})

export const PreviewTableTd = {
  padding: '3px 10px'
}

export const PreviewTable = {
  minHeight: '243px',
  maxHeight: '243px'
}
