import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Table from '../table'
import { Tooltip, Button, ButtonType, EmptyState } from '@toriihq/design-system'
import { renderAppCell } from '../table/appCell'
import UserDetails from '../userDetails'
import moment from 'moment'
import exportCSV from '@helpers/exportCSV'
import { deprecatedGetAppsByIds, isAppsLoading } from '@selectors/apps'
import { getIdOrg, getIsAppOwnerActive } from '@selectors/org'
import debounce from 'lodash/debounce'
import { SCOPES, TABLES, TORII_APP_ID } from '@root/constants'
import emptyImage from '@media/emptyState.svg'
import { css } from 'glamor'
import UsageIcon from '@components/usageIcon'
import { getAppUsers } from '@actions/'
import { getAppUsersES } from '@selectors/appUsers'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { getBadgeByStatus } from '@root/shared/customUserBadges'
import ToggleCard from '@components/toggleCard'
import AppOwnersToriiAccessPopup from '@components/popups/appOwnersToriiAccessPopup'
import { getIsSmallScreen } from '@selectors/ui'
import AppOwnerActions from './appOwnerActions/appOwnerActions'
import { SUPPORT_ARTICLES } from '@root/articles'
import useEffectAfterMount from '@shared/hooks/useEffectAfterMount'

const CSS = {
  row: css({}),
  actions: css({
    textAlign: 'right'
  })
}

CSS.hoverActions = css({
  opacity: 0,
  [`.${CSS.row}:hover &, [data-${CSS.row}]:hover &`]: {
    opacity: 1
  }
})

export const exportAppOwnersCSV = async (appOwners, appsById) => {
  const columnNames = ['User', 'Email', 'Number of Applications', 'Applications']
  const dataForCSV = appOwners.map(appOwner => {
    const appOwnerAppsNames = appOwner.idApps.map(appId => appsById[appId].name)
    return {
      'User': appOwner.fullName,
      'Email': appOwner.email,
      'Number of Applications': appOwner.idApps.length,
      'Applications': appOwnerAppsNames.join(', ')
    }
  })
  exportCSV(`appOwners_${moment().format('DD_MMMM_YYYY')}.csv`, dataForCSV, columnNames)
}

export const getData = (queryString, appOwners, apps) => {
  if (!queryString) {
    return appOwners
  }

  const lowercaseQ = queryString.toLowerCase()

  const ownSearchedApps = (appIds) => {
    return appIds.some((appId) => apps[appId].name.toLowerCase().includes(lowercaseQ))
  }

  const matchByName = (firstName, lastName, fullName) => {
    const firstNameMatch = firstName && firstName.toLowerCase().includes(lowercaseQ)
    const lastNameMatch = lastName && lastName.toLowerCase().includes(lowercaseQ)
    const fullNameMatch = fullName && fullName.toLowerCase().includes(lowercaseQ)

    return fullNameMatch || lastNameMatch || firstNameMatch
  }

  const matchByEmail = (email) => {
    return email && email.toLowerCase().includes(lowercaseQ)
  }

  return appOwners.filter(appOwner => matchByName(appOwner.firstName, appOwner.lastName, appOwner.fullName) || ownSearchedApps(appOwner.idApps) || matchByEmail(appOwner.email))
}

export const addScoreAndLastVisitTimeToAppOwners = ({ appOwners, toriiUsers }) => {
  if (isEmpty(appOwners) || isEmpty(toriiUsers)) {
    return
  }

  appOwners.forEach(user => {
    const userAsToriiUser = toriiUsers.find(toriiUser => toriiUser.idUser === user.id)
    if (userAsToriiUser) {
      user.score = userAsToriiUser.score
      user.lastVisitTime = userAsToriiUser.lastVisitTime
    }
  })
}

const setTrGroupStyle = () => ({
  className: CSS.row.toString()
})

const AppOwners = (props) => {
  const { appOwners, loading, setAutoReloadAppOwners } = props
  const isLoadingApps = useSelector(isAppsLoading)
  const appsById = useSelector(deprecatedGetAppsByIds)
  const idOrg = useSelector(getIdOrg)
  const [ queryString, setQueryString ] = useState('')
  const history = useHistory()
  const hasAppOwners = appOwners?.length > 0
  const isAppOwnerActive = useSelector(getIsAppOwnerActive)
  const toriiUsers = useSelector(getAppUsersES)[TORII_APP_ID]
  const dispatch = useDispatch()
  const isSmallScreen = useSelector(getIsSmallScreen)
  const [isAppOwnersToriiAccessPopupOpen, setIsAppOwnersToriiAccessPopupOpen] = useState(false)

  if (isAppOwnerActive) {
    addScoreAndLastVisitTimeToAppOwners({ appOwners, toriiUsers })
  }

  useEffect(() => {
    if (isAppOwnerActive && idOrg) {
      dispatch(getAppUsers({ idOrg, idApp: TORII_APP_ID }))
    }
  }, [idOrg, dispatch, isAppOwnerActive])

  useEffectAfterMount(() => {
    setAutoReloadAppOwners(true)

    return () => setAutoReloadAppOwners(false)
  }, [isAppOwnerActive])

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 150,
      Cell: (data) => {
        const { id, firstName, lastName, email, isDeletedInIdentitySources, photoUrl, status, lifecycleStatus } = data.row._original

        return (
          <UserDetails
            firstName={firstName}
            lastName={lastName}
            email={email}
            idUser={id}
            isDeletedInIdentitySources={isDeletedInIdentitySources}
            showPastUserBadge
            lifecycleStatus={lifecycleStatus}
            photoUrl={photoUrl}
            badge={getBadgeByStatus(status)}
          />
        )
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 150,
      show: true
    },
    {
      Header: (
        <Tooltip
          label='Usage is based on visits to Torii in the last 30 days'
        >Torii Usage</Tooltip>
      ),
      textHeader: 'Torii Usage',
      accessor: 'score',
      style: { display: 'flex', alignItems: 'center ' },
      Cell: ({ value: score, row: { _original: { lastVisitTime } } }) => <UsageIcon isUserUsage score={score} lastVisitTime={lastVisitTime} />,
      show: Boolean(isAppOwnerActive)
    },
    {
      Header: 'Owned Apps',
      id: 'idApps',
      accessor: (row) => (row.idApps || []).length,
      Cell: (row) => renderAppCell({ original: row.original, apps: appsById, numberOfOwnedAppsToDisplay: 4 }),
      hideFromCSV: true,
      minWidth: 150
    },
    {
      id: 'action',
      Header: '',
      sortable: false,
      className: css(CSS.actions, !isSmallScreen && CSS.hoverActions).toString(),
      hideFromCSV: true,
      maxWidth: 80,
      Cell: (data) => {
        const user = { ...data.row._original }
        return <AppOwnerActions user={user} idOrg={idOrg} />
      }
    }
  ]

  const onSearch = debounce((q) => {
    setQueryString(q)
  }, 250)
  const onHandleAddAppOwner = () => history.push(`/team/${idOrg}/applications`)

  const renderNoAppOwners = () => {
    return (
      <EmptyState
        image={<img alt='no app owners' src={emptyImage} />}
        title='You have no app owners yet'
        description={'You can add app owners in the applications page.'}
        buttons={[
          <Button type={ButtonType.primary} onClick={onHandleAddAppOwner} label='Add app owners' />
        ]}
      />
    )
  }

  const onAppOwnerToggle = (toggleAction) => {
    setIsAppOwnersToriiAccessPopupOpen(true)
  }

  const onPopupClose = () => {
    setIsAppOwnersToriiAccessPopupOpen(false)
  }

  const AppOwnerToggleCardProps = {
    id: 'isAppOwnerActiveToggle',
    title: 'App owners access to Torii',
    description: 'App owners will get Torii access to manage the apps they own. ',
    infoLink: SUPPORT_ARTICLES.APP_OWNER_ACCESS_FOR_TORII_ADMINS,
    onToggle: onAppOwnerToggle,
    scopes: [SCOPES.MEMBERS_AND_ROLES_WRITE]
  }

  return (
    <>
      {isAppOwnersToriiAccessPopupOpen ? <AppOwnersToriiAccessPopup
        isOpen={isAppOwnersToriiAccessPopupOpen}
        onClose={onPopupClose}
        idOrg={idOrg}
        numberOfAppOwners={!loading && appOwners ? appOwners.length : 0}
        isAppOwnerActive={isAppOwnerActive}
      /> : null}
      <ToggleCard {...AppOwnerToggleCardProps} checked={isAppOwnerActive} />
      <Table
        tableKey={TABLES.teamUsersTable.key}
        data={getData(queryString, appOwners, appsById) || []}
        columns={columns}
        loading={loading || isLoadingApps}
        searchable={hasAppOwners}
        forceShowSearch
        onSearch={onSearch}
        defaultSort={[{ id: 'idApps', desc: true }]}
        emptyStateMessage={renderNoAppOwners()}
        exportable={hasAppOwners}
        exportFunction={() => exportAppOwnersCSV(appOwners, appsById)}
        ignoreWhitespace
        getTrGroupProps={setTrGroupStyle}
      />
    </>
  )
}

AppOwners.propTypes = {
  appOwners: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      photoUrl: PropTypes.string,
      fullName: PropTypes.string,
      isDeletedInIdentitySources: PropTypes.bool,
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      idApps: PropTypes.arrayOf(PropTypes.number)
    })
  ),
  loading: PropTypes.bool,
  setAutoReloadAppOwners: PropTypes.func
}

export default AppOwners
