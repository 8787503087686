import {
  GET_ORG,
  UPDATE_ORG,
  UPDATE_APP_CATALOG_SETTINGS,
  CREATE_SOURCE_TOKEN,
  EXTENSION_INSTALLED,
  GET_EMAIL,
  GET_SCORE_METRICS,
  GET_SUPPORTED_FEATURES,
  UPDATE_FEATURE_USABILITY_STATUS,
  UPDATE_ORG_TRIAL_END_TIME,
  UPDATE_ORG_PAID_PLAN_END_TIME,
  GET_CATALOG_SETTINGS,
  GET_ORG_DOMAINS,
  UPDATE_ORG_DOMAINS,
  GET_LIFECYCLE_APPS,
  GET_LIFECYCLE_PREVIEW,
  UPDATE_LIFECYCLE_SETTINGS,
  GET_LIFECYCLE_SETTINGS,
  UPDATE_ORG_IS_DISABLED,
  UPDATE_IS_SCIM_ENABLED,
  CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION,
  UPDATE_ORG_APP_OWNER_SETTINGS,
  UPDATE_ORG_LOGO,
  REMOVE_ORG_LOGO,
  GET_USER_TYPE_SOURCES,
  UPDATE_ORG_PLAN,
  GET_MERGE_USERS_RULES,
  CREATE_MERGE_USERS_RULES,
  UPDATE_MERGE_USERS_RULE,
  DELETE_MERGE_USERS_RULE,
  GET_SUBSCRIPTIONS,
  UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION,
  GET_OFFBOARDING_SETTINGS,
  UPDATE_APP_OFFBOARDING_SETTINGS,
  UPDATE_IS_TORII_SUPPORT_ALLOWED_FOR_ORG
} from '@root/constants'
import ParseValidation from '@helpers/parse-validation'
import config from '@root/config'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'

const initialState = {
  loading: false,
  errorCode: null,
  metrics: {
    loading: false,
    isLoaded: false
  },
  catalogSettings: {
    resources: { users: {} }
  },
  loadingCatalogSettings: false,
  loadedCatalogSettings: false,
  offboardingSettings: {},
  loadingOffboardingSettings: false,
  isLoadedSupportedFeatures: false,
  domains: {
    loading: false,
    isLoaded: false,
    list: []
  },
  lifecycleSettings: {
    loading: false,
    isLoaded: false,
    userLifecycleConfig: []
  },
  lifecycleApps: {
    loading: false,
    isLoaded: false,
    apps: []
  },
  isDisabled: 0,
  isScimEnabled: false,
  isAppOwnerActive: false,
  loadingUserTypeSources: false,
  loadingMergeUsersRules: false,
  userTypeSources: [],
  mergeUsersRules: {
    loading: false,
    rules: []
  },
  subscriptions: {
    data: [],
    loading: false
  }
}

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SCORE_METRICS}_PENDING`: {
      return {
        ...state,
        metrics: {
          loading: true,
          isLoaded: false
        }
      }
    }
    case `${GET_SCORE_METRICS}_RESPONSE`: {
      const metrics = action.payload
      return {
        ...state,
        metrics: {
          loading: false,
          isLoaded: true,
          ...metrics
        }
      }
    }
    case `${GET_ORG}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_ORG}_RESPONSE`: {
      const { org } = action.payload
      return {
        ...state,
        ...org,
        errorCode: null,
        loading: false
      }
    }
    case `${GET_SUPPORTED_FEATURES}_RESPONSE`:
    case `${UPDATE_FEATURE_USABILITY_STATUS}_RESPONSE`: {
      const { supportedFeatures } = action.payload
      return {
        ...state,
        isLoadedSupportedFeatures: true,
        supportedFeatures: {
          ...config.features,
          ...supportedFeatures
        }
      }
    }
    case `${UPDATE_FEATURE_USABILITY_STATUS}_PENDING`: {
      const { feature, isEnabled } = action.meta
      return {
        ...state,
        supportedFeatures: {
          ...state.supportedFeatures,
          [feature]: isEnabled
        }
      }
    }
    case `${GET_CATALOG_SETTINGS}_PENDING`: {
      return {
        ...state,
        loadingCatalogSettings: true
      }
    }
    case `${GET_CATALOG_SETTINGS}_RESPONSE`: {
      const { catalogSettings, resources } = action.payload
      return {
        ...state,
        catalogSettings: {
          ...catalogSettings,
          resources: {
            ...resources
          }
        },
        loadingCatalogSettings: false,
        loadedCatalogSettings: true
      }
    }
    case `${UPDATE_APP_CATALOG_SETTINGS}_PENDING`: {
      const dataToUpdate = action.meta
      return {
        ...state,
        catalogSettings: {
          ...state.catalogSettings,
          ...dataToUpdate
        }
      }
    }
    case `${GET_OFFBOARDING_SETTINGS}_PENDING`: {
      return {
        ...state,
        loadingOffboardingSettings: true
      }
    }
    case `${GET_OFFBOARDING_SETTINGS}_RESPONSE`: {
      const { offboardingSettings } = action.payload
      return {
        ...state,
        offboardingSettings,
        loadingOffboardingSettings: false
      }
    }
    case `${UPDATE_APP_OFFBOARDING_SETTINGS}_PENDING`: {
      const dataToUpdate = action.meta
      return {
        ...state,
        offboardingSettings: {
          ...state.offboardingSettings,
          ...dataToUpdate
        }
      }
    }
    case `${GET_EMAIL}_RESPONSE`: {
      const validation = ParseValidation(action.payload)
      const { error } = validation
      const { org } = action.payload

      if (error) {
        return state
      }

      return {
        ...state,
        ...org
      }
    }
    case 'TOGGLE_CONNECT_SOURCE': {
      const { isConnectSourceOpen } = action.payload
      return {
        ...state,
        servicesConnectionError: isConnectSourceOpen === false ? false : state.servicesConnectionError
      }
    }
    case `${CREATE_SOURCE_TOKEN}_RESPONSE`: {
      const { source } = action.payload
      const servicesConnectionStatus = { ...state.servicesConnectionStatus }
      servicesConnectionStatus[source] = true
      return {
        ...state,
        servicesConnectionStatus,
        servicesConnectionError: false
      }
    }
    case `${CREATE_SOURCE_TOKEN}_FAILED`: {
      return {
        ...state,
        servicesConnectionError: true
      }
    }
    case `${CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION}_RESPONSE`: {
      const { source } = action.payload
      const servicesConnectionStatus = { ...state.servicesConnectionStatus }
      servicesConnectionStatus[source] = true
      return {
        ...state,
        servicesConnectionStatus,
        servicesConnectionError: false
      }
    }
    case `${CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION}_FAILED`: {
      return {
        ...state,
        servicesConnectionError: true
      }
    }
    case `${GET_ORG}_FAILED`: {
      return {
        ...state,
        errorCode: action?.payload?.statusCode,
        loading: false
      }
    }
    case EXTENSION_INSTALLED: {
      return {
        ...state,
        isConnectedToExtension: state.isConnectedToExtension || action.payload.isInstalled
      }
    }
    case `${UPDATE_ORG_TRIAL_END_TIME}_RESPONSE`: {
      return {
        ...state,
        trialEndTime: action.payload.org.trialEndTime
      }
    }
    case `${UPDATE_ORG_PAID_PLAN_END_TIME}_RESPONSE`: {
      const { trialEndTime, paidPlanEndTime } = action.payload.org

      return {
        ...state,
        trialEndTime,
        paidPlanEndTime
      }
    }
    case `${UPDATE_ORG}_PENDING`: {
      const org = omitBy(action.meta, isUndefined)
      return {
        ...state,
        ...org
      }
    }
    case `${UPDATE_ORG}_RESPONSE`: {
      const { org } = action.payload
      return {
        ...state,
        ...org
      }
    }
    case `${UPDATE_IS_TORII_SUPPORT_ALLOWED_FOR_ORG}_RESPONSE`: {
      const { isToriiSupportAllowedForOrg } = action.meta
      return {
        ...state,
        isToriiSupportAllowedForOrg
      }
    }
    case `${UPDATE_ORG_APP_OWNER_SETTINGS}_RESPONSE`: {
      return {
        ...state,
        isAppOwnerActive: action.payload.org.isAppOwnerActive
      }
    }
    case `${GET_ORG_DOMAINS}_PENDING`: {
      return {
        ...state,
        domains: {
          loading: true
        }
      }
    }
    case `${UPDATE_ORG_DOMAINS}_RESPONSE`:
    case `${GET_ORG_DOMAINS}_RESPONSE`: {
      const { domains } = action.payload
      return {
        ...state,
        domains: {
          loading: false,
          isLoaded: true,
          list: domains
        }
      }
    }
    case `${UPDATE_LIFECYCLE_SETTINGS}_PENDING`: {
      return {
        ...state,
        lifecycleSettings: {
          updating: true
        }
      }
    }
    case `${UPDATE_LIFECYCLE_SETTINGS}_FAILED`: {
      return {
        ...state,
        lifecycleSettings: {
          updating: false
        }
      }
    }
    case `${UPDATE_LIFECYCLE_SETTINGS}_RESPONSE`: {
      const { userLifecycleConfig } = action.payload
      return {
        ...state,
        lifecycleSettings: {
          updating: false,
          userLifecycleConfig: userLifecycleConfig
        }
      }
    }
    case `${UPDATE_ORG_IS_DISABLED}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${UPDATE_ORG_IS_DISABLED}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${UPDATE_ORG_IS_DISABLED}_RESPONSE`: {
      return {
        ...state,
        loading: false,
        isDisabled: +!state.isDisabled
      }
    }
    case `${UPDATE_ORG_PLAN}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${UPDATE_ORG_PLAN}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${UPDATE_ORG_PLAN}_RESPONSE`: {
      const { idPlan } = action.meta
      return {
        ...state,
        loading: false,
        idPlan
      }
    }
    case `${UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION}_PENDING`: {
      const { allowOnlyYearlySubscription } = action.meta

      return {
        ...state,
        loading: true,
        allowOnlyYearlySubscription
      }
    }
    case `${UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION}_FAILED`: {
      const { allowOnlyYearlySubscription } = action.meta

      return {
        ...state,
        loading: false,
        allowOnlyYearlySubscription: !allowOnlyYearlySubscription
      }
    }
    case `${UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION}_RESPONSE`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_LIFECYCLE_SETTINGS}_PENDING`: {
      return {
        ...state,
        lifecycleSettings: {
          loading: true
        }
      }
    }
    case `${GET_LIFECYCLE_SETTINGS}_FAILED`: {
      return {
        ...state,
        lifecycleSettings: {
          loading: false,
          isLoaded: false
        }
      }
    }
    case `${GET_LIFECYCLE_SETTINGS}_RESPONSE`: {
      const { userLifecycleConfig } = action.payload
      return {
        ...state,
        lifecycleSettings: {
          loading: false,
          isLoaded: true,
          userLifecycleConfig: userLifecycleConfig
        }
      }
    }
    case `${GET_LIFECYCLE_APPS}_PENDING`: {
      return {
        ...state,
        lifecycleApps: {
          loading: true
        }
      }
    }
    case `${GET_LIFECYCLE_APPS}_FAILED`: {
      return {
        ...state,
        lifecycleApps: {
          loading: false
        }
      }
    }
    case `${GET_LIFECYCLE_APPS}_RESPONSE`: {
      return {
        ...state,
        lifecycleApps: {
          loading: false,
          isLoaded: true,
          apps: action.payload
        }
      }
    }
    case `${GET_LIFECYCLE_PREVIEW}_PENDING`: {
      return {
        ...state,
        lifecyclePreview: {
          loading: true
        }
      }
    }
    case `${GET_LIFECYCLE_PREVIEW}_FAILED`: {
      return {
        ...state,
        lifecyclePreview: {
          loading: false
        }
      }
    }
    case `${GET_LIFECYCLE_PREVIEW}_RESPONSE`: {
      return {
        ...state,
        lifecyclePreview: {
          loading: false,
          isLoaded: true,
          previewData: action.payload
        }
      }
    }
    case `${UPDATE_IS_SCIM_ENABLED}_RESPONSE`: {
      const { isScimEnabled } = action.payload
      return {
        ...state,
        isScimEnabled
      }
    }
    case UPDATE_ORG_LOGO: {
      const meta = action.meta
      return {
        ...state,
        [meta.logoType]: meta.url
      }
    }
    case `${REMOVE_ORG_LOGO}_RESPONSE`: {
      const meta = action.meta
      return {
        ...state,
        [meta.logoType]: null
      }
    }
    case `${GET_USER_TYPE_SOURCES}_PENDING`: {
      return {
        ...state,
        loadingUserTypeSources: true
      }
    }
    case `${GET_USER_TYPE_SOURCES}_FAILED`: {
      return {
        ...state,
        loadingUserTypeSources: false
      }
    }
    case `${GET_USER_TYPE_SOURCES}_RESPONSE`: {
      const { userTypeSources, resources } = action.payload
      const userTypeSourcesApps = resources.apps

      return {
        ...state,
        loadingUserTypeSources: false,
        userTypeSources,
        userTypeSourcesApps
      }
    }
    case `${GET_MERGE_USERS_RULES}_PENDING`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: true
        }
      }
    }
    case `${GET_MERGE_USERS_RULES}_FAILED`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: false
        }
      }
    }
    case `${GET_MERGE_USERS_RULES}_RESPONSE`: {
      const { rules } = action.payload
      return {
        ...state,
        mergeUsersRules: {
          rules,
          loading: false
        }
      }
    }
    case `${CREATE_MERGE_USERS_RULES}_PENDING`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: true
        }
      }
    }
    case `${CREATE_MERGE_USERS_RULES}_FAILED`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: false
        }
      }
    }
    case `${CREATE_MERGE_USERS_RULES}_RESPONSE`: {
      const { rule } = action.payload
      return {
        ...state,
        mergeUsersRules: {
          rules: [rule],
          loading: false
        }
      }
    }
    case `${UPDATE_MERGE_USERS_RULE}_PENDING`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: true
        }
      }
    }
    case `${UPDATE_MERGE_USERS_RULE}_FAILED`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: false
        }
      }
    }
    case `${UPDATE_MERGE_USERS_RULE}_RESPONSE`: {
      const { rule } = action.payload
      return {
        ...state,
        mergeUsersRules: {
          rules: [rule],
          loading: false
        }
      }
    }
    case `${DELETE_MERGE_USERS_RULE}_PENDING`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: true
        }
      }
    }
    case `${DELETE_MERGE_USERS_RULE}_FAILED`: {
      return {
        ...state,
        mergeUsersRules: {
          ...state.mergeUsersRules,
          loading: false
        }
      }
    }
    case `${DELETE_MERGE_USERS_RULE}_RESPONSE`: {
      return {
        ...state,
        mergeUsersRules: {
          rules: [],
          loading: false
        }
      }
    }
    case `${GET_SUBSCRIPTIONS}_PENDING`: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loading: true
        }
      }
    }
    case `${GET_SUBSCRIPTIONS}_RESPONSE`: {
      const { subscriptions } = action.payload

      return {
        ...state,
        subscriptions: {
          data: subscriptions,
          loading: false
        }
      }
    }
    default: {
      return state
    }
  }
}

export default orgReducer
