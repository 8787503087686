import React, { useEffect } from 'react'
import TabsPage from '@pages/tabs'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import AppCatalog from '@components/appCatalog'
import AppCatalogPoliciesTable from '@components/workflows/appCatalogPoliciesTable'
import AppCatalogHeader from '@root/components/appCatalogHeader'
import { getWorkflowsTriggersConfig, getCatalogSettings } from '@actions/'
import { FEATURES } from '@root/shared/features'
import AppCatalogRequestNewAppPoliciesTable from '@root/components/workflows/appCatalogRequestNewAppPoliciesTable'

const AppCatalogPage = () => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)

  useEffect(() => {
    dispatch(getWorkflowsTriggersConfig())
  }, [dispatch])

  useEffect(() => {
    if (idOrg) {
      dispatch(getCatalogSettings({ idOrg }))
    }
  }, [dispatch, idOrg])

  const tabsName = ['settings', 'accessPolicies', 'newAppPolicies']
  const tabsHeader = [
    { header: 'General Settings' },
    { header: 'Access Request Policies' },
    { header: 'New Application Request Policies' }
  ]
  const tabsContent = [
    <AppCatalog />,
    <AppCatalogPoliciesTable />,
    <AppCatalogRequestNewAppPoliciesTable />
  ]
  const tabsFeature = [
    FEATURES.APP_CATALOG.TABS.SETTINGS,
    FEATURES.APP_CATALOG.TABS.POLICIES,
    FEATURES.APP_CATALOG.TABS.POLICIES
  ]

  return (
    <TabsPage
      overrideHeaderStyle={{ padding: 0 }}
      pageHeader='App Catalog'
      pageTitle='App Catalog'
      pageName='App Catalog'
      path={`/team/${idOrg}/appCatalog`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      tabsFeature={tabsFeature}
      contentRight={<AppCatalogHeader />}
    />
  )
}

export default AppCatalogPage
