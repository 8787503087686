import View from './view'
import { connect } from 'react-redux'
import { searchUsersAndApps } from '@actions/'
import { withRouter } from 'react-router-dom'
import { getIsSmallScreen } from '@selectors/ui'
import accessControl from '@root/lenses/accessControl'
import { SCOPES } from '@root/constants'
import { getIsOnlyAppOwner } from '@selectors/me'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const isSmallScreen = getIsSmallScreen(state)
  const isOnlyAppOwner = getIsOnlyAppOwner(state)
  const canSearchUsers = !isOnlyAppOwner && accessControl.isAccessibleWithState({ scopes: [SCOPES.USERS_READ], state })

  return {
    idOrg,
    isSmallScreen,
    canSearchUsers
  }
}

const mapDispatchToProps = {
  searchUsersAndApps
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
