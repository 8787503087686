import styled from 'styled-components'

export const ContainerDiv = styled.div<{ overrideStyle, boxHeight }>`
  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.overrideStyle};
  height: ${props => props.boxHeight}px;
`
