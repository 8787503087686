import {
  GET_API_KEYS
} from '@root/constants'
import { Reducer } from 'redux'
import { ApiKeysActions, ApiKeysState } from '@reducers/apiKeys/types'

const initialState: ApiKeysState = {
  isLoaded: false,
  loading: false,
  apiKeys: [],
  resources: {
    users: {},
    apps: {}
  },
  isScimEnabled: false
}

const apiKeysReducer: Reducer<ApiKeysState, ApiKeysActions> = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_API_KEYS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_API_KEYS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_API_KEYS}_RESPONSE`: {
      const { apiKeys, config, resources } = action.payload
      return {
        ...state,
        loading: false,
        isLoaded: true,
        apiKeys,
        config,
        resources
      }
    }
    default: {
      return state
    }
  }
}

export default apiKeysReducer
