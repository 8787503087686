import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import { State } from '@reducers/types'

export const getApiKeys = createSelector<State, State['apiKeys']['apiKeys'], State['apiKeys']['apiKeys']>(
  (state: State) => get(state, ['apiKeys', 'apiKeys'], []),
  identity
)

export const getApiKeysConfig = createSelector<State, State['apiKeys']['config'], State['apiKeys']['config']>(
  (state: State) => get(state, ['apiKeys', 'config'], {}),
  identity
)

export const getApiKeysResources = createSelector<State, State['apiKeys']['resources'], State['apiKeys']['resources']>(
  (state: State) => get(state, ['apiKeys', 'resources'], {}),
  identity
)

export const isLoadingApiKeys = createSelector<State, State['apiKeys']['loading'], State['apiKeys']['loading']>(
  (state: State) => get(state, ['apiKeys', 'loading'], false),
  identity
)

export const isLoadedApiKeys = createSelector<State, State['apiKeys']['isLoaded'], State['apiKeys']['isLoaded']>(
  (state: State) => get(state, ['apiKeys', 'isLoaded'], false),
  identity
)
