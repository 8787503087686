import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { instantAccessLogin } from '@actions/'
import qs from 'query-string'
import { getLogin } from '@selectors/login'

const mapStateToProps = (state, ownProps) => {
  const query = qs.parse(window.location.search)
  const { redirectUrl } = query

  const { id, secret } = ownProps.match.params
  const token = `${id}-${secret}`

  const login = getLogin(state)
  const { loading, success: isLoggedIn } = login

  return {
    token,
    redirectUrl,
    loading,
    isLoggedIn
  }
}

const mapDispatchToProps = {
  instantAccessLogin
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
