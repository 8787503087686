import { EMPTY_ARRAY, scoreSpecialValues } from '../constants'
import { getAppUsersES, getAllAppUsersState } from '@selectors/appUsers'
import moment from 'moment'

export const findAppUsers = (state, idApp) => {
  const allAppUsers = getAppUsersES(state)
  return allAppUsers[idApp] || EMPTY_ARRAY
}

export const getAppUsersState = (state, idApp) => {
  const allAppUsersState = getAllAppUsersState(state)
  return allAppUsersState[idApp] || EMPTY_ARRAY
}

export const getUsageScore = (score = 0, lastVisitTime) => {
  if (score > 0) {
    return score
  }

  if (!lastVisitTime) {
    return scoreSpecialValues.notCollectingUsage
  }

  const isInLast30Days = moment().diff(moment(lastVisitTime), 'days') <= 30
  if (isInLast30Days) {
    return scoreSpecialValues.notCollectingUsage
  }

  return scoreSpecialValues.noUsageInLast30Days
}
