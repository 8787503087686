import View from './view'
import { connect } from 'react-redux'
import Analytics from '@helpers/analytics'
import { getTeamMembersES, isTeamMembersLoadingES, isTeamMembersLoadedES } from '@selectors/users'
import { getRoles, toggleInviteUser, updateUser, getTeamMembers } from '@actions/'
import { getSelf } from '@selectors/me'
import { getAdminRoleOptionsForSelect } from '@selectors/roles'
import sortBy from 'lodash/sortBy'

const mapStateToProps = (state) => {
  const adminRoleOptions = getAdminRoleOptionsForSelect(state)
  const teamMembers = getTeamMembersES(state)
  const users = sortBy(teamMembers ?? [], ['firstName', 'lastName', 'email'])
  const isLoaded = isTeamMembersLoadedES(state)
  const loading = !isLoaded || (isTeamMembersLoadingES(state) && !teamMembers.length)

  return {
    users,
    loading,
    me: getSelf(state),
    adminRoleOptions
  }
}

const mapDispatchToProps = {
  getTeamMembers,
  toggleInviteUser,
  updateUser,
  getRoles
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    trackRoleChange
  }
}

const trackRoleChange = (user, prevIdRole) => {
  const { idRole, email } = user
  Analytics.track('Change role', {
    'New role': idRole,
    'Previous role': prevIdRole,
    'User affected': email
  })
  return user
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
