import React from 'react'
import { Styles } from './style'
import { SCOPES } from '@root/constants'
import EnableFor from '../enableFor'
import { getLifecycleApps, getUserTypeSourcesApps } from '@selectors/org'
import { Props, UserTypeSourceApp } from './types'
import ButtonActions from '../_shared/buttonActions'
import { Button, Tooltip } from '@toriihq/design-system'
import { useParamSelector } from '@root/shared/utils'
import { useSelector } from 'react-redux'
import AppDetails from '../appDetails'
import { App } from '@components/selectAppGroupDivided/types'
import keyBy from 'lodash/keyBy'

export default function UserTypeSources ({ type, onAddSourceClick, onRemoveSource, onEditSource }: Props) {
  const userTypeSources: UserTypeSourceApp[] = useParamSelector(getUserTypeSourcesApps, { type })
  const apps: App[] = useSelector(getLifecycleApps)
  const appsByIds = keyBy(apps, 'idApp')

  const getUserTypeSource = (userTypeSource: UserTypeSourceApp) => {
    const { idApp, id: idSource, idOrg, isConnected } = userTypeSource
    const app = appsByIds[idApp]

    return app ? <Styles.SourceApp key={idSource}>
      <Styles.SourceAppDetails>
        <AppDetails
          id={app.idApp}
          name={app.name}
          category={app.category}
          imageUrl={app.imageUrl}
          component='User Type Source'
          overrideStyle={{ width: '190px' }}
        />
      </Styles.SourceAppDetails>
      <Tooltip label='Connect account at the Integrations page'>
        <Styles.AccountInfo>{isConnected ? '' : 'The account is not connected'}</Styles.AccountInfo>
      </Tooltip>
      <Styles.ActionButtons>
        <ButtonActions
          iconColor='secondary'
          showDeleteAction
          showEditAction
          onEditClick={() => onEditSource({ source: userTypeSource, idOrg, idApp })}
          onDeleteClick={() => onRemoveSource({ idSource, idOrg, idApp })}
          enableForScopes={[SCOPES.SETTINGS_WRITE]}
        />
      </Styles.ActionButtons>
    </Styles.SourceApp> : null
  }

  return <Styles.Wrapper>
    {!userTypeSources.length && <Styles.NoSourcesLabel>No sources defined.</Styles.NoSourcesLabel>}
    {Boolean(userTypeSources.length) && <Styles.SourceApps>
      {userTypeSources.map(userTypeSource =>
        getUserTypeSource(userTypeSource)
      )}
    </Styles.SourceApps>}
    <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
      <Button onClick={() => onAddSourceClick()} label={'Add source'} />
    </EnableFor>
  </Styles.Wrapper>
}
