import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { TransactionMatchingRulesComponent } from '@components/transactionMatchingRules/transactionMatchingRulesComponent'
import { toggleMatchingRulesPopup, updateOrgMatchingRules, updateOrgMatchingRulesLocally } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalMatchingRules } from '@selectors/transactions'
import { getCurrentOrg } from '@selectors/org'
import { TRANSACTION_MAPPING_STATUS } from '@root/constants'
import analytics from '@root/helpers/analytics'

const TransactionMatchingRulesPopup = ({ cancel, isOpen }) => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const rules = useSelector(getLocalMatchingRules)
  const dispatch = useDispatch()

  const isRulesValid = useMemo(() => {
    if (!rules) {
      return false
    }
    const validateRule = (rule) => {
      return Boolean(rule.op && rule.term && rule.mappingStatus && (rule.mappingStatus === TRANSACTION_MAPPING_STATUS.IGNORED || rule.app))
    }

    return (rules.map(validateRule)).every(value => value === true)
  }, [rules])

  const onChange = async ({ rules, isLocally = true }) => {
    if (isLocally) {
      dispatch(await updateOrgMatchingRulesLocally({ rules }))
    } else {
      analytics.track('Edit expense-matching-rules', { 'Dialog name': 'Configure Expense Rules', 'Button Label': 'Apply rules' })
      dispatch(await updateOrgMatchingRules({ rules, idOrg }))
    }
  }

  const toggleMatchTransactionsPopup = (isOpen, isByUser = true) => {
    dispatch(toggleMatchingRulesPopup({ isOpen, isByUser, rules }))
  }

  const applyChange = async () => {
    await onChange({ rules, isLocally: false }).then(() => {
      toggleMatchTransactionsPopup(false, false)
    })
  }

  return (
    <ToriiPopup width='80%' isOpen={isOpen} onCloseAction={cancel}>
      <ToriiPopup.Header
        header='Configure Expense Rules'
        subHeader={
          <span>
            Define custom rules for matching transactions coming from Netsuite, Expensify, expense reports and others.
            <br />
            Each rule will apply one by one in the order you define.
          </span>} />
      <ToriiPopup.Content>
        <TransactionMatchingRulesComponent />
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonText='Cancel'
        mainButtonText='Apply rules'
        mainButtonAction={applyChange}
        isMainButtonDisabled={!isRulesValid}
      />
    </ToriiPopup>
  )
}

TransactionMatchingRulesPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default TransactionMatchingRulesPopup
