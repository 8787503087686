import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import noop from 'lodash/noop'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { Tooltip, Icon } from '@toriihq/design-system'
import SourceIcon from '../sourceIcon'
import Table from '../table'
import AppDetails from '../appDetails'
import {
  TABLES,
  OFFBOARDING_APPS_STATUS,
  TORII_BOT_ID_USER,
  DATE_FORMAT
} from '../../constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import UsageIcon from '../usageIcon'
import { getDisplayName } from '@lenses/users'
import Analytics from '../../helpers/analytics'
import userToolTip from '../../shared/userToolTip'
import OffboardingUserMessage from './offboardingUserMessage'
import uniq from 'lodash/uniq'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { getErrorMessageByType } from '@components/offboarding/errorMessage/view'
import NotCollecting from '@media/not_collecting.svg'
import { getActionFromExecutions } from '@root/lenses/offboarding'
import * as Style from './style'
import Currency from '../currency'
import Confirmation from '@components/confirmation'
import { getFormattedDate } from '@lenses/utils'
import UserAppsActionMenu from '@components/userApps/userAppsActionMenu'

const POLL_INTERVAL_IN_SECONDS = 5
const STOP_POLL_AFTER_IN_SECONDS = 30
const START_POLL_AFTER_IN_SECONDS = 6

const CSS = {
  confirmationText: css(texts.body, {
    marginTop: '6px'
  }),
  alignedFieldToLeft: css({
    textAlign: 'left',
    display: 'flex',
    paddingLeft: '14px !important'
  }),
  actions: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }),
  menu: css({
    display: 'flex',
    flexDirection: 'row-reverse !important'
  }),
  icon: css({
    position: 'relative',
    top: '-1px',
    fontSize: '16px'
  }),
  success: css({
    color: colors.blue
  }),
  linkButton: css({
    backgroundColor: 'transparent'
  })
}

const getAppStatus = ({ isOffboardingIgnored, isUserRemovedFromApp, status, executions = [] }) => {
  if (isOffboardingIgnored || isUserRemovedFromApp) {
    return <Tooltip
      placement='top'
      label={OFFBAORDING_STATUSES['Done'].label}
    >
      <Icon name='CheckCircleFill' color='success' />
    </Tooltip>
  } else if (status === OFFBOARDING_APPS_STATUS.needsAttention || executions.length === 0) {
    return <Tooltip
      placement='top'
      label={OFFBAORDING_STATUSES['Needs Attention'].label}
    >
      <Icon name='CircleAlertFilled' color='error' />
    </Tooltip>
  } else {
    return <Tooltip
      placement='top'
      label={OFFBAORDING_STATUSES['In Progress'].label}
    >
      <Icon name='CircleSyncFilled' color='warning' />
    </Tooltip>
  }
}

const OFFBAORDING_STATUSES = {
  'Needs Attention': { label: 'Needs Attention', value: 2 },
  'In Progress': { label: 'In Progress', value: 1 },
  'Done': { label: 'Done', value: 0 }
}

const getAppStatusInText = ({ isOffboardingIgnored, isUserRemovedFromApp, status, executions = [] }) => {
  if (isOffboardingIgnored || isUserRemovedFromApp) {
    return OFFBAORDING_STATUSES['Done'].label
  } else if (status === OFFBOARDING_APPS_STATUS.needsAttention || executions.length === 0) {
    return OFFBAORDING_STATUSES['Needs Attention'].label
  } else {
    return OFFBAORDING_STATUSES['In Progress'].label
  }
}

class UserApps extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showPopupWhenAllAppsAreRemoved: false,
      columns: this.getColumns(),
      polling: false
    }
  }

  onConfigureAppClick = ({ idApp, idAppAccount }) => {
    const { toggleConfigureAppForOffboarding, offboardingUserStatus, getOffboardingStatusOfUser, idOrg, idUser } = this.props
    const app = offboardingUserStatus.apps.find(app => app.id === idApp && app.idAppAccount === idAppAccount)

    toggleConfigureAppForOffboarding({
      isOpen: true,
      idApp,
      idAppAccount,
      actions: app.actions,
      onConfig: () => {
        setTimeout(() => getOffboardingStatusOfUser({ idOrg, idUser }), 8000)
        this.setState({ polling: true })
      }
    })
  }

  onViewCustomActionResponse = (action) => {
    const { toggleCustomActionResponsePopup } = this.props

    toggleCustomActionResponsePopup({
      isOpen: true,
      action
    })
  }

  getHeader = () => {
    const { appsWithTotalExpenses, isOffboarding } = this.props

    if (this.props.isActive) {
      return `APPLICATIONS (${appsWithTotalExpenses.length})`
    } else if (isOffboarding) {
      return <div>OFFBOARDING STATUS</div>
    }

    return ''
  }

  getOffboardingUserMessageText = ({ isOffboarded, isOffboardingIgnored, isUserRemovedFromApp, appRemovedBy, removedTime, ignoredTime, status, executionErrorType, name, executions = [] }) => {
    const { idOrg } = this.props
    const removedByUser = (idUser) => {
      if (idUser === TORII_BOT_ID_USER) {
        return 'Torii'
      }
      const user = this.props.usersById[appRemovedBy]
      if (user) {
        return getDisplayName(user)
      }
    }

    const appRemovedByUser = removedByUser(appRemovedBy)
    const action = getActionFromExecutions(executions)
    if (isOffboardingIgnored) {
      return `Offboarding skipped. The user was removed only from the app’s user list in Torii on ${moment.utc(ignoredTime).format(DATE_FORMAT)}`
    } else if (isUserRemovedFromApp) {
      if (action?.details?.Note) {
        return action.details.Note
      }

      return appRemovedByUser ? `Marked as completed by ${appRemovedByUser} on ${moment.utc(removedTime).format(DATE_FORMAT)} ` : 'Marked as completed'
    } else if (isOffboarded) {
      return 'Not completed'
    } else if (status === OFFBOARDING_APPS_STATUS.needsAttention && !executionErrorType) {
      return 'Offboarding method is not configured.'
    } else if (executionErrorType) {
      return getErrorMessageByType({ errorType: executionErrorType, appName: name, idOrg })
    } else if (executions.length === 0) {
      return 'Sending request to offboard was skipped'
    }

    return 'In progress'
  }

  setColumnsState = () => {
    this.setState({ ...this.state, columns: this.getColumns() })
  }

  getColumns = () => {
    const { user, isOffboarding, isOffboarded, hideLicenses, isActive, columnsRestrictions } = this.props
    return [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value: name, row: { idApp, category, imageUrl, isNew, isOwner, isPrimaryOwner, isUserRemovedFromApp, isOffboardingIgnored } }) => (
          <AppDetails
            id={idApp}
            name={name}
            category={category}
            imageUrl={imageUrl}
            isNew={isNew}
            badge={isPrimaryOwner ? primaryOwnerBadge : isOwner ? ownerBadge : null}
            component='User Apps list'
            overrideStyle={(isOffboarding && (isUserRemovedFromApp || isOffboardingIgnored)) && removedAppStyle}
          />
        ),
        minWidth: 220
      },
      {
        Header: 'Email',
        id: 'originEmail',
        accessor: 'originEmail'
      },
      {
        Header: 'Account',
        accessor: 'appAccountName',
        Cell: ({ row: { appAccountName } }) => appAccountName || '-'
      },
      {
        Header: 'Licenses',
        accessor: 'licenses',
        style: { display: 'flex', alignItems: 'center' },
        Cell: ({ row: { licenses = [] } }) => {
          if (!licenses.length) {
            return <img style={{ marginLeft: '2px' }} alt='No license' src={NotCollecting} />
          }
          return <Tooltip
            placement='top'
            label={licenses.map(license => <div key={license.type}>{license.name}</div>)}>
            <Icon name='CheckCircleFill' color='interactive' />
          </Tooltip>
        },
        textValue: ({ licenses }) => {
          return licenses && licenses.map(license => license.name).join(', ')
        },
        showFunc: () => !hideLicenses && isActive
      },
      {
        Header: 'Annual cost',
        id: 'annualCostConverted',
        accessor: 'annualCostConverted',
        Cell: ({ value }) => {
          return isNil(value) ? '-' : <div style={{ display: 'flex' }}> <Currency value={value} /> </div>
        },
        textValue: ({ value }) => isNil(value) ? '' : value / 100,
        sortMethod: Table.sortMethods.currency,
        ...Table.numericFieldProps,
        showFunc: () => (isActive && columnsRestrictions.hasExpensesAccess)
      },
      {
        Header: 'Last used date',
        accessor: 'lastVisitTime',
        show: !isOffboarding && !isOffboarded,
        Cell: ({ row: { lastVisitTime } }) => getFormattedDate({ date: lastVisitTime }) || '-',
        hideFromCSV: () => !isOffboarding && !isOffboarded
      },
      {
        textHeader: 'Usage',
        Header: (
          <Tooltip
            label={<Style.UsageHeaderTooltipLabel>Usage is based on visits in the last 30 days</Style.UsageHeaderTooltipLabel>}
          >
            Usage
          </Tooltip>
        ),
        accessor: 'score',
        maxWidth: 150,
        showFunc: () => isActive,
        Cell: ({ value: score, row: { lastVisitTime } }) => <div style={{ display: 'flex' }}><UsageIcon isUserUsage score={score} lastVisitTime={lastVisitTime} /> </div>,
        textValue: ({ value }) => (value >= 0 ? value : '')
      },
      {
        Header: 'Sources',
        accessor: 'sources',
        textValue: ({ value: sources }) => uniq(sources).join(', '),
        showFunc: () => isActive,
        Cell: ({ row: { name: appName, sources = [] } }) => {
          return (
            <div style={{ display: 'flex' }}>
              {sources.length === 0 ? '-' : sources.map(source => (<SourceIcon key={source} sourceType={source} tooltipText={userToolTip({ displayName: getDisplayName({ firstName: user.firstName, lastName: user.lastName, email: user.email }), appName, source })} />)) || '-'}
            </div>
          )
        },
        maxWidth: 230
      },
      {
        Header: 'Status',
        id: 'appStatus',
        style: { display: 'flex', alignItems: 'center' },
        accessor: ({ isOffboardingIgnored, isUserRemovedFromApp, status, executions }) => getAppStatusInText({ isOffboardingIgnored, isUserRemovedFromApp, status, executions }),
        sortable: true,
        showFunc: () => isOffboarding,
        Cell: ({ row: { isOffboardingIgnored, isUserRemovedFromApp, status, executions } }) => getAppStatus({ isOffboardingIgnored, isUserRemovedFromApp, status, executions }),
        sortMethod: (a, b) => {
          return OFFBAORDING_STATUSES[a].value - OFFBAORDING_STATUSES[b].value
        },
        hideFromCSV: () => !isOffboarding
      },
      {
        Header: 'Details',
        id: 'details',
        accessor: (row) => this.getOffboardingUserMessageText(row),
        sortable: true,
        showFunc: () => isOffboarding || isOffboarded,
        Cell: ({ row }) => {
          return <OffboardingUserMessage
            {...row}
            isOffboarded={isOffboarded}
            onConfigureAppClick={() => this.onConfigureAppClick({ idApp: row.idApp, idAppAccount: row.idAppAccount })}
            onViewCustomActionResponse={this.onViewCustomActionResponse}
          />
        },
        textValue: (row) => {
          return this.getOffboardingUserMessageText(row)
        },
        minWidth: 500,
        hideFromCSV: () => !isOffboarded
      },
      {
        id: 'action',
        Header: '',
        accessor: '',
        sortable: false,
        showFunc: () => isOffboarding,
        Cell: ({ row: { _original: { idUser, originEmail }, idApp, idAppAccount, isOffboardingIgnored, isUserRemovedFromApp, status, executionErrorType, appRemovedBy, executions } }) => {
          const props = { idUser, originEmail, idApp, idAppAccount, isOffboardingIgnored, isUserRemovedFromApp, status, executionErrorType, appRemovedBy, executions, onConfigureAppClick: this.onConfigureAppClick, changeRemovalStatus: this.changeRemovalStatus }
          return <UserAppsActionMenu {...props} />
        },
        hideFromCSV: true
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'isNew',
        show: false
      },
      {
        accessor: 'isCore',
        show: false
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'idApp',
        show: false
      },
      {
        accessor: 'isOwner',
        show: false
      },
      {
        accessor: 'isPrimaryOwner',
        show: false
      },
      {
        accessor: 'appRemovalAuditLog',
        show: false
      },
      {
        accessor: 'isOwnerNotifiedToRemoveUser',
        show: false
      },
      {
        accessor: 'isUserRemovedFromApp',
        show: false
      },
      {
        accessor: 'isMeOwner',
        show: false
      },
      {
        accessor: 'isOwnerDeletedInIdentitySources',
        show: false
      },
      {
        accessor: 'hasOwner',
        show: false
      },
      {
        accessor: 'isSendEmailDisabled',
        show: false
      },
      {
        accessor: 'reason',
        show: false
      },
      {
        accessor: 'externalStatus',
        show: false
      },
      {
        accessor: 'isOffboardingIgnored',
        show: false
      },
      {
        accessor: 'status',
        show: false
      },
      {
        accessor: 'executions',
        show: false
      },
      {
        accessor: 'executionErrorType',
        show: false
      },
      {
        accessor: 'appRemovedBy',
        show: false
      },
      {
        accessor: 'removedTime',
        show: false
      },
      {
        accessor: 'ignoredTime',
        show: false
      },
      {
        accessor: 'idAppAccount',
        show: false
      },
      {
        accessor: 'isExecuteWorkflow',
        show: false
      }
    ]
  }

  getIsUserOffboardingRunning = ({ user, apps }) => {
    const isOffboarding = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
    const isExecutionFailed = ({ status, executionErrorType }) => status === OFFBOARDING_APPS_STATUS.needsAttention && !!executionErrorType
    const isDone = ({ isOffboardingIgnored, isUserRemovedFromApp }) => isOffboardingIgnored || isUserRemovedFromApp
    const numberOfAppsLikelyInOffboardingProcess = apps.filter(app => !(isDone(app) || isExecutionFailed(app))).length

    return isOffboarding && numberOfAppsLikelyInOffboardingProcess > 0
  }

  componentDidMount () {
    this.fetchData()
    setTimeout(() => {
      this.setState({ polling: true })
    }, START_POLL_AFTER_IN_SECONDS * 1000)
  }

  componentDidUpdate (prevProps) {
    const { appsWithTotalExpenses, user, idUser, idOrg, hideLicenses, loading } = this.props
    if (prevProps.idUser !== idUser || prevProps.idOrg !== idOrg) {
      this.fetchData()
    }
    if ((prevProps.hideLicenses !== hideLicenses) || (prevProps.loading !== loading)) {
      this.setColumnsState()
    }

    const isOffboaridngInProgress = this.getIsUserOffboardingRunning({ user, apps: appsWithTotalExpenses })
    this.updateOffboardingPolling(isOffboaridngInProgress)
  }

  componentWillUnmount () {
    this.clearUserOffboardingInterval()
  }

  fetchData () {
    const { idOrg, idUser, getOffboardingStatusOfUser } = this.props
    getOffboardingStatusOfUser({ idOrg, idUser })
  }

  searchFilterMethod (row, search) {
    const values = [
      row.name,
      row.originEmail
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  getConfirmationText () {
    const { user } = this.props
    const displayName = getDisplayName({ ...user, emptyValue: '' })

    return <div {...CSS.confirmationText}>All apps were handled for <strong>{displayName}</strong>, user's status is now <strong>offboarded</strong>.</div>
  }

  onDecline = async () => {
    const { user } = this.props
    Analytics.track('Clicked Not now in Finish offboarding popup', {
      'Offboarded user name': getDisplayName(user),
      'Offboarded user id': user.id
    })

    this.setState({ showPopupWhenAllAppsAreRemoved: false })
  }

  finishOffboarding = async () => window.location.reload()

  changeRemovalStatus = async ({ idApp, isRemoved, showPopupWhenAllAppsAreRemoved = true, idTask, idActionExe, idAppAccount, idTicket, idUser, originEmail }) => {
    const { onChangeRemovalStatus } = this.props
    await onChangeRemovalStatus({ idApp, isRemoved, idTask, idActionExe, idAppAccount, idTicket, idUser, originEmail })
    this.setState({ showPopupWhenAllAppsAreRemoved })
  }

  shouldShowConfirmation () {
    const { showPopupWhenAllAppsAreRemoved } = this.state
    const { isOffboarding, appsWithTotalExpenses } = this.props
    const isAllAppsRemoved = appsWithTotalExpenses.every(app => app.isUserRemovedFromApp || app.isOffboardingIgnored)

    return appsWithTotalExpenses.length > 0 && isAllAppsRemoved && isOffboarding && showPopupWhenAllAppsAreRemoved
  }

  updateOffboardingPolling (isOffboardingRunning) {
    const { polling } = this.state
    if (isOffboardingRunning && polling) {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => this.fetchData(), POLL_INTERVAL_IN_SECONDS * 1000)
        setTimeout(() => {
          this.clearUserOffboardingInterval()
          this.setState({ polling: false })
        }, STOP_POLL_AFTER_IN_SECONDS * 1000)
      }
    } else {
      this.clearUserOffboardingInterval()
    }
  }

  clearUserOffboardingInterval = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  render () {
    const { appsWithTotalExpenses, loading, user, exportPrefix } = this.props
    const { polling } = this.state

    const isOffboarding = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
    const isOffboarded = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDED

    return (
      <Fragment>
        <Table
          tableKey={TABLES.userAppsTable.key}
          noDataText={noApps}
          exportable
          exportPrefix={exportPrefix}
          data={appsWithTotalExpenses}
          columns={this.state.columns}
          header={this.getHeader}
          loading={loading && !polling}
          searchable={!isOffboarded}
          searchFilterMethod={this.searchFilterMethod}
          {...(isOffboarding ? { defaultSorted: [{ id: 'appStatus', desc: true }] } : {})}
        />

        <Confirmation
          isOpen={this.shouldShowConfirmation()}
          header='Offboarding finished'
          text={this.getConfirmationText()}
          confirmText='Ok'
          confirm={this.finishOffboarding}
          close={noop}
        />

      </Fragment>
    )
  }
}

UserApps.propTypes = {
  user: PropTypes.object.isRequired,
  idOrg: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    score: PropTypes.number,
    sources: PropTypes.arrayOf(PropTypes.string),
    isNew: PropTypes.bool,
    category: PropTypes.string,
    isOwner: PropTypes.bool,
    isPrimaryOwner: PropTypes.bool,
    isUserRemovedFromApp: PropTypes.bool,
    isOffboardingIgnored: PropTypes.bool,
    isOwnerNotifiedToRemoveUser: PropTypes.bool,
    auditLog: PropTypes.shape({
      date: PropTypes.string,
      performedBy: PropTypes.string
    })
  }))
}

const noApps = (
  <div {...texts.heading}>No applications</div>
)

const ownerBadge = { text: 'Owner', color: 'blue' }
export const primaryOwnerBadge = { text: 'Primary owner', color: 'gray' }

const removedAppStyle = { opacity: 0.5 }

export default UserApps
