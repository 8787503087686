import styled from 'styled-components'

export const Subtitle = styled.span`
  font: ${({ theme }) => theme.typography.font.subtitle02};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const CenteredSubtitle = styled(Subtitle)`
  text-align: center;
`

export const Text = styled.span`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.tertiary};
`
