import React from 'react'
import PropTypes from 'prop-types'
import { WORKFLOW_ACTION_TYPES, WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import { getDisplayName } from '@lenses/users'
import { getUsersSpecialOptionsByTriggerType, getWorkflowFieldValueByActionStepAndFieldId } from '@lenses/workflows'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import Placeholder from '../../../placeholder'
import { Icon, Tooltip } from '@toriihq/design-system'
import { css } from 'glamor'
import { SEND_REQUEST_METHOD } from '@root/constants.t'

const CSS = {
  line: css({
    display: 'flex',
    gap: '4px',
    alignItems: 'center'
  })
}

const usersPlaceholderCSS = {
  width: 100,
  maxWidth: 'unset'
}

class ConfiguredMessage extends React.Component {
  getIgnoreReasonTooltip (action) {
    const reason = getWorkflowFieldValueByActionStepAndFieldId([action], { actionType: WORKFLOW_ACTION_TYPES.IGNORE, fieldId: 'reason' })
    return reason && <Tooltip
      label={`Reason: ${reason}`}>
      <Icon name='Info' />
    </Tooltip>
  }

  getCustomActionTooltip (action) {
    const method = getWorkflowFieldValueByActionStepAndFieldId([action], { actionType: action.type, fieldId: 'method' }).value
    const url = getWorkflowFieldValueByActionStepAndFieldId([action], { actionType: action.type, fieldId: 'url' })
    return <Tooltip
      label={`A ${method} request will be sent to ${url}`}>
      <Icon name='Info' />
    </Tooltip>
  }

  getSendRequestMethodMessage (action) {
    const defaultMessage = 'A request will be sent to'
    const { fields } = action
    const sendRequestMethodField = fields?.find(field => field.id === 'sendRequestMethod')
    const sendRequestMethod = sendRequestMethodField?.value?.value
    if (!sendRequestMethod) {
      console.error('sendRequestMethod is not defined')
      return defaultMessage
    }

    if (sendRequestMethod === SEND_REQUEST_METHOD.EMAIL) {
      return 'An email request will be sent to'
    } else if (sendRequestMethod === SEND_REQUEST_METHOD.SLACK) {
      return 'A Slack request will be sent to'
    }

    return defaultMessage
  }

  render () {
    const { actions, usersById, triggerByType, actionsByType, usersLoading, specialUsersOfActions } = this.props
    const specialUsersByTrigger = getUsersSpecialOptionsByTriggerType(triggerByType, WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING) || []
    const specialUsers = specialUsersOfActions.concat(specialUsersByTrigger)
    const specialUsersByKey = keyBy(specialUsers, 'value')

    const action = actions[0]

    const actionConfig = actionsByType[action.type] || {}
    const label = get(actionConfig, ['uiConfig', 'label'])
    switch (action.type) {
      case WORKFLOW_ACTION_TYPES.IGNORE:
        return <div {...CSS.line}><span>Will be ignored</span>{this.getIgnoreReasonTooltip(action)}</div>
      case WORKFLOW_ACTION_TYPES.REMOVE_USER:
      case WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK:
        const message = this.getSendRequestMethodMessage(action)
        return <Placeholder loading={usersLoading} style={usersPlaceholderCSS}><span> {message} <strong>{getWorkflowFieldValueByActionStepAndFieldId(actions, { actionType: action.type, fieldId: 'to' }).map(id => {
          return isNaN(id) || specialUsersByKey[id] ? (specialUsersByKey[id] || {}).label : getDisplayName(usersById[id] || {})
        }).join(', ')}</strong></span></Placeholder>
      case WORKFLOW_ACTION_TYPES.SEND_HTTP_REQUEST:
        const description = getWorkflowFieldValueByActionStepAndFieldId([action], { actionType: action.type, fieldId: 'requestDescription' })
        return <div {...CSS.line}><span>Automatic method: <strong>{description || label}</strong></span>{this.getCustomActionTooltip(action)}</div>
      case WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE:
        const project = getWorkflowFieldValueByActionStepAndFieldId([action], { actionType: action.type, fieldId: 'project' })
        const inProjectName = get(project, ['label']) ? <span>in <strong>{get(project, ['label'])}</strong></span> : null
        return <div><span>A Jira issue will be created {inProjectName}</span></div>
      default:
        return <span>Automatic method: <strong>{label}</strong></span>
    }
  }
}

ConfiguredMessage.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ConfiguredMessage
