import {
  SCOPES,
  SEARCH_USERS,
  SEARCH_USERS_EMAIL
} from '@root/constants'
import send from '@shared/redux-fetch'
import { isParentUserFilter } from '@actions/actions'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const searchUsersByEmail = ({ idOrg, email, limit, searchByCanonicalEmail }) => {
  return send(SEARCH_USERS_EMAIL, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      email,
      limit,
      searchByCanonicalEmail
    }, isUndefined)
  })
}
export const searchUsers = ({ idOrg, idUsers, q, limit, isExternal, excludePastUsers, sort, includeChildren = false }) => {
  return send(SEARCH_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      q,
      limit,
      isExternal,
      idUsers: idUsers ? JSON.stringify([].concat(idUsers)) : undefined,
      sort,
      isDeletedInIdentitySources: excludePastUsers ? !excludePastUsers : undefined,
      sortBySearchEngineScore: true,
      filters: !includeChildren ? JSON.stringify([isParentUserFilter]) : undefined,
      qFields: ['fullName', 'email', 'additionalEmails']
    }, isUndefined),
    scopes: [SCOPES.MEMBERS_AND_ROLES_READ, SCOPES.APP_OWNER_READ]
  })
}
