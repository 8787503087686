import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getAppDetailsByIdApp } from '@lenses/apps'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import { EMPTY_OBJECT } from '../../constants'
import { deleteAppDetailsFieldValue } from '@actions/'

const mapStateToProps = (state, ownProps) => {
  const { idApp, loading } = ownProps
  const { name } = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
  const fieldsDescription = {
    uploadContract: {
      description: 'Upload PDF, Word, Excel or image files',
      header: 'documents'
    },
    uploadInvoice: {
      description: `Upload any invoice you get from ${name}`,
      header: 'invoices'
    }
  }
  const { id: idOrg } = getCurrentOrg(state)
  const details = getAppDetailsByIdApp(state, idApp)
    .filter(detail => detail.category === 'app_details' && detail.type === 'fileUpload')
    .map(detail => ({
      ...detail,
      ...(fieldsDescription[detail.systemKey] || {})
    }))
  const loadingDetails = [{ idField: 1, systemKey: 1 }, { idField: 2, systemKey: 2 }]
  const loadingAppDetails = loading && details.length === 0
  const readonly = false

  return {
    readonly,
    details: loadingAppDetails ? loadingDetails : details,
    idOrg,
    idApp,
    loading: loadingAppDetails
  }
}

const mapDispatchToProps = {
  deleteAppDetailsFieldValue
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
